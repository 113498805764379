import { getLegalAgreementVerificationStatusSchema } from "@brm/schema-helpers/legal.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import type { ToolMinimal } from "@brm/schema-types/types.js"
import { WorkflowSoftwareRenewalKindSchema } from "@brm/schemas"
import { agreementRouteById } from "@brm/util/routes.js"
import { useDisclosure } from "@chakra-ui/react"
import { useLocation } from "react-router-dom"
import { useGetUserV1WhoamiQuery, useGetWorkflowV1DefinitionsQuery } from "../../app/services/generated-api.js"
import { LegalAgreementCardLayout } from "./LegalAgreementCardLayout.js"
import type { LegalAgreementOption } from "./LegalAgreementPicker.js"

interface Props {
  legalAgreement: LegalAgreementOption
  isPrimary?: boolean
  hideRenewalOption?: boolean
  isOwner?: boolean
  // Is duplicative subscription agreement, deprecate once we have tasks/inbox
  isDuplicativeSubscription?: boolean
  tool?: ToolMinimal
}

export const LegalAgreementCard = ({
  legalAgreement,
  hideRenewalOption,
  isDuplicativeSubscription: isDuplicate,
  isPrimary,
  isOwner,
  tool,
}: Props) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  // Fetch renewal workflow definition to determine if user can start a renewal from the table actions menu
  const { data: workflowDefinitions } = useGetWorkflowV1DefinitionsQuery()
  const location = useLocation()
  const startWorkflowDisclosure = useDisclosure()

  const verificationStatusSchema = getLegalAgreementVerificationStatusSchema(legalAgreement?.verification_status)
  const userCanUpdate = isOwner || hasPermission(whoami?.roles, "legal:update")
  const missingRenewalDefinition = !workflowDefinitions?.some(
    (def) => def.kind === WorkflowSoftwareRenewalKindSchema.const
  )
  const canRenewAgreement = !missingRenewalDefinition && !hideRenewalOption && legalAgreement.renewal_successor === null

  return (
    <LegalAgreementCardLayout
      legalAgreement={legalAgreement}
      verificationBadge={verificationStatusSchema}
      isDuplicate={isDuplicate}
      isPrimary={isPrimary}
      isEnterprise={legalAgreement.agreement_type === "enterprise"}
      tool={tool}
      userCanUpdate={userCanUpdate}
      to={agreementRouteById(legalAgreement.id)}
      showTCV={userCanUpdate}
      canRenewAgreement={canRenewAgreement}
      renewalSuccessorId={legalAgreement.renewal_successor?.id}
      onStartRenewal={startWorkflowDisclosure.onOpen}
      isStartWorkflowModalOpen={startWorkflowDisclosure.isOpen}
      onStartWorkflowModalClose={startWorkflowDisclosure.onClose}
      prevLocation={location}
    />
  )
}
