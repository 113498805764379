import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import type { LegalAgreementMinimal } from "../../app/services/generated-api.js"
import { LegalAgreementCardLayout } from "../../features/legal/LegalAgreementCardLayout.js"

interface AgreementSelectionModalProps {
  isOpen: boolean
  onClose: () => void
  agreements: LegalAgreementMinimal[]
  onSubmit: (agreementId: string | null) => void
  initialSelectedAgreement: string | null
  showNoAgreementOption: boolean
}

const AgreementSelectionModal: React.FC<AgreementSelectionModalProps> = ({
  isOpen,
  onClose,
  agreements,
  onSubmit,
  initialSelectedAgreement,
  showNoAgreementOption,
}) => {
  const [selectedAgreementId, setSelectedAgreementId] = useState<string | null>(initialSelectedAgreement)
  const borderColor = useColorModeValue("gray.200", "gray.600")

  const handleCardClick = (agreementId: string | null) => {
    setSelectedAgreementId(agreementId)
  }

  const handleSubmit = () => {
    onSubmit(selectedAgreementId)
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setSelectedAgreementId(initialSelectedAgreement)
    }
  }, [initialSelectedAgreement, isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="agreementSelection.title"
            defaultMessage="Select Agreement"
            description="Title of the agreement selection modal"
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {agreements.length === 0 ? (
            <Box p={4} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
              <Text align="center">
                <FormattedMessage
                  id="agreementSelection.noAgreements"
                  defaultMessage="No active agreements found"
                  description="Message shown when no agreements are available"
                />
              </Text>
            </Box>
          ) : (
            <VStack spacing={3} align="stretch">
              {agreements.map((agreement) => (
                <Box key={agreement.id} onClick={() => handleCardClick(agreement.id)}>
                  <LegalAgreementCardLayout
                    legalAgreement={agreement}
                    userCanUpdate={false}
                    showTCV={true}
                    canRenewAgreement={false}
                    onStartWorkflowModalClose={() => {}}
                    hoverable={true}
                    selected={selectedAgreementId === agreement.id}
                  />
                </Box>
              ))}
              {showNoAgreementOption && (
                <Box
                  onClick={() => handleCardClick(null)}
                  cursor="pointer"
                  p={6}
                  borderRadius="xl"
                  borderWidth="2px"
                  borderColor={selectedAgreementId === null ? "green.500" : borderColor}
                  _hover={{
                    borderColor: selectedAgreementId === null ? "green.500" : "green.300",
                  }}
                >
                  <Text fontSize="lg" fontWeight="medium">
                    <FormattedMessage
                      id="agreementSelection.noAgreement"
                      defaultMessage="No Agreement"
                      description="Option to remove agreement association"
                    />
                  </Text>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    <FormattedMessage
                      id="agreementSelection.noAgreement.description"
                      defaultMessage="Remove agreement association from this negotiation"
                      description="Description for the no agreement option"
                    />
                  </Text>
                </Box>
              )}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            <FormattedMessage
              id="agreementSelection.cancel"
              defaultMessage="Cancel"
              description="Label for the cancel button"
            />
          </Button>
          <Button
            colorScheme="brand"
            onClick={handleSubmit}
            isDisabled={selectedAgreementId === initialSelectedAgreement}
          >
            <FormattedMessage
              id="agreementSelection.select"
              defaultMessage="Select"
              description="Label for the select button"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AgreementSelectionModal
