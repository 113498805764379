import { NegotiationStatusSchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import {
  Badge,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Tooltip,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { usePutNegotiationV1ByIdStatusMutation, type NegotiationStatus } from "../../app/services/generated-api.js"
import { SHORT_TOOLTIP_OPEN_DELAY } from "../../util/constant.js"
import { CheckIcon } from "../icons/icons.js"

export default function NegotiationStatusBadge({
  status,
  negotiationId,
}: {
  status: NegotiationStatus
  negotiationId: string
}) {
  const intl = useIntl()
  const toast = useToast()
  const [updateNegotiationStatus] = usePutNegotiationV1ByIdStatusMutation()
  const selectedItemRef = useRef<HTMLButtonElement>(null)
  const schema = NegotiationStatusSchema.anyOf.find((s) => s.const === status)

  return (
    <Menu isLazy initialFocusRef={selectedItemRef}>
      <Tooltip
        label={
          <FormattedMessage
            id="negotiation.header.status"
            description="Tooltip explaining the status of the negotiation"
            defaultMessage="Change Status"
          />
        }
        openDelay={SHORT_TOOLTIP_OPEN_DELAY}
      >
        <MenuButton sx={{ "> span": { display: "flex" } }}>
          <Badge variant="clickable" colorScheme={schema?.colorScheme ?? "gray"}>
            {getTitle(status, schema)}
          </Badge>
        </MenuButton>
      </Tooltip>
      <Portal>
        <MenuList minW="12.125rem">
          <MenuOptionGroup defaultValue={status} fontWeight="medium" fontSize="sm" type="radio">
            {NegotiationStatusSchema.anyOf.map((enumSchema) => (
              <MenuItemOption
                {...(enumSchema.const === status ? { ref: selectedItemRef } : {})}
                key={enumSchema.const}
                value={enumSchema.const}
                icon={null}
                onClick={async () => {
                  try {
                    await updateNegotiationStatus({
                      id: negotiationId,
                      body: { status: enumSchema.const },
                    }).unwrap()
                  } catch (_) {
                    toast({
                      status: "error",
                      description: intl.formatMessage({
                        id: "status.change.error.title",
                        defaultMessage: "Error changing status",
                        description: "Title of the toast that appears when a status change fails",
                      }),
                    })
                  }
                  toast({
                    status: "success",
                    description: intl.formatMessage({
                      id: "status.change.success.title",
                      defaultMessage: "Status updated",
                      description: "Title of the toast that appears when a status is changed",
                    }),
                  })
                }}
              >
                <HStack justifyContent="space-between">
                  <Badge variant="clickable" colorScheme={enumSchema?.colorScheme ?? "gray"}>
                    {getTitle(enumSchema.const, enumSchema)}
                  </Badge>
                  {enumSchema.const === status && <Icon as={CheckIcon} />}
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}
