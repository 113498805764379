// To make sure local storage keys are unique and don't conflict with other keys we map them to this enum
export enum LocalStorageKeys {
  RENEWAL_CALENDAR_VIEW_MODE = "renewal_calendar_view_mode",
  RENEWAL_CALENDAR_DATE_TYPE = "renewal_calendar_date_type",
  MENTIONED_FILTER_STORAGE_KEY = "inbox_filter-mentioned",
  UNREAD_FILTER_STORAGE_KEY = "inbox_filter-unread",
}

export const getLocalStorageKeyForUser = (key: LocalStorageKeys, userId: string) => {
  return `${key}_user:${userId}`
}
