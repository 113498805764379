import type { ImageElement } from "@brm/schema-types/types.js"
import type { CreateToastFnReturn } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"
import { Transforms } from "slate"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import type { CustomEditor } from "../types.js"

export const insertImage = (editor: CustomEditor, url: string, fileName: string) => {
  const text = { text: "" }
  const image: ImageElement = { type: "image", url, file_name: fileName, children: [text] }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  })
}

export const handleImageUploadError = (err: unknown, toast: CreateToastFnReturn, intl: IntlShape) => {
  toast({
    description:
      getAPIErrorMessage(err) ??
      intl.formatMessage({
        id: "richText.image.upload.error",
        description: "Error toast displayed when a user uploads an image to a rich text input and an error occurs",
        defaultMessage: "Something went wrong while uploading your image",
      }),
    status: "error",
    duration: LONG_TOAST_DURATION,
  })
}
