import { isStandardObject } from "@brm/schema-helpers/rich-text/rich-text.js"
import { buildRichTextFromEmail, getChatContextFromRichText } from "@brm/type-helpers/rich-text.js"
import { hasOwnProperty, isObject } from "@brm/util/type-guard.js"
import traverse from "neotraverse"
import type { ReactNode } from "react"
import { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import type { Descendant } from "slate"
import type {
  DocumentMinimal,
  EmailWithReplyDrafts,
  NegotiationPlaybookSectionKey,
  ObjectType,
} from "../../app/services/generated-api.js"
import { isEmptyRichText } from "../../components/RichTextEditor/util/common.js"
import { ChatContext } from "./chat-context.js"

import equal from "fast-deep-equal"
import { useCustomCompareCallback } from "use-custom-compare"
// Provider component
export function ChatContextProvider({
  children,
  initialChatValue,
}: {
  children: ReactNode
  initialChatValue?: Descendant[] | undefined
}) {
  const intl = useIntl()
  const [chatValue, setChatValue] = useState<Descendant[] | undefined>(initialChatValue)

  const focusedContexts = useMemo(() => getChatContextFromRichText(chatValue), [chatValue])

  const getFocusedContexts = useCustomCompareCallback(
    (
      param:
        | { type: "document" | "negotiation-context" | "mention" }
        // If objectType is not provided, return all standard objects
        | { type: "standard-object"; objectType?: ObjectType }
    ) => {
      switch (param.type) {
        case "negotiation-context":
        case "mention":
        case "document":
          return focusedContexts.filter((context) => context.type === param.type)
        case "standard-object":
          return focusedContexts.filter(
            (context) => context.type === param.type && (!param.objectType || context.object_type === param.objectType)
          )
      }
    },
    [focusedContexts],
    (prev, next) => {
      return equal(prev, next)
    }
  )

  const insertEmailContext = useCallback(
    (email: EmailWithReplyDrafts) => {
      setChatValue((previousDraft: Descendant[] | undefined) => {
        const isEmptyDraft = isEmptyRichText(previousDraft)

        const emailRichText = buildRichTextFromEmail(email, intl)
        if (previousDraft === undefined || isEmptyDraft) {
          return emailRichText
        }
        const clone = structuredClone(previousDraft)
        // If the email node is already in the draft, remove it
        traverse(clone, { includeSymbols: false }).forEach(function () {
          if (isStandardObject(this.node) && this.node.object_type === "Email") {
            this.remove()
          }
        })
        return [...emailRichText, ...clone]
      })
    },
    [intl]
  )

  const insertDocumentContext = useCallback((document: DocumentMinimal) => {
    setChatValue((previousDraft: Descendant[] | undefined) => {
      const newDraft = previousDraft ? (isEmptyRichText(previousDraft) ? [] : Array.from(previousDraft)) : []
      newDraft.push({
        type: "paragraph",
        children: [
          {
            type: "document",
            id: document.id,
            file_name: document.file_name ?? "Document",
            mime_type: document.mime_type ?? "application/pdf",
            children: [{ text: "" }],
          },
        ],
      })
      newDraft.push({
        type: "paragraph",
        children: [{ text: " " }],
      })
      return newDraft
    })
  }, [])

  const insertPlaybookSectionContext = useCallback(
    (sectionKey: NegotiationPlaybookSectionKey, negotiationId: string) => {
      setChatValue((previousDraft: Descendant[] | undefined) => {
        const newDraft = previousDraft ? (isEmptyRichText(previousDraft) ? [] : Array.from(previousDraft)) : []
        newDraft.push({
          type: "paragraph",
          children: [
            {
              type: "negotiation-context",
              negotiation_id: negotiationId,
              section_key: sectionKey,
              children: [{ text: "" }],
            },
          ],
        })
        newDraft.push({
          type: "paragraph",
          children: [{ text: " " }],
        })
        return newDraft
      })
    },
    []
  )

  const insertText = useCallback((text: string) => {
    setChatValue((previousDraft: Descendant[] | undefined) => {
      const isEmptyDraft = isEmptyRichText(previousDraft)
      if (previousDraft === undefined || isEmptyDraft) {
        return [
          {
            type: "paragraph",
            children: [{ text }],
          },
        ]
      }

      const lastNode = previousDraft.at(-1)
      const isLastNodeEmpty = isEmptyRichText([lastNode])
      return [
        ...(isLastNodeEmpty ? previousDraft.slice(0, -1) : previousDraft),
        {
          type: "paragraph",
          children: [{ text }],
        },
      ]
    })
  }, [])

  const removeContext = useCallback(
    (
      // Extend type as necessary
      param: // If sectionKey is not provided, remove all negotiation-context nodes
      | { type: "negotiation-context"; sectionKey?: NegotiationPlaybookSectionKey }
        // If documentId is not provided, remove all document nodes
        | { type: "document"; documentId?: string }
    ) => {
      setChatValue((previousDraft: Descendant[] | undefined) => {
        const clone = structuredClone(previousDraft)
        traverse(clone, { includeSymbols: false }).forEach(function () {
          if (isObject(this.node) && hasOwnProperty(this.node, "type") && this.node.type === param.type) {
            switch (param.type) {
              case "negotiation-context":
                if (
                  !param.sectionKey ||
                  (hasOwnProperty(this.node, "section_key") && this.node.section_key === param.sectionKey)
                ) {
                  this.remove()
                  // TODO: Remove the sibling paragraph if it's empty
                }
                break
              case "document":
                if (!param.documentId || (hasOwnProperty(this.node, "id") && this.node.id === param.documentId)) {
                  this.remove()
                }
                break
            }
          }
        })
        return clone
      })
    },
    []
  )

  // The value that will be provided to consumers
  const value = {
    focusedContexts,
    getFocusedContexts,
    insertPlaybookSectionContext,
    chatValue,
    setChatValue,
    insertEmailContext,
    insertDocumentContext,
    insertText,
    removeContext,
  }

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}
