import type { AgreementRenewalTask, NegotiationReplyTask, ReplyTask, Task } from "@brm/schema-types/types.js"
import { workflowStepStandardTypeToText } from "@brm/type-helpers/workflow.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { Flags } from "@brm/util/flags.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Avatar,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useCallback, type FC, type ReactNode, type SVGProps } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { FormattedMessage, useIntl, type IntlShape } from "react-intl"
import { useLocation } from "react-router-dom"
import type { Descendant } from "slate"
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep.js"
import { isNotVoid } from "typed-assert"
import {
  usePostIntegrationV1ByIdDisableMutation,
  usePostNotificationV1TaskDismissMutation,
} from "../../../app/services/generated-api.js"
import Button from "../../../components/Button/Button.js"
import { ButtonStyles } from "../../../components/Button/types.js"
import { FormattedDate } from "../../../components/FormattedDate.js"
import {
  AgreementIcon,
  DotsHorizontalIcon,
  SnoozeIcon,
  ToolIcon,
  TrashIcon,
  VendorIcon,
} from "../../../components/icons/icons.js"
import { IntegrationProviderIcon } from "../../../components/icons/IntegrationProviderIcon.js"
import { Logo } from "../../../components/icons/Logo.js"
import { Link } from "../../../components/Link.js"
import { serializeRichText } from "../../../components/RichTextEditor/util/rich-text.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import StartWorkflowModal from "../../workflows/run/start/StartWorkflowModal.js"
import {
  getNotificationKeyboardShortcutsDisplayText,
  getTaskKeyboardShortcutsDisplayText,
  NotificationKeyboardShortcuts,
  TaskKeyboardShortcuts,
} from "../notification/helpers.js"
import { compareLocationToTaskUrl, getTaskPrimarySrcUrl } from "./helper.js"
import TaskDismissModal from "./TaskDismissModal.js"
import TaskSnoozeModal from "./TaskSnoozeModal.js"

const TaskItemNetworkWrapper: FC<{
  task: Task
  onRemoveTask: () => void
}> = ({ task, onRemoveTask }) => {
  const objectSchema = useObjectSchema(task.type === "workflow_field_gatherer" ? task.object_type : skipToken)
  return <TaskItem task={task} objectSchema={objectSchema} onRemoveTask={onRemoveTask} />
}

export const TaskItem: FC<{
  task: Task
  objectSchema?: ReadonlyObjectDeep<JSONSchemaObject>
  onRemoveTask: () => void
}> = ({ task, objectSchema, onRemoveTask }) => {
  const intl = useIntl()

  // Check if the task is selected by looking at the route and search params
  const location = useLocation()
  const isSelected = compareLocationToTaskUrl(location, task)
  const snoozeDisclosure = useDisclosure()
  const dismissDisclosure = useDisclosure()
  const startRenewalDisclosure = useDisclosure()
  const negotiationEnabled = useFeatureFlagEnabled(Flags.NEGOTIATION_ENABLED)

  const [dismissTask] = usePostNotificationV1TaskDismissMutation()
  const [disableIntegration] = usePostIntegrationV1ByIdDisableMutation()

  const onDismissTask = useCallback(async () => {
    await dismissTask({ taskDismissBody: { task } })
    onRemoveTask()
  }, [dismissTask, onRemoveTask, task])

  const workflowTaskPrimaryCTAText = intl.formatMessage({
    id: "inbox.task.workflow.primaryCTA",
    defaultMessage: "View Request",
    description: "Primary CTA text for viewing a workflow run task",
  })

  switch (task.type) {
    case "workflow_step_gatherer":
      isNotVoid(task.pending_steps[0])
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_step_gatherer.body.one_step",
              defaultMessage:
                "Complete the {stepNames} {count, plural, =1 {step} other {steps}}. The request is due on {dueDate}",
              description: "Task description for workflow step gatherer task",
            },
            {
              stepNames: intl.formatList(task.pending_steps.map((step) => workflowStepStandardTypeToText(step, intl))),
              count: task.pending_steps.length,
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText={workflowTaskPrimaryCTAText}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    case "workflow_run_step_ready_for_review":
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_run_step_ready_for_review.body",
              defaultMessage:
                "Review the {stepName} Step submitted by {submitterName}. The request is due on {dueDate}",
              description: "Task description for step ready for review task",
            },
            {
              stepName: workflowStepStandardTypeToText(task.workflow_run_step_display_name, intl),
              submitterName: displayPersonName(
                {
                  id: task.submitter_id,
                  first_name: task.submitter_first_name,
                  last_name: task.submitter_last_name,
                },
                intl
              ),
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText={workflowTaskPrimaryCTAText}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    case "workflow_field_gatherer": {
      if (!objectSchema) {
        return <Skeleton height="20" borderBottom="1px solid" borderColor="gray.200" />
      }
      const fieldSchema = getSchemaAtPath(objectSchema, [task.field_name])

      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_field_gatherer.body",
              defaultMessage:
                "Complete the {fieldName} criteria on the {stepName} step. The request is due on {dueDate}",
              description: "Task description for workflow field gatherer task",
            },
            {
              fieldName: getTitle(task.field_name, fieldSchema),
              stepName: workflowStepStandardTypeToText(task.workflow_run_step_display_name, intl),
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText={workflowTaskPrimaryCTAText}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )
    }
    case "workflow_run_aggregated_steps_ready_for_review_or_approval": {
      const gatherStepsBodyMessage =
        task.gather_steps.length >= 1
          ? intl.formatMessage(
              {
                id: "inbox.task.workflow_run_aggregated_steps_ready_for_review.body.gather_steps_message",
                defaultMessage: "Complete the {gatherStepName} {count, plural, =1 {step} other {steps}}. ",
                description: "Instructions to gather the steps for the request",
              },
              {
                gatherStepName: intl.formatList(
                  task.gather_steps.map((step) => workflowStepStandardTypeToText(step, intl))
                ),
                count: task.gather_steps.length,
              }
            )
          : undefined

      const approvalStepsBodyMessage = gatherStepsBodyMessage
        ? intl.formatMessage(
            {
              id: "inbox.task.workflow_run_aggregated_steps_ready_for_review.body.approval_steps_message",
              defaultMessage: "Review the {approvalStepNames} {count, plural, =1 {step} other {steps}}. ",
              description: "Instructions to review the approval steps for the request",
            },
            {
              approvalStepNames: intl.formatList(
                task.approve_steps.map((step) => workflowStepStandardTypeToText(step, intl))
              ),
              count: task.approve_steps.length,
            }
          )
        : intl.formatMessage(
            {
              id: "inbox.task.workflow_run_aggregated_steps_ready_for_review.body.approval_steps_message_with_no_gather_steps",
              defaultMessage:
                "Review {approvalStepNames} {count, plural, =1 {step} other {steps}} submitted by team members. ",
              description: "Task description for aggregated steps ready for review task",
            },
            {
              approvalStepNames: intl.formatList(
                task.approve_steps.map((step) => workflowStepStandardTypeToText(step, intl))
              ),
              count: task.approve_steps.length,
            }
          )

      const requestDueDateBodyMessage = intl.formatMessage(
        {
          id: "inbox.task.workflow_run_aggregated_steps_ready_for_review.body.request_due_date",
          defaultMessage: "The request is due on {dueDate}.",
          description: "Task description for aggregated steps ready for review task",
        },
        {
          dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
        }
      )

      return (
        <TaskItemCore
          title={task.workflow_name}
          body={[gatherStepsBodyMessage, approvalStepsBodyMessage, requestDueDateBodyMessage]}
          primaryCTAText={workflowTaskPrimaryCTAText}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )
    }
    case "workflow_run_aggregated_gather_fields":
      return (
        <TaskItemCore
          title={task.workflow_name}
          body={intl.formatMessage(
            {
              id: "inbox.task.workflow_run_aggregated_gather_fields.body",
              defaultMessage: "Complete fields on {stepNames} steps. The request is due on {dueDate}",
              description: "Task description for aggregated gather fields task",
            },
            {
              stepNames: task.gather_steps.map((step) => workflowStepStandardTypeToText(step, intl)).join(", "),
              dueDate: <FormattedDate value={task.workflow_run_due_date} month="long" />,
            }
          )}
          primaryCTAText={workflowTaskPrimaryCTAText}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
          fallbackAvatarLogo={VendorIcon}
        />
      )

    case "agreement_renewal": {
      const today = Temporal.Now.plainDateISO()
      const decisionDate = Temporal.PlainDate.from(task.agreement_decision_date)
      const daysUntilDecision = today.until(decisionDate, { largestUnit: "days" }).days
      const ltmSpendUSD = task.ltm_spend ? { currency_code: "USD", amount: task.ltm_spend.toString() } : undefined

      let bodyMessage

      if (task.agreement_end_date && task.auto_renews !== null) {
        bodyMessage = task.auto_renews
          ? task.ltm_spend !== null
            ? intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.auto_renews.with_ltm_spend",
                  defaultMessage:
                    "Agreement expires on {agreementEndDate}. The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}}. Your team spent {ltmSpend} on {toolName} in the last 12 months. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task with LTM spend which auto-renews",
                },
                {
                  agreementEndDate: <FormattedDate value={task.agreement_end_date} month="long" />,
                  numDays: daysUntilDecision,
                  ltmSpend: formatCurrency(ltmSpendUSD, intl),
                  toolName: task.tool_display_name ?? task.vendor_display_name,
                }
              )
            : intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.auto_renews.without_ltm_spend",
                  defaultMessage:
                    "Agreement expires on {agreementEndDate}. The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}} and is set to auto-renew. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task without LTM spend which auto-renews",
                },
                {
                  agreementEndDate: <FormattedDate value={task.agreement_end_date} month="long" />,
                  numDays: daysUntilDecision,
                }
              )
          : task.ltm_spend !== null
            ? intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.with_ltm_spend",
                  defaultMessage:
                    "Agreement expires on {agreementEndDate}. The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}}. Your team spent {ltmSpend} on {toolName} in the last 12 months. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task with LTM spend",
                },
                {
                  agreementEndDate: <FormattedDate value={task.agreement_end_date} month="long" />,
                  numDays: daysUntilDecision,
                  ltmSpend: formatCurrency(ltmSpendUSD, intl),
                  toolName: task.tool_display_name ?? task.vendor_display_name,
                }
              )
            : intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.without_ltm_spend",
                  defaultMessage:
                    "Agreement expires on {agreementEndDate}. The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}}. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task without LTM spend",
                },
                {
                  agreementEndDate: <FormattedDate value={task.agreement_end_date} month="long" />,
                  numDays: daysUntilDecision,
                }
              )
      } else {
        bodyMessage =
          task.ltm_spend !== null
            ? intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.with_ltm_spend.no_end_date",
                  defaultMessage:
                    "The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}}. Your team spent {ltmSpend} on {toolName} in the last 12 months. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task with LTM spend",
                },
                {
                  numDays: daysUntilDecision,
                  ltmSpend: formatCurrency(ltmSpendUSD, intl),
                  toolName: task.tool_display_name ?? task.vendor_display_name,
                }
              )
            : intl.formatMessage(
                {
                  id: "inbox.task.agreement_renewal.body.without_ltm_spend.no_end_date",
                  defaultMessage:
                    "The Agreement decision day is in {numDays} {numDays, plural, =1 {day} other {days}}. Review the details and decide whether to renew or cancel.",
                  description: "Task description for agreement renewal task without LTM spend",
                },
                {
                  numDays: daysUntilDecision,
                }
              )
      }

      const shouldShowNegotiation = negotiationEnabled && task.negotiation_status === "not_started"
      return (
        <>
          <StartWorkflowModal {...startRenewalDisclosure} initialLegalAgreementId={task.agreement_id} />
          <TaskItemCore
            title={task.agreement_name}
            body={bodyMessage}
            primaryCTAText={intl.formatMessage({
              id: "inbox.task.agreement_renewal.primaryCTA",
              defaultMessage: shouldShowNegotiation ? "Make a Decision" : "Start Renewal",
              description: "Primary CTA text for agreement renewal task",
            })}
            primaryCTAOnClick={shouldShowNegotiation ? undefined : startRenewalDisclosure.onOpen}
            clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
            isSelected={isSelected}
            avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
            fallbackAvatarLogo={VendorIcon}
            extraMenuItems={
              <AgreementRenewalMenu
                task={task}
                onStartRenewal={shouldShowNegotiation ? startRenewalDisclosure.onOpen : undefined}
                onSnoozeModalClose={snoozeDisclosure.onClose}
                onSnoozeModalOpen={snoozeDisclosure.onOpen}
                snoozeModalIsOpen={snoozeDisclosure.isOpen}
                onDismissModalClose={dismissDisclosure.onClose}
                onDismissModalOpen={dismissDisclosure.onOpen}
                dismissModalIsOpen={dismissDisclosure.isOpen}
                onRemoveTask={onRemoveTask}
              />
            }
            onSnooze={snoozeDisclosure.onOpen}
            onDismiss={dismissDisclosure.onOpen}
          />
        </>
      )
    }
    case "agreement_verification": {
      return (
        <TaskItemCore
          title={intl.formatMessage(
            {
              id: "inbox.task.agreement_verification.title",
              defaultMessage: "{numUnverifiedAgreements, plural, =1 {1 Agreement} other {# Agreements}} in review",
              description: "Title for agreement verification task",
            },
            {
              numUnverifiedAgreements: task.unverified_agreement_count,
            }
          )}
          body={intl.formatMessage({
            id: "inbox.task.agreement_verification.body",
            defaultMessage: "Please review and verify the agreements",
            description: "Task description for agreement verification task",
          })}
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.agreement_verification.primaryCTA",
            defaultMessage: "Review Agreements",
            description: "Primary CTA text for agreement verification task",
          })}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          fallbackAvatarLogo={AgreementIcon}
          fallbackAvatarLogoColor="warning.600"
          fallbackAvatarLogoBackgroundColor="warning.100"
        />
      )
    }
    case "reply": {
      const serializedRichText = serializeRichText(task.rich_text_body as Descendant[]).trim()
      return (
        <TaskItemCore
          title={getTitleForReplyTask(task, intl)}
          dueDate={task.due_by ?? undefined}
          body={serializedRichText}
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.reply.primaryCTA",
            defaultMessage: "Reply",
            description: "Primary CTA text for reply task",
          })}
          secondaryCTAText={intl.formatMessage({
            id: "inbox.task.reply.secondaryCTA",
            defaultMessage: "Dismiss",
            description: "Secondary CTA text for reply task",
          })}
          secondaryCTAOnClick={onDismissTask}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          avatarSrc={getPublicImageGcsUrl(task.created_by.profile_image?.gcs_file_name)}
          avatarFallbackName={displayPersonName(
            { first_name: task.created_by.first_name, last_name: task.created_by.last_name, id: task.created_by.id },
            intl
          )}
        />
      )
    }
    case "disconnected_integration": {
      return (
        <TaskItemCore
          title={intl.formatMessage(
            {
              id: "inbox.task.disconnected_integration.title",
              defaultMessage: "{integrationName} integration failure",
              description: "Title for disconnected integration task",
            },
            {
              integrationName: task.integration.display_name,
            }
          )}
          body={
            task.integration.crawler_last_success
              ? intl.formatMessage(
                  {
                    id: "inbox.task.disconnected_integration.body",
                    defaultMessage: "No new data has been synced since {lastSyncedAt}",
                    description: "Task description for disconnected integration task",
                  },
                  {
                    lastSyncedAt: formatDate(intl, task.integration.crawler_last_success),
                  }
                )
              : intl.formatMessage({
                  id: "inbox.task.disconnected_integration.body.no_last_synced_at",
                  defaultMessage: "The integration has been disconnected",
                  description: "Task description for disconnected integration task",
                })
          }
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.disconnected_integration.primaryCTA",
            defaultMessage: "Reconnect",
            description: "Primary CTA text for disconnected integration task",
          })}
          secondaryCTAText={intl.formatMessage({
            id: "inbox.task.disconnected_integration.secondaryCTA",
            defaultMessage: "Disable",
            description: "Secondary CTA text for disconnected integration task",
          })}
          secondaryCTAOnClick={async () => {
            await disableIntegration({ id: task.integration.id })
          }}
          fallbackAvatarLogo={() => <IntegrationProviderIcon integration={task.integration} />}
          fallbackAvatarLogoBackgroundColor="error.100"
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
        />
      )
    }
    case "tools_inactive_owner_reassignment":
      return (
        <TaskItemCore
          title={intl.formatMessage(
            {
              id: "inbox.task.tools_inactive_owner_reassignment.title",
              defaultMessage:
                "{tools_needing_reassignment_count} {tools_needing_reassignment_count, plural, =1 {Tool} other {Tools}} with {inactive_owners_count, plural, =1 {an inactive owner} other {inactive owners}}",
              description: "Title for tools with inactive owners task",
            },
            {
              tools_needing_reassignment_count: task.tools_needing_reassignment_count,
              inactive_owners_count: task.inactive_owners_count,
            }
          )}
          body={intl.formatMessage(
            {
              id: "inbox.task.tools_inactive_owner_reassignment.body",
              defaultMessage:
                "{tools_needing_reassignment_count, plural, =1 {This tool requires} other {These tools require}} a new owner assigned due to {inactive_owners_count, plural, =1 {its current owner being a former employee or inactive user} other {their current owners being former employees or inactive users}}.",
              description: "Task description explaining tools need new owners due to current owners being inactive",
            },
            {
              tools_needing_reassignment_count: task.tools_needing_reassignment_count,
              inactive_owners_count: task.inactive_owners_count,
            }
          )}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.tools_inactive_owner_reassignment.primaryCTA",
            defaultMessage: "Reassign Owners",
            description: "Primary CTA text for tools with inactive owners task",
          })}
          isSelected={isSelected}
          fallbackAvatarLogo={ToolIcon}
        />
      )
    case "vendors_inactive_owner_reassignment":
      return (
        <TaskItemCore
          title={intl.formatMessage(
            {
              id: "inbox.task.vendors_inactive_owner_reassignment.title",
              defaultMessage:
                "{vendors_needing_reassignment_count} {vendors_needing_reassignment_count, plural, =1 {Vendor} other {Vendors}} with {inactive_owners_count, plural, =1 {an inactive owner} other {inactive owners}}",
              description: "Title for vendors with inactive owners task",
            },
            {
              vendors_needing_reassignment_count: task.vendors_needing_reassignment_count,
              inactive_owners_count: task.inactive_owners_count,
            }
          )}
          body={intl.formatMessage(
            {
              id: "inbox.task.vendors_inactive_owner_reassignment.body",
              defaultMessage:
                "{vendors_needing_reassignment_count, plural, =1 {This vendor requires} other {These vendors require}} a new owner assigned due to {inactive_owners_count, plural, =1 {its current owner being a former employee or inactive user} other {their current owners being former employees or inactive users}}.",
              description: "Task description explaining vendors need new owners due to current owners being inactive",
            },
            {
              vendors_needing_reassignment_count: task.vendors_needing_reassignment_count,
              inactive_owners_count: task.inactive_owners_count,
            }
          )}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.vendors_inactive_owner_reassignment.primaryCTA",
            defaultMessage: "Reassign Owners",
            description: "Primary CTA text for vendors with inactive owners task",
          })}
          isSelected={isSelected}
          fallbackAvatarLogo={VendorIcon}
        />
      )
    case "agreement_duplicative_subscriptions":
      return (
        <TaskItemCore
          title={intl.formatMessage(
            {
              id: "inbox.task.agreement_duplicative_subscriptions.title",
              defaultMessage: "{duplicative_subscriptions_count} Duplicative subscriptions for {object_display_name}",
              description: "Title for agreement duplicative subscriptions task",
            },
            {
              duplicative_subscriptions_count: task.duplicative_legal_agreement_ids.length,
              object_display_name: task.object_display_name,
            }
          )}
          body={intl.formatMessage({
            id: "inbox.task.agreement_duplicative_subscriptions.body",
            defaultMessage: "Please review these potentially redundant subscriptions.",
            description: "Body for agreement duplicative subscriptions task",
          })}
          primaryCTAText={intl.formatMessage({
            id: "inbox.task.agreement_duplicative_subscriptions.primaryCTA",
            defaultMessage: "Review Subscriptions",
            description: "Primary CTA text for agreement duplicative subscriptions task",
          })}
          clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
          isSelected={isSelected}
          secondaryCTAText={intl.formatMessage({
            id: "inbox.task.agreement_duplicative_subscriptions.secondaryCTA",
            defaultMessage: "Dismiss",
            description: "Secondary CTA text for agreement duplicative subscriptions task",
          })}
          secondaryCTAOnClick={onDismissTask}
          avatarSrc={getPublicImageGcsUrl(task.object_logo_gcs_file_name)}
          fallbackAvatarLogo={task.object_type === "Tool" ? ToolIcon : VendorIcon}
        />
      )
    case "negotiation_reply":
      return (
        <>
          <TaskSnoozeModal
            isOpen={snoozeDisclosure.isOpen}
            onClose={snoozeDisclosure.onClose}
            task={task}
            onSnooze={onRemoveTask}
          />
          <TaskDismissModal
            isOpen={dismissDisclosure.isOpen}
            onClose={dismissDisclosure.onClose}
            taskDisplayName={intl.formatMessage(
              {
                id: "inbox.task.negotiation_reply.dismiss_modal.title",
                defaultMessage: "Negotiation Reply Reminder: {vendorDisplayName}",
                description: "Title shown in the dismiss modal for negotiation reply tasks",
              },
              {
                vendorDisplayName: task.vendor_display_name,
              }
            )}
            onDismiss={async () => {
              await dismissTask({ taskDismissBody: { task } })
              onRemoveTask()
              dismissDisclosure.onClose()
            }}
          />
          <TaskItemCore
            title={`${task.vendor_display_name}: Negotiation`}
            body={`Respond to email received on ${formatDate(intl, task.date_of_reply)}`}
            primaryCTAText={intl.formatMessage({
              id: "inbox.task.negotiation_reply.primaryCTA",
              defaultMessage: "Respond to email",
              description: "Primary CTA text for negotiation reply task",
            })}
            primaryCTAOnClick={negotiationEnabled ? undefined : startRenewalDisclosure.onOpen}
            secondaryCTAText={intl.formatMessage({
              id: "inbox.task.agreement_renewal.secondaryCTA",
              defaultMessage: "Snooze",
              description: "Secondary CTA text for agreement renewal task",
            })}
            secondaryCTAOnClick={snoozeDisclosure.onOpen}
            clickOnTaskSrc={getTaskPrimarySrcUrl(task)}
            isSelected={isSelected}
            avatarSrc={getPublicImageGcsUrl(task.tool_logo_gcs_file_name)}
            fallbackAvatarLogo={VendorIcon}
            extraMenuItems={
              <NegotiationReplyMenu
                task={task}
                onDismissModalClose={dismissDisclosure.onClose}
                onDismissModalOpen={dismissDisclosure.onOpen}
                dismissModalIsOpen={dismissDisclosure.isOpen}
                onRemoveTask={onRemoveTask}
              />
            }
            onSnooze={snoozeDisclosure.onOpen}
            onDismiss={dismissDisclosure.onOpen}
          />
        </>
      )
    default:
      unreachable(task)
  }
}

export const TaskItemCore: FC<{
  title: string
  body: ReactNode
  clickOnTaskSrc: string
  primaryCTAText: string
  secondaryCTAText?: string
  // If not provided, the primary CTA will be a link to the task's clickOnTaskSrc
  primaryCTAOnClick?: React.MouseEventHandler<HTMLButtonElement>
  secondaryCTAOnClick?: () => void
  avatarSrc?: string
  fallbackAvatarLogo?: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
  avatarFallbackName?: string
  fallbackAvatarLogoColor?: string
  fallbackAvatarLogoBackgroundColor?: string
  dueDate?: string
  isSelected: boolean
  extraMenuItems?: ReactNode
  onSnooze?: () => void
  onDismiss?: () => void
}> = ({
  avatarSrc,
  title,
  body,
  clickOnTaskSrc,
  primaryCTAOnClick,
  primaryCTAText,
  secondaryCTAText,
  secondaryCTAOnClick,
  isSelected,
  fallbackAvatarLogo,
  fallbackAvatarLogoColor,
  fallbackAvatarLogoBackgroundColor,
  avatarFallbackName,
  extraMenuItems,
  onSnooze,
  onDismiss,
  dueDate,
}) => {
  useHotkeys(
    [TaskKeyboardShortcuts.BACKSPACE, TaskKeyboardShortcuts.DELETE, TaskKeyboardShortcuts.SNOOZE],
    (e, handler) => {
      if (isSelected) {
        switch (handler.keys?.join("")) {
          case TaskKeyboardShortcuts.SNOOZE:
            onSnooze?.()
            break
          case NotificationKeyboardShortcuts.BACKSPACE:
          case NotificationKeyboardShortcuts.DELETE:
            onDismiss?.()
            break
          default:
            break
        }
        e.preventDefault()
      }
    },
    { enabled: isSelected }
  )

  const today = Temporal.Now.plainDateISO()
  const temporalDueDate = dueDate ? Temporal.PlainDate.from(dueDate) : undefined
  const daysUntilDue = temporalDueDate ? today.until(temporalDueDate, { largestUnit: "days" }).days : undefined

  return (
    <ListItem display="flex" transition="opacity 0.3s ease-in-out" px={4}>
      <Link
        to={clickOnTaskSrc}
        display="flex"
        _hover={{ textDecoration: "none" }}
        background={isSelected ? "gray.50" : undefined}
        width="100%"
        gap={3}
        overflow="hidden"
      >
        <Grid templateColumns="2rem 1fr" pt="2" columnGap={3} rowGap={1} flexGrow={1}>
          <GridItem colSpan={1} display="flex" alignItems="center">
            {avatarSrc ? (
              <Avatar size="md" src={avatarSrc} />
            ) : avatarFallbackName ? (
              <Avatar size="md" name={avatarFallbackName} />
            ) : (
              <Logo
                fallbackIcon={fallbackAvatarLogo}
                fallbackIconColor={fallbackAvatarLogoColor ?? "gray.600"}
                fallbackIconBackgroundColor={fallbackAvatarLogoBackgroundColor}
                boxSize="32px"
              />
            )}
          </GridItem>
          <GridItem colSpan={1} overflow="hidden">
            <Flex gap={1} justifyContent="space-between">
              <Text size="sm" fontWeight="medium" flex="0 1 auto" isTruncated>
                {title}
              </Text>
              {daysUntilDue !== undefined && (
                <Text size="sm" color="red.600" fontWeight="normal" flex="1 0 auto" maxWidth="fit-content">
                  {daysUntilDue === 0 ? (
                    <FormattedMessage
                      id="inbox.task.dueDate.today"
                      defaultMessage="Due today"
                      description="Due date for task today"
                    />
                  ) : daysUntilDue < 0 ? (
                    <FormattedMessage id="inbox.task.overdue" defaultMessage="Overdue" description="Overdue task" />
                  ) : (
                    <FormattedMessage
                      id="inbox.task.dueDate.inXDays"
                      defaultMessage="Due in {dueDate}d"
                      description="Due date for task"
                      values={{ dueDate: daysUntilDue }}
                    />
                  )}
                </Text>
              )}
            </Flex>
            <Text color="gray.600">{body}</Text>
          </GridItem>
          <GridItem colSpan={1}></GridItem>
          <GridItem
            colSpan={1}
            display="flex"
            gap="3"
            pb="2"
            borderBottom="1px solid"
            borderColor="gray.200"
            alignItems="center"
          >
            {secondaryCTAText && (
              <Button
                size="md"
                buttonStyles={ButtonStyles.Link}
                label={secondaryCTAText}
                onClick={secondaryCTAOnClick}
              />
            )}
            <Button
              size="md"
              buttonStyles={ButtonStyles.LinkBrand}
              label={primaryCTAText}
              onClick={primaryCTAOnClick}
            />
            {extraMenuItems && <TaskItemExtrasMenu extraMenuItems={extraMenuItems} />}
          </GridItem>
        </Grid>
      </Link>
    </ListItem>
  )
}

interface TaskItemExtrasMenuProps {
  extraMenuItems: ReactNode
}

const TaskItemExtrasMenu: FC<TaskItemExtrasMenuProps> = ({ extraMenuItems }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        top="2px"
        padding={1}
        size="sm"
        icon={<Icon as={DotsHorizontalIcon} />}
        aria-label="Menu"
        variant="ghost"
      />
      <MenuList>{extraMenuItems}</MenuList>
    </Menu>
  )
}

export default TaskItemNetworkWrapper

const NegotiationReplyMenu: FC<{
  task: NegotiationReplyTask
  onDismissModalClose: () => void
  onDismissModalOpen: () => void
  dismissModalIsOpen: boolean
  onRemoveTask: () => void
}> = ({ task, onDismissModalClose, onDismissModalOpen, dismissModalIsOpen, onRemoveTask }) => {
  const intl = useIntl()
  const [dismissTask] = usePostNotificationV1TaskDismissMutation()

  return (
    <>
      <TaskDismissModal
        isOpen={dismissModalIsOpen}
        onClose={onDismissModalClose}
        taskDisplayName={intl.formatMessage(
          {
            id: "inbox.task.negotiation_reply.dismiss_modal.title",
            defaultMessage: "Negotiation Reply Reminder: {vendorDisplayName}",
            description: "Title shown in the dismiss modal for negotiation reply tasks",
          },
          {
            vendorDisplayName: task.vendor_display_name,
          }
        )}
        onDismiss={async () => {
          await dismissTask({ taskDismissBody: { task } })
          onRemoveTask()
          onDismissModalClose()
        }}
      />
      <MenuItem icon={<Icon as={TrashIcon} />} onClick={onDismissModalOpen}>
        <HStack justifyContent="space-between">
          <FormattedMessage
            defaultMessage="Dismiss"
            description="Delete notification menu item"
            id="inbox.notification.delete"
          />
          <Text>{getNotificationKeyboardShortcutsDisplayText(NotificationKeyboardShortcuts.DELETE)}</Text>
        </HStack>
      </MenuItem>
    </>
  )
}

const AgreementRenewalMenu: FC<{
  task: AgreementRenewalTask
  onSnoozeModalClose: () => void
  onSnoozeModalOpen: () => void
  snoozeModalIsOpen: boolean
  onDismissModalClose: () => void
  onDismissModalOpen: () => void
  dismissModalIsOpen: boolean
  onRemoveTask: () => void
  onStartRenewal?: () => void
}> = ({
  task,
  onSnoozeModalClose,
  onSnoozeModalOpen,
  snoozeModalIsOpen,
  onDismissModalClose,
  onDismissModalOpen,
  dismissModalIsOpen,
  onRemoveTask,
  onStartRenewal,
}) => {
  const intl = useIntl()
  const [dismissTask] = usePostNotificationV1TaskDismissMutation()

  return (
    <>
      <TaskSnoozeModal
        isOpen={snoozeModalIsOpen}
        onClose={onSnoozeModalClose}
        task={task}
        maxDate={task.agreement_decision_date}
        onSnooze={onRemoveTask}
      />
      <TaskDismissModal
        isOpen={dismissModalIsOpen}
        onClose={onDismissModalClose}
        taskDisplayName={intl.formatMessage(
          {
            id: "inbox.task.agreement_renewal.dismiss_modal.title",
            defaultMessage: "Agreement Renewal Reminder: {agreementName}",
            description: "Title shown in the dismiss modal for agreement renewal tasks",
          },
          {
            agreementName: task.agreement_name,
          }
        )}
        onDismiss={async () => {
          await dismissTask({ taskDismissBody: { task } })
          onRemoveTask()
          onDismissModalClose()
        }}
      />
      <MenuItem icon={<Icon as={SnoozeIcon} />} onClick={onSnoozeModalOpen}>
        <HStack justifyContent="space-between">
          <FormattedMessage
            defaultMessage="Snooze..."
            description="Snooze notification menu item"
            id="inbox.notification.snooze"
          />
          <Text>{getTaskKeyboardShortcutsDisplayText(TaskKeyboardShortcuts.SNOOZE)}</Text>
        </HStack>
      </MenuItem>
      <MenuItem icon={<Icon as={TrashIcon} />} onClick={onDismissModalOpen}>
        <HStack justifyContent="space-between">
          <FormattedMessage
            defaultMessage="Dismiss"
            description="Delete notification menu item"
            id="inbox.notification.delete"
          />
          <Text>{getNotificationKeyboardShortcutsDisplayText(NotificationKeyboardShortcuts.DELETE)}</Text>
        </HStack>
      </MenuItem>
      <MenuItem icon={<Icon as={AgreementIcon} />} onClick={onStartRenewal}>
        <FormattedMessage
          defaultMessage="Start Renewal"
          description="Start renewal menu item"
          id="inbox.task.agreement_renewal.start_renewal"
        />
      </MenuItem>
    </>
  )
}

const getTitleForReplyTask = (task: ReplyTask, intl: IntlShape) => {
  if (task.workflow_run_id) {
    return intl.formatMessage(
      {
        id: "inbox.task.reply.title",
        defaultMessage: "Reply to {createdBy}: {workflowRunName}",
        description: "Title for reply task",
      },
      {
        workflowRunName: task.workflow_run_display_name,
        createdBy: `${task.created_by.first_name}`,
      }
    )
  }
  if (task.vendor_id) {
    return intl.formatMessage(
      {
        id: "inbox.task.reply.title",
        defaultMessage: "Reply to {createdBy}: {vendorName}",
        description: "Title for reply task",
      },
      {
        vendorName: task.vendor_display_name,
        createdBy: `${task.created_by.first_name}`,
      }
    )
  }
  if (task.tool_id) {
    return intl.formatMessage(
      {
        id: "inbox.task.reply.title",
        defaultMessage: "Reply to {createdBy}: {toolName}",
        description: "Title for reply task",
      },
      {
        toolName: task.tool_display_name,
        createdBy: `${task.created_by.first_name}`,
      }
    )
  }
  throw new Error("Reply task has no workflow run or vendor or tool")
}
