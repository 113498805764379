import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { isString } from "typed-assert"
import { useGetPersonV1ByIdQuery } from "../../app/services/generated-api.js"
import { DividedRowSubHeader } from "../../components/DividedRowSubHeader.js"
import { Link } from "../../components/Link.js"
import { LogoHeader } from "../../components/LogoHeader.js"
import { logoHeaderBoxSize } from "../../components/constants.js"
import { DisplayableIntegrationTypeIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { PlusIcon, UserIcon } from "../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { getRolesToShowFromRoles } from "../../util/user-roles.js"
import InviteOrUpdateUserModal from "../organization/invites/InviteOrUpdateUserModal.js"
import { RoleTag } from "../organization/roles/RoleTag.js"
import type { ToolTab } from "../tool/constants.js"
import PersonOverview from "./PersonOverview.js"
import PersonSecurity from "./PersonSecurity.js"
import { isPersonTab, personTabs, type PersonTab } from "./constants.js"

interface PersonDataTab {
  displayName: string
  panel: JSX.Element
}

export default function Person() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { personId, tab } = useParams()
  isString(personId, "Missing ID")
  const shownTab: ToolTab = tab && isPersonTab(tab) ? tab : "overview"

  const { data: person, isError } = useGetPersonV1ByIdQuery({ id: personId })
  const inviteModal = useDisclosure()

  useEffect(() => {
    if (isError) {
      navigate("/")
      return
    }
  }, [personId, isError, navigate])

  if (!person) {
    return null
  }

  const dataByTab: Record<PersonTab, PersonDataTab> = {
    overview: {
      displayName: intl.formatMessage({
        id: "person.tabs.overview",
        description: "Person view tab overview info",
        defaultMessage: "Overview",
      }),
      panel: <PersonOverview person={person} />,
    },
    security: {
      displayName: intl.formatMessage({
        id: "person.tabs.users",
        description: "Persons view tab security info",
        defaultMessage: "Security",
      }),
      panel: <PersonSecurity person={person} />,
    },
  }

  return (
    <Stack gap={4} position="relative" className="person-page-root" minHeight={0}>
      <LogoHeader
        backgroundColor="white"
        top={0}
        padding={0}
        logoElement={
          <Avatar
            boxSize={logoHeaderBoxSize}
            src={getPublicImageGcsUrl(person.profile_image?.gcs_file_name)}
            icon={<Icon as={UserIcon} />}
          />
        }
        heading={
          <HStack>
            <Stack gap={0}>
              <HStack>
                <Heading size="md">{person.display_name}</Heading>
                {person.user ? (
                  getRolesToShowFromRoles(person.user.roles).map((role) => <RoleTag key={role} role={role} />)
                ) : (
                  <Button leftIcon={<Icon as={PlusIcon} />} onClick={inviteModal.onOpen}>
                    <FormattedMessage
                      id="person.detail.invite.button"
                      description="button text for inviting a person to the organization"
                      defaultMessage="Invite User"
                    />
                  </Button>
                )}
              </HStack>
            </Stack>
          </HStack>
        }
      >
        <DividedRowSubHeader>
          {person.email && <Link to={`mailto:${person.email}`}>{person.email}</Link>}
          {person.integration_persons.length > 0 && (
            <>
              <Link to="/settings/integrations" display="flex" gap={2} alignItems="center">
                <FormattedMessage
                  id="integration.list"
                  description="Description field for the integrations an entity has sources from"
                  defaultMessage="Integrations:"
                />
              </Link>
              {person.integration_persons.map((integrationPerson) => {
                const status = integrationPerson.status ?? "inactive"
                return (
                  <Tooltip
                    key={integrationPerson.id}
                    label={intl.formatMessage(
                      {
                        defaultMessage: "{personName}’s {integrationName} account is {status}",
                        description: "Tooltip label for an integration person",
                        id: "integration.person.tooltip",
                      },
                      {
                        personName: displayPersonName(person, intl),
                        status,
                        integrationName: integrationPerson.integration.display_name,
                      }
                    )}
                    shouldWrapChildren
                  >
                    <DisplayableIntegrationTypeIcon
                      boxSize={6}
                      opacity={status === "inactive" ? 0.5 : 1}
                      type={integrationPerson.displayable_integration_type}
                      hideTooltip
                    />
                  </Tooltip>
                )
              })}
            </>
          )}
        </DividedRowSubHeader>
      </LogoHeader>
      <Tabs
        variant="line"
        display="flex"
        flexDirection="column"
        index={personTabs.indexOf(shownTab)}
        pt={1}
        minHeight="0"
        overflow="hidden"
      >
        <TabList>
          {personTabs.map((tab) => (
            <Tab key={tab} as={Link} to={`../${tab}`} relative="path">
              {dataByTab[tab].displayName}
            </Tab>
          ))}
        </TabList>
        <TabPanels px={0} minHeight="0" display="flex">
          {personTabs.map((tab) => (
            <TabPanel minHeight="0" overflowY="auto" key={tab}>
              {dataByTab[tab].panel}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {inviteModal.isOpen && (
        <InviteOrUpdateUserModal
          {...inviteModal}
          initialState={{
            email: person.email ?? undefined,
            first_name: person.first_name ?? undefined,
            last_name: person.last_name ?? undefined,
          }}
        />
      )}
    </Stack>
  )
}
