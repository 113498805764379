import type {
  DocumentElement,
  LegalAgreementMinimal,
  NegotiationContextElement,
  NegotiationPlaybookSection,
  ToolMinimal,
  VendorMinimal,
} from "@brm/schema-types/types.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { Flags } from "@brm/util/flags.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { agreementRouteById, toolRouteById, vendorRouteById } from "@brm/util/routes.js"
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useEffect, useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import {
  useDeleteNegotiationV1ByIdAgreementMutation,
  useGetNegotiationV1ByIdAgreementComparisonQuery,
  usePutNegotiationV1ByIdAgreementAndAgreementIdMutation,
  type DocumentMinimal,
} from "../../app/services/generated-api.js"
import { AgentResponse } from "../../features/betsy/AgentResponse.js"
import { useChatContext } from "../../features/betsy/use-chat-context.js"
import { formatFileSize } from "../../util/format.js"
import { FileTypeIcon } from "../Document/FileTypeIcon.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { AgentFocusIcon, ArrowUpIcon, FolderIcon, MoreMenuIcon } from "../icons/icons.js"
import { Link } from "../Link.js"
import { RenderedMarkdown } from "../RenderedMarkdown.js"
import { RelativeDateTimeDisplay } from "../Timestamp.js"
import AgreementSelectionModal from "./AgreementSelectionModal.js"
import { NegotiationPanelHeader } from "./NegotiationPanelHeader.js"

const sharedButtonStyle = {
  size: "sm",
  variant: "ghost",
}

const layoutProps = {
  maxWidth: "512px",
  minWidth: "320px",
  marginX: "auto",
}

export default function Playbook(props: {
  negotiationId: string
  sections: NegotiationPlaybookSection[]
  documents: DocumentMinimal[]
  getDocumentDownloadUrl: (documentId: string) => string
  inputLegalAgreement?: LegalAgreementMinimal | null
  vendor?: VendorMinimal | null
  tool?: ToolMinimal | null
  activeVendorAgreements: LegalAgreementMinimal[]
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const showDocuments = hashParams.get("tab") === "documents"
  const { sections, documents, getDocumentDownloadUrl, negotiationId } = props
  const intl = useIntl()
  const playbookContainerRef = useRef<HTMLDivElement>(null)
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const isPlaybookEnabled = useFeatureFlagEnabled(Flags.NEGOTIATION_PLAYBOOK_ENABLED)
  const isAgreementComparisonEnabled = useFeatureFlagEnabled(Flags.NEGOTIATION_PROPOSAL_COMPARISON_ENABLED)
  const { data: agreementComparisonSection, isLoading: isAgreementComparisonLoading } =
    useGetNegotiationV1ByIdAgreementComparisonQuery(isAgreementComparisonEnabled ? { id: negotiationId } : skipToken)

  // Add scroll event listener to show/hide the scroll to top button
  useEffect(() => {
    const handleScroll = () => {
      if (playbookContainerRef.current) {
        setShowScrollToTop(playbookContainerRef.current.scrollTop > 100)
      }
    }

    const container = playbookContainerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
      return () => container.removeEventListener("scroll", handleScroll)
    }
    return () => {}
  }, [])

  return (
    <>
      <NegotiationPanelHeader
        title={intl.formatMessage({
          id: "negotiation.playbook.title",
          defaultMessage: "Overview",
          description: "Title for the playbook section in the negotiation interface",
        })}
        after={
          documents.length > 0 && (
            <Button
              {...sharedButtonStyle}
              onClick={() => {
                if (showDocuments) {
                  hashParams.delete("tab")
                } else {
                  hashParams.set("tab", "documents")
                }
                navigate({
                  search: location.search,
                  hash: `#${hashParams}`,
                })
              }}
              colorScheme="brand"
              leftIcon={<Icon as={FolderIcon} />}
              isActive={showDocuments}
              borderWidth={1}
              borderColor="transparent"
              _active={{ borderColor: "brand.200", borderWidth: 1, backgroundColor: "brand.50" }}
            >
              <FormattedMessage
                id="negotiation.playbook.showDocuments"
                description="File count in the playbook"
                defaultMessage="{count, plural, one {# file} other {# files}}"
                values={{ count: documents.length }}
              />
            </Button>
          )
        }
      />

      <Flex ref={playbookContainerRef} flexDirection="column" height="100%" overflow="auto">
        {showScrollToTop && (
          <Box position="sticky" top="0" zIndex="1" width="100%" textAlign="center" paddingY={3}>
            <Button
              variant="solid"
              onClick={() => {
                playbookContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" })
              }}
              leftIcon={<Icon as={ArrowUpIcon} />}
              boxShadow="md"
              size="sm"
              backgroundColor="white"
            >
              <FormattedMessage
                id="negotiation.playbook.backToTop"
                description="Back to top button in the playbook section"
                defaultMessage="Back to Top"
              />
            </Button>
          </Box>
        )}
        <Stack paddingX={4} paddingTop={4} gap={6}>
          {showDocuments ? (
            <PlaybookDocuments documents={documents} getDocumentDownloadUrl={getDocumentDownloadUrl} />
          ) : (
            <>
              <Box>
                <LegalAgreementInfo
                  legalAgreement={props.inputLegalAgreement}
                  activeAgreements={props.activeVendorAgreements}
                  negotiationId={props.negotiationId}
                />
              </Box>
              {isAgreementComparisonEnabled && isAgreementComparisonLoading ? (
                <Flex fontSize="sm" color="purple.600" alignItems="center" gap={2}>
                  <Spinner size="sm" />
                  <FormattedMessage
                    id="negotiation.playbook.generatingComparison"
                    description="Generating comparison..."
                    defaultMessage="Comparing current proposal..."
                  />
                </Flex>
              ) : (
                isAgreementComparisonEnabled &&
                agreementComparisonSection?.comparison && (
                  <Box padding={2} backgroundColor="gray.50" borderRadius="lg" borderColor="gray.200" borderWidth={1}>
                    <Text fontSize="lg" fontWeight="semibold">
                      <FormattedMessage
                        id="negotiation.playbook.currentProposal"
                        defaultMessage="Current Proposal"
                        description="Current proposal title in the playbook"
                      />
                    </Text>
                    <RenderedMarkdown content={agreementComparisonSection.comparison} />
                  </Box>
                )
              )}
              <Box>{props.vendor && <VendorInfo vendor={props.vendor} />}</Box>
              <Box>{props.tool && <ToolInfo tool={props.tool} />}</Box>
              {isPlaybookEnabled && <PlaybookContextSections sections={sections} />}
            </>
          )}
        </Stack>
        <Flex marginBottom={80} />
      </Flex>
    </>
  )
}

const PlaybookDocuments = ({
  documents,
  getDocumentDownloadUrl,
}: {
  documents: DocumentMinimal[]
  getDocumentDownloadUrl: (documentId: string) => string
}) => {
  const { getFocusedContexts, insertDocumentContext, removeContext } = useChatContext()
  const focusedDocuments = useMemo(
    () => getFocusedContexts({ type: "document" }) as DocumentElement[],
    [getFocusedContexts]
  )

  return (
    <>
      {documents.map((document) => {
        const isFocused = focusedDocuments.some((context) => context.id === document.id)
        return (
          <PlaybookDocument
            key={document.id}
            document={document}
            getDocumentDownloadUrl={getDocumentDownloadUrl}
            isFocused={isFocused}
            onToggle={() => {
              if (isFocused) {
                removeContext({ type: "document", documentId: document.id })
              } else {
                insertDocumentContext(document)
              }
            }}
          />
        )
      })}
    </>
  )
}

const PlaybookDocument = ({
  document,
  getDocumentDownloadUrl,
  isFocused,
  onToggle,
}: {
  document: DocumentMinimal
  getDocumentDownloadUrl: (documentId: string) => string
  isFocused: boolean
  onToggle: () => void
}) => {
  const intl = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const documentInUrl = hashParams.get("document")

  useEffect(() => {
    if (ref.current && documentInUrl === document.id) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
      hashParams.delete("document")
      navigate({ search: location.search, hash: `#${hashParams}` }, { replace: true })
    }
  }, [isFocused, documentInUrl, document.id, navigate, location.pathname, hashParams, location.search])

  return (
    <>
      <HStack key={document.id} gap={2} alignItems="flex-start" ref={ref}>
        <FileTypeIcon mimeType={document.mime_type} boxSize={6} margin={1} />
        <Stack gap={3} flex={1}>
          <HStack justifyContent="space-between" alignItems="flex-start">
            <Stack gap={1}>
              <Text fontWeight="semibold" fontSize="lg" noOfLines={2}>
                {document.file_name}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {`${formatFileSize(document.file_size)} - ${formatDate(intl, document.created_at)}`}
              </Text>
            </Stack>
            <FocusSectionButton isFocused={isFocused} onToggle={onToggle} />
          </HStack>
          {document.summary ? (
            <Text fontSize="sm">
              <RenderedMarkdown content={document.summary} />
            </Text>
          ) : document.extraction_status !== "completed" && document.extraction_status !== "failed" ? (
            <Flex fontSize="sm" color="purple.600" alignItems="center" gap={2}>
              <Spinner size="sm" />
              <FormattedMessage
                id="negotiation.playbook.noSummary"
                description="No summary found for the document"
                defaultMessage="Generating summary..."
              />
            </Flex>
          ) : null}
          <Button
            as={Link}
            to={getDocumentDownloadUrl(document.id)}
            target="_blank"
            variant="outline"
            size="md"
            width="fit-content"
            boxShadow="xs"
            _hover={{ color: "gray.900", textDecoration: "none", backgroundColor: "gray.100" }}
          >
            <FormattedMessage
              id="negotiation.playbook.viewFile"
              description="View file button in the playbook section"
              defaultMessage="View File"
            />
          </Button>
        </Stack>
      </HStack>
      <Divider width="100%" />
    </>
  )
}

const PlaybookContextSections = ({ sections }: { sections: NegotiationPlaybookSection[] }) => {
  const { getFocusedContexts, insertPlaybookSectionContext, removeContext } = useChatContext()
  const focusedPlaybookSections = getFocusedContexts({ type: "negotiation-context" }) as NegotiationContextElement[]

  return (
    <>
      {sections.map((section, index) => {
        const isFocused = focusedPlaybookSections.some((context) => context.section_key === section.section_key)
        return (
          <PlaybookContextSection
            key={section.id}
            section={section}
            isFocused={isFocused}
            onToggle={() => {
              if (isFocused) {
                removeContext({ type: "negotiation-context", sectionKey: section.section_key })
              } else {
                insertPlaybookSectionContext(section.section_key, section.negotiation_id)
              }
            }}
            title={`${index + 1}. ${section.title}`}
          />
        )
      })}

      {sections.length === 0 && (
        <Center height="100%">
          <FormattedMessage
            id="negotiation.playbook.noSections"
            description="No sections found in the playbook"
            defaultMessage="No sections found"
          />
        </Center>
      )}
    </>
  )
}

const PlaybookContextSection = ({
  section,
  isFocused,
  onToggle,
  title,
}: {
  section: NegotiationPlaybookSection
  isFocused: boolean
  onToggle: () => void
  title: string
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const playbookSectionHash = hashParams.get("playbook")

  const { id, section_key, badges, content } = section
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (playbookSectionHash === section_key && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
      hashParams.delete("playbook")
      navigate({ search: location.search, hash: `#${hashParams}` }, { replace: true })
    }
  }, [playbookSectionHash, section_key, navigate, location.pathname, hashParams, location.search])

  return (
    <>
      <Box key={id} {...layoutProps} ref={ref}>
        <Flex gap={2} alignItems="center" marginBottom={4} flexWrap="wrap">
          <Heading size="xs">{title}</Heading>

          {badges.slice(0, 2).map((badge) => (
            <Badge key={badge} height="fit-content" colorScheme="brand" padding={1}>
              {badge}
            </Badge>
          ))}

          <Spacer />

          <FocusSectionButton isFocused={isFocused} onToggle={onToggle} />
        </Flex>

        <AgentResponse content={content} isLoading={false} status="waiting" />
      </Box>

      <Divider marginTop={3} {...layoutProps} />
    </>
  )
}

function FocusSectionButton({ isFocused, onToggle }: { isFocused: boolean; onToggle: () => void }) {
  if (isFocused) {
    return (
      <IconButtonWithTooltip
        {...sharedButtonStyle}
        variant="solid"
        colorScheme="purple"
        icon={<AgentFocusIcon height={16} width={16} color="white" />}
        label="Remove focus"
        aria-label="Remove focus"
        onClick={onToggle}
      />
    )
  }

  return (
    <IconButtonWithTooltip
      {...sharedButtonStyle}
      variant="ghost"
      colorScheme="purple"
      icon={<AgentFocusIcon height={16} width={16} />}
      label="Tell me more"
      aria-label="Tell me more"
      onClick={onToggle}
    />
  )
}

const LegalAgreementInfo = ({
  legalAgreement,
  activeAgreements,
  negotiationId,
  onAgreementChanged,
}: {
  legalAgreement?: LegalAgreementMinimal | null
  activeAgreements: LegalAgreementMinimal[]
  negotiationId?: string
  onAgreementChanged?: () => void
}) => {
  const intl = useIntl()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [updateNegotiationAgreement] = usePutNegotiationV1ByIdAgreementAndAgreementIdMutation()
  const [removeNegotiationAgreement] = useDeleteNegotiationV1ByIdAgreementMutation()

  if (!legalAgreement) {
    return (
      <ConnectAgreement
        activeAgreements={activeAgreements}
        negotiationId={negotiationId}
        onAgreementChanged={onAgreementChanged}
      />
    )
  }

  const handleAgreementSelected = async (agreementId: string | null) => {
    if (!negotiationId) return

    if (agreementId === null) {
      try {
        await removeNegotiationAgreement({ id: negotiationId }).unwrap()
        toast({
          title: "Agreement removed",
          description: "The agreement has been removed from this negotiation.",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        if (onAgreementChanged) {
          onAgreementChanged()
        }
      } catch (_err) {
        toast({
          title: "Error removing agreement",
          description: "There was a problem removing the agreement from this negotiation.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    } else {
      try {
        await updateNegotiationAgreement({
          id: negotiationId,
          agreementId,
        }).unwrap()

        toast({
          title: "Agreement updated",
          description: "The connected agreement has been updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        })

        if (onAgreementChanged) {
          onAgreementChanged()
        }
      } catch (_err) {
        toast({
          title: "Error updating agreement",
          description: "There was a problem updating the connected agreement.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  return (
    <Box padding={2} backgroundColor="gray.50" borderRadius="lg" borderColor="gray.200" borderWidth={1}>
      <Stack gap={2}>
        <HStack justifyContent="space-between" width="full">
          <Link
            to={agreementRouteById(legalAgreement.id)}
            target="_blank"
            fontSize="lg"
            fontWeight="semibold"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            display="block"
          >
            {legalAgreement.display_name}
          </Link>
          {legalAgreement.total_contract_value && (
            <HStack flexShrink={0}>
              <Text color="brand.500" fontSize="md" fontWeight="semibold">
                <FormattedMessage
                  id="negotiation.playbook.tcv"
                  defaultMessage="TCV"
                  description="Total Contract Value label"
                />
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {formatCurrency(legalAgreement.total_contract_value, intl)}
              </Text>
            </HStack>
          )}
        </HStack>

        {legalAgreement.decision_date && (
          <HStack>
            <Text fontSize="xs" fontWeight="medium">
              <FormattedMessage
                id="negotiation.playbook.renewalDecisionDate"
                defaultMessage="Renewal Decision Date"
                description="Renewal decision date label"
              />
            </Text>
            <Text fontSize="xs" color="gray.600">
              {formatDate(intl, legalAgreement.decision_date)}
              {" - "}
              <RelativeDateTimeDisplay dateTime={legalAgreement.decision_date} />
            </Text>
            <Spacer />
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Icon as={MoreMenuIcon} />}
                variant="ghost"
                size="sm"
                aria-label="Legal agreement options"
              />
              <MenuList>
                <MenuItem onClick={onOpen} isDisabled={activeAgreements.length === 0}>
                  <FormattedMessage
                    id="negotiation.playbook.changeConnectedAgreement"
                    defaultMessage="Change Connected Agreement"
                    description="Option to change the connected agreement in the negotiation playbook"
                  />
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        )}
      </Stack>

      <AgreementSelectionModal
        isOpen={isOpen}
        onClose={onClose}
        agreements={activeAgreements}
        onSubmit={handleAgreementSelected}
        initialSelectedAgreement={legalAgreement?.id}
        showNoAgreementOption={true}
      />
    </Box>
  )
}

const ConnectAgreement = ({
  activeAgreements,
  negotiationId,
  onAgreementChanged,
}: {
  activeAgreements: LegalAgreementMinimal[]
  negotiationId?: string
  onAgreementChanged?: () => void
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [updateNegotiationAgreement] = usePutNegotiationV1ByIdAgreementAndAgreementIdMutation()
  const toast = useToast()

  const handleAgreementSelected = async (agreementId: string | null) => {
    if (!negotiationId || !agreementId) return

    try {
      await updateNegotiationAgreement({
        id: negotiationId,
        agreementId,
      }).unwrap()

      toast({
        title: "Agreement connected",
        description: "The agreement has been connected successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      })

      if (onAgreementChanged) {
        onAgreementChanged()
      }
    } catch (_err) {
      toast({
        title: "Error connecting agreement",
        description: "There was a problem connecting the agreement.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Box padding={2} backgroundColor="gray.50" borderRadius="lg" borderColor="gray.200" borderWidth={1}>
      <Stack gap={3}>
        <HStack justifyContent="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            <FormattedMessage
              id="negotiation.playbook.noAgreement"
              defaultMessage="No Agreement Connected"
              description="Text shown when no agreement is connected to the negotiation"
            />
          </Text>
          <Button
            p={2}
            size="sm"
            colorScheme="brand"
            onClick={onOpen}
            isDisabled={activeAgreements.length === 0}
            minWidth="fit-content"
          >
            <FormattedMessage
              id="negotiation.playbook.connectAgreement"
              defaultMessage="Connect Agreement"
              description="Button to connect an agreement to the negotiation"
            />
          </Button>
        </HStack>

        <Text fontSize="sm" color="gray.600">
          <FormattedMessage
            id="negotiation.playbook.connectAgreementHelp"
            defaultMessage="If this is a renewal of an existing agreement, connect it to add more context to the negotiation."
            description="Help text explaining why to connect an agreement"
          />
        </Text>
      </Stack>

      <AgreementSelectionModal
        isOpen={isOpen}
        onClose={onClose}
        agreements={activeAgreements}
        onSubmit={handleAgreementSelected}
        initialSelectedAgreement={null}
        showNoAgreementOption={false}
      />
    </Box>
  )
}

// Custom type for vendor with vendor_listing
interface VendorWithListing extends VendorMinimal {
  vendor_listing?: {
    id: string
    object_type: "VendorListing"
    display_name: string
    description?: string
    website?: string
  }
}

const VendorInfo = ({ vendor }: { vendor: VendorWithListing }) => {
  return (
    <Box padding={2} backgroundColor="gray.50" borderRadius="lg" borderColor="gray.200" borderWidth={1}>
      <Stack gap={2}>
        <HStack justifyContent="space-between">
          <Link to={vendorRouteById(vendor.id)} target="_blank" fontSize="lg" fontWeight="semibold">
            {` Vendor: ${vendor.display_name}`}
          </Link>
        </HStack>

        {(vendor.description || vendor.vendor_listing?.description) && (
          <Text fontSize="sm" color="gray.600">
            {vendor.description || vendor.vendor_listing?.description}
          </Text>
        )}

        {(vendor.website || vendor.vendor_listing?.website) && (
          <HStack>
            <Text fontSize="xs" fontWeight="medium">
              <FormattedMessage
                id="negotiation.playbook.vendorWebsite"
                defaultMessage="Website"
                description="Vendor website label"
              />
            </Text>
            <Link
              to={vendor.website || vendor.vendor_listing?.website || "#"}
              target="_blank"
              fontSize="xs"
              color="brand.500"
              textDecoration="underline"
            >
              {vendor.website || vendor.vendor_listing?.website}
            </Link>
          </HStack>
        )}
      </Stack>
    </Box>
  )
}

// Custom type for tool with tool_listing
interface ToolWithListing extends ToolMinimal {
  tool_listing?: {
    id: string
    object_type: "ToolListing"
    display_name: string
    description?: string
    website?: string
  }
}

const ToolInfo = ({ tool }: { tool: ToolWithListing }) => {
  return (
    <Box padding={2} backgroundColor="gray.50" borderRadius="lg" borderColor="gray.200" borderWidth={1}>
      <Stack gap={2}>
        <HStack justifyContent="space-between">
          <Link to={toolRouteById(tool.id)} target="_blank" fontSize="lg" fontWeight="semibold">
            {`Tool: ${tool.display_name}`}
          </Link>
        </HStack>

        {(tool.description || tool.tool_listing?.description) && (
          <Text fontSize="sm" color="gray.600">
            {tool.description || tool.tool_listing?.description}
          </Text>
        )}

        {(tool.website || tool.tool_listing?.website) && (
          <HStack>
            <Text fontSize="xs" fontWeight="medium">
              <FormattedMessage
                id="negotiation.playbook.toolWebsite"
                defaultMessage="Tool Website"
                description="Tool website label"
              />
            </Text>
            <Link
              to={tool.website || tool.tool_listing?.website || "#"}
              target="_blank"
              fontSize="xs"
              color="brand.500"
              textDecoration="underline"
            >
              {tool.website || tool.tool_listing?.website}
            </Link>
          </HStack>
        )}
      </Stack>
    </Box>
  )
}
