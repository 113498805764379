import type { InboxNotification } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Temporal } from "@js-temporal/polyfill"
import type { IntlShape } from "react-intl"
import { createPath } from "react-router-dom"
import type { Descendant } from "slate"
import { serializeRichText } from "../../../components/RichTextEditor/util/rich-text.js"
import { log } from "../../../util/logger.js"
import { getNotificationLinkDestination } from "../link-destination.js"

export const getNotificationHeaderText = (notification: InboxNotification, intl: IntlShape) => {
  switch (notification.type) {
    case "mention": {
      // There is no field or step when the notification is coming from a workflow run's context.
      const fieldOrStep = notification.field_title || notification.workflow_run_step_display_name
      const requestToolOrVendorName =
        notification.workflow_run_display_name || notification.vendor_display_name || notification.tool_display_name
      if (fieldOrStep) {
        return intl.formatMessage(
          {
            id: "inbox.mention.header.with_field_or_step",
            description: "Notification header for mention notification",
            defaultMessage: "{requestToolOrVendorName} - {fieldOrStep}",
          },
          {
            requestToolOrVendorName,
            fieldOrStep,
          }
        )
      }
      return intl.formatMessage(
        {
          id: "inbox.mention.header.without_field_or_step",
          description: "Notification header for mention notification",
          defaultMessage: "{requestToolOrVendorName}",
        },
        {
          requestToolOrVendorName,
        }
      )
    }
    case "timeline_comment.reaction":
      // comments exist on workflows, tools, and vendors. We match the corresponding comment header formats.
      if (notification.workflow_run_display_name) {
        return intl.formatMessage(
          {
            id: "inbox.timeline_comment.reaction.header",
            description: "Notification header for workflow run timeline comment reaction",
            defaultMessage: "{requestDisplayName} - {fieldOrStep}",
          },
          {
            requestDisplayName: notification.workflow_run_display_name,
            fieldOrStep: notification.field_title || notification.workflow_run_step_display_name,
          }
        )
      }

      if (notification.tool_display_name) {
        return intl.formatMessage(
          {
            id: "inbox.timeline_comment.reaction.header",
            description: "Notification header for tool timeline comment reaction",
            defaultMessage: "{toolDisplayName}",
          },
          {
            toolDisplayName: notification.tool_display_name,
          }
        )
      }

      if (notification.vendor_display_name) {
        return intl.formatMessage(
          {
            id: "inbox.timeline_comment.reaction.header",
            description: "Notification header for vendor timeline comment reaction",
            defaultMessage: "{vendorDisplayName}",
          },
          {
            vendorDisplayName: notification.vendor_display_name,
          }
        )
      }

      return intl.formatMessage(
        {
          id: "inbox.timeline_comment.reaction.header",
          description: "Notification header for timeline comment reaction",
          defaultMessage: "{reactionActorName} reacted with {reaction_emoji}",
        },
        {
          reactionActorName: displayPersonName(notification.sender!, intl),
          reaction_emoji: notification.reaction_emoji,
        }
      )
    case "workflow_run.timeline_comment":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run.timeline_comment.header",
          description: "Notification header for workflow run timeline comment",
          defaultMessage: "{requestDisplayName} - {fieldOrStep}",
        },
        {
          requestDisplayName: notification.workflow_run_display_name,
          fieldOrStep: notification.field_title || notification.workflow_run_step_display_name,
        }
      )
    case "vendor_owned.timeline_comment":
    case "vendor.timeline_comment":
      return intl.formatMessage(
        {
          id: "inbox.vendor.timeline_comment.header",
          description: "Notification header for vendor timeline comment",
          defaultMessage: "{vendorDisplayName}",
        },
        {
          vendorDisplayName: notification.vendor_display_name,
        }
      )
    case "tool_owned.timeline_comment":
    case "tool.timeline_comment":
      return intl.formatMessage(
        {
          id: "inbox.tool.timeline_comment.header",
          description: "Notification header for tool timeline comment",
          defaultMessage: "{toolDisplayName}",
        },
        {
          toolDisplayName: notification.tool_display_name,
        }
      )
    case "workflow_run_step.approval_requested":
    case "workflow_run_step.approval_overridden":
    case "workflow_run_step.approved":
    case "workflow_run_step.changes_requested":
    case "workflow_run_step.unapproved":
    case "workflow_run_step.seller_submitted":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.header",
          description: "Notification header for event involving a workflow run step in a workflow run",
          defaultMessage: "{workflowRunDisplayname} - {stepDisplayName}",
        },
        {
          workflowRunDisplayname: notification.workflow_run_display_name,
          stepDisplayName: notification.workflow_run_step_display_name,
        }
      )
    case "workflow_run_step_field.reopened":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step_field.reopened.header",
          description: "Notification header for workflow run step field reopened",
          defaultMessage: "{workflowRunDisplayName} - {fieldTitle}",
        },
        {
          workflowRunDisplayName: notification.workflow_run_display_name,
          fieldTitle: notification.field_title,
        }
      )
    case "renewal_reminder":
    case "tool_owned_renewal_reminder":
    case "vendor_owned_renewal_reminder":
      return intl.formatMessage(
        {
          id: "inbox.opt_out_reminder.header",
          description: "Notification header for opt-out reminder",
          defaultMessage: "{agreementName}",
        },
        {
          agreementName: notification.legal_agreement.display_name,
        }
      )
    case "workflow_run.started":
    case "workflow_run.input_needed":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run.input_needed.header",
          description: "Notification header for workflow run input needed",
          defaultMessage: "{workflowRunDisplayName}",
        },
        {
          workflowRunDisplayName: notification.workflow_run_display_name,
        }
      )
    case "workflow_run.completed":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run.completed.header",
          description: "Notification header for workflow run completed",
          defaultMessage: "{workflowRunDisplayName}",
        },
        {
          workflowRunDisplayName: notification.workflow_run_display_name,
        }
      )
    case "integration.historical_agreement_crawl_done":
      return intl.formatMessage({
        id: "inbox.integration.historical_agreement_crawl_done.header",
        description: "Notification header for integration historical agreement crawl done",
        defaultMessage: "Gmail agreement import completed",
      })
    case "legal_agreement_created_by_system":
      return notification.legal_agreement.display_name
    case "document.email_ingested":
      return intl.formatMessage({
        id: "inbox.document.email_ingested.header",
        description: "Notification header for document ingested",
        defaultMessage: "A document was imported into BRM",
      })
    case "free_trial_expired":
      if (
        notification.trial_expires_at &&
        Temporal.Instant.compare(notification.trial_expires_at, Temporal.Now.instant()) < 0
      ) {
        return intl.formatMessage({
          id: "inbox.free_trial_expired.header",
          description: "Notification header for free trial expired",
          defaultMessage: "Your free trial has expired",
        })
      }
      return intl.formatMessage({
        id: "inbox.free_trial_expired.header",
        description: "Notification header for free trial expiring soon",
        defaultMessage: "Your free trial is expiring soon",
      })
    default:
      return unreachable(notification)
  }
}

export const getNotificationBodyText = (notification: InboxNotification, intl: IntlShape): string => {
  switch (notification.type) {
    case "timeline_comment.reaction":
      switch (notification.other_reactors.length) {
        case 0:
          return intl.formatMessage(
            {
              id: "inbox.timeline_comment.reaction.body",
              description: "Notification body for timeline comment reaction",
              defaultMessage: "{reactionEmoji} {reactor} reacted to: {commentText}",
            },
            {
              reactor: displayPersonName(notification.reactor, intl),
              reactionEmoji: notification.reaction_emoji,
              commentText: serializeRichText(notification.timeline_event.rich_text_body as Descendant[]).trim(),
            }
          )
        case 1:
          return intl.formatMessage(
            {
              id: "inbox.timeline_comment.reaction.body",
              description: "Notification body for timeline comment reaction",
              defaultMessage: "{reactionEmoji} {reactor} and {otherReactor} reacted to: {commentText}",
            },
            {
              reactor: displayPersonName(notification.reactor, intl),
              otherReactor: displayPersonName(notification.other_reactors[0] ?? "another", intl),
              reactionEmoji: notification.reaction_emoji,
              commentText: serializeRichText(notification.timeline_event.rich_text_body as Descendant[]).trim(),
            }
          )
        default:
          return intl.formatMessage(
            {
              id: "inbox.timeline_comment.reaction.body",
              description: "Notification body for timeline comment reaction",
              defaultMessage: "{reactionEmoji} {reactor} and others reacted to: {commentText}",
            },
            {
              reactor: displayPersonName(notification.reactor, intl),
              reactionEmoji: notification.reaction_emoji,
              commentText: serializeRichText(notification.timeline_event.rich_text_body as Descendant[]).trim(),
            }
          )
      }
    case "mention":
    case "workflow_run.timeline_comment":
    case "vendor.timeline_comment":
    case "tool.timeline_comment":
    case "vendor_owned.timeline_comment":
    case "tool_owned.timeline_comment": {
      const serializedRichText = serializeRichText(notification.timeline_event.rich_text_body as Descendant[]).trim()
      if (serializedRichText === "") {
        return intl.formatMessage(
          {
            id: "inbox.comment.noPlainText.body",
            description: "Notification body for mention notification",
            defaultMessage: "{userName} commented",
          },
          {
            userName: displayPersonName(notification.timeline_event.actor, intl),
          }
        )
      }
      return intl.formatMessage(
        {
          id: "inbox.comment.body",
          description: "Notification body for mention notification",
          defaultMessage: "{userName} commented: {commentText}",
        },
        {
          userName: displayPersonName(notification.timeline_event.actor, intl),
          commentText: serializedRichText,
        }
      )
    }
    case "workflow_run_step.approved":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.approved.body",
          description: "Notification body for workflow run step approved",
          defaultMessage: "Approved by {userDisplayName}",
        },
        {
          userDisplayName: displayPersonName(notification.sender, intl),
        }
      )
    case "workflow_run_step_field.reopened":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step_field.reopened.body",
          description: "Notification body for workflow run step field reopened",
          defaultMessage: "Reopened by {userDisplayName}",
        },
        {
          userDisplayName: displayPersonName(notification.timeline_event.actor, intl),
        }
      )
    case "workflow_run_step.approval_requested":
      return intl.formatMessage({
        id: "inbox.workflow_run_step.approval_requested.body",
        description: "Notification body for workflow run step approval requested",
        defaultMessage: "Your review is needed",
      })
    case "workflow_run_step.approval_overridden":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.approval_overridden.body",
          description: "Notification body for workflow run step approval overridden",
          defaultMessage: "{userDisplayName} approved {workflowRunStepDisplayName} on your behalf",
        },
        {
          userDisplayName: displayPersonName(notification.approver, intl),
          workflowRunStepDisplayName: notification.workflow_run_step_display_name,
        }
      )
    case "workflow_run_step.changes_requested":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.changes_requested.body",
          description: "Notification body for workflow run step changes requested",
          defaultMessage: "Changes Requested by {userDisplayName}",
        },
        {
          userDisplayName: displayPersonName(notification.sender, intl),
        }
      )
    case "workflow_run_step.unapproved":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.unapproved.body",
          description: "Notification body for workflow run step unapproved",
          defaultMessage: "Unapproved by {userDisplayName}",
        },
        {
          userDisplayName: displayPersonName(notification.sender, intl),
        }
      )
    case "workflow_run_step.seller_submitted":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run_step.seller_submitted.body",
          description: "Notification body for workflow run step seller submitted",
          defaultMessage: "{actorName} submitted information",
        },
        {
          actorName: notification.sender ? displayPersonName(notification.sender, intl) : "A seller",
        }
      )
    case "renewal_reminder":
    case "tool_owned_renewal_reminder":
    case "vendor_owned_renewal_reminder": {
      const decisionDate = notification.legal_agreement.decision_date ?? notification.legal_agreement.end_date
      // TODO: make this not possible
      if (!decisionDate) {
        return intl.formatMessage({
          id: "inbox.opt_out_reminder.body",
          description: "Notification body for opt-out reminder",
          defaultMessage: "Set to auto renew",
        })
      }
      return intl.formatMessage(
        {
          id: "inbox.tool_owned_opt_out_reminder.body",
          description: "Notification body for tool-owned opt-out reminder",
          defaultMessage: "Set to auto renew on {autoRenewDate}",
        },
        {
          autoRenewDate: formatDate(intl, decisionDate),
        }
      )
    }
    case "workflow_run.input_needed":
      return intl.formatMessage({
        id: "inbox.workflow_run.input_needed.body",
        description: "Notification body for workflow run input needed",
        defaultMessage: "Your input is needed",
      })
    case "workflow_run.started":
      return intl.formatMessage(
        {
          id: "inbox.workflow_run.started.body",
          description: "Notification body for workflow run started",
          defaultMessage: "Started by {userDisplayName}",
        },
        {
          userDisplayName: displayPersonName(notification.sender, intl),
        }
      )
    case "workflow_run.completed":
      switch (notification.workflow_run.status) {
        case "cancelled":
          return intl.formatMessage({
            id: "inbox.workflow_run.completed.cancelled.body",
            description: "Notification body for workflow run canceled",
            defaultMessage: "Cancelled",
          })
        case "approved":
          return intl.formatMessage({
            id: "inbox.workflow_run.completed.approved.body",
            description: "Notification body for workflow run approved",
            defaultMessage: "Approved",
          })
        case "rejected":
          return intl.formatMessage({
            id: "inbox.workflow_run.completed.rejected.body",
            description: "Notification body for workflow run rejected",
            defaultMessage: "Rejected",
          })
        case "in_progress":
          log.error("Workflow run completed should never have status in progress", { notificationId: notification.id })
          return intl.formatMessage({
            id: "inbox.workflow_run.completed.in_progress.body",
            description: "Notification body for workflow run in progress",
            defaultMessage: "In progress",
          })
        default:
          unreachable(notification.workflow_run.status)
      }
      break
    case "integration.historical_agreement_crawl_done":
      return intl.formatMessage(
        {
          id: "inbox.integration.historical_agreement_crawl_done.body",
          description: "Notification body for integration historical agreement crawl done",
          defaultMessage: "Found {agreementCount} agreements",
        },
        {
          agreementCount: notification.agreement_count,
        }
      )
    case "legal_agreement_created_by_system":
      return intl.formatMessage({
        id: "inbox.legal_agreement_created_by_system.body",
        description: "Notification body for legal agreement created",
        defaultMessage: "A new legal agreement was created",
      })
    case "free_trial_expired":
      return intl.formatMessage({
        id: "inbox.free_trial_expired.body",
        description: "Notification body for free trial expired",
        defaultMessage: "Purchase BRM to continue saving!",
      })
    case "document.email_ingested":
      if (notification.document_file_name) {
        return intl.formatMessage(
          {
            id: "inbox.document.email_ingested.body",
            description: "Notification body for document ingested",
            defaultMessage: "{documentDisplayName} was added to {objectDisplayName}",
          },
          {
            documentDisplayName: notification.document_file_name,
            objectDisplayName: notification.parent_object_name,
          }
        )
      }
      return intl.formatMessage(
        {
          id: "inbox.document.email_ingested.body",
          description: "Notification body for document ingested",
          defaultMessage: "A new document was added to {objectDisplayName}",
        },
        {
          objectDisplayName: notification.parent_object_name,
        }
      )
    default:
      return unreachable(notification)
  }
}

// Exported for storybook and notification items only
export enum NotificationItemFallbackIcons {
  "Tool",
  "Vendor",
  "User",
  "Gmail",
  "Announcement",
  "DocumentUpload",
}

export enum NotificationKeyboardShortcuts {
  TOGGLE_READ = "r",
  DELETE = "delete",
  BACKSPACE = "backspace",
  UP = "up",
  DOWN = "down",
  VI_DOWN_KEY = "j",
  VI_UP_KEY = "k",
  GMAIL_ARCHIVE_KEY = "e",
}

export const getNotificationKeyboardShortcutsDisplayText = (shortcut: NotificationKeyboardShortcuts): string => {
  switch (shortcut) {
    case NotificationKeyboardShortcuts.TOGGLE_READ:
      return "R"
    case NotificationKeyboardShortcuts.DELETE:
      return "\u232B"
    case NotificationKeyboardShortcuts.BACKSPACE:
      return "\u232B"
    case NotificationKeyboardShortcuts.UP:
      return "\u2191"
    case NotificationKeyboardShortcuts.DOWN:
      return "\u2193"
    case NotificationKeyboardShortcuts.VI_DOWN_KEY:
      return "K"
    case NotificationKeyboardShortcuts.VI_UP_KEY:
      return "J"
    case NotificationKeyboardShortcuts.GMAIL_ARCHIVE_KEY:
      return "E"
    default:
      return unreachable(shortcut)
  }
}

export enum TaskKeyboardShortcuts {
  SNOOZE = "s",
  DELETE = "delete",
  BACKSPACE = "backspace",
}

export const getTaskKeyboardShortcutsDisplayText = (shortcut: TaskKeyboardShortcuts): string => {
  switch (shortcut) {
    case TaskKeyboardShortcuts.SNOOZE:
      return "S"
    case TaskKeyboardShortcuts.DELETE:
      return "\u232B"
    case TaskKeyboardShortcuts.BACKSPACE:
      return "\u232B"
    default:
      return unreachable(shortcut)
  }
}

export const getNotificationSrcUrl = (notification: InboxNotification, organization_id: string) => {
  return `${notification.id}${createPath(getNotificationLinkDestination(notification, organization_id))}`
}
