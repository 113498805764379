import type { NegotiationPlaybookSectionKey, ObjectType } from "@brm/schema-types/types.js"
import type { ChatContextElement } from "@brm/type-helpers/rich-text.js"
import { createContext } from "react"
import type { Descendant } from "slate"
import type { DocumentMinimal, EmailWithReplyDrafts } from "../../app/services/generated-api.js"

export interface ChatContextType {
  focusedContexts: ChatContextElement[]
  // Filters focused contexts by type and object type
  getFocusedContexts: (
    param:
      | { type: "document" | "negotiation-context" | "mention" }
      | { type: "standard-object"; objectType: ObjectType }
  ) => ChatContextElement[]

  // Chat value
  chatValue: Descendant[] | undefined
  setChatValue: React.Dispatch<React.SetStateAction<Descendant[] | undefined>>

  // Insert context into the chat
  insertEmailContext: (email: EmailWithReplyDrafts) => void
  insertDocumentContext: (document: DocumentMinimal) => void
  insertText: (text: string) => void
  insertPlaybookSectionContext: (sectionKey: NegotiationPlaybookSectionKey, negotiationId: string) => void

  // Remove context from the chat
  removeContext: (
    param:
      | { type: "negotiation-context"; sectionKey?: NegotiationPlaybookSectionKey }
      | { type: "document"; documentId?: string }
  ) => void
}

// Create the context with default values
export const ChatContext = createContext<ChatContextType>({
  chatValue: undefined,
  focusedContexts: [],
  getFocusedContexts: () => [],
  setChatValue: () => {},
  insertEmailContext: () => {},
  insertDocumentContext: () => {},
  insertText: () => {},
  insertPlaybookSectionContext: () => {},
  removeContext: () => {},
})
