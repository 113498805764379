import type { DateString, Task } from "@brm/schema-types/types.js"
import { Temporal } from "@js-temporal/polyfill"
import { useCallback, useMemo, type FC } from "react"
import { useIntl } from "react-intl"
import { usePostNotificationV1TaskSnoozeMutation } from "../../../app/services/generated-api.js"
import CalendarDatePickerModal from "../../../components/CalendarDatePickerModal/CalendarDatePickerModal.js"
import type { DateShortcut } from "../../../util/form.js"

interface TaskSnoozeModalProps {
  isOpen: boolean
  onClose: () => void
  task: Task
  maxDate?: DateString
  onSnooze: () => void
}
const TaskSnoozeModal: FC<TaskSnoozeModalProps> = ({ isOpen, onClose, task, maxDate, onSnooze }) => {
  const [snoozeTask] = usePostNotificationV1TaskSnoozeMutation()
  const intl = useIntl()

  if (!(task.type === "agreement_renewal" || task.type === "negotiation_reply")) {
    throw new Error("Not a snoozable task")
  }

  const onSubmit = useCallback(
    async (date: DateString) => {
      await snoozeTask({ taskSnoozeBody: { task, snoozedUntil: date } })
      onSnooze()
      onClose()
    },
    [snoozeTask, onClose, task, onSnooze]
  )

  const options: DateShortcut[] = useMemo(() => {
    const optionalIntervals: DateShortcut[] = [
      {
        displayName: intl.formatMessage({
          id: "inbox.task.snooze.tomorrow",
          defaultMessage: "Tomorrow",
          description: "Snooze option for tomorrow",
        }),
        date: Temporal.Now.plainDateISO().add({ days: 1 }).toString(),
      },
      {
        displayName: intl.formatMessage({
          id: "inbox.task.snooze.week",
          defaultMessage: "In a week",
          description: "Snooze option for one week",
        }),
        date: Temporal.Now.plainDateISO().add({ days: 7 }).toString(),
      },
      ...[15, 30, 60].map((days) => ({
        displayName: intl.formatMessage(
          {
            id: "inbox.task.snooze.days",
            defaultMessage: "In {days} days",
            description: "Snooze option for specified number of days",
          },
          { days }
        ),
        date: Temporal.Now.plainDateISO().add({ days }).toString(),
      })),
    ]
    const optionList: DateShortcut[] = []

    for (const interval of optionalIntervals) {
      if (
        !maxDate ||
        Temporal.PlainDate.compare(Temporal.PlainDate.from(maxDate), Temporal.PlainDate.from(interval.date)) >= 0
      ) {
        optionList.push({ displayName: interval.displayName, date: interval.date })
      }
    }
    return optionList
  }, [intl, maxDate])

  return (
    <CalendarDatePickerModal
      header={intl.formatMessage({
        id: "inbox.task.snooze",
        defaultMessage: "Snooze until?",
        description: "Snooze modal header",
      })}
      isOpen={isOpen}
      maxDate={maxDate}
      onClose={onClose}
      onSubmit={onSubmit}
      options={options}
    />
  )
}

export default TaskSnoozeModal
