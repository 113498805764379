import { Box, Heading, Stack, Text } from "@chakra-ui/react"
import { OnboardingWelcomeCardButton } from "./OnboardingWelcomeCardButton.js"

export interface OnboardingWelcomeCardProps {
  preHeader?: string
  heading: string
  contentParagraphs?: string[]
  contentBullets?: string[]
  contentHighlight?: string
  cta: string
  ctaIcon?: React.ReactElement
  onCtaClick: () => void
  secondaryActionIntro?: string
  secondaryActionCta?: string
  secondaryActionOnClick?: () => void
}

export const OnboardingWelcomeCard = ({
  preHeader,
  heading,
  contentParagraphs,
  contentBullets,
  contentHighlight,
  cta,
  ctaIcon,
  onCtaClick,
  secondaryActionIntro,
  secondaryActionCta,
  secondaryActionOnClick,
}: OnboardingWelcomeCardProps) => {
  return (
    <Box position="relative" fontFamily="geograph" maxW={88}>
      {preHeader && (
        <Box position="absolute" bottom="100%" mb={8}>
          <OnboardingWelcomeCardPreHeader preHeader={preHeader} />
        </Box>
      )}
      <Stack spacing={8}>
        <Stack spacing={2}>
          <OnboardingWelcomeCardHeader header={heading} />
          {contentParagraphs && <OnboardingWelcomeCardContentParagraph paragraphs={contentParagraphs} />}
          {contentBullets && <OnboardingWelcomeCardContentBullets bullets={contentBullets} />}
          {contentHighlight && <OnboardingWelcomeCardContentHighlight highlight={contentHighlight} />}
        </Stack>
        <OnboardingWelcomeCardButton cta={cta} onClick={onCtaClick} icon={ctaIcon || undefined} />
        {secondaryActionCta && secondaryActionOnClick && (
          <OnboardingWelcomeCardSecondaryAction
            intro={secondaryActionIntro}
            cta={secondaryActionCta}
            onClick={secondaryActionOnClick}
          />
        )}
      </Stack>
    </Box>
  )
}

const OnboardingWelcomeCardPreHeader = ({ preHeader }: { preHeader: string }) => {
  return (
    <Text fontWeight="medium" fontSize="2xl" lineHeight="1.6rem" letterSpacing="-.018em" color="brand.500">
      {preHeader}
    </Text>
  )
}

const OnboardingWelcomeCardHeader = ({ header }: { header: string }) => {
  return (
    <Heading fontSize="2rem" fontWeight="bold" letterSpacing="-.016em" lineHeight={9} color="black">
      {header}
    </Heading>
  )
}

const OnboardingWelcomeCardContentParagraph = ({ paragraphs }: { paragraphs: string[] }) => {
  return (
    <Stack spacing={1}>
      {paragraphs.map((paragraph, index) => (
        <Text key={index} fontSize="xl" lineHeight="1.6rem" letterSpacing="-.024em" color="black">
          {paragraph}
        </Text>
      ))}
    </Stack>
  )
}

const OnboardingWelcomeCardContentBullets = ({ bullets }: { bullets: string[] }) => {
  return (
    <Box marginLeft="1.5rem">
      <Stack as="ul" spacing={0}>
        {bullets.map((bullet) => (
          <Text as="li" key={bullet} fontSize="xl" lineHeight="1.6rem" letterSpacing="-.024em" color="black">
            {bullet}
          </Text>
        ))}
      </Stack>
    </Box>
  )
}

const OnboardingWelcomeCardContentHighlight = ({ highlight }: { highlight: string }) => {
  return (
    <Text fontWeight="medium" fontSize="2xl" lineHeight="1.6rem" letterSpacing="-.018em" color="brand.500">
      {highlight}
    </Text>
  )
}
interface OnboardingWelcomeCardSecondaryActionProps {
  intro?: string
  cta: string
  onClick: () => void
}

const OnboardingWelcomeCardSecondaryAction = ({ intro, cta, onClick }: OnboardingWelcomeCardSecondaryActionProps) => {
  return (
    <Text fontSize="sm" color="black" textAlign="center">
      {intro && <Text as="span">{intro} </Text>}
      <Text as="span" textDecoration="underline" cursor="pointer" onClick={onClick}>
        {cta}
      </Text>
    </Text>
  )
}
