import { BRM_BUY_EMAIL } from "@brm/util/email.js"
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseModalProps,
} from "@chakra-ui/react"
import { useRef, type FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "./Link.js"
import BamLogo from "./icons/bam-logo.js"
import { posthogCaptureEvent } from "../util/posthog-captures.js"
export const TrialExpiredModal: FunctionComponent<UseModalProps> = (props) => {
  const contactUsButtonRef = useRef<HTMLButtonElement>(null)
  return (
    <Modal {...props} size="xl" isCentered initialFocusRef={contactUsButtonRef}>
      <ModalOverlay />
      <ModalContent overflow="hidden" gap={6}>
        <ModalHeader fontSize="1.5rem" fontWeight="bold">
          <FormattedMessage
            defaultMessage="Your FREE trial has expired"
            description="Trial expired modal"
            id="TrialExpiredModal.title"
          />
          <BamLogo
            style={{
              position: "absolute",
              top: "-85px",
              right: "-8px",
              width: "15%",
              height: "auto",
              transform: "scaleX(-1)",
            }}
          />
        </ModalHeader>
        <ModalBody fontSize="1.15rem">
          <Text>
            <FormattedMessage
              id="TrialExpiredModal.description"
              defaultMessage="Purchase BRM to continue using our services. We’d love to hear about your experience. Send us an email at {emailAddress} to unlock future savings."
              description="Trial expired modal"
              values={{
                emailAddress: (
                  <Link
                    cursor="pointer"
                    variant="highlighted"
                    to={`mailto:${BRM_BUY_EMAIL}`}
                    onClick={() => posthogCaptureEvent("trial_expired_modal_contact_us_button_clicked")}
                  >
                    {BRM_BUY_EMAIL}
                  </Link>
                ),
              }}
            />
          </Text>
        </ModalBody>
        <ModalFooter>
          <Link to={`mailto:${BRM_BUY_EMAIL}`} variant="noUnderline">
            <Button
              cursor="pointer"
              colorScheme="brand"
              borderRadius="full"
              fontSize="1.1rem"
              ref={contactUsButtonRef}
              onClick={() => posthogCaptureEvent("trial_expired_modal_contact_us_button_clicked")}
            >
              <FormattedMessage
                id="TrialExpiredModal.contactUs"
                defaultMessage="Contact us"
                description="Trial expired modal contact us button"
              />
            </Button>
          </Link>
          <BamLogo
            style={{
              position: "absolute",
              bottom: "-50px",
              left: "5px",
              width: "12%",
              height: "auto",
              rotate: "-70deg",
              transform: "scaleY(-1)",
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
