import type { AlternateActorType, TimelineEvent, User } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { requestRouteById } from "@brm/util/routes.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  GridItem,
  HStack,
  Icon,
  Text,
  Tooltip,
  chakra,
  useToast,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ReactElement } from "react"
import { Fragment, useRef, useState, type FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useSearchParams } from "react-router-dom"
import type { Descendant } from "slate"
import { useLazyGetTimelineV1EventsByIdDocumentAndDocumentIdUrlQuery } from "../../app/services/generated-api.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../features/workflows/run/utils.js"
import type { StreamingController, StreamingData } from "../../util/hooks/streaming.js"
import { log } from "../../util/logger.js"
import { scrollIntoViewCenter } from "../../util/scroll.js"
import { useObjectSchema } from "../../util/use-schema.js"
import BetsyDisplayName from "../ContextMenu/BetsyDisplayName.js"
import { Link, LinkOrSpan } from "../Link.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import { isEmptyRichText } from "../RichTextEditor/util/common.js"
import { Timestamp } from "../Timestamp.js"
import { IntegrationProviderIcon } from "../icons/IntegrationProviderIcon.js"
import { LockIcon } from "../icons/icons.js"
import { ActorAvatar } from "./ActorAvatar.js"
import { CommentTimelineEventBody } from "./CommentTimelineEventBody.js"
import { FieldChangeFieldTimelineEvent } from "./FieldChangeFieldTimelineEvent.js"
import { FieldChangeFieldTimelineEventSubdetail } from "./FieldChangeFieldTimelineEventSubdetail.js"
import { TimelineCommentInput } from "./TimelineCommentInput.js"
import { timelineObjectRenderer } from "./TimelineObjectEventRenderer.js"
import type { TimelineEventSections } from "./types.js"

const maxDefaultReplies = 2
type RepliesDisplay = "all" | "some" | "none"

export const TimelineEventListItem: FunctionComponent<
  {
    event: TimelineEvent
    nextEvent: TimelineEvent | undefined
    showFieldName?: boolean
    enableDeeplinking?: boolean
    streaming?: StreamingData
    streamingController?: StreamingController
    isReadOnly?: boolean
  } & GetLogoForOrganizationProps &
    GetOrganizationActorProps
> = ({
  event,
  nextEvent,
  showFieldName,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
  enableDeeplinking = true,
  streaming,
  streamingController,
  isReadOnly,
}) => {
  const intl = useIntl()
  const [currentSearchParams] = useSearchParams()
  const location = useLocation()
  const toast = useToast()
  const [getTimelineDocumentUrl] = useLazyGetTimelineV1EventsByIdDocumentAndDocumentIdUrlQuery()
  const [repliesDisplay, setRepliesDisplay] = useState<RepliesDisplay>("some")
  // Replies may be added to all timeline events in the future. For now, we only support comments.
  const showRepliesDivider = "replies" in event && event.replies.length > 0 && repliesDisplay !== "none"

  const currentlySelectedEventId = currentSearchParams.get("event")

  const showReplyInputAndAutoFocus: boolean =
    (event.id === currentlySelectedEventId ||
      ("replies" in event
        ? event.replies.some((reply) => {
            return reply.id === currentlySelectedEventId
          })
        : false)) &&
    !event.workflow_run_id

  const [showReplyInput, setShowReplyInput] = useState(showReplyInputAndAutoFocus)
  const commentInputRef = useRef<HTMLTextAreaElement | null>(null)

  const targetSearchParams = new URLSearchParams(currentSearchParams)
  if ("field_name" in event && "object_id" in event && "object_type" in event && event.field_name) {
    // Check for the presence of these field-object specific properties and only add them if they all exist.
    // Otherwise, only add the event ID to the search params.
    targetSearchParams.set("type", event.object_type)
    targetSearchParams.set("object", event.object_id)
    targetSearchParams.set("field", event.field_name)
    targetSearchParams.set("event", event.id)
  } else {
    targetSearchParams.set("event", event.id)
    targetSearchParams.delete("type")
    targetSearchParams.delete("object")
    targetSearchParams.delete("field")
  }
  const isTargetEvent = currentSearchParams.get("event") === event.id

  const onCopyLink = async () => {
    try {
      const url = new URL(location.pathname, window.location.origin)
      url.search = targetSearchParams.toString()
      await navigator.clipboard.writeText(url.toString())
      toast({
        status: "success",
        description: intl.formatMessage({
          id: "comment.copyLink.success.title",
          description: "Toast success message when a comment link is copied to the clipboard",
          defaultMessage: "Link copied to clipboard",
        }),
      })
    } catch (err) {
      log.error("Error copying link:", err)
      toast({
        status: "error",
        description: intl.formatMessage({
          id: "comment.copyLink.error.title",
          description: "Toast error message when copying a comment link fails",
          defaultMessage: "Something went wrong while copying the link. Please try again.",
        }),
      })
    }
  }

  const objectSchema = useObjectSchema("object_type" in event ? event.object_type : skipToken)
  if ("object_type" in event && !objectSchema) {
    return null
  }

  const actor = (
    <ActorText
      eventId={event.id}
      actor={event.actor}
      getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
    />
  )
  const actorAvatar = (
    <ActorAvatar
      eventId={event.id}
      actor={event.actor}
      getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
      getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
    />
  )

  let timelineEventSections: TimelineEventSections | undefined
  switch (event.type) {
    case "new_object":
    case "delete_object": {
      timelineEventSections = timelineObjectRenderer({
        actorText: actor,
        actorAvatar,
        objectValue: event.object_value,
        eventType: event.type,
        intl,
        location,
      })?.renderTimelineEventSections()

      break
    }
    // Timeline event replies are returned in the replies field of other timeline events and are never rendered as their own list item
    case "timeline_event_reply":
      break
    case "comment":
    case "field_approved":
    case "field_unapproved":
    case "workflow_step_unapproved":
    case "workflow_seller_invite_sent":
    case "workflow_seller_link_step_submitted":
    case "workflow_step_submitted_for_review":
    case "workflow_step_approved":
    case "workflow_step_completed":
    case "workflow_step_auto_approved":
    case "workflow_step_changes_requested":
    case "workflow_step_reopened":
    case "field_change": {
      const fieldSchema =
        objectSchema && "field_name" in event && event.field_name
          ? getSchemaAtPath(
              objectSchema,
              "is_custom_field" in event && event.is_custom_field ? ["custom", event.field_name] : [event.field_name]
            )
          : undefined

      let pathname: string | undefined
      let isTargetSearch = true
      if ((event.type === "field_change" || event.type === "comment") && event.workflow_run_id) {
        pathname = requestRouteById(event.workflow_run_id)
        if (event.workflow_run_step_id && event.object_type !== "WorkflowRun") {
          targetSearchParams.set("step", event.workflow_run_step_id)

          if (currentSearchParams.get("step") !== event.workflow_run_step_id) {
            isTargetSearch = false
          }
        } else {
          targetSearchParams.delete("step")
        }
      }

      const isTargetPath = pathname === location.pathname
      const isTargetField =
        "field_name" in event &&
        currentSearchParams.get("object") === event.object_id &&
        currentSearchParams.get("field") === event.field_name

      const isOnContext = !currentSearchParams.get("step")

      const isOnCurrentEvent = currentSearchParams.get("event") === event.id

      const fieldLabel: ReactElement | null =
        "field_name" in event && event.field_name ? (
          <LinkOrSpan
            variant="highlighted"
            to={
              // Only link to the field if it's not already the target
              !(isTargetPath && isTargetSearch && isTargetField) && pathname
                ? { pathname, search: targetSearchParams.toString() }
                : undefined
            }
          >
            {getTitle(event.field_name, fieldSchema)}
          </LinkOrSpan>
        ) : null

      const stepLabel: ReactElement | null =
        event.type === "comment" && event.workflow_run_step ? (
          <LinkOrSpan
            variant="highlighted"
            to={
              // Only link to the field if it's not already the target
              !(isTargetPath && isTargetSearch && isOnCurrentEvent) && pathname
                ? { pathname, search: targetSearchParams.toString() }
                : undefined
            }
          >
            {event.workflow_run_step.display_name}
          </LinkOrSpan>
        ) : null
      const contextLabel: ReactElement | null =
        event.type === "comment" &&
        event.workflow_run_id &&
        event.object_type === "WorkflowRun" &&
        !event.field_name ? (
          <LinkOrSpan
            variant="highlighted"
            to={
              // Only link to the field if it's not already the target
              !(isTargetPath && isTargetSearch && isTargetField && isOnContext && isOnCurrentEvent) && pathname
                ? { pathname, search: targetSearchParams.toString() }
                : undefined
            }
          >
            <FormattedMessage
              defaultMessage="context"
              description="The label for the context area of a request"
              id="timeline.event.comment.context.link.label"
            />
          </LinkOrSpan>
        ) : null

      let objectLink: ReactElement | undefined
      if (objectSchema && !event.workflow_run_id && "object_value" in event) {
        // Don't pass in defaultAvatar or defaultText because some events do not want to show anything for the actor
        const objectRenderer = timelineObjectRenderer({
          actorAvatar,
          actorText: actor,
          objectValue: event.object_value,
          intl,
          location,
        })

        // Only link to the object if we have a renderer and the object we render does not match the current path
        // matchesPath can be undefined, so we need to check for false explicitly
        if (objectRenderer && objectRenderer.matchesPath === false) {
          objectLink = (
            <Link fontWeight="medium" to={objectRenderer.to}>
              {objectRenderer.displayLabel}
            </Link>
          )
        }
      }

      timelineEventSections = {
        avatar: actorAvatar,
        detail:
          event.type === "comment" ? (
            showFieldName && fieldLabel ? (
              objectLink ? (
                <FormattedMessage
                  defaultMessage="{actor} commented on {fieldLabel} for {objectLink}"
                  description="Subtitle of a comment on the field timeline with a link to the object"
                  id="workflowRun.form.field.timeline.comment.subheading.withLink"
                  values={{ actor, fieldLabel, objectLink }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="{actor} commented on {fieldLabel}"
                  description="Subtitle of a comment on the field timeline"
                  id="workflowRun.form.field.timeline.comment.subheading"
                  values={{ actor, fieldLabel }}
                />
              )
            ) : objectLink ? (
              <FormattedMessage
                defaultMessage="{actor} commented on {objectLink}"
                description="Subtitle of a comment with a link to the object"
                id="workflowRun.form.timeline.comment.subheading.withLink"
                values={{ actor, objectLink }}
              />
            ) : event.workflow_run_step && !event.field_name ? (
              <FormattedMessage
                defaultMessage="{actor} commented on the {step} step"
                description="Subtitle of a comment on a request step"
                id="workflowRun.form.timeline.comment.subheading.step"
                values={{ actor, step: stepLabel }}
              />
            ) : event.workflow_run_id && event.object_type === "WorkflowRun" && !event.field_name ? (
              <FormattedMessage
                defaultMessage="{actor} commented on the {context}"
                description="Subtitle of a comment on the context"
                id="workflowRun.form.timeline.comment.subheading.context"
                values={{ actor, context: contextLabel }}
              />
            ) : (
              actor
            )
          ) : event.type === "field_change" ? (
            <FieldChangeFieldTimelineEvent
              event={event}
              actor={actor}
              objectLink={objectLink}
              objectSchema={objectSchema}
              fieldSchema={fieldSchema}
              fieldLabel={fieldLabel}
              showFieldName={showFieldName}
            />
          ) : // Today field approved events are local to the workflow_run and will never need to render the objectLink
          event.type === "field_approved" ? (
            showFieldName && fieldLabel ? (
              <FormattedMessage
                defaultMessage="{actor} approved {fieldLabel}"
                description="Subtitle of a field approval timeline event with field label"
                id="workflowRun.form.field.timeline.field_approved.subheading.field_label"
                values={{ actor, fieldLabel }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="{actor} approved the field"
                description="Subtitle of a field approval timeline event"
                id="workflowRun.form.field.timeline.field_approved.subheading"
                values={{ actor }}
              />
            )
          ) : // Today field approved events are local to the workflow_run and will never need to render the objectLink
          event.type === "field_unapproved" ? (
            showFieldName && fieldLabel ? (
              <FormattedMessage
                defaultMessage="{actor} unapproved {fieldLabel}"
                description="Subtitle of a field reopen timeline event with field label"
                id="workflowRun.form.field.timeline.field_unapproved.subheading.field_label"
                values={{ actor, fieldLabel }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="{actor} unapproved the field"
                description="Subtitle of a field reopened timeline event"
                id="workflowRun.form.field.timeline.field_unapproved.subheading"
                values={{ actor }}
              />
            )
          ) : // Today workflow step approved events are local to the workflow_run and will never need to render the objectLink
          event.type === "workflow_step_unapproved" ? (
            <FormattedMessage
              defaultMessage="{actor} unapproved a step: {workflowRunStep}"
              description="Subtitle of a workflow run step unapproval event on the field timeline"
              id="workflowRun.form.field.timeline.comment.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_seller_invite_sent" ? (
            <FormattedMessage
              defaultMessage="{actor} invited {invitedUser}"
              description="Subtitle of a workflow run link invite sent event on the field timeline"
              id="workflowRun.form.field.timeline.sellerInvited.subheading"
              values={{
                actor,
                invitedUser: (
                  <Text as="span" fontWeight="medium">
                    {/* ID is set here to a dummy value since invited_user is guaranteed to have email
                    which will be resolved before id by displayPersonName
                    TODO: (ofri): Update this logic once new links are guaranteed to have a pending user
                    */}
                    {displayPersonName({ ...event.invited_user, id: "1" }, intl)}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_seller_link_step_submitted" ? (
            <FormattedMessage
              defaultMessage="{actor} submitted a step: {workflowRunStep}"
              description="Subtitle of a workflow run step submitted by a seller event on the field timeline"
              id="workflowRun.form.field.timeline.sellerSubmittedStep.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_submitted_for_review" ? (
            <FormattedMessage
              defaultMessage="{actor} submitted a step for approval: {workflowRunStep}"
              description="Subtitle of a workflow run step submitted for approval by a user"
              id="workflowRun.form.field.timeline.submitted.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_approved" ? (
            <FormattedMessage
              defaultMessage="{actor} approved a step: {workflowRunStep}"
              description="Subtitle of a workflow run step approved by a user"
              id="workflowRun.form.field.timeline.approved.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_completed" ? (
            <FormattedMessage
              defaultMessage="{actor} completed a step: {workflowRunStep}"
              description="Subtitle of a workflow run step completed by a user"
              id="workflowRun.form.field.timeline.completed.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_auto_approved" ? (
            <FormattedMessage
              defaultMessage="A step submitted by {actor} was auto-approved: {workflowRunStep}"
              description="Subtitle of a workflow run step auto-approved event on the field timeline"
              id="workflowRun.form.field.timeline.autoApproved.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_changes_requested" ? (
            <FormattedMessage
              defaultMessage="{actor} requested changes to a step: {workflowRunStep}"
              description="Subtitle of a workflow run step changes requested event on the field timeline"
              id="workflowRun.form.field.timeline.changesRequested.subheading"
              values={{
                actor,
                workflowRunStep: (
                  <Text as="span" fontWeight="medium">
                    {event.workflow_run_step.display_name}
                  </Text>
                ),
              }}
            />
          ) : event.type === "workflow_step_reopened" ? (
            <FormattedMessage
              defaultMessage="{actor} reopened a step: {workflowRunStep}"
              description="Subtitle of a workflow run step reopened event on the field timeline"
              id="workflowRun.form.field.timeline.reopened.subheading"
              values={{ actor, workflowRunStep: event.workflow_run_step.display_name }}
            />
          ) : (
            unreachable(event)
          ),
        // Optional second-row event body (comments, added documents)
        subDetail: (
          <Flex direction="column">
            {event.type === "comment" ? (
              <CommentTimelineEventBody
                isReadOnly={isReadOnly}
                getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                event={event}
                highlight={isTargetEvent}
                onClickReply={() => {
                  setShowReplyInput(true)
                  commentInputRef.current?.focus()
                }}
                onCopyLink={onCopyLink}
                streaming={streaming}
              />
            ) : event.type === "field_change" ? (
              <FieldChangeFieldTimelineEventSubdetail
                event={event}
                actor={actor}
                fieldSchema={fieldSchema}
                fieldLabel={fieldLabel}
              />
            ) : event.type === "workflow_step_unapproved" ? (
              // Only show the rich text body if it's not empty
              event.rich_text_body && !isEmptyRichText(event.rich_text_body) ? (
                <Box
                  borderWidth="1px"
                  padding={3}
                  borderRadius="lg"
                  borderTopLeftRadius="none"
                  overflowWrap="anywhere"
                  minW={0}
                  flexShrink={1}
                  position="relative"
                >
                  <RichTextDisplay
                    content={event.rich_text_body as Descendant[]}
                    getDocumentUrl={async (documentId) =>
                      (await getTimelineDocumentUrl({ id: event.id, documentId }).unwrap()).download_url
                    }
                  />
                </Box>
              ) : null
            ) : null}
          </Flex>
        ),
        privacySection:
          event.type === "comment" && event.is_private ? (
            <Tooltip
              label={intl.formatMessage({
                id: "timeline.comment.private.tooltip",
                description: "The tooltip for the tag that explains the private status of a comment",
                defaultMessage: "Only your organization can view",
              })}
            >
              <Text as="span" fontSize="xs" color="gray.600" fontWeight="medium">
                <Icon as={LockIcon} />{" "}
                <FormattedMessage
                  id="timeline.comment.private"
                  description="Label explaining comment block is private"
                  defaultMessage="Internal"
                />
              </Text>
            </Tooltip>
          ) : undefined,
      }

      break
    }
    default: {
      unreachable(event)
    }
  }

  if (!timelineEventSections) {
    return null
  }

  const nextEventIsSameObjectAndField =
    nextEvent &&
    "object_id" in nextEvent &&
    "object_id" in event &&
    nextEvent.object_id === event.object_id &&
    "field_name" in nextEvent &&
    "field_name" in event &&
    // Don't draw a link when the comments are only on an object and not on a field
    event.field_name &&
    nextEvent.field_name === event.field_name

  return (
    <chakra.li key={event.id} className="event-item" display="contents">
      <GridItem
        ref={isTargetEvent ? scrollIntoViewCenter : undefined}
        justifySelf="center"
        alignItems="start"
        display="flex"
        whiteSpace="nowrap"
        pt={1}
        position="relative"
      >
        <Flex outline="4px solid white" zIndex={2}>
          {timelineEventSections.primaryIcon && timelineEventSections.avatar ? (
            <>
              {timelineEventSections.primaryIcon}
              <Box marginLeft={-3}>{timelineEventSections.avatar}</Box>
            </>
          ) : (
            (timelineEventSections.primaryIcon ?? timelineEventSections.avatar)
          )}
        </Flex>
        {(nextEventIsSameObjectAndField || showRepliesDivider) && (
          <Divider
            borderColor="gray.300"
            orientation="vertical"
            minH={2}
            borderLeftWidth="2px"
            position="absolute"
            top={0}
            left="calc(50% - 1px)"
            zIndex={1}
          />
        )}
      </GridItem>
      <GridItem display="flex" placeItems="center" py={1}>
        <Box w="full" color="gray.600">
          {/* Target highlight dot */}
          {isTargetEvent && <Circle size={2} bg="green.500" m={1} float="right" />}
          <Text as="span" mr={1}>
            {timelineEventSections.detail}
          </Text>{" "}
          {/* Timestamp */}
          <Text as="span" fontSize="xs">
            <Timestamp
              dateTime={event.created_at}
              linkProps={enableDeeplinking ? { to: { search: targetSearchParams.toString() } } : undefined}
            />
          </Text>{" "}
          {/* Edited indicator */}
          {event.updated_at && event.type === "comment" && (
            <Text as="span" fontSize="xs">
              <FormattedMessage
                id="timeline.comment.edited_at"
                description="Indicator that a comment has been edited"
                defaultMessage="(edited)"
              />
            </Text>
          )}
          {timelineEventSections.privacySection && (
            <chakra.span ml={1} whiteSpace="nowrap">
              {timelineEventSections.privacySection}
            </chakra.span>
          )}
        </Box>
      </GridItem>
      <GridItem
        display="flex"
        fontSize="sm"
        justifyContent="center"
        alignItems="center"
        color="gray.500"
        fontWeight="normal"
      >
        {timelineEventSections.integrations && timelineEventSections.integrations.length > 0 && (
          <HStack gap={0}>
            {timelineEventSections.integrations.map((integration) => (
              <IntegrationProviderIcon key={integration.id} integration={integration} boxSize={4} enableLink />
            ))}
          </HStack>
        )}
      </GridItem>
      <GridItem display="flex" justifyContent="center">
        {(nextEventIsSameObjectAndField || showRepliesDivider) && (
          <Divider borderColor="gray.300" orientation="vertical" minH={2} borderLeftWidth="2px" />
        )}
      </GridItem>
      {/* Optional second-row event body (comments, added documents) */}
      <GridItem
        gridColumn="2 / span 2"
        mt={0.5}
        className="subDetail" // For debugging
      >
        {timelineEventSections.subDetail}
      </GridItem>
      {"replies" in event && (
        <>
          {repliesDisplay === "all" || event.replies.length <= maxDefaultReplies ? (
            event.replies.map((reply, index) => (
              // this key is required, otherwise the reply will not re-render when it is updated
              <Fragment key={reply.id + reply.updated_at}>
                {index !== event.replies.length - 1 && (
                  <GridItem display="flex" justifyContent="center">
                    <Divider borderColor="gray.300" orientation="vertical" minH={2} />
                  </GridItem>
                )}
                <GridItem gridColumn="2 / span 2" mr={4}>
                  <CommentTimelineEventBody
                    isReadOnly={isReadOnly}
                    event={reply}
                    onCopyLink={onCopyLink}
                    onClickReply={() => {
                      setShowReplyInput(true)
                      commentInputRef.current?.focus()
                    }}
                    streaming={streaming}
                  />
                </GridItem>
              </Fragment>
            ))
          ) : event.replies.length > maxDefaultReplies && repliesDisplay === "some" ? (
            <>
              {/* First Reply */}
              <GridItem display="flex" justifyContent="center">
                <Divider borderColor="gray.300" orientation="vertical" minH={2} />
              </GridItem>
              <GridItem gridColumn="2 / span 2" mr={4}>
                <CommentTimelineEventBody
                  isReadOnly={isReadOnly}
                  event={event.replies[0]!}
                  onCopyLink={onCopyLink}
                  onClickReply={() => {
                    setShowReplyInput(true)
                    commentInputRef.current?.focus()
                  }}
                  streaming={streaming}
                />
              </GridItem>
              {/* Show all replies */}
              <GridItem display="flex" justifyContent="center">
                <Divider borderColor="gray.300" orientation="vertical" minH={2} />
              </GridItem>
              <GridItem gridColumn="2 / span 2" mr={4}>
                <Button
                  variant="link"
                  marginBottom={5}
                  onClick={() => setRepliesDisplay("all")}
                  fontWeight="normal"
                  width="full"
                >
                  <HStack gap={4} flex={1} textColor="gray.500">
                    <Divider />
                    <FormattedMessage
                      id="comment.show.more.replies"
                      description="Label for the button to show more replies"
                      defaultMessage="Show {count} more replies"
                      values={{ count: event.replies.length - 2 }}
                    />
                    <Divider />
                  </HStack>
                </Button>
              </GridItem>
              {/* Last Reply. No horizontal divider is drawn for the last reply */}
              <GridItem gridColumn="2 / span 2" mr={4}>
                <CommentTimelineEventBody
                  isReadOnly={isReadOnly}
                  event={event.replies[event.replies.length - 1]!}
                  onCopyLink={onCopyLink}
                  onClickReply={() => {
                    setShowReplyInput(true)
                    commentInputRef.current?.focus()
                  }}
                  streaming={streaming}
                />
              </GridItem>
            </>
          ) : repliesDisplay === "none" ? (
            <GridItem gridColumn="2 / span 2" mr={4}>
              <Button
                variant="link"
                marginBottom={4}
                onClick={() => setRepliesDisplay("all")}
                fontWeight="normal"
                width="full"
              >
                <HStack gap={4} flex={1} textColor="gray.500">
                  <Divider />
                  <FormattedMessage
                    id="comment.show.all.replies"
                    description="Label for the button to show all replies"
                    defaultMessage="Show {count} replies"
                    values={{ count: event.replies.length }}
                  />
                  <Divider />
                </HStack>
              </Button>
            </GridItem>
          ) : null}
          {showReplyInput && (
            <GridItem
              gridColumn="2 / span 2"
              // Grid within a grid item which will display the reply avatar + comment input side by side
              display="grid"
              gridTemplateColumns="[icons] auto [text] minmax(0, 1fr)"
              columnGap={2}
              mb={4}
              mr={4}
            >
              {repliesDisplay === "all" && event.replies.length > maxDefaultReplies && (
                <Button
                  gridColumn={2}
                  variant="link"
                  marginBottom={4}
                  onClick={() => setRepliesDisplay("none")}
                  fontWeight="normal"
                  width="full"
                >
                  <HStack gap={4} flex={1} textColor="gray.500">
                    <Divider />
                    <FormattedMessage
                      id="comment.hide.replies"
                      description="Label for the button to hide all replies"
                      defaultMessage="Hide replies"
                    />
                    <Divider />
                  </HStack>
                </Button>
              )}
              <TimelineCommentInput
                autoFocus={showReplyInputAndAutoFocus}
                onSaveComment={() => setRepliesDisplay("all")}
                objectId={event.object_id}
                objectType={event.object_type}
                parentId={event.id}
                label={intl.formatMessage({
                  id: "comment.reply.input.label",
                  description: "Label for the reply input",
                  defaultMessage: "Reply",
                })}
                streamingController={streamingController}
              />
            </GridItem>
          )}
        </>
      )}
    </chakra.li>
  )
}

const ActorText: FunctionComponent<
  {
    eventId: string
    actor: User | AlternateActorType
  } & GetOrganizationActorProps
> = ({ eventId, actor, getOrganizationActorWhenActorMissing }) => {
  const intl = useIntl()

  if (typeof actor === "string") {
    switch (actor) {
      case "system":
        return <BetsyDisplayName />
      case "unregistered_seller_user": {
        if (getOrganizationActorWhenActorMissing) {
          return (
            <FormattedMessage
              defaultMessage="{organizationDisplayName} user"
              description="The descriptor of of an anonymous actor from an organization"
              id="timeline.event.actor.organizationAnonymousActor"
              values={{ organizationDisplayName: getOrganizationActorWhenActorMissing().displayName }}
            />
          )
        }

        log.error("No organization actor found for unregistered_seller_user event", null, { eventId })
        return (
          <FormattedMessage
            id="timeline.event.actor.organizationAnonymousActor"
            description="The descriptor of a seller organization when the organization is not found"
            defaultMessage="Seller"
          />
        )
      }
      case "unknown":
        return (
          <FormattedMessage
            id="timeline.event.actor.unknown"
            defaultMessage="Unknown user"
            description="The descriptor of an unknown user"
          />
        )
      default:
        unreachable(actor)
    }
  }

  return (
    <Link fontWeight="medium" to={`/people/${actor.person.id}`}>
      {displayPersonName(actor, intl)}
    </Link>
  )
}
