import type { StandardObjectElement } from "@brm/schema-types/types.js"
import { formatDateTime } from "@brm/util/format-date-time.js"
import { agreementRouteById, requestRouteById, toolRouteById, vendorRouteById } from "@brm/util/routes.js"
import { Avatar, chakra, HStack, Stack, Text, useDisclosure, type ChakraProps } from "@chakra-ui/react"
import he from "he"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import { useLazyGetEmailV1ByIdQuery } from "../../app/services/generated-api.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { LinkOrSpan } from "../Link.js"
import Popover, { PopoverContent, PopoverTrigger } from "../Popover.js"

export const StandardObjectTag = ({
  attributes,
  children,
  element,
}: RenderElementProps &
  GetLogoForOrganizationProps & {
    element: StandardObjectElement
  }) => {
  const intl = useIntl()
  const [getEmailById] = useLazyGetEmailV1ByIdQuery()
  const selected = useSelected()
  const focused = useFocused()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [popoverContent, setPopoverContent] = useState<React.ReactNode | null>(null)
  const location = useLocation()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])

  const href = useMemo(() => {
    const { object_type, website, id } = element
    if (website) {
      return website
    }

    switch (object_type) {
      case "LegalAgreement":
        return agreementRouteById(id)
      case "Tool":
        return toolRouteById(id)
      case "Vendor":
        return vendorRouteById(id)
      case "WorkflowRun":
        return requestRouteById(id)
      case "Email":
        hashParams.set("email", id)
        return {
          search: location.search,
          hash: `#${hashParams}`,
        }
      default:
        return undefined
    }
  }, [element, location.search, hashParams])

  const spanStyle: ChakraProps = useMemo(
    () => ({
      padding: "0 2px",
      margin: "0 1px",
      verticalAlign: "baseline",
      display: "inline-block",
      borderRadius: "4px",
      backgroundColor: "brand.50",
      borderWidth: "1px",
      borderColor: "brand.200",
      fontSize: "0.9em",
      boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
    }),
    [selected, focused]
  )

  useEffect(() => {
    const fetchEmailData = async () => {
      if (element.object_type === "Email") {
        try {
          const email = await getEmailById({ id: element.id }).unwrap()
          setPopoverContent(
            <HStack padding={2} maxW="250px" alignItems="flex-start">
              <Avatar
                size="md"
                src={getPublicImageGcsUrl(email.from_person?.profile_image?.gcs_file_name)}
                name={email.from_person?.display_name ?? email.from_email_address}
              />
              <Stack gap={0.5} width="100%">
                <Text fontWeight="semibold" color="gray.600">
                  {email.from_person?.display_name ?? email.from_email_address}
                </Text>
                {email.received_at && (
                  <Text color="gray.500" fontSize="xs" flexShrink={0}>
                    {formatDateTime(intl, email.received_at)}
                  </Text>
                )}
                {email.snippet && (
                  <Text
                    color="gray.700"
                    fontSize="sm"
                    flexShrink={0}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    noOfLines={5}
                  >
                    {he.decode(email.snippet)}
                  </Text>
                )}
              </Stack>
            </HStack>
          )
        } catch (_) {
          setPopoverContent(null)
        }
      }
    }

    void fetchEmailData()
  }, [element.id, element.object_type, getEmailById, intl])

  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  const popoverTrigger = useMemo(() => {
    return (
      <chakra.span
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        {...attributes}
        contentEditable={false}
        data-cy={`mention-${element.display_name.replaceAll(" ", "-")}`}
        {...spanStyle}
      >
        <LinkOrSpan target={element.object_type === "Email" ? undefined : "_blank"} to={href}>
          {element.display_name}
        </LinkOrSpan>
        {children}
      </chakra.span>
    )
  }, [onOpen, onClose, attributes, element, spanStyle, href, children])

  return popoverContent ? (
    <Popover opened={isOpen} position="top">
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <PopoverContent style={{ borderWidth: 0 }}>{popoverContent}</PopoverContent>
    </Popover>
  ) : (
    popoverTrigger
  )
}
