import type { Citation } from "@brm/schema-types/types.js"
import { Avatar, Box, HStack, Progress, Stack, Text } from "@chakra-ui/react"
import "katex/dist/katex.min.css"
import type { ReactNode } from "react"
import type { Descendant } from "slate"
import BraimIcon from "../../components/BraimIcon.js"
import RichTextDisplay from "../../components/RichTextEditor/RichTextDisplay.js"
import { AgentResponse, type AgentAction } from "./AgentResponse.js"

export interface ChatMessageAuthor {
  name: string
  renderedName?: JSX.Element
  image?: string
  role: "user" | "assistant"
}

interface Props {
  author: ChatMessageAuthor
  content: string
  isLoading: boolean
  status?: string
  after?: ReactNode
  richText?: Descendant[]
  actions?: AgentAction[]
  isAgentTelemetry?: boolean
  citations?: Citation[] | undefined
  getDocumentTagHref?: (documentId: string) => string
  getDocumentUrl?: (documentId: string) => Promise<string>
}

export const ChatMessage = (props: Props) => {
  const {
    author,
    content,
    isLoading,
    richText,
    status,
    after,
    actions,
    isAgentTelemetry,
    citations,
    getDocumentTagHref,
    getDocumentUrl,
  } = props

  return (
    <HStack align="flex-start" gap="5">
      <Box pt="1">
        {author.role === "user" ? (
          <Avatar size="sm" src={author.image} name={author.name} />
        ) : (
          <BraimIcon boxSize={6} />
        )}
      </Box>
      <Stack width="100%" spacing="1">
        {author.renderedName ? author.renderedName : <Text fontWeight="medium">{author.name}</Text>}
        {author.role === "user" && richText ? (
          <RichTextDisplay content={richText} getDocumentTagHref={getDocumentTagHref} getDocumentUrl={getDocumentUrl} />
        ) : isAgentTelemetry ? (
          <Progress size="xs" isIndeterminate colorScheme="blue" />
        ) : (
          <AgentResponse
            isLoading={isLoading}
            content={content}
            status={status}
            actions={actions}
            citations={citations}
          />
        )}
        {!isLoading && after}
      </Stack>
    </HStack>
  )
}
