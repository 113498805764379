import { useCallback, useRef, useState } from "react"

export interface UseSpeechToTextProps {
  onText?: (text: string) => void
  onError?: (error: string) => void
}

const SUPPORTED_MIME_TYPES = [
  "audio/webm",
  "audio/mp4",
  "audio/mp3",
  "audio/wav",
  "audio/mpeg",
  "audio/mpga",
  "audio/m4a",
]

export function getSupportedMimeType(): string | undefined {
  for (const mimeType of SUPPORTED_MIME_TYPES) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return mimeType
    }
  }
  return undefined
}

export const SUPPORTED_AUDIO_MIME_TYPE = getSupportedMimeType()

export const useSpeechToText = ({ onText, onError }: UseSpeechToTextProps = {}) => {
  const [isListening, setIsListening] = useState(false)

  const mediaRecorderRef = useRef<MediaRecorder | null>(null)

  const startListening = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

      if (!SUPPORTED_AUDIO_MIME_TYPE) {
        throw new Error("No supported mime type found")
      }

      const recorder = new MediaRecorder(stream, { mimeType: SUPPORTED_AUDIO_MIME_TYPE })

      mediaRecorderRef.current = recorder

      const chunks: Blob[] = []
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data)
        }
      }

      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: recorder.mimeType })

        try {
          const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/audio/v1/transcribe`, {
            method: "POST",
            body: audioBlob,
            credentials: "include",
            headers: {
              "Content-Type": recorder.mimeType,
            },
          })

          if (!response.ok) {
            throw new Error("Failed to convert speech to text")
          }

          const { text } = await response.json()
          onText?.(text)
        } catch (err) {
          onError?.(err instanceof Error ? err.message : "Unknown error")
        }
      }

      recorder.start(1000)
      setIsListening(true)
    } catch (err) {
      onError?.(err instanceof Error ? err.message : "Microphone access denied")
    }
  }, [onText, onError])

  const stopListening = useCallback(() => {
    const recorder = mediaRecorderRef.current
    if (recorder && recorder.state !== "inactive") {
      recorder.stop()
      recorder.stream.getTracks().forEach((track) => track.stop())
      setIsListening(false)
    }
  }, [mediaRecorderRef])

  return {
    isListening,
    startListening,
    stopListening,
  }
}
