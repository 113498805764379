import type { VendorMinimal } from "@brm/schema-types/types.js"
import { vendorRouteById } from "@brm/util/routes.js"
import type { SystemStyleObject } from "@chakra-ui/react"
import { Tooltip } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import type { Except } from "type-fest"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import VendorDisplay from "../../../features/vendor/VendorDisplay.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { Link, type LinkProps } from "../../Link.js"
import LogoInfoCard from "../../LogoInfoCard.js"
import { VendorIcon } from "../../icons/icons.js"
import { type CellSize } from "./util.js"

interface Props extends Except<LinkProps, "to"> {
  showTooltip?: boolean
  size?: CellSize
  vendor: Pick<VendorMinimal, "id" | "display_name" | "image_asset" | "description">
  textContentStyle?: SystemStyleObject
}

export default function VendorCell({ vendor, showTooltip, size = "md", textContentStyle, ...linkProps }: Props) {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  if (whoami?.organization.is_lite) {
    return (
      <Tooltip
        padding={0}
        label={
          <LogoInfoCard
            displayName={vendor.display_name}
            logoUrl={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
            fallbackAvatarLogo={VendorIcon}
            description={vendor.description}
          />
        }
        shouldWrapChildren
      >
        <VendorDisplay
          vendorLogoUrl={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
          vendorName={vendor.display_name}
          size={size}
          textContentStyle={textContentStyle}
        />
      </Tooltip>
    )
  }
  return (
    <Tooltip
      isDisabled={!showTooltip && !whoami?.organization.is_lite}
      label={intl.formatMessage(
        {
          id: "vendor.cell.tooltip",
          description: "Tooltip explaining the entity displayed here is a vendor",
          defaultMessage: "Vendor: {name}",
        },
        {
          name: vendor.display_name,
        }
      )}
    >
      <Link to={vendorRouteById(vendor.id)} maxW="100%" {...linkProps}>
        <VendorDisplay
          vendorLogoUrl={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)}
          vendorName={vendor.display_name}
          size={size}
          textContentStyle={textContentStyle}
        />
      </Link>
    </Tooltip>
  )
}
