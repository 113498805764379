import { Box, Flex, type FlexProps } from "@chakra-ui/react"
import type { ReactNode } from "react"
import BamLogo from "../../components/icons/bam-logo.js"

interface OnboardingSlashLayoutProps extends FlexProps {
  leftContent: ReactNode
  rightContent?: ReactNode
}

export const OnboardingSlashLayout = ({ leftContent, rightContent, ...flexProps }: OnboardingSlashLayoutProps) => {
  return (
    <Flex position="relative" minHeight="100vh" overflow="hidden" {...flexProps}>
      {/* Background split */}
      <Box position="absolute" top={0} left={0} right={0} bottom={0} bgColor="green.350" zIndex={0} />
      <Box
        position="absolute"
        top={0}
        bottom={0}
        width="100%"
        bgColor="white"
        transform="skew(-10deg) translateX(-50%) translateX(8.7vh)"
        transformOrigin="top"
        zIndex={1}
        display={{ base: "none", md: "block" }}
      />

      {/* BAM Logo */}
      <Box position="absolute" transform="translateX(-25%)" top={0} zIndex={1} h="33.33vh" aspectRatio="1">
        <BamLogo w="full" h="full" />
      </Box>

      {/* Content */}
      <Flex flex={1} gap="17.4vh" px="5%" flexDirection={{ base: "column", md: "row" }}>
        <Box flex="1" display="flex" alignItems="center" justifyContent="right" zIndex={1}>
          {leftContent}
        </Box>
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="left"
          transform="translateY(-5vh) translateX(-8.7vh)"
        >
          {rightContent}
        </Box>
      </Flex>
    </Flex>
  )
}
