import { type colors } from "@brm/theme"
import { chakra, type StyleProps } from "@chakra-ui/system"
import React from "react"
export const HighlightPulseWrapper = React.forwardRef<
  HTMLSpanElement,
  {
    children: React.ReactNode
    isHighlighted: boolean
    highlightColor?: keyof typeof colors
  } & StyleProps
>(({ children, isHighlighted, highlightColor, ...props }, ref) => {
  const color = highlightColor ?? "brand"
  return (
    <chakra.span
      ref={ref}
      borderRadius={props.borderRadius ?? "sm"}
      display="inline-block"
      animation={isHighlighted ? "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite" : undefined}
      sx={{
        "@keyframes pulse": {
          "0%, 100%": {
            boxShadow: `0 0 3em 1em var(--chakra-colors-${color}-200)`,
          },
          "50%": {
            boxShadow: `0 0 1em 0.2em var(--chakra-colors-${color}-200)`,
          },
        },
      }}
      {...props}
    >
      {children}
    </chakra.span>
  )
})

HighlightPulseWrapper.displayName = "HighlightPulseWrapper"
