import { LoginError } from "@brm/type-helpers/user.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  chakra,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  useGetOauthV1SignupValidateQuery,
  usePostOrganizationV1OpenSignupMutation,
} from "../../app/services/generated-api.js"
import BrmLogo from "../../components/BrmLogo.js"
import { GoogleIcon } from "../../components/icons/provider-icons.js"
import { Link } from "../../components/Link.js"
import { OnboardingWelcomeCardButton } from "../../components/Onboarding/OnboardingWelcomeCardButton.js"
import { posthogCaptureEvent } from "../../util/posthog-captures.js"
// Types
interface OpenSignupFormState {
  organization_name: string
}

function TermsAndConditions() {
  return (
    <Text fontSize="xs">
      <FormattedMessage
        id="openSignup.agree-to-terms"
        description="Text for specifying that you agree to terms and conditions"
        defaultMessage="By using BRM you agree to our <privacyLink>privacy policy</privacyLink> and <termsLink>terms of service</termsLink>."
        values={{
          privacyLink: (chunks) => (
            <Link to="https://brm.ai/privacy" textDecor="underline">
              {chunks}
            </Link>
          ),
          termsLink: (chunks) => (
            <Link to="https://brm.ai/terms" textDecor="underline">
              {chunks}
            </Link>
          ),
        }}
      />
    </Text>
  )
}

// Main Component
export default function OpenSignup() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const [searchParams] = useSearchParams()
  const { data: validationData, isError: isValidationError } = useGetOauthV1SignupValidateQuery()
  const [openSignup] = usePostOrganizationV1OpenSignupMutation()
  const isMobile = useBreakpointValue({ base: true, md: false })

  // Handle validation error
  useEffect(() => {
    if (isValidationError) {
      setError(
        intl.formatMessage({
          id: "openSignup.error.invalidSession",
          description: "Error message when signup session is invalid",
          defaultMessage: "Invalid signup session. Please try signing up again.",
        })
      )
    }
  }, [isValidationError, intl])

  // Handle error from URL parameters
  useEffect(() => {
    const searchParamsError = searchParams.get("error")
    if (searchParamsError) {
      switch (searchParamsError) {
        case LoginError.USER_ALREADY_EXISTS:
        case LoginError.FREE_EMAIL_NOT_ALLOWED:
        case LoginError.ORGANIZATION_ALREADY_EXISTS:
          setError(searchParamsError)
          break
        default:
          setError(
            intl.formatMessage({
              id: "openSignup.error.generic",
              description: "Generic error message for open signup",
              defaultMessage: "Something went wrong. Please try again.",
            })
          )
      }
    }
  }, [searchParams, intl])

  useEffect(() => {
    posthogCaptureEvent("open_signup_viewed")
  }, [])

  const form = useForm<OpenSignupFormState>({
    defaultValues: {
      organization_name: "",
    },
    reValidateMode: "onSubmit",
  })

  const handleSubmit = async (values: OpenSignupFormState) => {
    posthogCaptureEvent("open_signup_create_organization", {
      organization_name: values.organization_name,
    })
    try {
      await openSignup({
        body: {
          organization_name: values.organization_name,
        },
      }).unwrap()

      // Redirect to home page after successful signup
      navigate("/")
    } catch (err) {
      if (
        isObject(err) &&
        "data" in err &&
        isObject(err.data) &&
        "message" in err.data &&
        typeof err.data.message === "string"
      ) {
        setError(err.data.message)
      } else {
        setError(
          intl.formatMessage({
            id: "openSignup.error.generic",
            description: "Generic error message for open signup",
            defaultMessage: "Something went wrong. Please try again.",
          })
        )
      }
    }
  }

  const content = (
    <VStack spacing="6" align="stretch" maxW="400px">
      {!validationData?.isValid ? (
        <>
          <Stack alignItems="center">
            <Heading size="lg" fontWeight="bold" fontFamily="geograph">
              <FormattedMessage
                id="openSignup.heading"
                description="Main heading on the signup page"
                defaultMessage="Create Your Account."
              />
            </Heading>
          </Stack>

          {error && (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>
                {error === LoginError.USER_ALREADY_EXISTS ? (
                  <FormattedMessage
                    id="openSignup.error.userExists"
                    description="Error message when user already exists"
                    defaultMessage="A user with your email already exists. {loginLink}"
                    values={{
                      loginLink: (
                        <Link to="/login" fontWeight="semibold" color="red.700">
                          <FormattedMessage
                            description="Link to login page in error message"
                            id="openSignup.error.userExists.loginLink"
                            defaultMessage="Log in instead"
                          />
                        </Link>
                      ),
                    }}
                  />
                ) : error === LoginError.FREE_EMAIL_NOT_ALLOWED ? (
                  <FormattedMessage
                    id="openSignup.error.freeEmailNotAllowed"
                    description="Error message when user tries to sign up with a free email provider"
                    defaultMessage="Please use your work email address. Free email providers are not allowed."
                  />
                ) : error === LoginError.ORGANIZATION_ALREADY_EXISTS ? (
                  <FormattedMessage
                    id="openSignup.error.organizationExists"
                    description="Error message when an organization with the email domain already exists"
                    defaultMessage="An organization with your email domain already exists. {loginLink}"
                    values={{
                      loginLink: (
                        <Link to="/login" fontWeight="semibold" color="red.700">
                          <FormattedMessage
                            description="Link to login page in error message"
                            id="openSignup.error.organizationExists.loginLink"
                            defaultMessage="Log in instead"
                          />
                        </Link>
                      ),
                    }}
                  />
                ) : (
                  error
                )}
              </AlertDescription>
            </Alert>
          )}
          <Stack spacing="1" alignItems="center">
            <Text fontSize="lg" fontWeight="semibold">
              <FormattedMessage
                id="openSignup.subheading"
                description="Subheading on the signup page"
                defaultMessage="Sign up using your work email."
              />
            </Text>
            <Box alignSelf="stretch" mt={2}>
              <OnboardingWelcomeCardButton
                cta={intl.formatMessage({
                  id: "openSignup.form.google",
                  description: "Continue with Google button text",
                  defaultMessage: "Continue with Google",
                })}
                icon={<GoogleIcon />}
                onClick={() => {
                  posthogCaptureEvent("open_signup_start_google_sso")
                  window.location.href = `${import.meta.env.VITE_API_BASE_URL}/oauth/v1/signin/google?open_signup=true`
                }}
              />
            </Box>
          </Stack>
        </>
      ) : (
        <>
          <Stack spacing="1">
            <Heading size="lg" fontWeight="bold" fontFamily="geograph">
              <FormattedMessage
                id="openSignup.organization.heading"
                description="Main heading for organization setup"
                defaultMessage="Your Organization."
              />
            </Heading>
            <Text fontSize="lg" fontWeight="regular" fontFamily="geograph">
              <FormattedMessage
                id="openSignup.organization.subheading"
                description="Subheading for organization setup"
                defaultMessage="This helps BRM’s SuperAgents identify the right contracts and keep everything structured."
              />
            </Text>
          </Stack>

          <Stack
            spacing="5"
            as={chakra.form}
            onSubmit={(event) => {
              event.preventDefault()
              void form.handleSubmit(handleSubmit)(event)
            }}
          >
            <Controller
              control={form.control}
              name="organization_name"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid} isRequired>
                  <FormLabel htmlFor="organization_name">
                    <FormattedMessage
                      id="openSignup.form.organization_name"
                      description="Label for open signup form organization name field"
                      defaultMessage="Organization Name"
                    />
                  </FormLabel>
                  <Input {...field} id="organization_name" type="text" />
                </FormControl>
              )}
            />

            <OnboardingWelcomeCardButton
              cta={intl.formatMessage({
                id: "openSignup.form.submit",
                description: "Submit button text for open signup form",
                defaultMessage: "Save & Continue",
              })}
              onClick={() => {
                void form.handleSubmit(handleSubmit)()
              }}
            />
          </Stack>
        </>
      )}

      <TermsAndConditions />
    </VStack>
  )

  return (
    <Container maxW="container.xl" h="100vh" display="flex" alignItems="center" justifyContent="center">
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        gap={{ base: "8", md: "24" }}
        w="full"
      >
        {isMobile ? (
          <>
            <BrmLogo h="120px" w="120px" />
            {content}
          </>
        ) : (
          <>
            <Flex justify="center">
              <BrmLogo h="300px" w="300px" />
            </Flex>
            <Flex justify="center">{content}</Flex>
          </>
        )}
      </Flex>
    </Container>
  )
}
