import type { PickableEntityType } from "@brm/schema-types/types.js"
import { isObject } from "@brm/util/type-guard.js"
import { Editor, Transforms, type Descendant, type Text } from "slate"
import type { CustomEditor } from "../types.js"

export const EMPTY_RICH_TEXT_BODY: Descendant[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

export const RICH_TEXT_SPACE: Text = { text: " " }

export const DEFAULT_PICKABLE_ENTITIES: PickableEntityType[] = [
  "legal_agreement",
  "field",
  "tool",
  "vendor",
  "workflow_run",
]

export function isEmptyRichText(value: unknown, trim: boolean = false) {
  if (!Array.isArray(value)) {
    return false
  }
  if (value.length !== 1) {
    return false
  }
  const [firstNode] = value
  return Boolean(
    isObject(firstNode) &&
      "type" in firstNode &&
      firstNode.type === "paragraph" &&
      "children" in firstNode &&
      Array.isArray(firstNode.children) &&
      firstNode.children.length === 1 &&
      isObject(firstNode.children[0]) &&
      "text" in firstNode.children[0] &&
      typeof firstNode.children[0].text === "string" &&
      (trim ? firstNode.children[0].text.trim() === "" : firstNode.children[0].text === "")
  )
}

function isOnlyWhitespaceText(node: Descendant): node is { text: string } {
  return "text" in node && node.text.trim() === ""
}

export function isWhitespaceParagraphNode(node: Descendant): boolean {
  return "type" in node && node.type === "paragraph" && node.children.every(isOnlyWhitespaceText)
}

export function trimRichTextWhiteSpace(body: Descendant[]): Descendant[] {
  const richTextBody = structuredClone(body)
  // Trim all leading and trailing empty paragraphs from the body to remove extraneous newlines at the beginning and end
  let firstParagraph = richTextBody.at(0)
  while (firstParagraph && isWhitespaceParagraphNode(firstParagraph) && richTextBody.length > 1) {
    richTextBody.shift()
    firstParagraph = richTextBody.at(0)
  }

  let lastParagraph = richTextBody.at(-1)
  while (lastParagraph && isWhitespaceParagraphNode(lastParagraph) && richTextBody.length > 1) {
    richTextBody.pop()
    lastParagraph = richTextBody.at(-1)
  }
  return richTextBody
}

export const moveToNextNode = (editor: CustomEditor) => {
  const nextNodeEntry = Editor.next(editor)
  if (nextNodeEntry) {
    const [, nextPath] = nextNodeEntry
    Transforms.select(editor, Editor.start(editor, nextPath))
  }
}
