import type { BlockType, LeafType } from "@brm/schema-helpers/rich-text/ast-types.js"
import { isBlockNode, iterateRichText } from "@brm/schema-helpers/rich-text/rich-text.js"
import type {
  CommentTimelineEvent,
  ObjectType,
  PickableEntityFilter,
  PickableEntityType,
  TimelineEventReplyTimelineEvent,
} from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Progress,
  Spacer,
  Text,
  Tooltip,
  chakra,
  useToast,
} from "@chakra-ui/react"
import { useMemo, useRef, useState, type FunctionComponent } from "react"
import { createPortal } from "react-dom"
import { Controller, useForm, type Control, type UseFormSetValue } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Descendant } from "slate"
import { isNotNull } from "typed-assert"
import {
  useDeleteTimelineV1EventsByIdMutation,
  useLazyGetTimelineV1EventsByIdDocumentAndDocumentIdUrlQuery,
  useLazyGetUserV1DocumentsByIdUrlQuery,
  usePostDynamoV1ApplyByTimelineEventIdMutation,
  usePutTimelineV1EventsByIdMutation,
} from "../../app/services/generated-api.js"
import { AgentResponse } from "../../features/betsy/AgentResponse.js"
import { timelineGridGapPx, timelineIconsWidthPx } from "../../features/timeline/constants.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import type { StreamingData } from "../../util/hooks/streaming.js"
import { log } from "../../util/logger.js"
import { validateRichTextRequired } from "../../util/validation.js"
import { AttachmentButton } from "../RichTextEditor/EditorToolbar.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import RichTextEditor from "../RichTextEditor/RichTextEditor.js"
import {
  DEFAULT_PICKABLE_ENTITIES,
  EMPTY_RICH_TEXT_BODY,
  trimRichTextWhiteSpace,
} from "../RichTextEditor/util/common.js"
import { Timestamp } from "../Timestamp.js"
import { ActorAvatar } from "./ActorAvatar.js"
import { CommentActions } from "./CommentActions.js"
import PendingReplyTaskNotice from "./PendingReplyTaskNotice.js"
import { ReactionRow } from "./ReactionRow.js"

interface FormState {
  richTextBody: Descendant[] | null
}

/**
 * Displays a single body of a timeline event which can either be a comment or a timeline_event_reply.
 */
export const CommentTimelineEventBody: FunctionComponent<
  {
    event: CommentTimelineEvent | (TimelineEventReplyTimelineEvent & { isLast?: boolean })
    onCopyLink: () => void
    onClickReply?: () => void
    highlight?: boolean
    streaming?: StreamingData
    isReadOnly?: boolean
  } & GetLogoForOrganizationProps
> = ({ event, onClickReply, onCopyLink, highlight, getLogoToShowByOrganizationId, streaming, isReadOnly }) => {
  const intl = useIntl()
  const toast = useToast()

  const [isEditMode, setIsEditMode] = useState(false)
  const [promptDelete, setPromptDelete] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)

  const [deleteTimelineEvent, { isLoading: isDeleting }] = useDeleteTimelineV1EventsByIdMutation()
  const [editTimelineEvent, { isLoading: isEditSaving }] = usePutTimelineV1EventsByIdMutation()
  const [getTimelineDocumentUrl] = useLazyGetTimelineV1EventsByIdDocumentAndDocumentIdUrlQuery()

  const isAgentProgress = Array.from(iterateRichText(event.rich_text_body as (BlockType | LeafType)[])).some(
    (node) => isBlockNode(node) && node.type === "progress"
  )
  const [applyTimelineEvent, { isLoading: isApplying }] = usePostDynamoV1ApplyByTimelineEventIdMutation()

  const form = useForm<FormState>({
    defaultValues: {
      richTextBody: event.rich_text_body as Descendant[] | null,
    } satisfies FormState,
    disabled: !isEditMode,
  })
  const formId = `edit-comment-form:${event.id}`

  const handleDeleteComment = async () => {
    try {
      await deleteTimelineEvent({
        id: event.id,
      }).unwrap()

      toast({
        status: "success",
        description: intl.formatMessage({
          id: "comment.delete.success.title",
          defaultMessage: "Comment deleted successfully",
          description: "Title of the toast that appears when a comment is deleted",
        }),
      })
    } catch (_err) {
      toast({
        status: "error",
        description: intl.formatMessage({
          id: "comment.delete.error.title",
          defaultMessage: "Something went wrong while deleting your comment",
          description: "Title of the toast that appears when an error occurs while deleting a comment",
        }),
      })
    }
  }

  const handleSetEditMode = () => {
    setIsEditMode(true)
    setPromptDelete(false)
  }

  const handlePromptDelete = () => {
    setPromptDelete(true)
    setIsEditMode(false)
  }

  const handleApply = async () => {
    try {
      await applyTimelineEvent({
        timelineEventId: event.id,
      }).unwrap()
      toast({
        description: intl.formatMessage({
          id: "request.comment.apply.success",
          description: "Toast success message when applying succeeds",
          defaultMessage: "Applied",
        }),
        status: "success",
      })
    } catch (err) {
      log.error("Failed to apply comment", { err })
      toast({
        description: intl.formatMessage({
          id: "request.comment.apply.warning",
          description: "Toast warning message when applying fails",
          defaultMessage: "Failed to apply",
        }),
        status: "warning",
      })
    }
  }

  const isSystem = event.actor === "system"
  const hasReactions = event.reaction_groups && event.reaction_groups.length > 0
  const hasReplyTasks = event.reply_tasks && event.reply_tasks.length > 0

  return (
    <>
      {event.type === "timeline_event_reply" && (
        <Box position="relative">
          {/* Draw a line left of the avatar to connect to the divider */}
          <Box
            position="absolute"
            backgroundColor="white"
            right="100%"
            display="block"
            // 1px for half of the border width
            width={`${timelineIconsWidthPx / 2 + timelineGridGapPx + 1}px`}
            // Center of Avatar + border width, which is always at the top left
            height="calc(12px + 2px)"
            borderBottomWidth="2px"
            borderLeftWidth="2px"
            borderBottomLeftRadius="16px"
          />
        </Box>
      )}
      <Box mb={hasReactions && !hasReplyTasks ? 0 : 4} className="comment-body">
        <HStack alignItems="start">
          {event.type === "timeline_event_reply" && (
            <Tooltip
              label={
                <chakra.span color="gray.600">
                  <Text as="span" fontWeight="medium" mr={1}>
                    {displayPersonName(event.actor, intl)}
                  </Text>{" "}
                  {/* Timestamp */}
                  <Text as="span" fontSize="xs">
                    <Timestamp dateTime={event.created_at} />
                  </Text>{" "}
                  {/* Edited indicator */}
                  {event.updated_at && (
                    <Text as="span" fontSize="xs">
                      <FormattedMessage
                        id="timeline.comment.edited_at"
                        description="Indicator that a comment has been edited"
                        defaultMessage="(edited)"
                      />
                    </Text>
                  )}
                </chakra.span>
              }
            >
              <ActorAvatar eventId={event.id} actor={event.actor} />
            </Tooltip>
          )}
          {isEditMode ? (
            <CommentBodyEditMode
              control={form.control}
              setValue={form.setValue}
              formId={formId}
              formRef={formRef}
              isEditSaving={isEditSaving}
              isPrivate={event.type === "comment" ? (event.is_private ?? undefined) : undefined}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              objectId={event.object_id}
              objectType={event.object_type}
              workflowRunId={event.workflow_run_id ?? undefined}
            />
          ) : (
            <Box position="relative" minW={0} flex={1}>
              <Box
                borderWidth={highlight ? "2px" : "1px"}
                px={3}
                py={2}
                borderColor={highlight ? "brand.600" : "none"}
                borderRadius="lg"
                borderTopLeftRadius="none"
                overflowWrap="anywhere"
                overflowX="auto"
                // Comment should only show as private if is_private is explicitly false
                background={event.type === "comment" && event.is_private === false ? "blue.50" : "white"}
              >
                {isAgentProgress ? (
                  streaming && streaming.isLoading ? (
                    <AgentResponse {...streaming} />
                  ) : (
                    <Box minHeight={4} display="grid" alignItems="center">
                      <Progress isIndeterminate size="xs" colorScheme="blue" flexGrow={1} />
                    </Box>
                  )
                ) : isSystem ? (
                  <AgentResponse
                    isLoading={false}
                    status="waiting"
                    content={event.rich_text_body?.[0]?.children?.[0]?.text ?? ""}
                  />
                ) : (
                  <RichTextDisplay
                    content={event.rich_text_body as Descendant[]}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                    getDocumentUrl={async (documentId) =>
                      (await getTimelineDocumentUrl({ id: event.id, documentId }).unwrap()).download_url
                    }
                  />
                )}
              </Box>

              <Flex position="absolute" top={-2} width="100%" justifyContent="flex-end" zIndex={1} px={1.5}>
                {!isEditMode && !promptDelete && (
                  <CommentActions
                    event={event}
                    onClickReply={onClickReply}
                    onCopyLink={onCopyLink}
                    onClickEdit={handleSetEditMode}
                    onClickDelete={handlePromptDelete}
                    onApply={handleApply}
                    isApplyLoading={isApplying}
                    isReadOnly={isReadOnly}
                  />
                )}
              </Flex>
            </Box>
          )}
        </HStack>

        <HStack alignItems="flex-start" width="100%" justifyContent="space-between">
          {/* Left justify - Reactions */}
          <Box>
            {hasReactions && (
              <Box position="relative" px={1} mt={isEditMode ? 1 : -1.5}>
                <ReactionRow event={event} />
              </Box>
            )}
          </Box>

          {/* Right justify - Delete and Edit buttons */}
          <Box>
            {promptDelete && (
              <Flex justifyContent="end" pt={1} gap={2}>
                <Button
                  variant="unstyled"
                  color="gray.600"
                  size="sm"
                  height="auto"
                  onClick={() => setPromptDelete(false)}
                  isDisabled={isDeleting}
                >
                  <FormattedMessage
                    id="timeline.comment.delete.cancel"
                    description="Confirmation message shown to user to cancel deletion of a comment on a timeline event"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  variant="unstyled"
                  color="error.500"
                  size="sm"
                  height="auto"
                  onClick={handleDeleteComment}
                  isDisabled={isDeleting}
                >
                  <FormattedMessage
                    id="timeline.comment.delete.confirmation"
                    description="Confirmation message shown to user to confirm deletion of a comment on a timeline event"
                    defaultMessage="Delete"
                  />
                </Button>
              </Flex>
            )}

            {isEditMode && (
              <Flex justifyContent="end" pt={1}>
                <Button variant="ghost" size="sm" onClick={() => setIsEditMode(false)} isDisabled={isEditSaving}>
                  <FormattedMessage
                    id="timeline.comment.edit.cancel"
                    description="Confirmation message shown to user to cancel editing of a comment on a timeline event"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="brand"
                  size="sm"
                  type="submit"
                  form={formId}
                  isDisabled={isEditSaving}
                >
                  <FormattedMessage
                    id="timeline.comment.save.confirmation"
                    description="Confirmation message shown to user to confirm deletion of a comment on a timeline event"
                    defaultMessage="Save"
                  />
                </Button>
              </Flex>
            )}
          </Box>
        </HStack>

        {/* Render form in a portal because forms cannot be nested in HTML (but you can use the `form` attribute to achieve the same effect) */}
        {createPortal(
          <chakra.form
            ref={formRef}
            method="dialog"
            id={formId}
            noValidate
            onSubmit={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              await form.handleSubmit(async (formValues) => {
                isNotNull(formValues.richTextBody, "Comment update must have a body")
                try {
                  await editTimelineEvent({
                    id: event.id,
                    timelineUpdate: {
                      rich_text_body: trimRichTextWhiteSpace(formValues.richTextBody),
                    },
                  })
                  toast({
                    status: "success",
                    description: intl.formatMessage({
                      id: "comment.save.success.title",
                      defaultMessage: "Comment saved!",
                      description: "Title of the toast that appears when a comment is saved",
                    }),
                  })
                } catch (_) {
                  toast({
                    status: "error",
                    description: intl.formatMessage({
                      id: "comment.save.error.title",
                      defaultMessage: "Something went wrong while saving your comment",
                      description: "Title of the toast that appears when an error occurs while saving a comment",
                    }),
                  })
                }
                // Timeout before remounting the comment view to make sure that the form renders
                // with the new value of the rich text body for the uncontrolled component RichTextEditor
                setTimeout(() => setIsEditMode(false), 500)
              })(e)
            }}
          />,
          document.body,
          formId
        )}

        {hasReplyTasks && (
          <Box pt={1}>
            {event.reply_tasks?.map((replyTask) => <PendingReplyTaskNotice key={replyTask.id} replyTask={replyTask} />)}
          </Box>
        )}
      </Box>
    </>
  )
}

function CommentBodyEditMode({
  control,
  setValue,
  formId,
  formRef,
  isEditSaving,
  getLogoToShowByOrganizationId,
  isPrivate,
  objectId,
  objectType,
  workflowRunId,
}: {
  control: Control<FormState>
  setValue: UseFormSetValue<FormState>
  formId: string
  formRef: React.RefObject<HTMLFormElement>
  isEditSaving: boolean
  isPrivate?: boolean
  objectId?: string
  objectType: ObjectType
  workflowRunId?: string
} & GetLogoForOrganizationProps) {
  const intl = useIntl()
  const [getDocumentUrl] = useLazyGetUserV1DocumentsByIdUrlQuery()
  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(() => {
    const entities = workflowRunId
      ? ([...DEFAULT_PICKABLE_ENTITIES, "workflow_seller", "workflow_buyer"] satisfies PickableEntityType[])
      : DEFAULT_PICKABLE_ENTITIES
    return {
      entities,
      requesting_entity: workflowRunId
        ? {
            object_type: "WorkflowRun",
            object_id: workflowRunId,
          }
        : {
            object_type: objectType,
            object_id: objectId,
          },
    }
  }, [objectId, objectType, workflowRunId])
  return (
    <Controller
      name="richTextBody"
      control={control}
      rules={{
        validate: (value) =>
          validateRichTextRequired(
            value,
            intl.formatMessage({
              id: "comment.input.body.validate.empty",
              description: "Validation message shown when user attempts to create a comment that is empty",
              defaultMessage: "Comment cannot be empty",
            })
          ),
      }}
      render={({ field, fieldState }) => (
        <FormControl isRequired isInvalid={fieldState.invalid}>
          <RichTextEditor
            disableFileDrop={false}
            getDocumentUrl={async (id) => (await getDocumentUrl({ id }).unwrap()).download_url}
            pickableEntityFilters={pickableEntityFilters}
            form={formId}
            initialValue={field.value ?? EMPTY_RICH_TEXT_BODY}
            onChange={(value) => {
              setValue("richTextBody", value)
              field.onChange(value)
            }}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            isPrivate={isPrivate}
            containerProps={{
              onKeyDown: (event) => {
                if (event.key === "Enter") {
                  if ((event.metaKey || event.ctrlKey) && !isEditSaving) {
                    formRef.current?.requestSubmit()
                  }
                }
              },
            }}
            hasError={fieldState.invalid}
            additionalToolbarButtons={
              <Flex gap={1} flexGrow={1} alignItems="center">
                <Spacer />
                <AttachmentButton />
              </Flex>
            }
          />
          {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
        </FormControl>
      )}
    />
  )
}
