import type { EmailDraftInput, NegotiationConversation, UUIDString } from "@brm/schema-types/types.js"
import { negotiationRouteById, toolRouteById, vendorRouteById } from "@brm/util/routes.js"
import {
  Alert,
  AlertIcon,
  Button,
  Center,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import type { Descendant } from "slate"
import {
  useGetNegotiationV1ByIdQuery,
  usePostNegotiationV1AutomatedChurnMutation,
  usePostNegotiationV1RegenerateMutation,
  usePostNegotiationV1ResetMutation,
} from "../../app/services/generated-api.js"
import { EMPTY_EMAIL_DRAFT } from "../../components/negotiation/util.js"
import Chat from "../../features/betsy/Chat.js"
import { ChatContextProvider } from "../../features/betsy/ChatContextProvider.js"
import EmailComposer from "../../features/betsy/EmailComposer.js"
import FullTextSearch from "../../features/search/FullTextSearch.js"
import StartWorkflowModal from "../../features/workflows/run/start/StartWorkflowModal.js"
import { negotiationEmailDocumentDownloadUrl } from "../../util/document.js"
import { StatusCodes } from "../../util/error.js"
import { posthogCaptureEvent } from "../../util/posthog-captures.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { ToolLogo, VendorLogo } from "../icons/Logo.js"
import { Link } from "../Link.js"
import NegotiationPanelHeader from "./NegotiationPanelHeader.js"
import NegotiationStatusBadge from "./NegotiationStatusBadge.js"
import Playbook from "./Playbook.js"

interface DecisionPanelProps {
  legalAgreementId: UUIDString | undefined
  onStartNegotiation: () => void
  onStartChurn: () => void
}

function DecisionPanel(props: DecisionPanelProps) {
  const { legalAgreementId, onStartNegotiation, onStartChurn } = props
  const startRenewalDisclosure = useDisclosure()

  return (
    <>
      <StartWorkflowModal {...startRenewalDisclosure} initialLegalAgreementId={legalAgreementId} />
      <Flex flexDirection="column" width="100%" height="100%">
        <NegotiationPanelHeader title="" />
        <Flex flexDirection="column" width="100%" height="100%" p={6}>
          <Spacer flex="1" />
          <VStack spacing={6} width="100%" alignItems="center" textAlign="center">
            <Heading as="h2" size="lg" fontWeight="normal">
              <FormattedMessage
                id="negotiation.decision.heading"
                defaultMessage="Make the Call"
                description="Main heading for the decision-making section in negotiation component"
              />
            </Heading>

            {/* add a cheeky bit of text about how you can change your mind later  */}
            <Text color="gray.700" fontSize="md" pt={2}>
              <FormattedMessage
                id="negotiation.decision.changeMind"
                defaultMessage="No stress, you can change your mind later."
                description="Text for indicating user can change their mind later"
              />
            </Text>

            <VStack spacing={3} width="100%" maxWidth="300px" pt={2}>
              <Button variant="outline" width="100%" height="40px" onClick={onStartNegotiation}>
                <FormattedMessage
                  id="negotiation.decision.button.negotiate"
                  defaultMessage="Start to Negotiate"
                  description="Button text for selecting to start negotiation in decision panel"
                />
              </Button>

              {legalAgreementId && (
                <Button variant="outline" width="100%" height="40px" onClick={startRenewalDisclosure.onOpen}>
                  <FormattedMessage
                    id="negotiation.decision.button.startRenewal"
                    defaultMessage="Start the Renewal"
                    description="Button text for starting renewal process in decision panel"
                  />
                </Button>
              )}

              <Button variant="outline" width="100%" borderRadius="md" height="40px" onClick={onStartChurn}>
                <FormattedMessage
                  id="negotiation.decision.button.churn"
                  defaultMessage="Start to Churn"
                  description="Button text for selecting to churn (discontinue) in decision panel"
                />
              </Button>
            </VStack>
          </VStack>
          <Spacer flex="2" /> {/* Make bottom spacer larger than top spacer */}
        </Flex>
      </Flex>
    </>
  )
}

const negotiationAgentQuips = [
  "I can scan through the fine print to find leverage points.",
  "Let me untangle this legal spaghetti for you",
  "I’ll decrypt these contract clauses while keeping your internal goals just between us.",
  "Looking for those hidden contract details that we can use to your advantage...",
  "Checking market rates so you know exactly what to ask for.",
  "Let me search the web for some competitive intel you can use in your favor.",
  "Work with me to craft the perfect response.",
  "Researching market trends so you don’t have to",
  "I’ll help you draft emails that get better terms without the stress.",
  "Writing you something that gets results.",
  "Crafting messages that won’t reveal your budget—that’s our little secret.",
  "Taking detailed notes on this negotiation for you to reference later.",
  "I’ll remember all the details so you can focus on the bigger picture.",
  "Building your negotiation playbook while we work through this together.",
  "Creating a knowledge base as we go—this’ll help with future negotiations too.",
  "Looking through your agreement for those tricky cancellation terms.",
  "Working to find that sweet spot between standing firm and keeping things cordial.",
]

function getRandomQuip(): string {
  const quip =
    negotiationAgentQuips[Math.floor(Math.random() * negotiationAgentQuips.length)] ?? "let’s get this deal done."
  return `*${quip}*` // italicize the quip
}

const Negotiation = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])

  const [queryParams, setQueryParams] = useState<URLSearchParams>(new URLSearchParams(location.search))
  const intent = queryParams.get("intent")

  const [quip] = useState<string>(getRandomQuip())

  useEffect(() => {
    setQueryParams(new URLSearchParams(location.search))
  }, [location.search])

  const emailIdInUrl = hashParams.get("email")
  const toast = useToast()
  const intl = useIntl()
  const { negotiationId } = useParams()
  const {
    data: negotiation,
    isLoading,
    error,
  } = useGetNegotiationV1ByIdQuery(negotiationId ? { id: negotiationId } : skipToken)

  const [selectedConversation, setSelectedConversation] = useState<NegotiationConversation | undefined>(
    negotiation?.conversations.at(-1)
  )

  const [isAnswerLoading, setIsAnswerLoading] = useState(false)

  const getEmailDraft = useCallback(() => {
    return negotiation?.primary_email_thread?.emails && negotiation.primary_email_thread.emails.length > 0
      ? (negotiation?.primary_email_thread.emails.at(-1)?.reply_email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT)
      : (negotiation?.initial_email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT)
  }, [negotiation])

  const [emailDraft, setEmailDraft] = useState<EmailDraftInput>(getEmailDraft())

  useEffect(() => {
    setEmailDraft(getEmailDraft())
  }, [negotiation, getEmailDraft])

  const [automatedChurn] = usePostNegotiationV1AutomatedChurnMutation()

  const [regenerateNegotiation] = usePostNegotiationV1RegenerateMutation()
  const [resetNegotiation] = usePostNegotiationV1ResetMutation()
  const [emailThreadId, setEmailThreadId] = useState<string | undefined>(
    negotiation?.primary_email_thread?.email_thread_id ?? undefined
  )
  const currentEmailThread = useMemo(() => {
    return negotiation?.email_threads.find((emailThread) => emailThread.email_thread_id === emailThreadId)
  }, [negotiation?.email_threads, emailThreadId])

  useEffect(() => {
    if (negotiation?.primary_email_thread) {
      setEmailThreadId(negotiation.primary_email_thread.email_thread_id)
    }
  }, [negotiation?.primary_email_thread])

  useEffect(() => {
    if (negotiation?.conversations.length) {
      const conversationId = queryParams.get("conversation")
      if (conversationId) {
        setSelectedConversation(negotiation.conversations.find((conversation) => conversation.id === conversationId))
      } else {
        setSelectedConversation(negotiation.conversations.at(-1))
      }
    }
  }, [negotiation?.conversations, queryParams])

  useEffect(() => {
    if (emailIdInUrl) {
      const focusedThreadId = negotiation?.email_threads.find((emailThread) =>
        emailThread.emails.find((email) => email.id === emailIdInUrl)
      )?.email_thread_id
      if (focusedThreadId && emailThreadId !== focusedThreadId) {
        setEmailThreadId(focusedThreadId)

        // Toast will close automatically when scrolling is triggered. Waiting a bit to ensure the toast stays visible.
        setTimeout(() => {
          toast({
            title: intl.formatMessage({
              id: "email.threadNavigated",
              description: "Navigated to email thread",
              defaultMessage: "Navigated to email thread",
            }),
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        }, 200)
      }
    }
  }, [emailIdInUrl, negotiation?.email_threads, toast, emailThreadId, intl])

  useEffect(() => {
    if (!negotiationId) {
      navigate(`/negotiations/${crypto.randomUUID()}`)
    }
  }, [negotiationId, navigate])

  const onReset = useCallback(async () => {
    if (!negotiation) return
    const response = await resetNegotiation({
      body: {
        negotiation_id: negotiation.id,
      },
    }).unwrap()
    return response?.negotiation_conversation
  }, [resetNegotiation, negotiation])

  if (isLoading) {
    return (
      <Center h="100%" flex={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  if (error && "status" in error && error.status === StatusCodes.FORBIDDEN) {
    return (
      <Alert status="error">
        <Icon as={AlertIcon} color="error" />
        <FormattedMessage
          id="requests.start.modal.object_type.error"
          description="User-friendly error message to display when something goes wrong"
          defaultMessage="You can only view your own negotiations. Collaboration features are coming soon!"
        />
      </Alert>
    )
  }

  if (!negotiation) {
    return null
  }

  // this header holds the search bar and the title
  const headerHeight = "4rem"

  return (
    <ChatContextProvider initialChatValue={selectedConversation?.initial_chat_value as Descendant[] | undefined}>
      <Stack height="100%" width="100%" gap={0}>
        {/* Header */}
        <Flex
          px={6}
          py={2}
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="gray.100"
          height={headerHeight}
        >
          <Flex alignItems="center" gap={2}>
            <Heading
              size="md"
              display="flex"
              alignItems="baseline"
              justifyContent="center"
              flexDirection="row"
              gap={2}
              fontWeight="bold"
              color="gray.900"
            >
              <FormattedMessage
                id="negotiation.title"
                defaultMessage="Negotiate {with} {toolOrVendor}"
                description="Negotiation title"
                values={{
                  toolOrVendor: (
                    <Link
                      to={
                        negotiation?.tool ? toolRouteById(negotiation.tool.id) : vendorRouteById(negotiation.vendor.id)
                      }
                      target="_blank"
                      variant="outline"
                      size="lg"
                      fontSize="4xl"
                      gap={2}
                      fontWeight="bold"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="row"
                    >
                      {negotiation?.tool ? negotiation?.tool.display_name : negotiation?.vendor.display_name}
                      {negotiation?.tool ? (
                        <ToolLogo
                          logo={getPublicImageGcsUrl(negotiation?.tool.image_asset?.gcs_file_name)}
                          borderColor="gray.200"
                          borderWidth={1}
                          borderStyle="solid"
                          boxSize={10}
                        />
                      ) : (
                        <VendorLogo
                          logo={getPublicImageGcsUrl(negotiation?.vendor.image_asset?.gcs_file_name)}
                          borderRadius="full"
                          borderColor="gray.200"
                          borderWidth={1}
                          borderStyle="solid"
                          boxSize={10}
                        />
                      )}
                    </Link>
                  ),
                  with: (
                    <chakra.span as="span" fontSize="3xl" color="gray.600" fontWeight="normal">
                      <FormattedMessage id="negotiation.with" defaultMessage="with" description="with" />
                    </chakra.span>
                  ),
                }}
              />
            </Heading>
            <NegotiationStatusBadge status={negotiation.status} negotiationId={negotiation.id} />
          </Flex>
          <FullTextSearch />
        </Flex>

        {/* 3 Panel Layout, but some of the layout styles are deeper in Chat.tsx and EmailComposer.tsx */}
        <Flex height={`calc(100% - ${headerHeight})`}>
          <Flex flexDirection="column" width="33.33%" minWidth="320px" height="100%">
            <Playbook
              negotiationId={negotiation.id}
              inputLegalAgreement={negotiation.input_legal_agreement}
              vendor={negotiation.vendor}
              tool={negotiation.tool}
              sections={negotiation.playbook}
              documents={negotiation.documents}
              getDocumentDownloadUrl={(documentId) =>
                negotiationEmailDocumentDownloadUrl({ negotiationId: negotiation.id, documentId })
              }
              activeVendorAgreements={negotiation.active_agreements}
            />
          </Flex>
          <Divider orientation="vertical" />

          {intent === "decision" ? (
            <Flex flexDirection="column" width="67%" height="100%">
              <DecisionPanel
                legalAgreementId={negotiation.active_agreements[0]?.id}
                onStartNegotiation={() => {
                  setQueryParams(new URLSearchParams(""))
                  navigate({
                    pathname: negotiationRouteById(negotiation.id),
                  })
                }}
                onStartChurn={async () => {
                  try {
                    await automatedChurn({
                      body: {
                        negotiation_id: negotiation.id,
                      },
                    }).unwrap()
                    setQueryParams(new URLSearchParams(""))
                    navigate({
                      pathname: negotiationRouteById(negotiation.id),
                    })
                  } catch (_) {
                    toast({
                      title: intl.formatMessage({
                        id: "negotiation.churn.error",
                        description: "Error message for churning a negotiation",
                        defaultMessage: "Error churning negotiation",
                      }),
                      status: "error",
                    })
                  }
                }}
              />
            </Flex>
          ) : (
            <>
              <Flex width="33.33%" minWidth="320px" height="100%">
                <EmailComposer
                  emailThread={currentEmailThread}
                  onThreadChange={setEmailThreadId}
                  isLoading={isAnswerLoading}
                  emailDraft={emailDraft}
                  onChange={(value) => setEmailDraft({ ...emailDraft, ...value })}
                  negotiation={negotiation}
                  negotiationConversation={selectedConversation}
                  onSendSuccess={async () => {
                    posthogCaptureEvent("negotiation_email_sent")
                    await onReset()
                  }}
                />
              </Flex>

              <Divider orientation="vertical" />

              <Flex width="33.33%" minWidth="320px" height="100%">
                <Chat
                  showAgentTelemetry={selectedConversation?.show_agent_telemetry ?? false}
                  conversation={selectedConversation?.betsy_conversation ?? { id: crypto.randomUUID() }}
                  onSubmitStreamingUrl={`${import.meta.env.VITE_API_BASE_URL}/negotiation/v1/compose`}
                  setEmailDraft={setEmailDraft}
                  emailDraft={emailDraft}
                  setIsLoading={setIsAnswerLoading}
                  defaultMessages={
                    // let the conversation fully load before showing the agent quip
                    selectedConversation?.betsy_conversation.messages.length === 0
                      ? [
                          {
                            role: "assistant",
                            content: quip,
                            tool_calls: [],
                            citations: [],
                          },
                        ]
                      : []
                  }
                  onConversationSelect={(conversation_id: UUIDString) => {
                    // find the conversation
                    const conversation = negotiation.conversations.find(
                      (conversation) => conversation.id === conversation_id
                    )
                    if (!conversation) return

                    setSelectedConversation(conversation)
                    const newQueryParams = new URLSearchParams({
                      ...queryParams,
                      conversation: conversation.id,
                    })

                    // if the next conversation is the last one, remove the conversation param
                    if (conversation === negotiation.conversations.at(-1)) {
                      newQueryParams.delete("conversation")
                    }
                    setQueryParams(newQueryParams)
                    navigate(
                      {
                        pathname: window.location.pathname,
                        search: newQueryParams.toString(),
                      },
                      { replace: true }
                    )
                  }}
                  onRegenerate={async () => {
                    if (!selectedConversation) return
                    await regenerateNegotiation({
                      body: {
                        negotiation_conversation_id: selectedConversation.id,
                      },
                    }).unwrap()
                    posthogCaptureEvent("negotiation_try_again")
                  }}
                  onReset={async () => {
                    posthogCaptureEvent("negotiation_new_conversation")
                    await onReset()
                  }}
                  onChurn={async () => {
                    await automatedChurn({
                      body: {
                        negotiation_id: negotiation.id,
                      },
                    }).unwrap()
                  }}
                  addOns={{
                    negotiation: {
                      enabled: true,
                      negotiationConversation: selectedConversation,
                      negotiation,
                      emailThread: currentEmailThread,
                      onSendSuccess: async () => {
                        posthogCaptureEvent("negotiation_email_sent")
                        await onReset()
                      },
                      onThreadChange: setEmailThreadId,
                    },
                  }}
                  additionalRequestParams={{
                    negotiation_conversation_id: selectedConversation?.id,
                    thread_id: emailThreadId,
                  }}
                />
              </Flex>
            </>
          )}
        </Flex>
      </Stack>
    </ChatContextProvider>
  )
}

export default Negotiation
