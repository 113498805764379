import { Box, Button, Text, useToast } from "@chakra-ui/react"
import { useEffect, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetIntegrationV1PrivateQuery } from "../../app/services/generated-api.js"
import { GmailIcon } from "../../components/icons/provider-icons.js"
import NegotiationAgentLayout from "./NegotiationAgentLayout.js"

const NegotiationAgentPermissions = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  // Get email and error query parameters from URL
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const email = useMemo(() => searchParams.get("email"), [searchParams])
  const error = useMemo(() => searchParams.get("error"), [searchParams])

  // Fetch integrations
  const { data: integrations, isLoading } = useGetIntegrationV1PrivateQuery()

  // Required Gmail scope for sending emails
  const requiredScope = "https://www.googleapis.com/auth/gmail.send"

  // Find existing Gmail integration for this email (if any)
  const existingGmailIntegration = useMemo(() => {
    if (!integrations || !email) return null
    return integrations.find((integration) => integration.provider === "gmail_oauth" && integration.email === email)
  }, [integrations, email])

  // URL for OAuth connection with additional scopes
  const googleOAuthURL = useMemo(() => {
    return `${import.meta.env.VITE_API_BASE_URL}/oauth/v1/connect/gmail_oauth?additionalScopes=${requiredScope}&negotiationAgent=true${
      email ? `&expectedEmail=${encodeURIComponent(email)}` : ""
    }${existingGmailIntegration ? `&integrationId=${existingGmailIntegration.id}` : ""}`
  }, [email, existingGmailIntegration, requiredScope])

  useEffect(() => {
    // Handle error messages from OAuth flow
    if (error) {
      toast({
        title: error,
        status: "error",
        duration: 5000,
        isClosable: true,
      })

      // Remove error from URL without refreshing the page
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete("error")
      const newUrl = `${location.pathname}${newSearchParams.toString() ? `?${newSearchParams.toString()}` : ""}`
      window.history.replaceState(null, "", newUrl)
    }
  }, [error, toast, intl, searchParams, location.pathname])

  useEffect(() => {
    // Skip if still loading or no email provided
    if (isLoading || !email || !integrations) return

    // Check if integration already exists with the required scope
    const gmailIntegration = integrations.find(
      (integration) =>
        integration.provider === "gmail_oauth" &&
        integration.email === email &&
        integration.scope?.includes(requiredScope)
    )

    // If integration with required scope exists, redirect to success page
    if (gmailIntegration) {
      toast({
        title: intl.formatMessage({
          id: "negotiationAgent.permissions.alreadyConnected",
          defaultMessage: "Gmail already connected",
          description: "Toast message when Gmail is already connected with required permissions",
        }),
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      navigate("/negotiation-agent/success")
    }
  }, [integrations, isLoading, email, navigate, intl, toast, requiredScope])

  return (
    <NegotiationAgentLayout>
      <Box mb={2}>
        <Text fontSize="5xl" fontWeight="bold">
          <FormattedMessage
            id="negotiationAgent.permissions.heading"
            defaultMessage="Supercharge Your Negotiations."
            description="Main heading for the negotiation agent permissions page"
          />
        </Text>
      </Box>

      <Box mb={8}>
        <Text fontSize="xl">
          <FormattedMessage
            id="negotiationAgent.permissions.content"
            defaultMessage="Allow BRM’s negotiation AI to access your inbox to bring in relevant vendor email threads. This gives you the full context needed to negotiate smarter."
            description="Main content paragraph explaining how the negotiation agent works"
          />
        </Text>
      </Box>

      {/* Gmail connect button */}
      <Box>
        <Button
          size="lg"
          bgColor="black"
          color="white"
          borderRadius="full"
          as="a"
          href={googleOAuthURL}
          width="100%"
          leftIcon={<GmailIcon boxSize={6} />}
          _hover={{
            bgColor: "gray.800",
          }}
        >
          <FormattedMessage
            id="negotiationAgent.permissions.cta"
            defaultMessage="Connect to Gmail"
            description="Call to action button text to connect Gmail"
          />
        </Button>
      </Box>
    </NegotiationAgentLayout>
  )
}

export default NegotiationAgentPermissions
