import type { VendorListItem } from "@brm/schema-types/types.js"
import { Box, Stat, StatGroup, StatLabel, StatNumber, VStack } from "@chakra-ui/react"
import { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  useGetVendorV1ManagedCountQuery,
  usePostVendorV1ListQuery,
} from "../../app/services/generated-api.js"
import DataTable from "../../components/DataTable/DataTable.js"
import { ROOT_COLUMN_ID, useSchemaColumns } from "../../components/DataTable/use-schema-columns.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  type TableParamsState,
} from "../../util/schema-table.js"
import { useObjectSchema } from "../../util/use-schema.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"

const defaultColumns: (keyof VendorListItem)[] = ["status"]

export default function BillingSettings() {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: managedVendorsCount } = useGetVendorV1ManagedCountQuery()
  const vendorSchema = useObjectSchema("Vendor")
  const primaryColumn = ROOT_COLUMN_ID

  // Define state variables for pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  // Define a simplified TableParamsState with static sorting on status ASC
  const tableParams: TableParamsState<keyof VendorListItem> = useMemo(
    () => ({
      page,
      pageSize,
      sorting: [{ id: "status", desc: true }],
      sortingColumns: ["status"] as const,
      filterMap: new Map(),
      savedViewState: {},
    }),
    [page, pageSize]
  )

  // Update pagination directly using state setters
  const updateTableParams = useCallback((params: Partial<TableParamsState<keyof VendorListItem>>) => {
    if (params.page !== undefined) {
      setPage(params.page)
    }
    if (params.pageSize !== undefined) {
      setPageSize(params.pageSize)
    }
  }, [])

  // Get API parameters using the helper function
  const apiParams = useMemo(
    () => tableParams && vendorSchema && packageSortFilterOptionsForAPI(tableParams, vendorSchema, intl),
    [intl, tableParams, vendorSchema]
  )

  // Query vendors using the proper parameters
  const { data: vendorData } = usePostVendorV1ListQuery(
    apiParams ? { listQueryStringParams: apiParams } : { listQueryStringParams: { limit: 100 } }
  )

  // Get the columns to show using the helper function
  const shownColumns = useMemo(() => shownColumnsForTableParamState(tableParams, defaultColumns), [tableParams])

  const columns = useSchemaColumns<VendorListItem>({ objectSchema: vendorSchema, shownColumns, primaryColumn })

  const currentPlan = whoami?.organization?.is_lite ? "Lite" : "Enterprise"
  const managedVendors = managedVendorsCount?.count ?? 0

  return (
    <SettingsContainer>
      <SettingsHeader
        title={intl.formatMessage({
          defaultMessage: "Billing",
          description: "Billing settings page header",
          id: "billing.page.header",
        })}
      />
      <Box>
        <StatGroup>
          <Stat>
            <StatLabel>
              <FormattedMessage
                id="billing.stats.managedVendors"
                description="Label for managed vendors count"
                defaultMessage="Managed Vendors"
              />
            </StatLabel>
            <StatNumber>{managedVendors}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>
              <FormattedMessage
                id="billing.stats.currentPlan"
                description="Label for current plan type"
                defaultMessage="Current Plan"
              />
            </StatLabel>
            <StatNumber>{currentPlan}</StatNumber>
          </Stat>
        </StatGroup>

        <VStack mt={6} align="start" spacing={4}>
          {/* Add vendor table */}
          {columns && (
            <Box w="full" overflowX="auto" borderLeft={1} borderRight={1} borderColor="gray.200">
              <DataTable<VendorListItem>
                columns={columns}
                data={vendorData?.vendors ?? []}
                isSticky
                stretchLastColumn
                paginationProps={{
                  page: tableParams.page,
                  pageSize: tableParams.pageSize,
                  onPageChange: (page) => updateTableParams({ page }),
                  onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
                  totalListElements: vendorData?.total ?? 0,
                }}
              />
            </Box>
          )}
        </VStack>
      </Box>
    </SettingsContainer>
  )
}
