import { emptyLegalClauses } from "@brm/schema-helpers/legal.js"
import { isBooleanWithRichTextDetails, isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import {
  isDocumentOrURLStringType,
  isDocumentsType,
  isDocumentType,
  isFrequencyOrOneTimeType,
} from "@brm/schema-helpers/schema.js"
import type {
  AgreementType,
  Applicable,
  CommentCounts,
  CompliantWithDocument,
  CreditCardPatch,
  CurrencyAmount,
  DateString,
  DocumentMinimal,
  DocumentOrURLString,
  FieldMetadataWithSuggestions,
  FieldSourceInputProperties,
  FieldSourceOutputProperties,
  FormFieldConfig,
  HttpUrlString,
  InvoiceInterval,
  LegalClauses,
  LegalClausesFieldsMetadata,
  ObjectType,
  PickableEntityFilter,
  Suggestion,
  ToolOptionWithVendor,
  VendorOptionWithTools,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { DateDurationString, FieldSourceInputPropertiesSchema, LegalClausesSchema } from "@brm/schemas"
import {
  decisionDateDerivedFromFieldsSet,
  derivedDecisionDate,
  getDecisionDateBounds,
} from "@brm/type-helpers/legal.js"
import {
  isApplicableWithDocumentType,
  isCompliantWithDocumentType,
  isCreditCardType,
  isCurrencyAmountType,
  isPersonType,
  isStdObjSchema,
} from "@brm/type-helpers/schema.js"
import { formatDuration, getOneTimeFrequencyDisplayName } from "@brm/util/format-date-time.js"
import {
  getEnumOptions,
  getSchemaAtPath,
  getTitle,
  isBooleanType,
  isEnumArrayType,
  isEnumType,
  isIntegerType,
  isNullableSchema,
  isNumberType,
  isStringType,
  unwrapNullableSchema,
} from "@brm/util/schema.js"
import { hasOwnProperty, isObject, isObjectWithId, isTruthy } from "@brm/util/type-guard.js"
import { deepEqualStdObjects } from "@brm/util/workflow.js"
import * as cfWorkerJsonSchema from "@cfworker/json-schema"
import { Box, Flex, FormControl, Textarea } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import equal from "fast-deep-equal"
import { excludeKeys } from "filter-obj"
import objectPath from "object-path"
import type { ReactNode } from "react"
import { forwardRef, memo, useCallback, useMemo, useRef, useState } from "react"
import type {
  Control,
  ControllerRenderProps,
  RefCallBack,
  SetValueConfig,
  UseFormResetField,
  Validate,
} from "react-hook-form"
import { Controller, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import LegalClausesSelector from "../../features/legal/LegalClausesSelector.js"
import {
  ToolListingMultiPickerWithModal,
  ToolOptionPickerCreatableWithModal,
} from "../../features/tool/NewToolModal.js"
import { VendorOptionPickerCreatableWithModal } from "../../features/vendor/NewVendorModal.js"
import {
  canApproveWorkflowRunStep,
  type GetLogoForOrganizationProps,
  type GetOrganizationActorProps,
  type WorkflowRunWithExternalFlag,
} from "../../features/workflows/run/utils.js"
import type { DateShortcut } from "../../util/form.js"
import {
  isLegalClausesType,
  isToolListingArrayType,
  isToolOptionsType,
  isToolReferenceType,
  isVendorOptionType,
} from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { DocumentUpload, type DocumentChangeHandler } from "../Document/DocumentUpload.js"
import { DocumentUploadOrUrlInput, UrlInput } from "../Document/DocumentUploadOrUrlInput.js"
import ExtractLegalDocumentUpload from "../Document/ExtractLegalDocumentUpload.js"
import BasicOrganizationEntityPicker from "../Form/BasicOrganizationEntityPicker.js"
import { BooleanSelect } from "../Form/BooleanSelect.js"
import { CreditCardInputGroup } from "../Form/CreditCardInput.js"
import { CurrencyAmountInputGroup } from "../Form/CurrencyAmountInput.js"
import { DATE_DURATION_UNITS, DATE_TIME_DURATION_UNITS } from "../Form/duration-unit.js"
import { DurationInputGroup, type DurationInputOption } from "../Form/DurationInput.js"
import EnumMultiSelect from "../Form/EnumMultiSelect.js"
import { EnumSelect } from "../Form/EnumSelect.js"
import { FrequencyInputGroup } from "../Form/FrequencyInput.js"
import ToolPicker from "../Form/ToolPicker.js"
import RichTextDisplay from "../RichTextEditor/RichTextDisplay.js"
import RichTextEditor from "../RichTextEditor/RichTextEditor.js"
import { DEFAULT_PICKABLE_ENTITIES, EMPTY_RICH_TEXT_BODY } from "../RichTextEditor/util/common.js"
import { DatePickerInput } from "./DatePicker.js"
import type { InputContainerProps } from "./InputContainer.js"
import { InputContainer } from "./InputContainer.js"
import { NumberInput } from "./NumberInput.js"
import { StringInput } from "./StringInput.js"
import type { DynamicFormFieldApproval, TypedSuggestion, ValueWithSource } from "./types.js"
import { fieldHasFormValue, formatValidationErrors, getDefaultUserFieldSource } from "./utils.js"

export interface DynamicFormFieldProps {
  formField: FormFieldConfig
  path: (string | number)[]
  /** react-hook-form `useForm().control` */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>

  /** react-hook-form `useForm().getValues()` function */
  getValue: (pathString: string) => unknown

  /** react-hook-form `useForm().setValue()` function */
  setValue: (pathString: string, value: unknown, options?: SetValueConfig) => void

  /** react-hook-form `useForm().resetField()` function */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetField: UseFormResetField<any>

  /** The workflow run. Used for fetching field timeline items. */
  workflowRun?: WorkflowRunWithExternalFlag

  /** The workflow run step. Used for posting comments and modifying approval status of step fields. */
  workflowRunStep?: WorkflowRunStepWithContext
  /** Only relevant when workflowRun is provided. If present, the DynamicFormField is being rendered to an external seller user. */
  externalLinkCode?: string

  /** The counts of comments by object ID and field name. */
  commentCounts?: CommentCounts

  /** The full base schema, used to determine types. */
  rootBaseSchema: ReadonlyDeep<JSONSchema>
  isReadOnly: boolean
  getDocumentDownloadUrl?: (path: (string | number)[], document: DocumentMinimal) => string
  onDocumentClick?: (document: DocumentMinimal) => void
  /** Some forms submit onBlur, but the onBlur event for DocumentUpload is not intuitively triggered by user actions, so the onBlur action can be passed in here and called on DocumentUpload change  */
  onDocumentChange?: DocumentChangeHandler
  renderFieldWrapper?: (wrapperProps: InputContainerProps) => ReactNode

  /** Renderer to display field source information */
  renderFieldSource?: (provenancePath: (string | number)[], fieldSource?: FieldSourceOutputProperties) => ReactNode
  /** Callback to trigger provenance highlighting */
  onProvenanceClick?: (path: (string | number)[], fieldSource?: FieldSourceOutputProperties) => void
  /** Will render accept buttons per field if non empty */
  onVerifyField?: (fieldSourcePath: (string | number)[]) => void
  /** Selected document to be set active in document fields */
  selectedDocument?: DocumentMinimal
  /** Date shortcuts for date fields. If not provided, will show default shortcuts anchored off of today's date. */
  dateShortcuts?: DateShortcut[]
  /** Duration shortcuts for duration fields. If not provided, will not show duration shortcuts unless there are suggestions. */
  durationShortcuts?: DurationInputOption[]
  /** Default value for the field */
  defaultValue?: unknown
}

function DynamicFormPropsAreEqual(prevProps: DynamicFormFieldProps, nextProps: DynamicFormFieldProps) {
  return (
    prevProps.formField === nextProps.formField &&
    prevProps.path.join(".") === nextProps.path.join(".") &&
    prevProps.control === nextProps.control &&
    prevProps.getValue === nextProps.getValue &&
    prevProps.setValue === nextProps.setValue &&
    prevProps.resetField === nextProps.resetField &&
    prevProps.workflowRun === nextProps.workflowRun &&
    prevProps.workflowRunStep === nextProps.workflowRunStep &&
    prevProps.externalLinkCode === nextProps.externalLinkCode &&
    prevProps.commentCounts === nextProps.commentCounts &&
    prevProps.rootBaseSchema === nextProps.rootBaseSchema &&
    prevProps.isReadOnly === nextProps.isReadOnly &&
    prevProps.getDocumentDownloadUrl === nextProps.getDocumentDownloadUrl &&
    prevProps.onDocumentClick === nextProps.onDocumentClick &&
    prevProps.onDocumentChange === nextProps.onDocumentChange &&
    prevProps.renderFieldWrapper === nextProps.renderFieldWrapper &&
    prevProps.renderFieldSource === nextProps.renderFieldSource &&
    prevProps.onProvenanceClick === nextProps.onProvenanceClick &&
    prevProps.onVerifyField === nextProps.onVerifyField &&
    prevProps.selectedDocument === nextProps.selectedDocument &&
    prevProps.dateShortcuts === nextProps.dateShortcuts &&
    prevProps.durationShortcuts === nextProps.durationShortcuts &&
    equal(prevProps.defaultValue, nextProps.defaultValue)
  )
}

/**
 * Renders a form field based on the field's schema type. Automatically selects the appropriate
 * input component (text, number, date, document, etc.).
 */
export const DynamicFormField = memo(function DynamicFormField(
  props: DynamicFormFieldProps & GetLogoForOrganizationProps & GetOrganizationActorProps
): ReactNode {
  const {
    path,
    formField,
    control,
    getValue,
    setValue,
    resetField,
    commentCounts,
    rootBaseSchema,
    workflowRun,
    workflowRunStep,
    getDocumentDownloadUrl,
    onDocumentClick,
    onDocumentChange: parentOnDocumentChange,
    renderFieldWrapper,
    renderFieldSource,
    onProvenanceClick,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    onVerifyField,
    selectedDocument,
    dateShortcuts,
    durationShortcuts,
    defaultValue,
    externalLinkCode,
  } = props
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const value = getValue(path.join("."))
  const suggestionRef = useRef<HTMLElement | null>(null)
  const isRequired = formField.is_required

  const setSuggestionRef = useCallback(
    (fieldRef?: RefCallBack) => (e: HTMLElement | null) => {
      fieldRef?.(e)
      suggestionRef.current = e
    },
    []
  )
  const onDocumentChange = useCallback<DocumentChangeHandler>(
    ({ documents, type, document }) =>
      parentOnDocumentChange?.({ documents, type, document, field_name: formField.field_name }),
    [parentOnDocumentChange, formField.field_name]
  )

  const schemaWithNullable = useMemo(() => getSchemaAtPath(rootBaseSchema, path, false), [rootBaseSchema, path])
  const fieldCanBeNull = isNullableSchema(schemaWithNullable)
  const schema = schemaWithNullable ? unwrapNullableSchema(schemaWithNullable) : undefined
  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(() => {
    return workflowRun?.id
      ? {
          ...(workflowRun.id && {
            requesting_entity: {
              object_type: "WorkflowRun",
              object_id: workflowRun.id,
            },
          }),
          entities: [...DEFAULT_PICKABLE_ENTITIES, "workflow_seller", "workflow_buyer"],
        }
      : { entities: DEFAULT_PICKABLE_ENTITIES }
  }, [workflowRun?.id])

  const [provenancePath, setProvenancePath] = useState<(string | number)[]>(path)
  const parentObjectPath = formField.is_custom ? path.slice(0, -2) : path.slice(0, -1)

  const parentObject = getValue(parentObjectPath.join("."))
  const parentId = isObjectWithId(parentObject) ? parentObject.id : undefined
  const parentType =
    isObject(parentObject) && "object_type" in parentObject && typeof parentObject.object_type === "string"
      ? (parentObject.object_type as ObjectType)
      : undefined
  const parentObjectBaseSchema = getSchemaAtPath(rootBaseSchema, parentObjectPath)

  const decisionDateDeps = Array.from(decisionDateDerivedFromFieldsSet, (field) =>
    [...parentObjectPath, field].join(".")
  )
  const decisionDateDerivedFromValues = useWatch({
    control,
    name: decisionDateDeps,
    disabled: formField.field_name !== "decision_date",
  })

  const fieldMetadata = useWatch({
    control,
    name: [
      ...parentObjectPath,
      "fields_metadata",
      formField.is_custom ? `custom.${formField.field_name}` : formField.field_name,
    ].join("."),
  }) as FieldMetadataWithSuggestions | LegalClausesFieldsMetadata | undefined

  const updateFieldMetadata = useCallback(
    (fieldSource?: FieldSourceInputProperties | LegalClausesFieldsMetadata) => {
      const now = Temporal.Now.instant().toString()
      // Don't update fields source metadata from external link. If we want to save the seller user that modified the field, we will need to change this
      if (formField.field_name && whoami && !workflowRun?.is_external) {
        const fieldSourceProps = FieldSourceInputPropertiesSchema.properties
        if (formField.field_name === "clauses" && fieldSource) {
          const clausesMetadata = excludeKeys(
            (getValue([...parentObjectPath, "fields_metadata", formField.field_name].join(".")) ||
              {}) as LegalClausesFieldsMetadata,
            [...Object.keys(LegalClausesSchema.properties), ...Object.keys(fieldSourceProps)]
          )
          setValue(
            [...parentObjectPath, "fields_metadata", formField.field_name].join("."),
            {
              ...clausesMetadata,
              ...fieldSource,
              // Always verify when selecting clauses
              verified: true,
              verified_at: now,
              verified_by: whoami.id,
              updated_at: now,
            } satisfies FieldMetadataWithSuggestions,
            {
              shouldDirty: true,
            }
          )
        } else {
          const maybeCustomFieldName = formField.is_custom ? ["custom", formField.field_name] : [formField.field_name]
          const otherFieldMetadata = excludeKeys(
            getValue([...parentObjectPath, "fields_metadata", ...maybeCustomFieldName].join(".")) || {},
            (k) => k in fieldSourceProps
          )
          setValue(
            [...parentObjectPath, "fields_metadata", ...maybeCustomFieldName].join("."),
            {
              ...otherFieldMetadata,
              ...(fieldSource || getDefaultUserFieldSource(intl, whoami)),
              updated_at: now,
            } satisfies FieldMetadataWithSuggestions,
            {
              shouldDirty: true,
            }
          )
        }
      }

      // This must be handled separately because the suggestions on the original fieldMetadata may be outdated from the current value in the form.
      const decisionDateMetadataPath = [...parentObjectPath, "fields_metadata", "decision_date"].join(".")
      const decisionDatePath = [...parentObjectPath, "decision_date"].join(".")
      const decisionDateMetadata = getValue(decisionDateMetadataPath) as FieldMetadataWithSuggestions | undefined
      if (
        formField.field_name &&
        decisionDateDerivedFromFieldsSet.has(formField.field_name) &&
        (!decisionDateMetadata || decisionDateMetadata.type === "derived")
      ) {
        const derivedDate = derivedDecisionDate({
          end_date: getValue([...parentObjectPath, "end_date"].join(".")) as string | undefined,
          auto_renewal_opt_out_period: getValue([...parentObjectPath, "auto_renewal_opt_out_period"].join(".")) as
            | string
            | undefined,
          invoice_interval: getValue([...parentObjectPath, "invoice_interval"].join(".")) as
            | InvoiceInterval
            | undefined,
          agreement_type: getValue([...parentObjectPath, "agreement_type"].join(".")) as AgreementType | undefined,
          first_invoice_date: getValue([...parentObjectPath, "first_invoice_date"].join(".")) as string | undefined,
        })

        setValue(decisionDatePath, derivedDate?.decision_date ?? null, { shouldDirty: true })
        setValue(decisionDateMetadataPath, derivedDate?.fields_metadata ?? {}, { shouldDirty: true })
      }
    },
    [
      formField.field_name,
      getValue,
      parentObjectPath,
      setValue,
      formField.is_custom,
      intl,
      whoami,
      workflowRun?.is_external,
    ]
  )

  const suggestedValues: Suggestion[] | undefined = useMemo(() => {
    if (formField.field_name === "decision_date") {
      const depObject = decisionDateDerivedFromValues
        .map((val, i) => {
          // decisionDateDeps will have a value of the entire path from the root but we only want to get the last field
          const key = decisionDateDeps[i]?.split(".").pop()
          if (!key) return {}
          return {
            [key]: val,
          }
        })
        .reduce((a, b) => ({ ...a, ...b }))

      const derivedDate = derivedDecisionDate(depObject)
      if (derivedDate) {
        return [
          {
            value: derivedDate.decision_date,
            field_sources: [
              {
                ...derivedDate.fields_metadata,
                source_display_name: intl.formatMessage({
                  defaultMessage: "BRM Suggestion",
                  description: "The option label for a field calculated from other fields",
                  id: "dynamicForm.decisionDate.option.derived",
                }),
              },
            ],
          },
        ] satisfies Suggestion[]
      }
    }
    return fieldMetadata?.suggestions
  }, [decisionDateDeps, decisionDateDerivedFromValues, fieldMetadata?.suggestions, formField.field_name, intl])

  /**
   * Change handler that wraps the original onChange handler and also updates the field source metadata to the provided value
   */
  const onChangeWithFieldSource = (onChange: ControllerRenderProps["onChange"]) => {
    return (new_value: unknown, fieldSource: FieldMetadataWithSuggestions | LegalClausesFieldsMetadata | undefined) => {
      // if the new value is the same as the current value, we don't need to do anything
      if (deepEqualStdObjects(new_value, value)) {
        return
      }
      onChange(new_value)
      updateFieldMetadata(fieldSource)
    }
  }

  if (!isObject(schema)) {
    return null
  }

  const commentCount = parentId ? commentCounts?.[parentId]?.[formField.field_name] : undefined
  const fieldApprovals = workflowRunStep && "field_approvals" in workflowRunStep ? workflowRunStep.field_approvals : {}
  const fieldApproval = parentId ? fieldApprovals[parentId]?.[formField.field_name] : undefined
  const fieldGatherers = workflowRun?.field_gatherers || {}
  const fieldGatherer = parentId ? fieldGatherers[parentId]?.[formField.field_name] : undefined
  const fieldIsApproved = fieldApproval?.status === "approved" || workflowRunStep?.status === "approved"
  const fieldApprovalMetadata: DynamicFormFieldApproval = fieldApproval && { ...fieldApproval, fieldIsApproved }

  const isReadOnly =
    props.isReadOnly ||
    Boolean(schema.readOnly) ||
    // An approved field is read-only for everyone that does not have approval permission
    ((fieldApproval?.status === "approved" || workflowRunStep?.status === "approved") &&
      !!workflowRunStep &&
      !!workflowRun &&
      !canApproveWorkflowRunStep(whoami, workflowRunStep, workflowRun))

  // Get last element in path, which is the field name without any nested objects in between
  const title = getTitle(formField.field_name ?? "", schema)
  const description = schema.uiDescription || schema.description
  const controllerProps = { name: path.join("."), control }

  const wrapperProps: Omit<InputContainerProps, "children"> & GetLogoForOrganizationProps & GetOrganizationActorProps =
    {
      formField,
      description,
      label: title,
      workflowRun,
      fieldMetadata,
      objectId: parentId,
      objectType: parentType,
      getValue,
      path,
      fieldName: formField.field_name,
      fieldFilledOut: fieldHasFormValue(schema, value),
      isCustomField: formField.is_custom,
      isReadOnly,
      commentCount,
      workflowRunStep,
      fieldApproval: fieldApprovalMetadata,
      fieldGatherer,
      renderFieldWrapper,
      getLogoToShowByOrganizationId,
      getOrganizationActorWhenActorMissing,
      renderFieldSource:
        fieldMetadata && renderFieldSource
          ? () =>
              renderFieldSource(
                provenancePath,
                // For almost all fields, the fieldMetadata a singular fieldMetadata object. For “clauses”, it is an object with nested field metadata for each clause
                // We will check if the provenance path has been modified to something longer than the actual path, then get the metadata pointing to the specific
                // sub-metadata
                provenancePath.length > path.length
                  ? objectPath.get(fieldMetadata, provenancePath.slice(parentObjectPath.length + 1))
                  : fieldMetadata
              )
          : undefined,
      onVerifyField: onVerifyField
        ? () =>
            onVerifyField([
              ...parentObjectPath,
              ...(formField.field_name
                ? [formField.is_custom ? `custom.${formField.field_name}` : formField.field_name]
                : []),
            ])
        : undefined,
      fieldRef: suggestionRef,
      value,
      suggestions: suggestedValues ?? [],
    }

  const requiredMessage = intl.formatMessage({
    defaultMessage: "Please provide an answer to this field",
    description: "Error message for required field",
    id: "form.error.required",
  })

  // If you see validation errors without the code entering this block, check to see if the surrounding html form has a `noValidate` attribute
  const validate: Validate<unknown, unknown> = (value) => {
    // Rudimentary support for the AJV's `transform` keyword so that we don't fail on leading/trailing whitespace
    if (typeof value === "string" && schema.transform?.includes("trim")) {
      value = value.trim()
    }
    if (typeof value === "string" && value.length === 0) {
      value = null
    }
    if (formField.is_required && !fieldHasFormValue(schema, value)) {
      return requiredMessage
    }
    // Never allow user to input something < 1980
    if (isStringType(schema, value) && schema.format === "date") {
      const isValidDateRange = !value || Temporal.PlainDate.from(value).year >= 1980
      if (!isValidDateRange) {
        return intl.formatMessage({
          id: "form.date.invalidRange",
          defaultMessage: "Please select a date after 1980.",
          description: "Error message for invalid date range",
        })
      }
    }
    if (value !== null && value !== undefined) {
      // The root schema validates the general shape of the data, e.g. a string being a URL or a date, min/max for numbers, etc.
      //
      // We pass an empty object as the "lookup" argument to avoid infinite recursion on $refs. The schema is assumed to be
      // already dereferenced at this point. Attempting to dereference it again (within the cfworker validate implementation)
      // will infinitely recurse.
      const baseSchemaResult = cfWorkerJsonSchema.validate(value, schema as cfWorkerJsonSchema.Schema, undefined, {})
      if (baseSchemaResult.errors[0]) {
        return formatValidationErrors({
          errors: baseSchemaResult.errors,
          schema: baseSchemaResult,
          fieldName: formField.field_name,
        })
      }
    }
    return true
  }

  const schemaWithValues = { schema, value }

  if (isCompliantWithDocumentType(schema, value)) {
    const castedSuggestions = suggestedValues as TypedSuggestion<CompliantWithDocument>[]
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = castedSuggestions
      ?.map(({ value, field_sources }) => {
        if (value?.document && typeof value.document === "string") {
          return {
            value: value.document,
            field_sources,
          } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    const booleanSuggestions: ValueWithSource<Applicable>[] = castedSuggestions
      ?.map(({ value, field_sources }) => {
        if (value?.compliant && typeof value.compliant === "boolean") {
          return { value: value.compliant, field_sources }
        }
        return undefined
      })
      .filter(isTruthy)

    const hasTrueSuggestion = booleanSuggestions?.find((suggestion) => suggestion.value)
    const onTrueUrlSuggestion = hasTrueSuggestion ? (urlSuggestions?.[0]?.value ?? null) : null

    const documentOrUrlValue = value?.document ?? onTrueUrlSuggestion

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <BooleanSelect
                ref={setSuggestionRef(field.ref)}
                value={field.value?.compliant ?? null}
                onChange={(compliant, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(
                    // set document null here as a fallback to make sure the whole object is always present
                    // If there is a document in the field value, it will override that
                    compliant
                      ? { document: onTrueUrlSuggestion, ...field.value, compliant }
                      : { compliant, document: null },
                    !field.value?.document ? fieldSource : undefined
                  )
                }
                allowNull
                menuPortalTarget={document.body}
                isRequired={isRequired}
                isReadOnly={isReadOnly}
                suggestions={booleanSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />

              {field.value?.compliant && (!isReadOnly || value?.document) && (
                // Document upload field is never required
                <Flex mt={2} direction="column">
                  <DocumentUploadOrUrlInput
                    onDocumentClick={onDocumentClick}
                    setSuggestionRef={setSuggestionRef()}
                    selectedDocument={selectedDocument}
                    value={documentOrUrlValue}
                    onChange={({ value: documentOrURL, field_sources }, documentChangeProps) => {
                      onChangeWithFieldSource(field.onChange)(
                        { ...field.value, document: documentOrURL },
                        field_sources?.[0]
                      )
                      if (documentChangeProps) {
                        onDocumentChange(documentChangeProps)
                      }
                    }}
                    isReadOnly={isReadOnly}
                    getLinkDestination={
                      getDocumentDownloadUrl
                        ? (document) => getDocumentDownloadUrl([...path, "document"], document)
                        : undefined
                    }
                    urlSuggestions={urlSuggestions}
                    fieldMetadata={fieldMetadata}
                    fieldApproval={fieldApprovalMetadata}
                  />
                </Flex>
              )}
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isCreditCardType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{
          validate: (v, formValues) => {
            if (!v) {
              return validate(v, formValues)
            }
            if (!v?.display_name) {
              return intl.formatMessage({
                id: "form.creditCard.error.displayNameRequired",
                defaultMessage: "Display name is required",
                description: "Error message when display name is not set",
              })
            }
            if (!v?.spend_limit_interval) {
              return intl.formatMessage({
                id: "form.creditCard.error.spendLimitIntervalRequired",
                defaultMessage: "Please set a spend limit interval",
                description: "Error message when spend limit interval is not set when spend limit amount is present",
              })
            }
            if (!v?.spend_limit) {
              return intl.formatMessage({
                id: "form.creditCard.error.spendLimitAmountRequired",
                defaultMessage: "Please set a spend limit amount",
                description: "Error message when spend limit amount is not set when spend limit interval is present",
              })
            }
            return validate(v, formValues)
          },
        }}
        render={({ field, fieldState }) => (
          <FormControl as="fieldset" isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <CreditCardInputGroup
                {...field}
                ref={field.ref}
                isReadOnly={isReadOnly}
                isInvalid={fieldState.invalid}
                onChange={onChangeWithFieldSource(field.onChange)}
                value={field.value}
                vendorId={workflowRun?.merged_state.vendor?.id}
                defaultValue={(isObject(defaultValue) ? defaultValue : undefined) as CreditCardPatch | undefined}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }

  if (isStringType(schema, value) && schema.format === "date") {
    const suggestions: ValueWithSource<Temporal.PlainDate>[] = (suggestedValues as TypedSuggestion<DateString>[])?.map(
      (suggestion) => ({
        value: Temporal.PlainDate.from(suggestion.value),
        field_sources: suggestion.field_sources,
      })
    )
    if (formField.field_name === "decision_date") {
      const startDate = getValue([...parentObjectPath, "start_date"].join(".")) as string | undefined
      const endDate = getValue([...parentObjectPath, "end_date"].join(".")) as string | undefined
      const suggestedDate = suggestions?.[0]?.value
      const { minDate, maxDate } = getDecisionDateBounds(
        {
          startDate: startDate ? Temporal.PlainDate.from(startDate) : undefined,
          endDate: endDate ? Temporal.PlainDate.from(endDate) : undefined,
          suggestedDate: suggestedDate ? Temporal.PlainDate.from(suggestedDate) : undefined,
        },
        intl
      )
      return (
        <Controller
          {...controllerProps}
          rules={{
            validate: (v, formValues) => {
              if (!v) {
                return
              }
              // Validate v between min and max date
              if (minDate && Temporal.PlainDate.compare(Temporal.PlainDate.from(v), minDate.value) < 0) {
                return intl.formatMessage(
                  {
                    id: "form.date.error.beforeMinDate",
                    defaultMessage: "Please select a date after {minDate}",
                    description: "Error message when decision date is set before the minimum allowed date",
                  },
                  {
                    minDate: minDate.displayName,
                  }
                )
              }
              if (maxDate && Temporal.PlainDate.compare(Temporal.PlainDate.from(v), maxDate.value) > 0) {
                return intl.formatMessage(
                  {
                    id: "form.date.error.afterMaxDate",
                    defaultMessage: "Please select a date before {maxDate}",
                    description: "Error message when decision date is set after the maximum allowed date",
                  },
                  {
                    maxDate: maxDate.displayName,
                  }
                )
              }
              return validate(v, formValues)
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
                <DatePickerInput
                  {...field}
                  ref={setSuggestionRef(field.ref)}
                  onChange={onChangeWithFieldSource(field.onChange)}
                  value={value ?? ""}
                  isReadOnly={isReadOnly}
                  isInvalid={fieldState.invalid}
                  suggestions={suggestions}
                  ariaLabel={intl.formatMessage({
                    id: "form.date.ariaLabel",
                    defaultMessage: "Enter date",
                    description: "Date input field aria label",
                  })}
                  fieldMetadata={fieldMetadata}
                  fieldApproval={fieldApprovalMetadata}
                  minDate={minDate?.value}
                  maxDate={maxDate?.value}
                  dateShortcuts={dateShortcuts}
                />
              </InputContainer>
            </FormControl>
          )}
        />
      )
    }
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <DatePickerInput
                {...field}
                ref={setSuggestionRef(field.ref)}
                onChange={onChangeWithFieldSource(field.onChange)}
                value={value ?? ""}
                isReadOnly={isReadOnly}
                isInvalid={fieldState.invalid}
                suggestions={suggestions}
                ariaLabel={intl.formatMessage({
                  id: "form.date.ariaLabel",
                  defaultMessage: "Enter date",
                  description: "Date input field aria label",
                })}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
                dateShortcuts={dateShortcuts}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isFrequencyOrOneTimeType(schema, value)) {
    const frequencySuggestions = (suggestedValues as TypedSuggestion<string>[] | undefined)?.map((suggestion) => {
      if (suggestion.value === "one_time") {
        return {
          value: "one_time" as const,
          field_sources: suggestion.field_sources,
          label: getOneTimeFrequencyDisplayName(intl),
        }
      }
      const duration = Temporal.Duration.from(suggestion.value)
      return {
        value: duration,
        field_sources: suggestion.field_sources,
        label: formatDuration(intl, duration),
      }
    })

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl
            as="fieldset"
            isReadOnly={isReadOnly}
            isInvalid={fieldState.invalid}
            isRequired={isRequired}
            /** Fieldsets have default "min-inline-size: min-content" which will make the input grow larger than its container */
            minInlineSize="auto"
          >
            <InputContainer
              {...wrapperProps}
              isDirty={fieldState.isDirty}
              labelProps={{ as: "legend" }}
              errorMessage={fieldState.error?.message}
            >
              <FrequencyInputGroup
                ref={setSuggestionRef(field.ref)}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata}
                suggestedOptions={frequencySuggestions}
                value={field.value}
                onChange={(value, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(value?.toString() ?? null, fieldSource)
                }
                units={schema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.format === "duration") {
    const durationSuggestions: ValueWithSource<Temporal.Duration>[] =
      (suggestedValues as TypedSuggestion<string>[])?.map((suggestion) => {
        const duration = Temporal.Duration.from(suggestion.value)
        return {
          value: duration,
          field_sources: suggestion.field_sources,
          label: formatDuration(intl, duration),
        }
      }) || []
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl
            as="fieldset"
            isReadOnly={isReadOnly}
            isInvalid={fieldState.invalid}
            isRequired={isRequired}
            /** Fieldsets have default "min-inline-size: min-content" which will make the input grow larger than its container */
            minInlineSize="auto"
          >
            <InputContainer
              {...wrapperProps}
              isDirty={fieldState.isDirty}
              labelProps={{ as: "legend" }}
              errorMessage={fieldState.error?.message}
            >
              <DurationInputGroup
                ref={setSuggestionRef(field.ref)}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata}
                suggestions={durationSuggestions}
                value={field.value}
                onChange={(value, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(value?.toString() ?? null, fieldSource)
                }
                units={schema.pattern === DateDurationString.pattern ? DATE_DURATION_UNITS : DATE_TIME_DURATION_UNITS}
                isReadOnly={isReadOnly}
                durationShortcuts={durationShortcuts}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.format === "uri") {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <UrlInput
                {...field}
                setSuggestionRef={setSuggestionRef()}
                onChange={({ value, field_sources }) => {
                  if (typeof value === "string") {
                    onChangeWithFieldSource(field.onChange)(value, field_sources?.[0])
                  }
                }}
                value={field.value ?? ""}
                isReadOnly={isReadOnly}
                isInvalid={fieldState.invalid}
                suggestions={suggestedValues as ValueWithSource<string>[]}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isIntegerType(schema, value) || isNumberType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <NumberInput
                {...field}
                ref={setSuggestionRef(field.ref)}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata as FieldMetadataWithSuggestions}
                onChange={onChangeWithFieldSource(field.onChange)}
                value={field.value ?? ""}
                placeholder={schema.placeholder}
                pattern={schema.pattern}
                inputMode={schema.inputMode}
                isReadOnly={isReadOnly}
                step={isIntegerType(schema, value) ? 1 : undefined}
                suggestions={suggestedValues as TypedSuggestion<number>[]}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isStringType(schema, value) && schema.singleLine) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
                <StringInput
                  {...field}
                  ref={setSuggestionRef(field.ref)}
                  fieldApproval={fieldApprovalMetadata}
                  fieldMetadata={fieldMetadata as FieldMetadataWithSuggestions}
                  onChange={onChangeWithFieldSource(field.onChange)}
                  value={field.value ?? ""}
                  placeholder={schema.placeholder}
                  pattern={schema.pattern}
                  inputMode={schema.inputMode}
                  isReadOnly={isReadOnly}
                  suggestions={suggestedValues as TypedSuggestion<string>[]}
                />
              </InputContainer>
            </FormControl>
          )
        }}
      />
    )
  }
  if (isStringType(schema, value)) {
    return (
      // Multi-line text
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <Textarea
                {...field}
                rows={4}
                onChange={(value) => onChangeWithFieldSource(field.onChange)(value, undefined)}
                value={field.value ?? ""}
                placeholder={schema.placeholder}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isRichTextType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              {isReadOnly ? (
                <Box
                  borderWidth="1px"
                  px={3}
                  py={2}
                  borderRadius="lg"
                  overflowWrap="anywhere"
                  background="var(--chakra-colors-gray-50)"
                >
                  <RichTextDisplay
                    content={field.value ?? EMPTY_RICH_TEXT_BODY}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                    placeholder={
                      schema.placeholder ??
                      intl.formatMessage({
                        id: "form.richText.placeholder",
                        defaultMessage: "Start typing here...",
                        description: "Placeholder for rich text input field",
                      })
                    }
                  />
                </Box>
              ) : (
                <RichTextEditor
                  ref={field.ref}
                  onChange={(value) => onChangeWithFieldSource(field.onChange)(value, undefined)}
                  initialValue={field.value ?? EMPTY_RICH_TEXT_BODY}
                  placeholder={
                    schema.placeholder ??
                    intl.formatMessage({
                      id: "form.richText.placeholder",
                      defaultMessage: "Start typing here...",
                      description: "Placeholder for rich text input field",
                    })
                  }
                  isReadOnly={isReadOnly}
                  hasError={!!fieldState.error}
                  pickableEntityFilters={pickableEntityFilters}
                  getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                  disableMentions={!whoami}
                  disableFileDrop={true} // TODO: should file uploads be available for rich text details?
                />
              )}
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isBooleanWithRichTextDetails(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => {
          const { value, onChange, ...restFieldProps } = field
          return (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
                <BooleanSelect
                  {...restFieldProps}
                  menuPortalTarget={document.body}
                  value={value?.value ?? null}
                  fieldMetadata={fieldMetadata}
                  fieldApproval={fieldApprovalMetadata}
                  onChange={(newValue, fieldSource) =>
                    onChangeWithFieldSource(onChange)(
                      value ? { ...value, value: newValue } : { value: newValue, details: null },
                      fieldSource
                    )
                  }
                />
                {field.value?.value && (!isReadOnly || value?.details) && (
                  <Flex mt={2} direction="column">
                    <RichTextEditor
                      ref={field.ref}
                      onChange={(newDetailsValue) =>
                        onChangeWithFieldSource(field.onChange)({ ...field.value, details: newDetailsValue }, undefined)
                      }
                      initialValue={field.value?.details ?? EMPTY_RICH_TEXT_BODY}
                      placeholder={
                        schema.placeholder ??
                        intl.formatMessage({
                          id: "form.richText.placeholder",
                          defaultMessage: "Start typing here...",
                          description: "Placeholder for rich text input field",
                        })
                      }
                      isReadOnly={isReadOnly}
                      hasError={!!fieldState.error}
                      pickableEntityFilters={pickableEntityFilters}
                      getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                      disableMentions={!whoami}
                      disableFileDrop={true} // TODO: should file uploads be available for rich text details?
                    />
                  </Flex>
                )}
              </InputContainer>
            </FormControl>
          )
        }}
      />
    )
  }
  if (isDocumentOrURLStringType(schema, value)) {
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = (suggestedValues as TypedSuggestion<DocumentOrURLString>[])
      ?.map(({ value, field_sources }) => {
        if (value && typeof value === "string") {
          return { value, field_sources } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <DocumentUploadOrUrlInput
                setSuggestionRef={setSuggestionRef()}
                onDocumentClick={onDocumentClick}
                selectedDocument={selectedDocument}
                value={field.value}
                onChange={({ value: documentOrURL, field_sources }, documentChangeProps) => {
                  onChangeWithFieldSource(field.onChange)(documentOrURL, field_sources?.[0])
                  if (documentChangeProps) {
                    onDocumentChange(documentChangeProps)
                  }
                }}
                isReadOnly={isReadOnly}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
                ref={field.ref}
                urlSuggestions={urlSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (
    isDocumentsType(schema, value) &&
    isStdObjSchema(parentObjectBaseSchema) &&
    parentObjectBaseSchema.properties.object_type.const === "LegalAgreement" &&
    formField.field_name === "documents" &&
    !formField.is_custom
  ) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <ExtractLegalDocumentUpload
                ref={field.ref}
                onDocumentClick={onDocumentClick}
                selectedDocument={selectedDocument}
                value={field.value ?? []}
                onDocumentChange={({ documents, type, document }) => {
                  if (documents.length === 0) {
                    setValue(path.join("."), [], { shouldDirty: true })
                  } else {
                    onChangeWithFieldSource(field.onChange)(documents, undefined)
                  }
                  onDocumentChange?.({ documents, type, document })
                }}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
                multiple={true}
                isReadOnly={isReadOnly}
                extractionRequest={
                  workflowRun
                    ? externalLinkCode
                      ? {
                          extraction_request_type: "link_agreement",
                          workflow_run_id: workflowRun.id,
                          link_code: externalLinkCode,
                        }
                      : { extraction_request_type: "workflow_run_agreement", workflow_run_id: workflowRun.id }
                    : undefined
                }
                fieldName={formField.field_name}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isDocumentType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <DocumentUpload
                selectedDocument={selectedDocument}
                onDocumentClick={onDocumentClick}
                ref={field.ref}
                value={field.value ? [field.value] : []}
                onChange={({ documents, type, document }) => {
                  const [firstDocument] = documents
                  if (!firstDocument) {
                    setValue(path.join("."), null, { shouldDirty: true })
                  } else {
                    onChangeWithFieldSource(field.onChange)(firstDocument, undefined)
                  }
                  onDocumentChange?.({ documents, type, document })
                }}
                multiple={false}
                isReadOnly={isReadOnly}
                getLinkDestination={
                  getDocumentDownloadUrl ? (document) => getDocumentDownloadUrl(path, document) : undefined
                }
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isCurrencyAmountType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl
            as="fieldset"
            isInvalid={fieldState.invalid}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            /** Fieldsets have default "min-inline-size: min-content" which will make the input grow larger than its container */
            minInlineSize="auto"
          >
            <InputContainer
              {...wrapperProps}
              isDirty={fieldState.isDirty}
              labelProps={{ as: "legend" }}
              errorMessage={fieldState.error?.message}
            >
              <CurrencyAmountInputGroup
                ref={setSuggestionRef(field.ref)}
                value={field.value}
                onChange={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<CurrencyAmount>[]}
                menuPortalTarget={document.body}
                fieldApproval={fieldApprovalMetadata}
                fieldMetadata={fieldMetadata as Exclude<typeof fieldMetadata, LegalClausesFieldsMetadata>}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isApplicableWithDocumentType(schema, value)) {
    const castedSuggestions = suggestedValues as TypedSuggestion<CompliantWithDocument>[]
    const urlSuggestions: ValueWithSource<HttpUrlString>[] = castedSuggestions
      ?.map(({ value, field_sources }) => {
        if (value?.document && typeof value.document === "string") {
          return {
            value: value.document,
            field_sources,
          } as TypedSuggestion<string>
        }
        return undefined
      })
      .filter(isTruthy)

    const booleanSuggestions: ValueWithSource<Applicable>[] = castedSuggestions
      ?.map(({ value, field_sources }) => {
        if (value?.compliant && typeof value.compliant === "boolean") {
          return { value: value.compliant, field_sources }
        }
        return undefined
      })
      .filter(isTruthy)

    const hasTrueSuggestion = booleanSuggestions?.find((suggestion) => suggestion.value)
    const onTrueUrlSuggestion = hasTrueSuggestion ? (urlSuggestions?.[0]?.value ?? null) : null

    const documentOrUrlValue = value?.document ?? onTrueUrlSuggestion

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <BooleanSelect
                ref={setSuggestionRef(field.ref)}
                value={field.value?.applicable ?? null}
                onChange={(compliant, fieldSource) =>
                  onChangeWithFieldSource(field.onChange)(
                    // set document null here as a fallback to make sure the whole object is always present
                    // If there is a document in the field value, it will override that
                    compliant
                      ? { document: onTrueUrlSuggestion, ...field.value, compliant }
                      : { compliant, document: null },
                    !field.value?.document ? fieldSource : undefined
                  )
                }
                allowNull
                menuPortalTarget={document.body}
                isRequired={isRequired}
                isReadOnly={isReadOnly}
                suggestions={booleanSuggestions}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />

              {value?.applicable && (!isReadOnly || value?.document) && (
                // Document upload field is never required
                <Flex mt={2} direction="column">
                  <DocumentUploadOrUrlInput
                    setSuggestionRef={setSuggestionRef()}
                    onDocumentClick={onDocumentClick}
                    selectedDocument={selectedDocument}
                    value={documentOrUrlValue}
                    onChange={({ value: documentOrURL, field_sources }, documentChangeProps) => {
                      onChangeWithFieldSource(field.onChange)(
                        { ...field.value, document: documentOrURL },
                        field_sources?.[0]
                      )
                      if (documentChangeProps) {
                        onDocumentChange(documentChangeProps)
                      }
                    }}
                    isReadOnly={isReadOnly}
                    getLinkDestination={
                      getDocumentDownloadUrl
                        ? (document) => getDocumentDownloadUrl([...path, "document"], document)
                        : undefined
                    }
                    urlSuggestions={urlSuggestions}
                    fieldMetadata={fieldMetadata}
                    fieldApproval={fieldApprovalMetadata}
                  />
                </Flex>
              )}
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isBooleanType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <BooleanSelect
                {...field}
                ref={setSuggestionRef(field.ref)}
                menuPortalTarget={document.body}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
                onChange={onChangeWithFieldSource(field.onChange)}
                isRequired
                allowNull={fieldCanBeNull}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<boolean>[]}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isEnumType(schemaWithValues)) {
    const schema = schemaWithValues.schema
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl
            isInvalid={fieldState.invalid}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            defaultValue={getEnumOptions(schema)[0]?.const}
          >
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <EnumSelect
                {...field}
                ref={setSuggestionRef(field.ref)}
                suggestions={suggestedValues as TypedSuggestion<string>[]}
                value={field.value}
                menuPortalTarget={document.body}
                schema={schema}
                allowNull={fieldCanBeNull}
                onChange={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isEnumArrayType(schemaWithValues)) {
    const enumSuggestions: ValueWithSource<string>[] = suggestedValues
      ? (suggestedValues as TypedSuggestion<string>[]).filter(isTruthy)
      : []

    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => {
          const noneOption = {
            label: intl.formatMessage({
              defaultMessage: "None",
              description: "Label for none option in multi select",
              id: "form.select.none",
            }),
            value: null,
          }
          const options = [
            ...getEnumOptions(schemaWithValues.schema).map((option) => ({
              label: option.title,
              value: option.const,
            })),
            noneOption,
          ]

          let currentValue: Array<{ label: string; value: unknown }>

          if (!field.value) {
            // No value set yet, empty array
            currentValue = []
          } else if (field.value.length === 0) {
            // Empty array selected, show "None" option
            currentValue = [noneOption]
          } else if (Array.isArray(field.value)) {
            // Convert field value array to Set for O(1) lookups
            const valuesSet = new Set(field.value)
            // Filter options to only those that match selected values
            currentValue = options.filter((t) => valuesSet.has(t.value))
          } else {
            log.error("Expected array value for enum array field", { fieldValue: field.value })
            currentValue = []
          }
          return (
            <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
              <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
                <EnumMultiSelect
                  ref={setSuggestionRef(field.ref)}
                  isReadOnly={isReadOnly}
                  value={currentValue}
                  options={options}
                  suggestions={enumSuggestions}
                  onChange={(selectedOptions) => {
                    if (selectedOptions.length === 0) {
                      // Options are cleared, set value to null
                      onChangeWithFieldSource(field.onChange)(null, undefined)
                    } else if (selectedOptions.some((option) => option.value === null)) {
                      // "None" option was selected
                      if (currentValue.every((option) => option.value !== null)) {
                        // If coming from non-null values, set empty array
                        onChangeWithFieldSource(field.onChange)([], undefined)
                      } else {
                        // Filter out "None" and keep other selections
                        onChangeWithFieldSource(field.onChange)(
                          selectedOptions.filter((option) => option.value !== null).map((option) => option.value),
                          undefined
                        )
                      }
                    } else {
                      // Normal selection, extract just the values
                      onChangeWithFieldSource(field.onChange)(
                        selectedOptions.map((option) => option.value),
                        undefined
                      )
                    }
                  }}
                />
              </InputContainer>
            </FormControl>
          )
        }}
      />
    )
  }
  if (isToolListingArrayType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <ToolListingMultiPickerWithModal
                inputRef={field.ref}
                value={field.value ?? []}
                onChange={(value) => onChangeWithFieldSource(field.onChange)(value, undefined)}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isLegalClausesType(schema, value)) {
    const selected = provenancePath.at(-1) as keyof LegalClauses
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <LegalClausesSelector
                ref={setSuggestionRef(field.ref)}
                fieldMetadata={fieldMetadata as LegalClausesFieldsMetadata}
                fieldApproval={fieldApprovalMetadata}
                getDefaultFieldSource={() => (whoami ? getDefaultUserFieldSource(intl, whoami) : {})}
                tagOnClick={(clause: keyof LegalClauses) => {
                  if (path) {
                    const newPath = [...path, clause]
                    setProvenancePath?.(newPath)
                    onProvenanceClick?.(newPath, (fieldMetadata as LegalClausesFieldsMetadata)?.[clause])
                  }
                }}
                menuPortalTarget={document.body}
                value={value ?? emptyLegalClauses}
                onChangeClauses={onChangeWithFieldSource(field.onChange)}
                isReadOnly={isReadOnly}
                suggestions={suggestedValues as TypedSuggestion<keyof LegalClauses>[]}
                selected={selected}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isToolOptionsType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{
          validate: (value) =>
            Array.isArray(value) &&
            value.some(
              (item) => isObject(item) && hasOwnProperty(item, "tool_listing_id") && item.tool_listing_id === "new"
            )
              ? undefined
              : validate(value, undefined),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isReadOnly={isReadOnly} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              {/* TODO: support picking multiple tools */}
              <ToolOptionPickerCreatableWithModal
                isLite={whoami?.organization.is_lite}
                inputRef={setSuggestionRef(field.ref)}
                autoFocus={false}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
                suggestions={suggestedValues as TypedSuggestion<ToolOptionWithVendor>[]}
                allowCreate={true}
                value={field.value?.[0]}
                onCreate={(tool) => {
                  // If we could not create a tool successfully, reset the field
                  if (!tool || !tool.vendor) {
                    resetField([...parentObjectPath, formField.field_name].join("."))
                    return
                  }

                  onChangeWithFieldSource(field.onChange)([tool], undefined)
                  // If there is no vendorOption property in the parent schema, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isVendorOptionType(unwrapNullableSchema(parentObjectBaseSchema.properties?.vendor))
                  ) {
                    return
                  }

                  // Otherwise set the value and field source metadata for the vendor
                  setValue([...parentObjectPath, "vendor"].join("."), tool.vendor, { shouldDirty: true })
                  if (whoami) {
                    setValue(
                      [...parentObjectPath, "fields_metadata", "vendor"].join("."),
                      getDefaultUserFieldSource(intl, whoami),
                      {
                        shouldDirty: true,
                      }
                    )
                  }
                }}
                onChange={(tool, fieldSource) => {
                  onChangeWithFieldSource(field.onChange)(tool ? [tool] : [], fieldSource)
                  // If there is no vendorOption property in the parent schema or if the tool update is undefined, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isVendorOptionType(unwrapNullableSchema(parentObjectBaseSchema.properties?.vendor)) ||
                    !tool?.vendor
                  ) {
                    return
                  }

                  const vendor = tool.vendor
                  if (vendor && whoami) {
                    // Otherwise set the value and field source metadata for the vendor
                    setValue([...parentObjectPath, "vendor"].join("."), vendor, {
                      shouldDirty: true,
                    })
                    const newFieldSource = fieldSource || getDefaultUserFieldSource(intl, whoami)
                    if (newFieldSource) {
                      setValue([...parentObjectPath, "fields_metadata", "vendor"].join("."), newFieldSource, {
                        shouldDirty: true,
                      })
                    }
                  }
                }}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isToolReferenceType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <ToolPicker
                value={field.value}
                onChange={(tool) => onChangeWithFieldSource(field.onChange)(tool, undefined)}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isVendorOptionType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{
          validate: (value) =>
            isObject(value) && hasOwnProperty(value, "vendor_listing_id") && value.vendor_listing_id === "new"
              ? undefined
              : validate(value, undefined),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <VendorOptionPickerCreatableWithModal
                allowCreate={true}
                allowNull={fieldCanBeNull}
                autoFocus={false}
                isLite={whoami?.organization.is_lite}
                value={field.value}
                inputRef={setSuggestionRef(field.ref)}
                isReadOnly={isReadOnly}
                onCreate={(vendor) => {
                  // If we could not create a vendor successfully, reset the field
                  if (!vendor) {
                    resetField([...parentObjectPath, formField.field_name].join("."))
                    return
                  }

                  onChangeWithFieldSource(field.onChange)(vendor, undefined)
                  // If there is no toolOptions property in the parent schema, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isToolOptionsType(parentObjectBaseSchema.properties?.tools)
                  ) {
                    return
                  }

                  // Otherwise set the value and field source metadata for the tools
                  const firstTool = vendor.tools?.[0]
                  if (firstTool && whoami) {
                    setValue([...parentObjectPath, "tools"].join("."), [firstTool], { shouldDirty: true })
                    setValue(
                      [...parentObjectPath, "fields_metadata", "tools"].join("."),
                      getDefaultUserFieldSource(intl, whoami),
                      {
                        shouldDirty: true,
                      }
                    )
                  }
                }}
                onChange={(vendor, fieldSource) => {
                  onChangeWithFieldSource(field.onChange)(vendor, fieldSource)
                  // If there is no toolOptions property in the parent schema, we are done
                  if (
                    !isObject(parentObjectBaseSchema) ||
                    !isToolOptionsType(parentObjectBaseSchema.properties?.tools)
                  ) {
                    return
                  }
                  const firstTool = vendor?.tools?.[0]
                  // Otherwise set the value and field source metadata for the tools field
                  if (firstTool && whoami) {
                    // If a tool exists associated with the vendor, set the value of tool
                    setValue([...parentObjectPath, "tools"].join("."), [firstTool], {
                      shouldDirty: true,
                    })
                    // If tool has changed, set the field source metadata for the tools
                    const newFieldSource = fieldSource || getDefaultUserFieldSource(intl, whoami)
                    if (newFieldSource) {
                      setValue([...parentObjectPath, "fields_metadata", "tools"].join("."), newFieldSource, {
                        shouldDirty: true,
                      })
                    }
                  }
                }}
                suggestions={suggestedValues as TypedSuggestion<VendorOptionWithTools>[]}
                fieldMetadata={fieldMetadata}
                fieldApproval={fieldApprovalMetadata}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }
  if (isPersonType(schema, value)) {
    return (
      <Controller
        {...controllerProps}
        rules={{ validate }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired={isRequired}>
            <InputContainer {...wrapperProps} isDirty={fieldState.isDirty} errorMessage={fieldState.error?.message}>
              <BasicOrganizationEntityPicker
                value={field.value}
                includedEntities={["person"]}
                onChange={(person) => onChangeWithFieldSource(field.onChange)(person, undefined)}
                isReadOnly={isReadOnly}
                menuPortalTarget={document.body}
              />
            </InputContainer>
          </FormControl>
        )}
      />
    )
  }

  log.error("Encountered unknown schema type", { schema })
  return null
}, DynamicFormPropsAreEqual)

export const DynamicFormRefWrapper = forwardRef<
  HTMLDivElement,
  DynamicFormFieldProps & GetLogoForOrganizationProps & GetOrganizationActorProps
>(function DynamicFormRefWrapper(props, ref) {
  return (
    <Box ref={ref}>
      <DynamicFormField {...props} />
    </Box>
  )
})
