import { Icon, Tooltip } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { EyeOffIcon } from "./icons.js"

export default function NoPermissionIcon() {
  const intl = useIntl()
  return (
    <Tooltip
      shouldWrapChildren
      label={intl.formatMessage({
        id: "data.table.no_permission",
        defaultMessage: "You do not have permission to view this",
        description: "Tooltip text for a cell that the user does not have permission to view",
      })}
    >
      <Icon as={EyeOffIcon} />
    </Tooltip>
  )
}
