import { type WorkflowDef, type WorkflowKind } from "@brm/schema-types/types.js"
import { requestDefinitionRouteById } from "@brm/util/routes.js"
import {
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { useEffect, useMemo, useState, type FunctionComponent } from "react"
import type { Control } from "react-hook-form"
import { useFieldArray, useForm } from "react-hook-form"
import { FormattedMessage, FormattedTime, useIntl } from "react-intl"
import { useBlocker, useNavigate } from "react-router-dom"
import { assert } from "typed-assert"
import {
  usePostPersonV1ListQuery,
  usePostWorkflowV1DefinitionsMutation,
  usePutWorkflowV1DefinitionsByIdMutation,
} from "../../../app/services/generated-api.js"
import { FormattedDate } from "../../../components/FormattedDate.js"
import { Link } from "../../../components/Link.js"
import { BackIcon } from "../../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { displayWorkflowKind } from "../util.js"
import DefinitionStepCards from "./DefinitionStepCards.js"
import StepDefinition from "./StepDefinition.js"
import type { ConfigureWorkflowFormState, ConfigureWorkflowStepsFormState } from "./utils.js"
import { defaultActiveSteps, fillStepTempIdsAndRequiredArrays, getDefaultStepState } from "./utils.js"

const WorkflowDefinitionForm: FunctionComponent<{
  workflowKind: WorkflowKind
  workflowDef?: WorkflowDef
}> = ({ workflowDef, workflowKind }) => {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()
  const isEdit = !!workflowDef
  const [createWorkflowDef, { isLoading: createIsLoading }] = usePostWorkflowV1DefinitionsMutation()
  const [updateWorkflowDef, { isLoading: updateIsLoading }] = usePutWorkflowV1DefinitionsByIdMutation()

  // Attempt to fetch at least one person in the org that has a manager to determine if this org is
  // eligible to assign manager to approver on a step
  const { data: personsWithManagers } = usePostPersonV1ListQuery({
    listQueryStringParams: {
      limit: 1,
      filter: [[{ column: "manager", fields: { comparator: "exists", value: true } }]],
    },
  })

  const hasManagersInSystem = personsWithManagers && personsWithManagers.persons.length > 0
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0)

  const openStepForm = (stepId: string) => {
    const stepIndex = controlledSteps.findIndex((step) => step.id === stepId)
    if (stepIndex < 0) {
      throw new Error(`Step with id ${stepId} not found`)
    }
    setSelectedStepIndex(stepIndex)
  }

  const defaultStepState = useMemo(() => getDefaultStepState(intl, workflowKind), [intl, workflowKind])

  const form = useForm<ConfigureWorkflowFormState>({
    defaultValues: {
      display_name: intl.formatMessage(
        {
          id: "request.config.default.display_name",
          description: "Default display name for request in configuration",
          defaultMessage: "BRM Standard {workflowKind} Request",
        },
        {
          workflowKind: displayWorkflowKind(workflowKind),
        }
      ),
      workflow_kind: workflowKind,
      description: (() => {
        switch (workflowKind) {
          case "software_purchase":
            return intl.formatMessage({
              id: "request.config.default.description.purchase",
              description: "Purchase workflow kind BRM standard description",
              defaultMessage:
                "This request is used for any new software purchase. BRM has researched and compiled the most common requirements to help teams securely, and quickly work through a new purchase request.",
            })
          case "vendor_purchase":
            return intl.formatMessage({
              id: "request.config.default.description.purchase",
              description: "Purchase workflow kind BRM standard description",
              defaultMessage:
                "This request is used to purchase non-software vendor spend. BRM has researched and compiled the most common requirements to help teams securely, and quickly work through a new purchase request.",
            })
          case "software_renewal":
            return intl.formatMessage({
              id: "request.config.default.description.renewal",
              description: "Renewal workflow kind BRM standard description",
              defaultMessage:
                "This request is used for any software renewal. BRM worked to determine what the correct renewal process should be to make sure teams are ensuring if their teams are using the right tools, and getting the right price.",
            })
          case "vendor_renewal":
            return intl.formatMessage({
              id: "request.config.default.description.renewal",
              description: "Renewal workflow kind BRM standard description",
              defaultMessage:
                "This request is used to renew non-software vendor spend. BRM worked to determine what the correct renewal process should be to make sure teams are ensuring if their teams are using the right tools, and getting the right price.",
            })
          case "gather_data":
            return intl.formatMessage({
              id: "request.config.default.description.gather_data",
              description: "Data gathering workflow kind BRM standard description",
              defaultMessage:
                "This request is used to collect and organize information from teams. BRM helps streamline the data gathering process to ensure all necessary information is captured efficiently.",
            })
          default:
            return ""
        }
      })(),
      steps: (workflowDef?.steps ??
        // The legal step is not enabled by default because the agreement UX in Requests is not great at time of writing.
        // It can be added manually though, e.g. in case a customer wants to use it for custom fields.
        structuredClone(defaultStepState).filter(
          (step) => defaultActiveSteps.has(step.type)
          // React hook form deep partial typing is broken, it cannot handle unknown values and converts to {} | undefined.
          // type safety is preserved by the satisfies operator
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        )) satisfies ConfigureWorkflowFormState["steps"] as any,
    } satisfies ConfigureWorkflowFormState,
  })

  // React-router-dom hook that can block internal app navigation. We use to show a modal that they can confirm to leave the page and discard their edits or stay
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      form.formState.isDirty &&
      currentLocation.pathname !== nextLocation.pathname &&
      // Allow navigation from the create workflow definition page to the edit workflow definition page
      !(
        currentLocation.pathname.includes("/requests/definitions/create") &&
        nextLocation.pathname.includes("/requests/definitions/")
      )
  )

  // Browser-native listener that will block navigation from the app to external pages when there is unsaved form state
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault()
        // Returning true shows the browser prompt to warn before navigating away, closing the page, or refreshing
        return true
      }
      return false
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [form.formState.isDirty])

  const { fields, remove, replace } = useFieldArray({
    control: form.control,
    name: "steps",
  })
  const watchSteps = form.watch("steps")
  const controlledSteps = fields.map((field, index) => {
    return {
      ...field,
      ...watchSteps[index],
    }
  })

  const selectedStep = controlledSteps[selectedStepIndex]

  /**
   * Deletes a step and unselects it if it is currently selected
   */
  const deleteStepById = (id: string) => {
    const deletedStepIndex = controlledSteps.findIndex((step) => step.id === id)
    if (selectedStepIndex === deletedStepIndex) {
      setSelectedStepIndex(0)
    }
    assert(deletedStepIndex >= 0, "Attempted to delete a step by id that does not exist")
    remove(deletedStepIndex)
  }

  const addStep = (step: ConfigureWorkflowFormState["steps"][number]) => {
    replace([...controlledSteps, step])
  }

  const onSubmit = form.handleSubmit(
    async (values: ConfigureWorkflowFormState) => {
      const workflowDefInput = fillStepTempIdsAndRequiredArrays(values)

      try {
        // Edit or create depending on the presence of workflowDef, the payload is the same
        if (isEdit) {
          await updateWorkflowDef({ id: workflowDef.id, workflowDefUpdate: workflowDefInput }).unwrap()
          // Reset form with new values to clear dirty state
          form.reset(values)
        } else {
          const createdWorkflowDef = await createWorkflowDef({ workflowDefInput }).unwrap()
          // If creation, navigate you to the newly created workflow definition which is the update flow
          navigate(requestDefinitionRouteById(createdWorkflowDef.id), { replace: true })
        }

        toast({
          description: intl.formatMessage(
            {
              id: "requests.config.save.success.toast",
              description: "Save config success toast title",
              defaultMessage: "{workflowKind} request configuration saved!",
            },
            { workflowKind: displayWorkflowKind(values.workflow_kind) }
          ),
          status: "success",
        })
      } catch (err: unknown) {
        const errorDescription = getAPIErrorMessage(err)
        toast({
          title: intl.formatMessage(
            {
              id: "requests.config.save.error.toast",
              description: "Save config error toast title",
              defaultMessage: "There was an issue saving this {workflowKind} request configuration",
            },
            { workflowKind: displayWorkflowKind(values.workflow_kind) }
          ),
          description: errorDescription,
          status: "error",
        })
      }
    },
    () =>
      toast({
        description: intl.formatMessage({
          id: "requests.config.save.validation.toast",
          description: "Save config validation error toast title",
          defaultMessage: "We have highlighted some issues with your configuration. Please fix them and submit again.",
        }),
        status: "error",
      })
  )

  const stepTypesPresentSet = new Set(controlledSteps.map((step) => step.type))
  // Find steps that are missing from the form state, these are the standard steps that are not present in the existing config and we will allow the user to add back in
  const missingStandardSteps = defaultStepState.filter((step) => !stepTypesPresentSet.has(step.type))

  return (
    <>
      <Flex flexDirection="column" flex={1} minHeight={0}>
        <HStack justifyContent="space-between" gap={4} padding={4}>
          <Link to="/settings/requests" display="inline-flex">
            <Icon as={BackIcon} boxSize={6} />
          </Link>
          <Heading size="sm">{form.watch("display_name")}</Heading>
          <Spacer />
          <HStack gap={4}>
            {workflowDef?.updated_at && (
              <Text>
                <FormattedMessage
                  id="request.config.updated_at"
                  description="When the request configuration was last updated"
                  defaultMessage="Last updated {updatedAtDate} at {updatedAtTime}"
                  values={{
                    updatedAtDate: <FormattedDate value={workflowDef.updated_at} />,
                    updatedAtTime: <FormattedTime value={workflowDef.updated_at} />,
                  }}
                />
              </Text>
            )}
            <Button
              colorScheme="brand"
              onClick={onSubmit}
              isDisabled={isEdit && !form.formState.isDirty}
              isLoading={createIsLoading || updateIsLoading}
            >
              <FormattedMessage
                id="request.config.save"
                description="Button text to submit and save request configuration"
                defaultMessage="Save"
              />
            </Button>
          </HStack>
        </HStack>
        <Divider />
        <Flex flex={1} minHeight={0}>
          <Stack flex={1} maxW={275}>
            <DefinitionStepCards
              control={form.control}
              steps={controlledSteps}
              missingStandardSteps={missingStandardSteps}
              openStepForm={openStepForm}
              deleteStepById={deleteStepById}
              addStep={addStep}
              selectedStepId={selectedStep?.id}
              showSelected
            />
          </Stack>
          {selectedStep ? (
            <StepDefinition
              // Force rerender on switching step to prevent stale conditional prompt form state
              key={selectedStep.id}
              step={selectedStep}
              steps={watchSteps}
              stepIndex={selectedStepIndex}
              control={form.control as unknown as Control<ConfigureWorkflowStepsFormState>}
              approverVariableTypes={hasManagersInSystem ? ["manager"] : []}
            />
          ) : (
            <Spacer />
          )}
        </Flex>
      </Flex>
      {blocker.state === "blocked" && (
        <Modal isOpen={blocker.state === "blocked"} onClose={blocker.reset} returnFocusOnClose={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Heading size="sm">
                <FormattedMessage
                  id="workflow.unsavedChanges.title"
                  description="Title for unsaved changes modal"
                  defaultMessage="You have unsaved changes"
                />
              </Heading>
            </ModalHeader>
            <ModalBody>
              <Text>
                <FormattedMessage
                  id="workflow.unsavedChanges.message"
                  description="Message for unsaved changes modal"
                  defaultMessage="You have made changes to your request configuration. If you navigate away, you will lose these changes."
                />
              </Text>
            </ModalBody>
            <ModalFooter gap={2}>
              <Button onClick={blocker.reset}>
                <FormattedMessage
                  id="workflow.unsavedChanges.cancel"
                  description="Button text to cancel navigation and keep editing"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button colorScheme="error" onClick={blocker.proceed}>
                <FormattedMessage
                  id="workflow.unsavedChanges.leave"
                  description="Button text to leave without saving changes"
                  defaultMessage="Leave without saving"
                />
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default WorkflowDefinitionForm
