import { Text, VStack } from "@chakra-ui/react"
import type { Temporal } from "@js-temporal/polyfill"
import { useIntl } from "react-intl"

interface CalendarDayProps {
  date: Temporal.PlainDate
}

export function CalendarDay({ date }: CalendarDayProps) {
  const intl = useIntl()
  const dayNumber = date.day
  const monthName = date.toLocaleString(intl.locale, { month: "short" })

  return (
    <VStack spacing={0} justifyContent="center" bg="gray.100" borderRadius="lg" minW="3em" minH="3em">
      <Text fontSize="lg" fontWeight="bold" color="gray.600">
        {dayNumber}
      </Text>
      <Text fontSize="sm" color="gray.600">
        {monthName}
      </Text>
    </VStack>
  )
}
