import { Box, Button } from "@chakra-ui/react"

interface OnboardingWelcomeCardButtonProps {
  cta: string
  icon?: React.ReactElement | null
  onClick: () => void
}

export const OnboardingWelcomeCardButton = ({ cta, icon, onClick }: OnboardingWelcomeCardButtonProps) => {
  return (
    <Box
      padding="3px"
      borderRadius="full"
      _hover={{
        cursor: "pointer",
        "& button": {
          border: "2px solid var(--chakra-colors-brand-600)",
          boxShadow: "0 3px 0 0 var(--chakra-colors-brand-600),0 0 2em 0 var(--chakra-colors-brand-lime)",
          transform: "translateY(-3px)",
          bgGradient: "linear(to-r, #F9FF84, brand.lime, brand.500, #F9FF84)",
          backgroundSize: "200% 100%",
          animation: "gradient 4s linear infinite",
          color: "black",
        },
      }}
    >
      <Button
        size="lg"
        bgColor="black"
        color="white"
        borderRadius="full"
        onClick={onClick}
        leftIcon={icon || undefined}
        width="100%"
        sx={{
          "@keyframes gradient": {
            "100%": {
              backgroundPosition: "0% 50%",
            },
            "0%": {
              backgroundPosition: "-200% 50%",
            },
          },
        }}
        _active={{
          boxShadow: "0 0 2em 0 var(--chakra-colors-brand-lime)",
          transform: "translateY(0)",
        }}
      >
        {cta}
      </Button>
    </Box>
  )
}
