import { capitalize } from "@brm/util/string.js"
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  type UseModalProps,
} from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import BamLogo from "../icons/bam-logo.js"

export const OnboardingFreeTrialBegunModal: FunctionComponent<
  UseModalProps & { organizationName: string; trialExpiresAt: string | null }
> = (props) => {
  return (
    <Modal {...props} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader fontSize="1.5rem" fontWeight="bold">
          <Box flex="1" maxWidth="50%" fontWeight="bold">
            {props.trialExpiresAt ? (
              <FormattedMessage
                defaultMessage="Welcome to {organizationName}’s 7-Day FREE trial"
                description="Trial begun modal"
                id="TrialBegunModal.title"
                values={{
                  organizationName: capitalize(props.organizationName),
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Welcome to BRM"
                description="Trial begun modal"
                id="TrialBegunModal.title"
              />
            )}
          </Box>
          <BamLogo
            style={{
              position: "absolute",
              top: "-85px",
              right: "-8px",
              width: "15%",
              height: "auto",
              transform: "scaleX(-1)",
            }}
          />
        </ModalHeader>
        <ModalBody display="grid" gridTemplateColumns="1fr 1fr" alignItems="stretch" fontSize="1.15rem" gap="8" pb="4">
          <Text gridArea="1 / 1" as="div">
            <Box marginBottom={2}>
              <FormattedMessage
                id="TrialBegunModal.descriptionPart1"
                defaultMessage="We’re about to show you a Sample Agreement so you can see BRM in action."
                description="Trial begun modal part 1"
              />
            </Box>
            <Box>
              <FormattedMessage
                id="TrialBegunModal.descriptionPart2"
                defaultMessage="In BRM, an <strong>Agreement</strong> is more than just a single document - it’s a collection of contracts, terms of service, and other key files."
                description="Trial begun modal part 2"
              />
            </Box>
          </Text>
          <Text gridArea="1 / 2" as="div">
            <Box marginBottom={2}>
              <FormattedMessage
                id="TrialBegunModal.descriptionPart3"
                defaultMessage="Vendors often spread information across multiple documents, making important details easy to miss."
                description="Trial begun modal part 3"
              />
            </Box>

            <Box color="brand.600" fontWeight="bold">
              <FormattedMessage
                id="TrialBegunModal.descriptionPart4"
                defaultMessage="<strong>We’re here to keep you ahead of the game, ensuring nothing slips through the cracks.</strong>"
                description="Trial begun modal part 4"
              />
            </Box>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brand" onClick={props.onClose} size="lg" borderRadius="full" width="50%">
            <FormattedMessage
              id="TrialBegunModal.continue"
              defaultMessage="Continue"
              description="Trial begun modal continue button"
            />
          </Button>
          <BamLogo
            style={{
              position: "absolute",
              bottom: "-50px",
              left: "5px",
              width: "12%",
              height: "auto",
              rotate: "-70deg",
              transform: "scaleY(-1)",
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
