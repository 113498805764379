import { posthog } from "posthog-js"

export type PosthogEventName =
  | "$pageview"
  | "trial_expired_modal_contact_us_button_clicked"
  | "onboarding_clicked_trial_begun_modal"
  | "onboarding_force_navigate"
  | "trial_expired_modal_closed"
  | "trial_expired_modal_viewed"
  | "ai_context_menu_click"
  | "negotiation_try_again"
  | "negotiation_new_conversation"
  | "negotiation_email_sent"
  | "onboarding_clicked_callout_next"
  | "onboarding_clicked_callout_back"
  | "ai_mention_comment"
  | "open_signup_create_organization"
  | "open_signup_start_google_sso"
  | "negotiation_agent_info_popover_open"
  | "negotiation_notebook_open"
  | "negotiation_writing_persona_open"
  | "legal_agreement_verify_field"
  | "legal_agreement_verify_all"
  | "legal_agreement_verify_field_lite"
  | "legal_agreement_verify_all_lite"
  | "onboarding_reenter_flow"
  | "onboarding_start_flow"
  | "onboarding_complete_flow"
  | "onboarding_go_next_step"
  | "onboarding_clicked_begin_setup"
  | "onboarding_connect_gmail_button_clicked"
  | "onboarding_tell_me_more_button_clicked"
  | "onboarding_connect_slack_button_clicked"
  | "onboarding_clicked_skip_connect_slack"
  | "global_search_input"
  | "chat_with_support_button_clicked"
  | "help_center_button_clicked"
  | "gmail_integration_disabled"
  | "tool_owned_renewal_reminder_disabled"
  | "vendor_owned_renewal_reminder_disabled"
  | "calendar_subscription_accessed"
  | "gmail_integration_disconnected"
  | "open_signup_viewed"
export function posthogCaptureEvent(eventName: PosthogEventName, properties?: Record<string, string>) {
  // eslint-disable-next-line no-restricted-properties
  posthog.capture(eventName, properties)
}
