import { Box, Heading, ListItem, OrderedList, Progress, Text, UnorderedList, chakra } from "@chakra-ui/react"
import { type RenderElementProps } from "slate-react"
import { AgentResponseDisplay } from "../../features/betsy/AgentResponse.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { log } from "../../util/logger.js"
import { AgentTag } from "./AgentTag.js"
import { Document } from "./Document.js"
import { FieldTag } from "./FieldTag.js"
import { Image } from "./Image.js"
import InlineLink from "./InlineLink.js"
import { Mention } from "./Mention.js"
import { NegotiationContextTag } from "./NegotiationContextTag.js"
import { StandardObjectTag } from "./StandardObjectTag.js"
import type { ExtendedElementProps } from "./types.js"

export const Element = (
  props: RenderElementProps &
    ExtendedElementProps &
    GetLogoForOrganizationProps & {
      getDocumentUrl?: (documentId: string) => Promise<string>
      getDocumentTagHref?: (documentId: string) => string
    }
) => {
  const {
    isReadOnly: isReadOnly,
    getLogoToShowByOrganizationId,
    getDocumentUrl,
    getDocumentTagHref,
    ...baseElementProps
  } = props
  const { attributes, children, element } = baseElementProps
  switch (element.type) {
    case "link": {
      return <InlineLink url={element.url} isReadOnly={isReadOnly} {...baseElementProps} />
    }
    case "image":
      return <Image {...baseElementProps} element={element} isReadOnly={isReadOnly} />
    case "document":
      return (
        <Document
          {...baseElementProps}
          element={element}
          isReadOnly={isReadOnly}
          getDocumentUrl={getDocumentUrl}
          getDocumentTagHref={getDocumentTagHref}
        />
      )
    case "mention":
      return (
        <Mention
          {...baseElementProps}
          element={element}
          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        />
      )
    case "block-quote":
      return (
        <chakra.blockquote
          ml={1}
          pl={2}
          opacity={0.85}
          borderLeftWidth="2px"
          borderLeftColor="gray.300"
          {...attributes}
        >
          {children}
        </chakra.blockquote>
      )
    case "bulleted-list":
      return (
        <UnorderedList marginInlineStart={6} {...attributes}>
          {children}
        </UnorderedList>
      )
    case "numbered-list":
      return (
        <OrderedList marginInlineStart={6} {...attributes}>
          {children}
        </OrderedList>
      )
    case "heading-one":
    case "heading-two":
      return (
        <Heading fontSize="lg" size="xs" {...attributes}>
          {children}
        </Heading>
      )
    case "list-item":
      return <ListItem {...attributes}>{children}</ListItem>
    case "field-tag":
      return <FieldTag {...baseElementProps} element={element} />
    case "agent-tag":
      return <AgentTag {...baseElementProps} element={element} />
    case "standard-object":
      return <StandardObjectTag {...baseElementProps} element={element} />
    case "agent-response":
      // there is a short circuit in the TimelineCommentBody that prevents this from being rendered.
      // ideally, the agent response gets rendered to richtext which would allow us to copy&paste rich text.
      // but for now, this is a stopgap to allow us to copy&paste the text.
      // something like: https://www.npmjs.com/package/remark-slate-transformer could work.
      log.error(
        "AgentResponseDisplay should not get used in the RichTextEditor",
        new Error("AgentResponseDisplay should not get used in the RichTextEditor"),
        { element }
      )
      return <AgentResponseDisplay {...baseElementProps} element={element} />
    case "progress":
      // this is effectively agent-progress
      // there is a short circuit in the TimelineCommentBody that prevents this from being rendered too
      log.error(
        "AgentProgress should not get used in the RichTextEditor",
        new Error("AgentProgress should not get used in the RichTextEditor"),
        { element }
      )
      return (
        <Box height={4} display="grid" alignItems="center">
          <Progress isIndeterminate size="xs" colorScheme="blue" />
          {children}
        </Box>
      )
    case "negotiation-context":
      return <NegotiationContextTag {...baseElementProps} element={element} />
    default:
      return <Text {...attributes}>{children}</Text>
  }
}
