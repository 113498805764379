import { Box, Button, Card, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { AlertIcon, BackIcon, LockIcon, SettingsIcon, SupportChatIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"

export function PermissionDeniedCard() {
  return (
    <Card p={8}>
      <Stack spacing={6} align="center">
        {/* Lock Icon */}
        <Icon as={LockIcon} color="red.500" boxSize={20} bg="red.50" p={5} borderRadius="full" />
        {/* Main Heading */}
        <Heading size="lg">
          <FormattedMessage
            id="legal.permissionDenied.title"
            defaultMessage="Access Restricted"
            description="Title shown when user doesn’t have permission to view content"
          />
        </Heading>
        {/* Permission Required Box */}
        <Box p={4} borderRadius="md" borderWidth={1} borderColor="red.200" bg="red.50">
          <Flex gap={3}>
            <Icon as={AlertIcon} color="red.500" mt={1} />
            <Stack gap={1}>
              <Text fontWeight="medium" color="red.500">
                <FormattedMessage
                  id="legal.permissionDenied.requiredHeading"
                  defaultMessage="Permission Required"
                  description="Heading for the permission required section"
                />
              </Text>
              <Text color="red.500">
                <FormattedMessage
                  id="legal.permissionDenied.requiredMessage"
                  defaultMessage="This agreement requires additional access privileges. Please contact your organization administrator to request access."
                  description="Message explaining how to request access permissions"
                />
              </Text>
            </Stack>
          </Flex>
        </Box>
        {/* What to do next section */}
        <Stack width="full" p={4} spacing={4} bg="gray.50" borderRadius="md">
          <Flex gap={3} alignItems="center">
            <Icon as={SettingsIcon} mt={1} />
            <Text fontWeight="medium">
              <FormattedMessage
                id="legal.permissionDenied.nextStepsHeading"
                defaultMessage="What to do next:"
                description="Heading for next steps section"
              />
            </Text>
          </Flex>
          <Stack pl={6}>
            <Text color="gray.700">
              <FormattedMessage
                id="legal.permissionDenied.step1"
                defaultMessage="1. Contact an <usersLink>organization administrator</usersLink> to upgrade your permissions"
                description="First step instruction"
                values={{
                  usersLink: (chunks) => (
                    <Link to="/settings/users" color="blue.500">
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Text>
            <Text color="gray.700">
              <FormattedMessage
                id="legal.permissionDenied.step3"
                defaultMessage="2. If you already have the necessary permissions and believe this is an error, contact BRM support"
                description="Third step instruction"
              />
            </Text>
          </Stack>
        </Stack>
        {/* Action Buttons */}
        <Flex gap={4} width="full">
          <Link to="/agreements" flex={1}>
            <Button width="full" colorScheme="brand" leftIcon={<Icon as={BackIcon} />}>
              <FormattedMessage
                id="legal.permissionDenied.returnToAgreements"
                defaultMessage="Return to agreements"
                description="Label for the return to agreements button"
              />
            </Button>
          </Link>
          <Button
            variant="outline"
            flex={1}
            onClick={() => window.Pylon?.("show")}
            leftIcon={<Icon as={SupportChatIcon} />}
          >
            <FormattedMessage
              id="legal.permissionDenied.goBack"
              defaultMessage="Contact support"
              description="Label for the go back button"
            />
          </Button>
        </Flex>
      </Stack>
    </Card>
  )
}
