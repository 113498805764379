import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { formatDate } from "@brm/util/format-date-time.js"
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
  type StyleProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import React, { useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { useLocalStorage } from "usehooks-ts"
import {
  useGetUserV1CalendarKeyQuery,
  useGetUserV1WhoamiQuery,
  usePostLegalV1AgreementsListQuery,
} from "../../app/services/generated-api.js"
import { ChevronLeftIcon, ChevronRightIcon, CopyIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import OnboardingCallout from "../../components/Onboarding/OnboardingCallout.js"
import PageWrapper from "../../components/PageWrapper.js"
import Select from "../../components/Select/Select.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import { getLocalStorageKeyForUser, LocalStorageKeys } from "../../util/local-storage.js"
import { selectCurrentOnboardingStep } from "../onboarding/onboarding-slice.js"
import FullTextSearch from "../search/FullTextSearch.js"
import AgreementCalendarListItem from "./AgreementCalendarListItem.js"
import { AgreementGroupCard } from "./AgreementGroupCard.js"
import AgreementOverflow from "./AgreementOverflow.js"
import type { AgreementGroup, AgreementGroupKey } from "./types.js"

// RenewalCalendar component
const RenewalCalendar: React.FC<{
  currentMonth: Temporal.PlainDate
  agreements: LegalAgreementListItem[]
  dateType: "decision_date" | "end_date"
}> = ({ currentMonth, agreements, dateType }) => {
  const today = Temporal.Now.plainDateISO()

  const generateCalendarDays = () => {
    const daysInMonth = currentMonth.daysInMonth
    const firstDayOfMonth = currentMonth.with({ day: 1 }).dayOfWeek % 7 // Sunday is 0, Saturday is 6

    const calendarDays = []

    const dayItemStyle: StyleProps = {
      borderWidth: "0.5px",
      borderColor: "gray.200",
      p: 2,
      display: "flex",
      minWidth: 0,
      flexDirection: "column" as const,
    }

    // Add days from previous month
    for (let i = 0; i < firstDayOfMonth; i++) {
      const day = currentMonth.subtract({ months: 1 }).daysInMonth - firstDayOfMonth + i + 1
      calendarDays.push(
        <GridItem key={`prev-${day}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{day}</Text>
        </GridItem>
      )
    }

    // Add days of current month
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = currentMonth.with({ day })
      const dayAgreements =
        agreements.filter(
          (agreement) => agreement[dateType] && Temporal.PlainDate.from(agreement[dateType]).equals(currentDate)
        ) || []
      const isToday = currentDate.equals(today)

      const agreementsToShow = dayAgreements.slice(0, 2)
      const remainingAgreements = dayAgreements.slice(2)

      calendarDays.push(
        <GridItem key={day} {...dayItemStyle}>
          <Flex justifyContent="center" alignItems="center" width="24px" height="24px" mb={1}>
            <Text
              fontWeight="bold"
              color={isToday ? "white" : undefined}
              bg={isToday ? "brand.500" : undefined}
              borderRadius="full"
              width="24px"
              height="24px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {day}
            </Text>
          </Flex>
          <VStack align="stretch" spacing={1} overflow="auto" flex={1}>
            {agreementsToShow.map((agreement) => (
              <AgreementCalendarListItem key={agreement.id} agreement={agreement} />
            ))}
            {remainingAgreements.length > 0 && (
              <AgreementOverflow allAgreements={dayAgreements} overflowCount={remainingAgreements.length} />
            )}
          </VStack>
        </GridItem>
      )
    }

    // Add days from next month
    const remainingDays = (7 - ((firstDayOfMonth + daysInMonth) % 7)) % 7
    for (let i = 1; i <= remainingDays; i++) {
      calendarDays.push(
        <GridItem key={`next-${i}`} {...dayItemStyle} opacity={0.5}>
          <Text fontWeight="bold">{i}</Text>
        </GridItem>
      )
    }

    return calendarDays
  }

  const weekdays = [
    <FormattedMessage
      key="sunday"
      id="calendar.sunday"
      defaultMessage="Sun"
      description="Abbreviated name for Sunday in calendar header"
    />,
    <FormattedMessage
      key="monday"
      id="calendar.monday"
      defaultMessage="Mon"
      description="Abbreviated name for Monday in calendar header"
    />,
    <FormattedMessage
      key="tuesday"
      id="calendar.tuesday"
      defaultMessage="Tue"
      description="Abbreviated name for Tuesday in calendar header"
    />,
    <FormattedMessage
      key="wednesday"
      id="calendar.wednesday"
      defaultMessage="Wed"
      description="Abbreviated name for Wednesday in calendar header"
    />,
    <FormattedMessage
      key="thursday"
      id="calendar.thursday"
      defaultMessage="Thu"
      description="Abbreviated name for Thursday in calendar header"
    />,
    <FormattedMessage
      key="friday"
      id="calendar.friday"
      defaultMessage="Fri"
      description="Abbreviated name for Friday in calendar header"
    />,
    <FormattedMessage
      key="saturday"
      id="calendar.saturday"
      defaultMessage="Sat"
      description="Abbreviated name for Saturday in calendar header"
    />,
  ]

  return (
    <Grid
      templateColumns="repeat(7, 1fr)"
      templateRows={`auto repeat(${Math.ceil(((currentMonth.with({ day: 1 }).dayOfWeek % 7) + currentMonth.daysInMonth) / 7)}, 1fr)`}
      height="100%"
      flex={1}
      borderWidth="0.5px"
      borderColor="gray.200"
    >
      {weekdays.map((day, index) => (
        <GridItem key={index} textAlign="center" fontWeight="bold" p={2} borderWidth="0.5px" borderColor="gray.200">
          {day}
        </GridItem>
      ))}
      {generateCalendarDays()}
    </Grid>
  )
}

const RenewalAgenda: React.FC<{
  agreements: LegalAgreementListItem[]
  todayRef: React.RefObject<HTMLDivElement>
  dateType: "decision_date" | "end_date"
}> = ({ agreements, todayRef, dateType }) => {
  const intl = useIntl()
  const today = Temporal.Now.plainDateISO()

  const agreementGroups = useMemo(() => {
    const groups = new Map<AgreementGroupKey, AgreementGroup>([
      [
        "past30",
        {
          label: intl.formatMessage({
            id: "renewalCalendar.past30Days",
            defaultMessage: "Past 30 Days",
            description: "Label for agreements due in the past 30 days",
          }),
          agreements: [],
          stats: { agreementCount: 0, tcvTotalUsd: 0 },
        },
      ],
      [
        "30",
        {
          label: intl.formatMessage({
            id: "renewalCalendar.next30Days",
            defaultMessage: "Next 30 Days",
            description: "Label for agreements due in the next 30 days",
          }),
          agreements: [],
          stats: { agreementCount: 0, tcvTotalUsd: 0 },
        },
      ],
      [
        "60",
        {
          label: intl.formatMessage({
            id: "renewalCalendar.next60Days",
            defaultMessage: "Next 60 Days",
            description: "Label for agreements due in the next 60 days",
          }),
          agreements: [],
          stats: { agreementCount: 0, tcvTotalUsd: 0 },
        },
      ],
      [
        "90",
        {
          label: intl.formatMessage({
            id: "renewalCalendar.next90Days",
            defaultMessage: "Next 90 Days",
            description: "Label for agreements due in the next 90 days",
          }),
          agreements: [],
          stats: { agreementCount: 0, tcvTotalUsd: 0 },
        },
      ],
      [
        "other",
        {
          label: intl.formatMessage({
            id: "renewalCalendar.other",
            defaultMessage: "And beyond...",
            description: "Label for agreements due beyond 90 days",
          }),
          agreements: [],
          stats: { agreementCount: 0, tcvTotalUsd: 0 },
        },
      ],
    ])

    agreements.forEach((agreement) => {
      const agreementDate = agreement[dateType] ? Temporal.PlainDate.from(agreement[dateType]) : null
      if (!agreementDate) return

      const daysUntil = today.until(agreementDate).days
      let groupKey: AgreementGroupKey = "other"
      if (daysUntil >= -30 && daysUntil < 0) groupKey = "past30"
      else if (daysUntil >= 0 && daysUntil <= 30) groupKey = "30"
      else if (daysUntil > 30 && daysUntil <= 60) groupKey = "60"
      else if (daysUntil > 60 && daysUntil <= 90) groupKey = "90"

      const group = groups.get(groupKey)!
      group.agreements.push(agreement)
      group.stats.agreementCount += 1
      if (agreement.usd_total_contract_value) {
        group.stats.tcvTotalUsd += Number(agreement.usd_total_contract_value)
      }
    })

    // Sort agreements within each group by date
    groups.forEach((group) => {
      group.agreements.sort((a, b) => {
        const dateA = a[dateType] ? Temporal.PlainDate.from(a[dateType]) : null
        const dateB = b[dateType] ? Temporal.PlainDate.from(b[dateType]) : null
        if (!dateA || !dateB) return 0
        return Temporal.PlainDate.compare(dateA, dateB)
      })
    })

    return groups
  }, [agreements, intl, today, dateType])

  return (
    <VStack align="stretch" gap={6} ref={todayRef}>
      {Array.from(agreementGroups.entries(), ([key, group]) => (
        <AgreementGroupCard key={key} group={group} dateType={dateType} />
      ))}
    </VStack>
  )
}

const RenewalCalendarPage: React.FC = () => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [currentMonth, setCurrentMonth] = useState(Temporal.Now.plainDateISO())
  const [dateType, setDateType] = useLocalStorage<"decision_date" | "end_date">(
    getLocalStorageKeyForUser(LocalStorageKeys.RENEWAL_CALENDAR_DATE_TYPE, whoami?.id || ""),
    "decision_date"
  )
  const intl = useIntl()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const calendarKeyQuery = useGetUserV1CalendarKeyQuery()
  const toast = useToast()
  const [view, setView] = useLocalStorage<"calendar" | "agenda">(
    getLocalStorageKeyForUser(LocalStorageKeys.RENEWAL_CALENDAR_VIEW_MODE, whoami?.id || ""),
    "agenda"
  )
  const todayRef = useRef<HTMLDivElement>(null)

  const inOnboardingFlow = useSelector(selectCurrentOnboardingStep) === "overview.view_renewal_agenda"

  const calendarUrl = calendarKeyQuery.data
    ? `${import.meta.env.VITE_API_BASE_URL}/calendar/v1/renewals/${calendarKeyQuery.data?.calendar_key}`
    : ""

  const { onCopy } = useClipboard(calendarUrl)

  const getMonthDates = (date: Temporal.PlainDate) => {
    const startDate = date.with({ day: 1 }).toString()
    const endDate = date.with({ day: date.daysInMonth }).add({ days: 1 }).toString()
    return { startDate, endDate }
  }

  const { startDate, endDate } = getMonthDates(currentMonth)
  const prevMonth = currentMonth.subtract({ months: 1 })
  const nextMonth = currentMonth.add({ months: 1 })

  const { startDate: prevStartDate, endDate: prevEndDate } = getMonthDates(prevMonth)
  const { startDate: nextStartDate, endDate: nextEndDate } = getMonthDates(nextMonth)

  const currentMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: dateType,
              fields: {
                comparator: "between",
                minValue: startDate,
                maxValue: endDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const agendaQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        sort: {
          by: dateType,
          direction: "ASC",
        },
        filter: [
          [
            {
              column: dateType,
              fields: {
                comparator: "gte",
                value: currentMonth.subtract({ months: 1 }).with({ day: 1 }).toString(),
              },
            },
          ],
        ],
        offset: 0,
        limit: 500,
      },
    },
    {
      skip: view !== "agenda",
    }
  )

  // Prime the cache for previous and next months only in calendar view
  const _prevMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: dateType,
              fields: {
                comparator: "between",
                minValue: prevStartDate,
                maxValue: prevEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const _nextMonthQuery = usePostLegalV1AgreementsListQuery(
    {
      listQueryStringParams: {
        filter: [
          [
            {
              column: dateType,
              fields: {
                comparator: "between",
                minValue: nextStartDate,
                maxValue: nextEndDate,
              },
            },
          ],
        ],
      },
    },
    {
      skip: view !== "calendar",
    }
  )

  const isLoading =
    (view === "calendar" && currentMonthQuery.isLoading) || (view === "agenda" && agendaQuery.isLoading) || !whoami

  const error = view === "calendar" ? currentMonthQuery.error : agendaQuery.error
  const agreements = view === "calendar" ? currentMonthQuery.data?.items : agendaQuery.data?.items

  const selectOptions = {
    calendar: {
      value: "calendar" as const,
      label: intl.formatMessage({
        id: "renewalCalendar.calendarView",
        defaultMessage: "Calendar",
        description: "Label for the Calendar view option",
      }),
    },
    agenda: {
      value: "agenda" as const,
      label: intl.formatMessage({
        id: "renewalCalendar.agendaView",
        defaultMessage: "Agenda",
        description: "Label for the Agenda view option",
      }),
    },
  }

  return (
    <PageWrapper>
      <Flex direction="column" height="100%" overflow="hidden" mr={`-${PAGE_PADDING_X}`} my={`-${PAGE_PADDING_Y}`}>
        <Flex py={3} gap={4} borderBottomWidth="1px" paddingX={PAGE_PADDING_X} alignItems="center">
          <Heading as="h1" size="xs" whiteSpace="nowrap">
            <FormattedMessage
              id="renewalCalendar.title"
              defaultMessage="Renewals"
              description="Title for the Renewal Calendar page"
            />
          </Heading>
          <Select<{ value: "calendar" | "agenda"; label: string }>
            value={selectOptions[view]}
            onChange={(newValue) => (newValue ? setView(newValue.value) : undefined)}
            options={[selectOptions.calendar, selectOptions.agenda]}
            menuPortalTarget={document.body}
          />
          <FullTextSearch order={2} ml="auto" />
        </Flex>
        <Flex direction="column" flex={1} overflow="hidden">
          <Box position="sticky" top={0} bg="white" zIndex={1} pr={PAGE_PADDING_X}>
            <Flex justifyContent="space-between" alignItems="center" mt={4} px={4} py={2}>
              <Flex alignItems="center" flex={1} gap={4}>
                <ButtonGroup>
                  {view === "calendar" && (
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: "renewalCalendar.previousMonth",
                        defaultMessage: "Previous month",
                        description: "Label for the button to go to the previous month",
                      })}
                      icon={<ChevronLeftIcon />}
                      onClick={() => setCurrentMonth((prevMonth) => prevMonth.subtract({ months: 1 }))}
                      variant="ghost"
                    />
                  )}
                  <Button
                    onClick={() => {
                      if (view === "calendar") {
                        setCurrentMonth(Temporal.Now.plainDateISO())
                      } else if (view === "agenda") {
                        todayRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
                      }
                    }}
                  >
                    <FormattedMessage
                      id="renewalCalendar.today"
                      defaultMessage="Today, {date}"
                      description="Label for the button to go to today’s date"
                      values={{
                        date: formatDate(intl, currentMonth.toString(), {
                          month: "short",
                          year: "numeric",
                          day: "numeric",
                        }),
                      }}
                    />
                  </Button>
                  {view === "calendar" && (
                    <IconButton
                      aria-label={intl.formatMessage({
                        id: "renewalCalendar.nextMonth",
                        defaultMessage: "Next month",
                        description: "Label for the button to go to the next month",
                      })}
                      icon={<ChevronRightIcon />}
                      onClick={() => setCurrentMonth((prevMonth) => prevMonth.add({ months: 1 }))}
                      variant="ghost"
                    />
                  )}
                </ButtonGroup>
                <Tooltip
                  label={
                    <FormattedMessage
                      id="renewalCalendar.dateTypeTooltip"
                      defaultMessage="View agreements by decision date or end date"
                      description="Tooltip explaining the date type toggle options"
                    />
                  }
                  placement="top"
                >
                  <Tabs
                    size="sm"
                    variant="enclosed-shaded"
                    colorScheme="gray"
                    index={dateType === "decision_date" ? 0 : 1}
                    onChange={(index) => setDateType(index === 0 ? "decision_date" : "end_date")}
                  >
                    <TabList>
                      <Tab whiteSpace="nowrap">
                        <FormattedMessage
                          id="renewalCalendar.decisionDate"
                          defaultMessage="Decision Date"
                          description="Label for the Decision Date option"
                        />
                      </Tab>
                      <Tab whiteSpace="nowrap">
                        <FormattedMessage
                          id="renewalCalendar.endDate"
                          defaultMessage="End Date"
                          description="Label for the End Date option"
                        />
                      </Tab>
                    </TabList>
                  </Tabs>
                </Tooltip>
              </Flex>
              {view === "calendar" && (
                <Flex justifyContent="center" flex={1}>
                  <Text fontSize="xl" fontWeight="bold" textAlign="center">
                    <FormattedMessage
                      id="renewalCalendar.currentMonth"
                      defaultMessage="{month}"
                      description="Current month display in the Renewal Calendar"
                      values={{
                        month: formatDate(intl, currentMonth.toString(), { month: "long", year: "numeric" }),
                      }}
                    />
                  </Text>
                </Flex>
              )}

              <Flex flex={1} justifyContent="flex-end">
                <Button onClick={onOpen}>
                  <FormattedMessage
                    id="renewalCalendar.addToCalendar"
                    defaultMessage="Add to Calendar"
                    description="Button text for adding renewals to a Calendar"
                  />
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box flex={1} overflow="auto" pr={PAGE_PADDING_X} pb={PAGE_PADDING_Y}>
            <Flex direction="column" height="100%">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Spinner />
                </Flex>
              ) : error ? (
                <Flex justifyContent="center" alignItems="center" flex={1}>
                  <Text>
                    <FormattedMessage
                      id="renewalCalendar.errorLoading"
                      defaultMessage="Error loading agreements"
                      description="Error message displayed when agreements fail to load"
                    />
                  </Text>
                </Flex>
              ) : view === "calendar" ? (
                <Box flex={1} display="flex" flexDirection="column">
                  <RenewalCalendar currentMonth={currentMonth} agreements={agreements || []} dateType={dateType} />
                </Box>
              ) : (
                <Box flex={1} mt={4}>
                  <RenewalAgenda agreements={agreements || []} todayRef={todayRef} dateType={dateType} />
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="renewalCalendar.modalTitle"
              defaultMessage="Add Renewal Calendar"
              description="Title for the modal to add renewal calendar"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {calendarKeyQuery.isLoading ? (
              <Spinner />
            ) : calendarKeyQuery.isError ? (
              <Text color="red.500">
                <FormattedMessage
                  id="renewalCalendar.errorLoadingCalendarKey"
                  defaultMessage="Error loading calendar key"
                  description="Error message when calendar key fails to load"
                />
              </Text>
            ) : (
              <VStack spacing={6} align="stretch">
                <Box>
                  <Text fontWeight="bold" mb={1}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructions"
                      defaultMessage="Default Calendar App"
                      description="Header for simple calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.simpleInstructionsText"
                      defaultMessage="Click the button below to add the Renewal Calendar to your default calendar app. This is recommended for most users."
                      description="Instructions for adding calendar URL to default calendar app"
                    />
                  </Text>
                  <Button as="a" href={`webcal://${calendarUrl.replace(/^https?:\/\//u, "")}`} colorScheme="brand">
                    <FormattedMessage
                      id="renewalCalendar.addToCalendarButton"
                      defaultMessage="Add to Calendar"
                      description="Button text for adding calendar using webcal protocol"
                    />
                  </Button>
                </Box>
                <Divider />
                <Box>
                  <Text fontWeight="bold" mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructions"
                      defaultMessage="Other Calendar Apps"
                      description="Header for custom calendar addition instructions"
                    />
                  </Text>
                  <Text mb={2}>
                    <FormattedMessage
                      id="renewalCalendar.customInstructionsText"
                      defaultMessage="The following URL can be added to any calendar product that supports iCal, such as {googleCalendarLink}, {appleCalendarLink}, {microsoftOutlookLink} and {outlookComLink}."
                      description="Instructions for adding calendar URL to various calendar applications"
                      values={{
                        googleCalendarLink: (
                          <Link color="brand.700" to="https://support.google.com/calendar/answer/37100" isExternal>
                            <FormattedMessage
                              id="renewalCalendar.googleCalendar"
                              defaultMessage="Google Calendar"
                              description="Link text for Google Calendar support page"
                            />
                          </Link>
                        ),
                        appleCalendarLink: (
                          <Link
                            color="brand.700"
                            to="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.appleCalendar"
                              defaultMessage="Apple Calendar"
                              description="Link text for Apple Calendar support page"
                            />
                          </Link>
                        ),
                        microsoftOutlookLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-calendars-into-outlook-8e8364e1-400e-4c0f-a573-fe76b5a2d379"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.microsoftOutlook"
                              defaultMessage="Microsoft Outlook"
                              description="Link text for Microsoft Outlook support page"
                            />
                          </Link>
                        ),
                        outlookComLink: (
                          <Link
                            color="brand.700"
                            to="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-or-outlook-on-the-web-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
                            isExternal
                          >
                            <FormattedMessage
                              id="renewalCalendar.outlookCom"
                              defaultMessage="Outlook.com"
                              description="Link text for Outlook.com support page"
                            />
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                  <Box
                    position="relative"
                    width="100%"
                    onClick={() => {
                      onCopy()
                      toast({
                        title: intl.formatMessage({
                          id: "renewalCalendar.urlCopied",
                          defaultMessage: "Calendar URL copied",
                          description: "Toast message shown after copying the calendar URL",
                        }),
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      })
                    }}
                    cursor="pointer"
                  >
                    <Textarea value={calendarUrl} isReadOnly pr="40px" pointerEvents="none" />
                    <Box position="absolute" right="8px" top="50%" transform="translateY(-50%)" pointerEvents="none">
                      <Icon as={CopyIcon} color="gray.500" />
                    </Box>
                  </Box>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>
              <FormattedMessage
                id="renewalCalendar.close"
                defaultMessage="Close"
                description="Button to close the modal"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {inOnboardingFlow && !isOpen && (
        <Portal>
          <Box
            position="fixed"
            top={12}
            left="50%"
            transform="translateX(-50%)"
            borderRadius="md"
            boxShadow="md"
            zIndex="popover"
            bg="white"
            border="2px solid var(--chakra-colors-brand-500)"
          >
            <OnboardingCallout step="overview.view_renewal_agenda" />
          </Box>
        </Portal>
      )}
    </PageWrapper>
  )
}

export default RenewalCalendarPage
