import { Center } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { useParams } from "react-router-dom"
import { useGetLegalV1AgreementsByIdQuery, useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import Spinner from "../../../components/spinner.js"
import { isBrmError, StatusCodes } from "../../../util/error.js"
import { AgreementDeletedCard } from "../AgreementDeletedCard.js"
import { PermissionDeniedCard } from "../PermissionDeniedCard.js"
import { LegalAgreementEditor, type LegalAgreementEditorProps } from "./LegalAgreementEditor.js"
import LegalAgreementEditorLite from "./LegalAgreementEditorLite.js"

// Conditionally renders the legal agreement editor in full or the LITE version based on the user's organizatio
export default function LegalAgreementEditorContainer({ create }: Pick<LegalAgreementEditorProps, "create">) {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { legalAgreementId } = useParams()

  const {
    data: legalAgreement,
    error,
    isLoading,
    refetch,
  } = useGetLegalV1AgreementsByIdQuery(legalAgreementId ? { id: legalAgreementId } : skipToken)

  if (!whoami) {
    return null
  }

  // If an agreement request was made, show a loading spinner until it is loaded
  if (isLoading) {
    return (
      <Center h="100%" flex={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  if (isBrmError(error)) {
    if (error.status === StatusCodes.NOT_FOUND) {
      return (
        <Center h="100%" flex={1}>
          <PermissionDeniedCard />
        </Center>
      )
    }
    if (error.status === StatusCodes.GONE) {
      return (
        <Center h="100%" flex={1}>
          <AgreementDeletedCard />
        </Center>
      )
    }
  }

  const isLiteOrganization = whoami.organization.is_lite

  if (isLiteOrganization) {
    return <LegalAgreementEditorLite legalAgreement={legalAgreement} refetchAgreement={refetch} />
  }

  return <LegalAgreementEditor create={create} legalAgreement={legalAgreement} refetchAgreement={refetch} />
}
