import type { ConversationSummary } from "@brm/schema-types/types.js"
import { Box, Button, Center, Flex, Spinner, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { EditIcon } from "../../components/icons/icons.js"
import { Timestamp } from "../../components/Timestamp.js"
import { isApplePlatform } from "../../util/platform.js"
import { formatRelativeTime } from "../../util/timestamp.js"

interface Props {
  isLoading: boolean
  conversations: ConversationSummary[]
  selectedConversationId?: string
  onConversationSelected: (conversationId: string) => void
  onNewConversation?: () => void
  disableNewConversation?: boolean
}

const thresholds = { day: 2, month: 2, year: 2, hour: 0, minute: 0, second: 0 }

export default function BetsyConversationList({
  isLoading,
  conversations,
  selectedConversationId,
  onConversationSelected,
  onNewConversation,
}: Props) {
  const intl = useIntl()

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner />
      </Center>
    )
  }

  if (!conversations || conversations.length === 0) {
    return (
      <Center height="100%">
        <FormattedMessage
          id="conversationList.noConversations"
          description="Message displayed when there are no conversations"
          defaultMessage="No Conversations"
        />
      </Center>
    )
  }

  const getTimestampWithConversations = (conversations: ConversationSummary[]) => {
    const dateTime = conversations?.[0]?.created_at
    return (
      <Stack mx={5} key={dateTime}>
        {dateTime && (
          <Text fontWeight="medium" px={3} color="gray.600" textTransform="capitalize">
            <Timestamp displayDate={true} dateTime={dateTime} style="long" thresholds={thresholds} />
          </Text>
        )}
        {conversations.map((conversation) => (
          <Button
            flexShrink={0}
            fontSize="sm"
            fontWeight="medium"
            justifyContent="left"
            key={conversation.id}
            variant="ghost"
            px={3}
            aria-current={conversation.id === selectedConversationId ? "page" : "false"}
            onClick={() => onConversationSelected(conversation.id)}
          >
            <Text isTruncated>{conversation.display_name}</Text>
          </Button>
        ))}
      </Stack>
    )
  }

  // Group conversations by relative time
  const conversationsWithRelativeTime = conversations
    .map((conversation) => ({
      conversation,
      relativeTime: formatRelativeTime({
        dateTime: conversation.created_at,
        style: "long",
        numeric: "auto",
        locale: intl.locale,
        thresholds,
      }),
    }))
    .reduce(
      (result, item) => {
        return { ...result, [item.relativeTime]: [...(result[item.relativeTime] || []), item.conversation] }
      },
      {} as Record<string, ConversationSummary[]>
    )

  return (
    <Stack overflowX="hidden" overflowY="auto" spacing="2" width="100%" borderEndWidth="1px" pt="6" paddingBottom={50}>
      {onNewConversation && (
        <Box paddingBottom={5} mx={5}>
          <Button
            variant="outline"
            height="auto"
            leftIcon={<EditIcon height="20px" width="20px" />}
            size="sm"
            justifyContent="left"
            fontSize="sm"
            fontWeight="semibold"
            px={3}
            py={2}
            gap={1}
            shadow="xs"
            width="full"
            onClick={() => onNewConversation()}
          >
            <Flex justifyContent="space-between" width="full" overflow="hidden">
              <FormattedMessage
                id="conversationList.new_conversation"
                description="New conversation button"
                defaultMessage="New conversation"
              />
              <Text>{isApplePlatform() ? "⌘ + ⇧ + k" : "Ctrl + ⇧ + k"}</Text>
            </Flex>
          </Button>
        </Box>
      )}
      <Stack gap={7}>
        {Object.keys(conversationsWithRelativeTime).map((relativeTime) =>
          getTimestampWithConversations(conversationsWithRelativeTime[relativeTime] || [])
        )}
      </Stack>
    </Stack>
  )
}
