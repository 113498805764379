import {
  type CustomizableObjectType,
  type FieldCategory,
  type FieldConfig,
  type FieldConfigMinimal,
  type SimilarFieldQuery,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { uniqBy } from "@brm/util/collections.js"
import { getTitle, isEnumArrayType, isEnumType, titleToFieldName } from "@brm/util/schema.js"
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Stack,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
  chakra,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import type { SingleValue } from "chakra-react-select"
import { forwardRef, useMemo } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { useDebouncedCallback } from "use-debounce"
import { useLazyPostSchemaV1FieldsDuplicatesQuery } from "../../../app/services/generated-api.js"
import { CheckboxWithHelpTooltip } from "../../../components/Form/CheckboxWithHelpTooltip.js"
import { HelpIcon } from "../../../components/icons/icons.js"
import { Link } from "../../../components/Link.js"
import Select from "../../../components/Select/Select.js"
import Spinner from "../../../components/spinner.js"
import { initializeReactHookFormState } from "../../../util/form.js"
import { EnumOptionEditor } from "./EnumOptionEditor.js"
import FieldSchemaTypeSelect from "./FieldSchemaTypeSelect.js"
import type { CriterionFormState, JSONSchemaObjectOnly } from "./types.js"
import { DEFAULT_CRITERION_FORM_STATE, customizableObjectTypes, formatCustomizableObjectType } from "./util.js"

const categorizableObjectTypes = new Set<CustomizableObjectType>(["Tool", "Vendor", "WorkflowRun"])

export const CriterionConfigForm = forwardRef<
  HTMLFormElement,
  {
    id?: string
    fieldConfig?: FieldConfig
    titleInputRef?: React.MutableRefObject<HTMLInputElement | null>
    onValidSubmit?: (config: FieldConfigMinimal) => void
    validateField: (field: Pick<FieldConfig, "field_name" | "object_type" | "is_custom">) => true | string
    defaultValues?: CriterionFormState
  }
>(function CriterionConfigForm(
  {
    fieldConfig,
    titleInputRef,
    defaultValues = { ...DEFAULT_CRITERION_FORM_STATE },
    onValidSubmit,
    validateField,
    ...formProps
  },
  ref
) {
  const intl = useIntl()

  const [findDuplicates, duplicatesResult] = useLazyPostSchemaV1FieldsDuplicatesQuery()
  const duplicateFields = uniqBy(duplicatesResult.data ?? [], (field) => field.field_name)

  const debouncedFindDuplicates = useDebouncedCallback(findDuplicates, 500, { trailing: true })

  interface ObjectTypeOption {
    value: CustomizableObjectType
    label: string
  }
  const objectTypeOptions = useMemo(
    (): ObjectTypeOption[] =>
      customizableObjectTypes.map((type) => ({
        label: formatCustomizableObjectType(type, intl),
        value: type,
      })),
    [intl]
  )
  interface CategoryOption {
    value: FieldCategory | null
    label: string
  }
  const categoryOptions = useMemo((): CategoryOption[] => {
    const options: CategoryOption[] = [
      // You cannot remove the category from a standard tool field that has a category by default
      ...(!fieldConfig || fieldConfig.is_custom || !fieldConfig.category
        ? [
            {
              value: null,
              label: intl.formatMessage({
                defaultMessage: "General",
                description: "Category for general criteria",
                id: "settings.criteria.category.general",
              }),
            },
          ]
        : []),
      ...FieldCategorySchema.anyOf.map((category) => ({
        label: getTitle(category.const, category),
        value: category.const,
      })),
    ]

    // Sort the options alphabetically by label, keeping "None" at the top
    return options.sort((a, b) => {
      if (a.value === null) return -1
      if (b.value === null) return 1
      return a.label.localeCompare(b.label)
    })
  }, [fieldConfig, intl])

  const form = useForm<CriterionFormState>({
    defaultValues: (fieldConfig
      ? {
          dataType: fieldConfig.field_schema as JSONSchemaObjectOnly,
          title: getTitle(fieldConfig.field_name, fieldConfig.field_schema),
          description:
            (fieldConfig.field_schema as JSONSchemaObjectOnly)?.uiDescription ??
            (fieldConfig.field_schema as JSONSchemaObjectOnly)?.description ??
            "",
          category: fieldConfig.category,
          object_type: fieldConfig.object_type as CustomizableObjectType,
          is_internal_only: fieldConfig.is_internal_only,
          is_enabled: fieldConfig?.is_enabled ?? true,
          request_config_disabled: fieldConfig?.request_config_disabled ?? false,
          required_in_request: fieldConfig?.required_in_request ?? false,
        }
      : defaultValues) satisfies CriterionFormState,
  })
  initializeReactHookFormState(form)

  const schema = useWatch({ name: "dataType", control: form.control })
  const schemaWithValue = { schema }

  // For new fields, check for duplicates as the user types
  if (!fieldConfig) {
    form.watch(async (formState) => {
      if (!formState.object_type) {
        return
      }
      // Clone state before passing it to rtk-query because rtk-query will deeply freeze anything passed to it,
      // which then breaks form state changes.
      const similarFieldQuery: SimilarFieldQuery = structuredClone({
        object_type: formState.object_type,
        category: formState.category ?? undefined,
        field_schema: {
          ...formState.dataType,
          title: formState.title?.trim() || undefined,
          description: formState.description?.trim() || undefined,
          // Ensure we don't store a $id in the schema, which can mess with RefResolver
          $id: undefined,
        },
      })
      await debouncedFindDuplicates({ similarFieldQuery })
    })
  }

  const objectType = useWatch({ control: form.control, name: "object_type" })

  // Changing data types of existing fields is not currently allowed (it would require deleting all existing data of the field)
  const isDataTypeReadOnly = !!fieldConfig

  const isNewCriterion = fieldConfig === undefined

  return (
    <chakra.form
      gap={3}
      display="flex"
      flexDir="column"
      noValidate
      ref={ref}
      {...formProps}
      onSubmit={form.handleSubmit((values) => {
        const isCustom = fieldConfig?.is_custom ?? true

        const validation = validateField({
          field_name: titleToFieldName(values.title, intl),
          object_type: values.object_type,
          is_custom: isCustom,
        })
        if (validation !== true) {
          form.setError("title", { message: validation })
          return
        }
        // Clone state for protection before passing it to the caller because rtk-query will deeply freeze
        // anything passed to it, which then breaks form state changes.
        const submittedValues: FieldConfigMinimal = structuredClone({
          object_type: values.object_type,
          field_name: fieldConfig?.field_name ?? titleToFieldName(values.title, intl),
          is_custom: isCustom,
          is_enabled: values.is_enabled,
          category: values.category,
          field_schema: isCustom
            ? {
                ...values.dataType,
                title: values.title.trim() || undefined,
                description: values.description.trim() || undefined,
                // Ensure we don't store a $id in the schema, which can mess with RefResolver
                $id: undefined,
              }
            : null,
          is_internal_only: values.is_internal_only,
          needs_reextraction: fieldConfig?.needs_reextraction ?? false,
          request_config_disabled: values.request_config_disabled,
          required_in_request: values.required_in_request,
          order_index: fieldConfig?.order_index ?? 0,
        })
        onValidSubmit?.(submittedValues)
      })}
    >
      <Controller
        control={form.control}
        name="title"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please provide a name for this criterion",
            description: "Error message when criterion name is missing",
            id: "settings.criteria.criterion.name.error.required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={fieldConfig?.is_custom === false}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.name.label"
                description="Label for the criterion name input"
                defaultMessage="Criteria name"
              />
            </FormLabel>
            <InputGroup>
              <Input
                {...field}
                autoFocus={isNewCriterion}
                ref={(el) => {
                  field.ref(el)
                  if (titleInputRef) {
                    titleInputRef.current = el
                  }
                }}
              />
              {duplicatesResult.isFetching && (
                <InputRightElement>
                  <Spinner />
                </InputRightElement>
              )}
            </InputGroup>
            {fieldState.error ? (
              <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
            ) : duplicateFields.length ? (
              <FormHelperText role="alert">
                <FormattedMessage
                  defaultMessage="{fieldList} already {fieldCount, plural, one {exists} other {exist}} as {fieldCount, plural, one {a criterion} other {criteria}} in your BRM. Please review it to avoid duplicates."
                  description="Hint message for duplicate custom criterion"
                  id="settings.criteria.criterion.duplicate.custom"
                  values={{
                    fieldList: (
                      <FormattedList
                        value={duplicateFields.map((field) => {
                          const fieldTitle = getTitle(field.field_name, field.field_schema)
                          return (
                            <Link
                              key={field.field_name}
                              variant="highlighted"
                              to={{
                                pathname: "/settings/criteria/",
                                search: new URLSearchParams({
                                  objectType: field.object_type,
                                  filterText: fieldTitle,
                                }).toString(),
                              }}
                              relative="path"
                            >
                              {fieldTitle}
                            </Link>
                          )
                        })}
                      />
                    ),
                    fieldCount: duplicateFields.length,
                  }}
                />
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      />
      {!isNewCriterion &&
        // LegalAgreement is a special case where the field is not configurable unless it is custom
        (objectType !== "LegalAgreement" || fieldConfig?.is_custom) &&
        schema?.configurable !== false && (
          <Controller
            control={form.control}
            name="is_enabled"
            render={({ field }) => (
              <FormControl>
                <FormLabel htmlFor="isEnabled">
                  <FormattedMessage
                    defaultMessage="Enabled"
                    description="Label for the criterion enable/disable dropdown"
                    id="settings.criteria.object.enableCriterion"
                  />{" "}
                  <Tooltip
                    label={
                      <FormattedMessage
                        defaultMessage="You can turn fields on or off depending on if these criteria are relevant to your BRM. For example you should only enable PCI DSS if you are a payment provider or processor that has to maintain PCI compliance."
                        description="Tooltip explaining the consequence of disabling a criterion"
                        id="settings.criteria.object.enableCriterionTooltip"
                      />
                    }
                    shouldWrapChildren
                  >
                    <Icon as={HelpIcon} lineHeight="inherit" boxSize="1lh" verticalAlign="bottom" />
                  </Tooltip>
                </FormLabel>
                <Select<{ value: boolean; label: string }, false>
                  id="isEnabled"
                  value={
                    field.value
                      ? {
                          value: true,
                          label: intl.formatMessage({
                            defaultMessage: "Yes",
                            description: "Option for enabling a criterion",
                            id: "settings.criteria.object.enableCriterionYes",
                          }),
                        }
                      : {
                          value: false,
                          label: intl.formatMessage({
                            defaultMessage: "No",
                            description: "Option for disabling a criterion",
                            id: "settings.criteria.object.enableCriterionNo",
                          }),
                        }
                  }
                  onChange={(selectedOption: SingleValue<{ value: boolean; label: string }>) =>
                    field.onChange(selectedOption?.value ?? true)
                  }
                  options={[
                    {
                      value: true,
                      label: intl.formatMessage({
                        defaultMessage: "Yes",
                        description: "Option for enabling a criterion",
                        id: "settings.criteria.object.enableCriterionYes",
                      }),
                    },
                    {
                      value: false,
                      label: intl.formatMessage({
                        defaultMessage: "No",
                        description: "Option for disabling a criterion",
                        id: "settings.criteria.object.enableCriterionNo",
                      }),
                    },
                  ]}
                  isClearable={false}
                />
              </FormControl>
            )}
          />
        )}

      <Controller
        control={form.control}
        name="description"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please provide a description for this criterion",
            description: "Error message when criterion description is missing",
            id: "settings.criteria.criterion.description.error.required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={fieldConfig?.is_custom === false}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.description.label"
                description="Label for the criterion description input"
                defaultMessage="Criteria description"
              />
            </FormLabel>
            <Textarea {...field} />
            {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      <Controller
        control={form.control}
        name="dataType"
        rules={{
          required: intl.formatMessage({
            defaultMessage: "Please select a data type for this criterion",
            description: "Error message when criterion data type is missing",
            id: "settings.criteria.criterion.dataType.error.required",
          }),
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={isDataTypeReadOnly}>
            <FormLabel>
              <FormattedMessage
                id="settings.criteria.criterion.dataType.label"
                description="Label for the criterion data type input"
                defaultMessage="Criteria data type"
              />
            </FormLabel>
            <FieldSchemaTypeSelect
              value={field.value}
              onChange={field.onChange}
              ref={field.ref}
              isDisabled={field.disabled}
              isReadOnly={isDataTypeReadOnly}
            />
            {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {(isEnumType(schemaWithValue) || isEnumArrayType(schemaWithValue)) && (
        <EnumOptionEditor
          form={form}
          enumRootPath={schemaWithValue.schema.type === "array" ? "dataType.items" : "dataType"}
          isReadOnly={isDataTypeReadOnly}
          savedCriterion={fieldConfig}
        />
      )}

      {isNewCriterion && (
        <Controller
          control={form.control}
          name="object_type"
          render={({ field, fieldState }) => (
            <>
              <FormControl isInvalid={fieldState.invalid} isRequired isReadOnly={!!fieldConfig}>
                <FormLabel>
                  <FormattedMessage
                    id="settings.criteria.criterion.objectType.label"
                    description="Label for the criterion object type input"
                    defaultMessage="Associated object"
                  />
                </FormLabel>
                <Select<ObjectTypeOption, false, never>
                  options={objectTypeOptions}
                  value={objectTypeOptions.find((o) => o.value === field.value)}
                  onChange={(option) => {
                    field.onChange(option?.value ?? null)
                    if (!option?.value || !categorizableObjectTypes.has(option.value)) {
                      form.setValue("category", null)
                    }
                  }}
                  isDisabled={field.disabled}
                  isSearchable={false}
                  ref={(selectInstance) => field.ref(selectInstance?.inputRef)}
                  menuPortalTarget={document.body}
                  isReadOnly={!!fieldConfig}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-popover)" }),
                  }}
                />
                {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
              </FormControl>
              {field.value === "LegalAgreement" && (
                <FormattedMessage
                  id="settings.criteria.criterion.objectType.agreement.automagicallyExtract"
                  description="Helper text for the Agreement object type"
                  defaultMessage="After adding Agreement criteria, BRM automagically extracts this field from existing documents"
                />
              )}
            </>
          )}
        />
      )}

      {categorizableObjectTypes.has(objectType) && (
        <Controller
          control={form.control}
          name="category"
          render={({ field, fieldState }) => (
            <FormControl
              isInvalid={fieldState.invalid}
              isRequired={!categoryOptions.some((o) => o.value === null)}
              isReadOnly={(fieldConfig?.field_schema as JSONSchemaObject)?.configurable === false}
            >
              <FormLabel>
                <FormattedMessage
                  id="settings.criteria.criterion.category.label"
                  description="Label for the criterion category input"
                  defaultMessage="Criteria category"
                />{" "}
                <Tooltip
                  label={
                    <Box padding={1}>
                      <FormattedMessage
                        id="settings.criteria.criterion.category.tooltip"
                        description="Tooltip explaining what the category determines"
                        defaultMessage="The category determines where and how this criterion appears in the system"
                      />

                      <UnorderedList paddingLeft={1}>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item1"
                            description="Entry for the field category tool tip"
                            defaultMessage="Where the field appears on a request"
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item2"
                            description="Entry for the field category tool tip"
                            defaultMessage="Where the field appears in the tool or vendor detail page"
                          />
                        </ListItem>
                        <ListItem>
                          <FormattedMessage
                            id="settings.criteria.criterion.category.label.item3"
                            description="Entry for the field category tool tip"
                            defaultMessage="The permissions required to view or edit the field"
                          />
                        </ListItem>
                      </UnorderedList>

                      {fieldConfig && (
                        <FormattedMessage
                          defaultMessage="If the field is currently configured to be part of a request step, changing the category will also move it to the new corresponding step."
                          description="Helper text for changing a field’s category (edit only)"
                          id="settings.criteria.criterion.category.stepMoveHelperText"
                        />
                      )}
                    </Box>
                  }
                  shouldWrapChildren
                >
                  <Icon as={HelpIcon} lineHeight="inherit" boxSize="1lh" verticalAlign="bottom" />
                </Tooltip>
              </FormLabel>
              <Select<CategoryOption, false, never>
                options={categoryOptions}
                value={categoryOptions.find((o) => o.value === field.value)}
                onChange={(option) => field.onChange(option?.value ?? null)}
                isDisabled={field.disabled}
                isSearchable={false}
                ref={(selectInstance) => field.ref(selectInstance?.inputRef)}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-popover)" }),
                }}
              />
              {fieldState.error && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
            </FormControl>
          )}
        />
      )}
      <FormControl>
        <FormLabel as={Text}>
          <FormattedMessage
            defaultMessage="Requests"
            description="Label for the field’s enabled on requests"
            id="settings.criteria.criterion.enabledOn.label"
          />
        </FormLabel>
      </FormControl>
      {!schema?.readOnly && schema?.configurable !== false && (
        <>
          <Controller
            control={form.control}
            name="request_config_disabled"
            render={({ field, fieldState }) => (
              <CheckboxWithHelpTooltip
                isDisabled={objectType === "LegalAgreement" && !fieldConfig?.is_custom}
                isInvalid={!!fieldState.error}
                errorMessage={fieldState.error?.message}
                isChecked={!field.value}
                onChange={(checked) => field.onChange(!checked)}
                inputRef={field.ref}
                label={intl.formatMessage({
                  id: "settings.criteria.criterion.requestConfigEnabled.label",
                  description: "Label for the criterion request config enabled checkbox",
                  defaultMessage: "Gather in request forms",
                })}
                tooltip={intl.formatMessage({
                  defaultMessage: "If checked, this field will be available in request forms",
                  description: "Tooltip for the criterion request config enabled checkbox",
                  id: "settings.criteria.criterion.requestConfigEnabled.tooltip",
                })}
              />
            )}
          />
          {!form.watch("request_config_disabled") && (
            <Stack pl={4} gap={3}>
              <Controller
                control={form.control}
                name="required_in_request"
                render={({ field, fieldState }) => (
                  <CheckboxWithHelpTooltip
                    isDisabled={objectType === "LegalAgreement" && !fieldConfig?.is_custom}
                    isInvalid={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                    isChecked={field.value}
                    onChange={field.onChange}
                    inputRef={field.ref}
                    label={intl.formatMessage({
                      id: "settings.criteria.criterion.requiredInRequest.label",
                      description: "Label for the criterion required in request checkbox",
                      defaultMessage: "Required in request form",
                    })}
                    tooltip={intl.formatMessage({
                      defaultMessage: "If checked, this field must be filled out in request forms",
                      description: "Tooltip for the criterion required in request checkbox",
                      id: "settings.criteria.criterion.requiredInRequest.tooltip",
                    })}
                  />
                )}
              />
              <Controller
                control={form.control}
                name="is_internal_only"
                render={({ field, fieldState }) => (
                  <CheckboxWithHelpTooltip
                    isInvalid={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                    isChecked={!field.value}
                    onChange={(checked) => field.onChange(!checked)}
                    inputRef={field.ref}
                    label={intl.formatMessage({
                      id: "settings.criteria.criterion.isInternalOnly.label",
                      description: "Label for the criterion internal only checkbox",
                      defaultMessage: "Shared with sellers",
                    })}
                    tooltip={intl.formatMessage({
                      defaultMessage: "If checked, this field will be visible to invited sellers in request forms",
                      description: "Tooltip for the criterion internal only checkbox",
                      id: "settings.criteria.criterion.isInternalOnly.tooltip",
                    })}
                  />
                )}
              />
            </Stack>
          )}
        </>
      )}
    </chakra.form>
  )
})
