import { Box } from "@chakra-ui/react"
import type { Document } from "./DocumentAttachment.js"
import DocumentAttachment from "./DocumentAttachment.js"

interface AttachedDocumentSectionProps {
  attachments: Document[]
}

const AttachedDocumentSection = ({ attachments }: AttachedDocumentSectionProps) => {
  if (!attachments.length) return null

  return (
    <Box py={4} paddingRight={3} gap={2} display="flex" flexDirection="column" pb={4} marginLeft={12} paddingLeft={2}>
      {attachments.map((attachment) => (
        <DocumentAttachment key={attachment.id} document={attachment} />
      ))}
    </Box>
  )
}

export default AttachedDocumentSection
