import { hasPermission } from "@brm/schema-helpers/role.js"
import { type Role } from "@brm/schema-types/types.js"
import { Checkbox, Divider, FormControl, FormLabel, Stack, Text, Tooltip } from "@chakra-ui/react"
import type { ChangeEvent, FC } from "react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetUserV1WhoamiQuery } from "../../../app/services/generated-api.js"
import RoleRadioOption from "./RoleRadioOption.js"

type RoleSection = "core" | "data"

const sectionForRole: Record<Role, RoleSection> = {
  admin: "core",
  super_admin: "core",
  finance: "data",
  legal: "data",
  compliance: "data",
  it: "data",
  compliance_approver: "data",
  finance_approver: "data",
  it_approver: "data",
  legal_approver: "data",
}

// Used so that if we ever change the Role enum this file will have a compile error
const valueForRole: Record<Role, Role> = {
  admin: "admin",
  super_admin: "super_admin",
  finance: "finance",
  legal: "legal",
  compliance: "compliance",
  compliance_approver: "compliance_approver",
  it: "it",
  it_approver: "it_approver",
  finance_approver: "finance_approver",
  legal_approver: "legal_approver",
}

export const RoleSelect: React.FunctionComponent<{
  onChange: (roles: Role[]) => void
  roles: Role[]
}> = ({ onChange, roles }) => {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  // CheckboxGroup returns an array even if single select is true
  const [coreRole, setCoreRole] = useState<Role | "user">(
    roles.includes("super_admin") ? "super_admin" : roles.includes("admin") ? "admin" : "user"
  )
  const isSuperAdmin = coreRole === "super_admin"

  const [dataRoles, setDataRoles] = useState<ReadonlySet<Role>>(
    new Set(roles.filter((r) => sectionForRole[r] === "data"))
  )

  if (!whoami || !hasPermission(whoami.roles, "admin_role:create")) {
    return null
  }

  const onCoreRoleChange = (newValue: Role | "user") => {
    setCoreRole(newValue)
    onSectionChanged({ core: newValue })
  }

  const onDataRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDataRoles = new Set(dataRoles)
    const value = event.target.value as Role
    if (event.target.checked) {
      newDataRoles.add(value)
    } else {
      newDataRoles.delete(value)
    }
    setDataRoles(newDataRoles)
    onSectionChanged({ data: Array.from(newDataRoles) })
  }

  const onSectionChanged = (change: { core?: Role | "user"; data?: Role[]; workflow?: Role[] }) => {
    const core = change.core || coreRole
    if (core === "super_admin") {
      onChange(["super_admin"])
      return
    }

    const data = new Set(change.data || dataRoles)

    // Approver roles are a superset of non approver roles lets only set the top level role
    if (data.has("compliance_approver")) {
      data.delete("compliance")
    }

    if (data.has("finance_approver")) {
      data.delete("finance")
    }

    if (data.has("it_approver")) {
      data.delete("it")
    }

    onChange([...(core === "user" ? [] : [core]), ...data])
  }

  return (
    <>
      <FormControl as="fieldset" isRequired={true}>
        <FormLabel as="legend" mb={2} pt={2} paddingBottom={2} fontSize="md" fontWeight="medium">
          <FormattedMessage
            defaultMessage="Role"
            description="Heading of section that lists user permissions"
            id="role.select.permissions.heading"
          />
        </FormLabel>
        <Stack gap={2}>
          {hasPermission(whoami.roles, "super_admin_role:create") && (
            <RoleRadioOption
              isChecked={isSuperAdmin}
              onChange={onCoreRoleChange}
              valueForRole={valueForRole["super_admin"]}
              label={intl.formatMessage({
                id: "superAdmin.role.input.title",
                description: "Title for the checkbox that sets the core role to superAdmin level",
                defaultMessage: "Super Admin",
              })}
              details={intl.formatMessage({
                id: "superAdmin.role.input.details",
                description: "Detail for checkbox that explains the permissions granted to a superAdmin level",
                defaultMessage:
                  "Ability to add and delete integrations, manage organization settings, and invite other Super Admins",
              })}
            />
          )}
          <RoleRadioOption
            isChecked={coreRole === "admin"}
            onChange={onCoreRoleChange}
            valueForRole={valueForRole["admin"]}
            label={intl.formatMessage({
              id: "admin.role.input.title",
              description: "Title for the checkbox that sets the core role to admin level",
              defaultMessage: "Admin",
            })}
            details={intl.formatMessage({
              id: "admin.role.input.details",
              description: "Detail for checkbox that explains the permissions granted to a admin level",
              defaultMessage: "Ability to add integrations, manage organization settings, and invite other Admins",
            })}
          />
          <RoleRadioOption
            isChecked={coreRole === "user"}
            onChange={onCoreRoleChange}
            valueForRole="user"
            label={intl.formatMessage({
              id: "user.role.input.title",
              description: "Title for the checkbox that sets the core role to user level",
              defaultMessage: "User",
            })}
            details={intl.formatMessage({
              id: "user.role.input.details",
              description: "Detail for checkbox that explains the permissions granted to a user level",
              defaultMessage: "Permission to view tools and invite users",
            })}
          />
        </Stack>
      </FormControl>
      <FormControl as="fieldset">
        <FormLabel as="legend" mb={2} pt={2} paddingBottom={2} fontSize="md" fontWeight="medium">
          <FormattedMessage
            defaultMessage="Permissions"
            description="Heading of section that lists user permissions"
            id="role.select.permissions.heading"
          />
          <Text size="sm" fontWeight="normal" color="gray.600">
            <FormattedMessage
              defaultMessage="Manage user permissions for compliance, finance, IT, and Legal data and approvals"
              description="Subheading of section that lists user permissions"
              id="role.select.permissions.subheading"
            />
          </Text>
        </FormLabel>
        <Stack gap={2}>
          <UserPermissionsRow
            isSuperAdmin={isSuperAdmin}
            sectionType="compliance"
            editorPermission={{
              role: "compliance",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("compliance") || isSuperAdmin,
            }}
            approverPermission={{
              role: "compliance_approver",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("compliance_approver") || isSuperAdmin,
            }}
          />
          <Divider marginY={4} />
          <UserPermissionsRow
            isSuperAdmin={isSuperAdmin}
            sectionType="finance"
            editorPermission={{
              role: "finance",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("finance") || isSuperAdmin,
            }}
            approverPermission={{
              role: "finance_approver",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("finance_approver") || isSuperAdmin,
            }}
          />
          <Divider marginY={4} />
          <UserPermissionsRow
            isSuperAdmin={isSuperAdmin}
            sectionType="it"
            editorPermission={{
              role: "it",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("it") || isSuperAdmin,
            }}
            approverPermission={{
              role: "it_approver",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("it_approver") || isSuperAdmin,
            }}
          />
          <Divider marginY={4} />
          <UserPermissionsRow
            isSuperAdmin={isSuperAdmin}
            sectionType="legal"
            editorPermission={{
              role: "legal",
              onChange: onDataRoleChange,
              isChecked: dataRoles.has("legal") || isSuperAdmin,
            }}
          />
          <Divider marginY={4} />
        </Stack>
      </FormControl>
    </>
  )
}

const sectionTypeToSectionHeader: Record<UserPermissionsRowProps["sectionType"], string> = {
  compliance: "Compliance",
  legal: "Legal",
  finance: "Finance",
  it: "IT",
}

const sectionTypeToSectionName: Record<UserPermissionsRowProps["sectionType"], string> = {
  compliance: "compliance",
  legal: "legal",
  finance: "finance",
  it: "IT",
}

interface UserPermissionsRowProps {
  isSuperAdmin: boolean
  sectionType: "compliance" | "legal" | "finance" | "it"
  editorPermission: UserPermissionsRowPermission
  approverPermission?: UserPermissionsRowPermission
}

interface UserPermissionsRowPermission {
  role: Role
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

const UserPermissionsRow: FC<UserPermissionsRowProps> = (props) => {
  return (
    <Stack gap={2} alignItems="flex-start">
      <Text fontWeight="semibold">
        <FormattedMessage
          id="permissions.role.input.title"
          description="Title for a section to control permissions"
          defaultMessage="{sectionName}"
          values={{
            sectionName: sectionTypeToSectionHeader[props.sectionType],
          }}
        />
      </Text>
      <Stack gap={2}>
        <CheckboxTooltipWrapper
          isEnabled={props.isSuperAdmin}
          formattedTooltipMessage={
            <FormattedMessage
              id="roles.select.data.superAdmin.tooltip"
              description="Tooltip for data roles when the core role is super admin"
              defaultMessage="Super Admins have all data permissions enabled"
            />
          }
        >
          <Stack>
            <CheckboxTooltipWrapper
              isEnabled={!!props.approverPermission?.isChecked && !props.isSuperAdmin}
              formattedTooltipMessage={
                <FormattedMessage
                  id="roles.select.data.approver.tooltip"
                  description="Tooltip for view and edit when approver is checked"
                  defaultMessage="Approvers must have view and edit permissions"
                />
              }
            >
              <Checkbox
                value={valueForRole[props.editorPermission.role]}
                isReadOnly={props.isSuperAdmin}
                isDisabled={props.isSuperAdmin || props.approverPermission?.isChecked}
                isChecked={props.editorPermission.isChecked || props.approverPermission?.isChecked}
                onChange={props.editorPermission.onChange}
                alignItems="flex-start"
                sx={{ ".chakra-checkbox__control": { marginTop: "0.2rem" } }}
              >
                <Text fontWeight="medium">
                  <FormattedMessage
                    id="permissions.editPermissions.role.input.title"
                    description="Title for the checkbox that enables edit permissions for this section"
                    defaultMessage="View and edit data"
                  />
                </Text>
                <Text fontWeight="light">
                  <FormattedMessage
                    id="permissions.role.input.details"
                    description="Detail for the checkbox that enables edit permissions for this section"
                    defaultMessage="Create, edit, and delete {sectionType} data"
                    values={{
                      sectionType: sectionTypeToSectionName[props.sectionType],
                    }}
                  />
                </Text>
              </Checkbox>
            </CheckboxTooltipWrapper>
            {props.approverPermission && (
              <Checkbox
                value={valueForRole[props.approverPermission.role]}
                isReadOnly={props.isSuperAdmin}
                isDisabled={props.isSuperAdmin}
                isChecked={props.approverPermission.isChecked}
                onChange={props.approverPermission.onChange}
                alignItems="flex-start"
                sx={{ ".chakra-checkbox__control": { marginTop: "0.2rem" } }}
              >
                <Text fontWeight="medium">
                  <FormattedMessage
                    id="permissions.approvePermissions.role.input.title"
                    description="Title for the checkbox that enables approval permissions for this section"
                    defaultMessage="Approve requests"
                  />
                </Text>
                <Text fontWeight="light">
                  <FormattedMessage
                    id="permissions.approvePermissions.role.input.details"
                    description="Detail for the checkbox that enables approval permissions for this section"
                    defaultMessage="Approve {sectionType} fields and steps in requests"
                    values={{
                      sectionType: sectionTypeToSectionName[props.sectionType],
                    }}
                  />
                </Text>
              </Checkbox>
            )}
          </Stack>
        </CheckboxTooltipWrapper>
      </Stack>
    </Stack>
  )
}

interface CheckboxTooltipWrapperProps {
  isEnabled: boolean
  children: React.ReactNode
  formattedTooltipMessage: React.ReactNode
}

const CheckboxTooltipWrapper: FC<CheckboxTooltipWrapperProps> = (props) => {
  if (!props.isEnabled) {
    return props.children
  }
  return (
    <Tooltip label={props.formattedTooltipMessage} shouldWrapChildren>
      {props.children}
    </Tooltip>
  )
}
