import { Avatar, Card, CardBody, Divider, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import type { SVGProps } from "react"
import { FormattedMessage } from "react-intl"
import FeaturedIcon from "./FeaturedIcon/FeaturedIcon.js"
import { InfoIcon } from "./icons/icons.js"

interface LogoInfoCardProps {
  displayName: string
  logoUrl?: string
  fallbackAvatarLogo: (props: SVGProps<SVGSVGElement>) => React.JSX.Element
  description: string | null
}

export default function LogoInfoCard({ displayName, logoUrl, fallbackAvatarLogo, description }: LogoInfoCardProps) {
  return (
    <Card boxShadow="lg">
      <CardBody>
        <HStack>
          <Avatar size="sm" src={logoUrl} icon={<Icon as={fallbackAvatarLogo} />} />
          <Text fontSize="lg" fontWeight="semibold">
            {displayName}
          </Text>
        </HStack>
        <Divider my={3} />
        {description ? (
          <Text color="gray.600">{description}</Text>
        ) : (
          <Stack alignItems="center">
            <FeaturedIcon icon={<Icon as={InfoIcon} />} borderColor="gray.50" backgroundColor="gray.100" />
            <Text color="gray.500">
              <FormattedMessage
                id="components.logoInfoCard.noDescription"
                defaultMessage="No description available for {displayName} yet"
                description="Message shown when no description is available."
                values={{ displayName }}
              />
            </Text>
            <Text color="gray.400">
              <FormattedMessage
                id="components.logoInfoCard.checkBack"
                defaultMessage="Check back later for updates"
                description="Message encouraging users to check back for updates when vendor description is missing"
              />
            </Text>
          </Stack>
        )}
      </CardBody>
    </Card>
  )
}
