import { type Plugin, type RenderViewer } from "@brmlabs/react-pdf-viewer-core"

export interface PageThumbnailPluginProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PageThumbnail: React.ReactElement
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props

  return {
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps

      slot.children = PageThumbnail

      // Reset the sub slot
      if (!slot.subSlot) {
        slot.subSlot = {}
      }
      slot.subSlot.attrs = {}
      slot.subSlot.children = null

      return slot
    },
  }
}
