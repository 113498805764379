import { Box, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import NegotiationAgentLayout from "./NegotiationAgentLayout.js"

const NegotiationAgentSuccess = () => {
  return (
    <NegotiationAgentLayout>
      <Box mb={2}>
        <Text fontSize="5xl" fontWeight="bold">
          <FormattedMessage
            id="negotiationAgent.success.heading"
            defaultMessage="Inbox Connected."
            description="Main heading for the negotiation agent success page"
          />
        </Text>
      </Box>

      <Box mb={8}>
        <Text fontSize="xl">
          <FormattedMessage
            id="negotiationAgent.success.content"
            defaultMessage="Now with your email connected to BRM’s negotiation AI you can use our Gmail add-on to see vendor insights right inside your inbox."
            description="Main content paragraph explaining the benefit of using the Gmail add-on"
          />
        </Text>
      </Box>

      <Box mb={6}>
        <Text fontSize="md" color="gray.600">
          <FormattedMessage
            id="negotiationAgent.success.closeTab"
            defaultMessage="Please close this tab and return to gmail"
            description="Text to close the tab and return to gmail"
          />
        </Text>
      </Box>
    </NegotiationAgentLayout>
  )
}

export default NegotiationAgentSuccess
