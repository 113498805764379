import type { DocumentElement } from "@brm/schema-types/types.js"
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Box, chakra, Popover, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react"
import type { ChakraProps } from "@chakra-ui/system"
import { useEffect, useMemo, useState } from "react"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import { DocumentThumbnailPreview } from "../Document/DocumentThumbnailPreview.js"
import { LinkOrSpan } from "../Link.js"
import type { ExtendedElementProps } from "./types.js"

export const Document = ({
  attributes,
  children,
  element,
  getDocumentUrl,
  getDocumentTagHref,
}: RenderElementProps & {
  element: DocumentElement
  getDocumentUrl?: (documentId: string) => Promise<string>
  getDocumentTagHref?: (documentId: string) => string
} & ExtendedElementProps) => {
  const [url, setUrl] = useState<string | undefined>(undefined)
  const selected = useSelected()
  const focused = useFocused()

  useEffect(() => {
    if (!url) {
      void getDocumentUrl?.(element.id).then(setUrl)
    }
  }, [getDocumentUrl, element.id, url])

  const spanStyle: ChakraProps = useMemo(
    () => ({
      padding: "0 2px",
      margin: "0 1px",
      verticalAlign: "baseline",
      display: "inline-block",
      borderRadius: "4px",
      backgroundColor: "brand.50",
      borderWidth: "1px",
      borderColor: "brand.200",
      fontSize: "0.9em",
      boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
    }),
    [selected, focused]
  )

  const popoverTrigger = useMemo(() => {
    return (
      <chakra.span
        {...attributes}
        contentEditable={false}
        data-cy={`mention-${element.file_name.replaceAll(" ", "-")}`}
        {...spanStyle}
      >
        <LinkOrSpan
          target={getDocumentTagHref ? undefined : "_blank"}
          to={getDocumentTagHref?.(element.id) ?? url}
          color="gray.700"
          _hover={{
            color: "gray.700",
            textDecoration: "underline",
          }}
        >
          {element.file_name}
        </LinkOrSpan>
        {children}
      </chakra.span>
    )
  }, [attributes, children, element.file_name, spanStyle, url, getDocumentTagHref, element.id])

  return url ? (
    <Popover placement="top" trigger="hover">
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <Portal>
        <PopoverContent width="100%" maxW="300px">
          <Box padding={2}>
            <DocumentThumbnailPreview fileUrl={url} displayName={element.file_name} mimeType={element.mime_type} />
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  ) : (
    popoverTrigger
  )
}
