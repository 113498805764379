import { HStack, Text, type SystemStyleObject } from "@chakra-ui/react"
import { CELL_SIZE_PROPS_MAP, type CellSize } from "../../components/DataTable/CellRenderer/util.js"
import { ToolLogo } from "../../components/icons/Logo.js"

interface ToolDisplayProps {
  toolLogoUrl: string | undefined
  toolName: string
  textContentStyle?: SystemStyleObject
  size?: CellSize
}

export default function ToolDisplay({ toolLogoUrl, toolName, textContentStyle, size = "md" }: ToolDisplayProps) {
  const cellSizeProps = CELL_SIZE_PROPS_MAP[size]

  return (
    <HStack>
      <ToolLogo logo={toolLogoUrl} boxSize={cellSizeProps.boxSize} />
      <Text
        fontWeight={cellSizeProps.fontWeight}
        maxW="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        minW={0}
        sx={textContentStyle}
      >
        {toolName}
      </Text>
    </HStack>
  )
}
