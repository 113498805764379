import { Button, HStack, Icon, Stack, Tag, TagLabel, TagLeftIcon, useDisclosure, useToast } from "@chakra-ui/react"
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl"
import { usePostUserV1CsvMutation, usePostUserV1ListQuery } from "../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import { DotIcon, DownloadIcon, PlusIcon } from "../../components/icons/icons.js"
import { log } from "../../util/logger.js"
import InviteOrUpdateUserModal from "../organization/invites/InviteOrUpdateUserModal.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"
import { UserList } from "./UserList.js"

export default function UsersSettings() {
  const intl = useIntl()
  const toast = useToast()
  const inviteModal = useDisclosure()
  const [exportCsv, exportCsvResult] = usePostUserV1CsvMutation()
  const { data: userList } = usePostUserV1ListQuery({
    body: {
      filter: [
        [
          {
            column: "status",
            fields: {
              comparator: "any",
              values: ["active", "pending"],
            },
          },
        ],
      ],
    },
  })
  const activeCount = userList?.users.filter((u) => u.status === "active").length ?? 0
  const inviteCount = userList?.users.filter((u) => u.status === "pending").length ?? 0

  const exportCsvLabel = intl.formatMessage({
    id: "tableFilters.exportToCsv",
    description: "Tooltip and aria label for csv export button",
    defaultMessage: "Export table to CSV file",
  })

  return (
    <SettingsContainer>
      <SettingsHeader
        title={intl.formatMessage({
          defaultMessage: "Users",
          description: "Users table heading on organization view page",
          id: "organization.view.users.heading",
        })}
      />
      <Stack spacing={4}>
        <HStack spacing={8} justify="space-between">
          <HStack>
            <Tag variant="outline">
              <TagLeftIcon boxSize={3} as={DotIcon} color="green.500" width="0.5em" />
              <TagLabel>
                <FormattedPlural
                  value={activeCount}
                  one={
                    <FormattedMessage
                      id="organization.view.users.active.one"
                      description="Number of active users in organization"
                      defaultMessage="{count} active"
                      values={{ count: activeCount }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id="organization.view.users.active.other"
                      description="Number of active users in organization"
                      defaultMessage="{count} active"
                      values={{ count: activeCount }}
                    />
                  }
                />
              </TagLabel>
            </Tag>
            <Tag variant="outlineGrayBg">
              <TagLabel>
                <FormattedPlural
                  value={inviteCount}
                  one={
                    <FormattedMessage
                      id="organization.view.users.active.one"
                      description="Number of active users in organization"
                      defaultMessage="{count} pending invitation"
                      values={{ count: inviteCount }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id="organization.view.users.active.other"
                      description="Number of active users in organization"
                      defaultMessage="{count} pending invitations"
                      values={{ count: inviteCount }}
                    />
                  }
                />
              </TagLabel>
            </Tag>
          </HStack>
          <HStack>
            <IconButtonWithTooltip
              label={exportCsvLabel}
              variant="outline"
              aria-label={exportCsvLabel}
              icon={<Icon as={DownloadIcon} />}
              isLoading={exportCsvResult.isLoading}
              onClick={async () => {
                try {
                  const result = await exportCsv().unwrap()
                  if (result.url) {
                    const link = document.createElement("a")
                    link.href = result.url
                    link.click()
                  }
                } catch (err) {
                  log.error("Failed to export CSV file", err)
                  toast({
                    description: intl.formatMessage({
                      id: "tool.list.filer.csv.error",
                      description: "error message when the csv export fails",
                      defaultMessage: "Failed to export CSV file",
                    }),
                    status: "error",
                  })
                }
              }}
            />
            <Button colorScheme="brand" leftIcon={<Icon as={PlusIcon} />} onClick={inviteModal.onOpen}>
              <FormattedMessage
                id="organization.view.invite.button"
                description="button text for inviting a new user to organization"
                defaultMessage="Add User"
              />
            </Button>
          </HStack>
        </HStack>
        <UserList userList={userList} />
      </Stack>
      {inviteModal.isOpen && <InviteOrUpdateUserModal {...inviteModal} />}
    </SettingsContainer>
  )
}
