import { Box, Flex } from "@chakra-ui/react"
import type { ReactNode } from "react"
import BrmLogo from "../../components/BrmLogo.js"

export const OnboardingLogoLayout = ({ content }: { content: ReactNode }) => {
  return (
    <Box minH="100vh" bg="white">
      <Flex align="center" justify="space-between" h="full" gap={32} transform="translateX(-4rem)">
        <Box flex="1" display="flex" justifyContent="right">
          <BrmLogo w={72} h={72} />
        </Box>

        <Box flex="1">{content}</Box>
      </Flex>
    </Box>
  )
}
