import { getStandardObjectsFromRichText } from "@brm/schema-helpers/rich-text/rich-text.js"
import { serializeRichTextToMarkdown } from "@brm/schema-helpers/rich-text/serialize.js"
import type {
  Conversation,
  ConversationSummary,
  DocumentMinimal,
  EmailDraftInput,
  EmailThread,
  Message,
  NegotiationConversation,
  NegotiationWithContext,
  PickableEntityFilter,
  ToolCall,
} from "@brm/schema-types/types.js"
import { buildRichTextFromEmailNode } from "@brm/type-helpers/rich-text.js"
import { displayPersonName } from "@brm/util/names.js"
import { negotiationRouteById, negotiationRouteByIdWithDocument } from "@brm/util/routes.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
  useToast,
  type UseDisclosureReturn,
  type UseToastOptions,
} from "@chakra-ui/react"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type Ref,
} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { type Descendant } from "slate"
import { useDebouncedCallback } from "use-debounce"
import braimLogo from "../../../assets/braim.svg"
import {
  useGetUserV1WhoamiQuery,
  useGetUserV1WritingPersonaQuery,
  useLazyGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery,
  usePostDocumentV1ByIdBackgroundExtractMutation,
  usePostNegotiationV1UpdateContextMutation,
  usePostUserV1WritingPersonaMutation,
} from "../../app/services/generated-api.js"
import BetsyDisplayName from "../../components/ContextMenu/BetsyDisplayName.js"
import { DocumentDisplay } from "../../components/Document/DocumentDisplay.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import {
  AgentMemoryIcon,
  BackIcon,
  BeakerIcon,
  CopyIcon,
  EditIcon,
  EmailIcon,
  FolderIcon,
  RenewalIcon,
  SendIcon,
  ShareIcon,
  WritingPersonaIcon,
} from "../../components/icons/icons.js"
import NegotiationPanelHeader from "../../components/negotiation/NegotiationPanelHeader.js"
import Popover, { PopoverContent, PopoverTrigger } from "../../components/Popover.js"
import { AttachmentButton } from "../../components/RichTextEditor/EditorToolbar.js"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor.js"
import {
  DEFAULT_PICKABLE_ENTITIES,
  EMPTY_RICH_TEXT_BODY,
  isEmptyRichText,
  trimRichTextWhiteSpace,
} from "../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { Timestamp } from "../../components/Timestamp.js"
import { log } from "../../util/logger.js"
import { posthogCaptureEvent } from "../../util/posthog-captures.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { SpeechToTextIconButton } from "../audio/SpeechToTextButton.js"
import type { AgentAction } from "./AgentResponse.js"
import BetsyConversationList from "./BetsyConversationList.js"
import { ChatMessage, type ChatMessageAuthor } from "./ChatMessage.js"
import { EMAIL_COMPOSER_ID } from "./EmailComposer.js"
import { NegotiationDataModal } from "./NegotiationDataModal.js"
import { useChatContext } from "./use-chat-context.js"
import { defaultSuggestedPrompts } from "./util.js"
import { WritingPersonaModal } from "./WritingPersonaModal.js"

const DEFAULT_ERROR_TOAST: UseToastOptions = {
  description: "Something went wrong",
  status: "error",
}

const iconButtonStyle = {
  variant: "ghost",
  size: "sm",
} as const

interface AttachedFilesMenuProps {
  onDocument: (document: DocumentMinimal) => Promise<void>
  documents: DocumentMinimal[]
}

function AttachedFilesMenu(props: AttachedFilesMenuProps) {
  const { onDocument, documents } = props
  const intl = useIntl()
  const menuListRef = useRef<HTMLDivElement>(null)

  // We'll use this callback when the menu opens to scroll to the bottom
  const onMenuOpen = useCallback(() => {
    // Use requestAnimationFrame to ensure DOM is updated
    requestAnimationFrame(() => {
      if (menuListRef.current) {
        menuListRef.current.scrollTo({
          top: menuListRef.current.scrollHeight,
          behavior: "instant",
        })
      }
    })
  }, [])

  // Add keyboard event handler to ensure items scroll into view when navigating
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      // Give time for focus to change before scrolling
      requestAnimationFrame(() => {
        // query for the element where tabIndex is 0, which is how chakra v2 sets the focused element
        // eslint-disable-next-line prefer-smart-quotes/prefer
        const focusedElement = menuListRef.current?.querySelector("[tabindex='0']") as HTMLElement
        if (focusedElement) {
          focusedElement.scrollIntoView({
            block: "nearest",
            behavior: "smooth",
          })
        }
      })
    }
  }, [])

  const attachedFilesLabel = intl.formatMessage({
    id: "chat.folder",
    description: "Aria label for the folder icon button in the chat",
    defaultMessage: "Attached Files",
  })

  return (
    <Menu placement="top-end" onOpen={onMenuOpen} computePositionOnMount>
      <>
        <Tooltip label={attachedFilesLabel}>
          <MenuButton
            as={IconButton}
            {...iconButtonStyle}
            icon={<Icon as={FolderIcon} />}
            aria-label={attachedFilesLabel}
          />
        </Tooltip>
        <MenuList ref={menuListRef} maxHeight="15rem" overflowY="auto" onKeyDown={handleKeyDown}>
          {documents.length === 0 ? (
            <Box px={4} py={2} color="gray.500">
              <FormattedMessage
                id="chat.folder.empty"
                defaultMessage="No attached files"
                description="Text shown when there are no attached files"
              />
            </Box>
          ) : (
            documents.map((document, index) => (
              <MenuItem key={index} onClick={() => onDocument(document)}>
                <Flex width="100%" alignItems="center" gap={2}>
                  <DocumentDisplay document={document} />
                  <Spacer />
                  <Timestamp dateTime={document.created_at} />
                </Flex>
              </MenuItem>
            ))
          )}
        </MenuList>
      </>
    </Menu>
  )
}

function AgentInfoPopover(props: UseDisclosureReturn) {
  const { isOpen, onOpen, onClose } = props

  return (
    <Popover
      position="top"
      opened={isOpen}
      onClose={onClose}
      onKeyDown={(e) => {
        if (e.key === "Escape" && isOpen) {
          onClose()
        }
      }}
    >
      <PopoverTrigger>
        <Button
          variant="link"
          onClick={() => {
            posthogCaptureEvent("negotiation_agent_info_popover_open")
            onOpen()
          }}
          color="gray.400"
          fontSize="9px"
        >
          <FormattedMessage
            id="chat.agent.about"
            defaultMessage="About the negotiation agent"
            description="About the negotiation agent"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Box p={4} width="20rem">
          <Stack spacing={2}>
            <Text fontWeight="medium">
              <FormattedMessage
                id="chat.agent.info.access"
                defaultMessage="The negotiation agent has access to:"
                description="Header for agent access information"
              />
            </Text>
            <UnorderedList spacing={1} paddingLeft={4}>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.access.emails"
                  defaultMessage="Connected email threads"
                  description="Agent has access to email threads"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.access.conversation"
                  defaultMessage="Current conversation thread"
                  description="Agent has access to current conversation"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.access.context"
                  defaultMessage="Negotiation notes"
                  description="Agent has access to negotiation notes"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.access.summary"
                  defaultMessage="Summary agreement information"
                  description="Agent has access to agreement summaries"
                />
              </ListItem>
            </UnorderedList>

            <Text fontWeight="medium">
              <FormattedMessage
                id="chat.agent.info.actions"
                defaultMessage="The agent can:"
                description="Header for agent capabilities"
              />
            </Text>
            <UnorderedList spacing={1} paddingLeft={4}>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.actions.browse"
                  defaultMessage="Browse the web"
                  description="Agent can browse the web"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.actions.notes"
                  defaultMessage="Take notes"
                  description="Agent can take notes"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.actions.email"
                  defaultMessage="Draft an email"
                  description="Agent can draft emails"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="chat.agent.info.actions.agreement"
                  defaultMessage="Read the full agreement"
                  description="Agent can read the full agreement"
                />
              </ListItem>
            </UnorderedList>
          </Stack>
        </Box>
      </PopoverContent>
    </Popover>
  )
}

const ChatRichTextEditor = forwardRef<
  HTMLDivElement,
  {
    isLoadingAnswer: boolean
    isReadOnly: boolean
    showAgentTelemetry: boolean
    startNewConversation?: () => void
    submit: (draft: Descendant[] | undefined) => Promise<void>
    pickableEntityFilters: Omit<PickableEntityFilter, "name">
    enableWritingPersonaModal: boolean
    onNegotiationContextOpen?: () => void
    negotiationId?: string
    documents?: DocumentMinimal[]
    getDocumentTagHref?: (documentId: string) => string
    getDocumentUrl?: (documentId: string) => Promise<string>
  }
>(function ChatRichTextEditor(
  {
    isLoadingAnswer,
    isReadOnly,
    showAgentTelemetry,
    startNewConversation,
    submit,
    pickableEntityFilters,
    enableWritingPersonaModal,
    onNegotiationContextOpen,
    negotiationId,
    documents,
    getDocumentTagHref,
    getDocumentUrl,
  },
  ref: Ref<HTMLDivElement | null>
) {
  const intl = useIntl()
  const toast = useToast()

  const inputRef = useRef<HTMLDivElement>(null)

  const { data: writingPersona, refetch: refetchWritingPersona } = useGetUserV1WritingPersonaQuery()

  const [updateWritingPersona, { isLoading: isUpdatingWritingPersona }] = usePostUserV1WritingPersonaMutation()

  const writingPersonaDisclosure = useDisclosure()
  const attachedFilesDisclosure = useDisclosure()

  const { insertDocumentContext, insertText, chatValue, setChatValue } = useChatContext()

  const [backgroundExtract] = usePostDocumentV1ByIdBackgroundExtractMutation()

  const debouncedOnChange = useDebouncedCallback((content: Descendant[]) => {
    setChatValue(content)
    return content
  }, 500)

  useImperativeHandle(ref, () => inputRef.current)

  const onSubmit = useCallback(async () => {
    const lastValue = debouncedOnChange.flush() ?? chatValue
    const savedDraft = structuredClone(lastValue)
    const emailNode = getStandardObjectsFromRichText(savedDraft).find((obj) => obj.object_type === "Email")
    setChatValue(emailNode ? buildRichTextFromEmailNode(emailNode) : EMPTY_RICH_TEXT_BODY)
    try {
      await submit(savedDraft)
    } catch (_) {
      setChatValue(savedDraft)
    }
  }, [chatValue, debouncedOnChange, setChatValue, submit])

  return (
    <>
      <WritingPersonaModal
        {...writingPersonaDisclosure}
        isUpdating={isUpdatingWritingPersona}
        userPersona={writingPersona?.writing_persona ?? null}
        onPersonaUpdate={async (persona: string) => {
          await updateWritingPersona({ body: { writing_persona: persona } }).unwrap()
          await refetchWritingPersona()
        }}
      />
      <RichTextEditor
        placeholder={intl.formatMessage({
          id: "chat.placeholder",
          description: "Placeholder for the chat input",
          defaultMessage: "Type here to chat...",
        })}
        forceFocus={true}
        isReadOnly={isReadOnly}
        disableFileDrop={!negotiationId}
        initialValue={chatValue ?? EMPTY_RICH_TEXT_BODY}
        disableTextEditingToolbar={true}
        containerProps={{
          onKeyDown: async (e) => {
            // standard enter key behavior
            if (e.key === "Enter" && !isLoadingAnswer && !isReadOnly && !showAgentTelemetry) {
              e.stopPropagation()
              e.preventDefault()
              // if the chat is empty, don't submit
              // TODO: the empty check is not great, you can still put \n\n in the chat and send it
              if (chatValue && !isEmptyRichText(chatValue)) {
                await onSubmit()
              }
            } else if (e.key === "k" && e.metaKey && e.shiftKey) {
              startNewConversation?.()
            }
          },
        }}
        onChange={debouncedOnChange}
        getDocumentUrl={getDocumentUrl}
        getDocumentTagHref={getDocumentTagHref}
        pickableEntityFilters={pickableEntityFilters}
        ref={inputRef}
        onDocument={async (document) => {
          if (negotiationId) {
            await backgroundExtract({
              documentBackgroundExtractionRequest: {
                extraction_request_type: "negotiation",
                negotiation_id: negotiationId,
              },
              id: document.id,
            })
          }
        }}
        additionalToolbarButtons={
          <Flex
            gap={1}
            flexGrow={1}
            flexWrap="wrap"
            justifyContent="flex-start"
            height="fit-content"
            alignItems="center"
          >
            {onNegotiationContextOpen && (
              <IconButtonWithTooltip
                {...iconButtonStyle}
                onClick={() => {
                  onNegotiationContextOpen()
                  posthogCaptureEvent("negotiation_notebook_open")
                }}
                icon={<Icon as={AgentMemoryIcon} />}
                label={intl.formatMessage({
                  id: "chat.notebook",
                  description: "Notebook",
                  defaultMessage: "Agent Notebook",
                })}
              />
            )}

            {enableWritingPersonaModal && (
              <IconButtonWithTooltip
                {...iconButtonStyle}
                onClick={() => {
                  writingPersonaDisclosure.onOpen()
                  posthogCaptureEvent("negotiation_writing_persona_open")
                }}
                icon={<Icon as={WritingPersonaIcon} />}
                label={intl.formatMessage({
                  id: "chat.persona",
                  description: "Persona context",
                  defaultMessage: "Writing Persona",
                })}
              />
            )}

            <Spacer />

            {documents && (
              <AttachedFilesMenu
                onDocument={async (document) => {
                  // insert the document into the rich text editor
                  insertDocumentContext(document)
                  attachedFilesDisclosure.onClose()
                  // focus on the rich text editor
                  inputRef?.current?.focus()
                }}
                documents={documents ?? []}
              />
            )}

            {/* only show the attachment button if we have a negotiation id */}
            {negotiationId && (
              <AttachmentButton
                onDocument={async (document) => {
                  if (negotiationId) {
                    await backgroundExtract({
                      documentBackgroundExtractionRequest: {
                        extraction_request_type: "negotiation",
                        negotiation_id: negotiationId,
                      },
                      id: document.id,
                    })
                  }
                }}
              />
            )}

            <SpeechToTextIconButton
              {...iconButtonStyle}
              onText={insertText}
              onError={() => {
                toast(DEFAULT_ERROR_TOAST)
              }}
            />

            <IconButtonWithTooltip
              {...iconButtonStyle}
              variant="link"
              isDisabled={isReadOnly || isLoadingAnswer || !chatValue || showAgentTelemetry}
              isLoading={isLoadingAnswer}
              colorScheme="brand"
              aria-keyshortcuts="Enter"
              onClick={onSubmit}
              icon={<Icon as={SendIcon} />}
              aria-label={intl.formatMessage({
                id: "chat.send",
                description: "Aria label for the send button in the chat",
                defaultMessage: "Send",
              })}
              label="Send"
            />
          </Flex>
        }
      />
    </>
  )
})

const CHAT_HEADER_HEIGHT: number = 40

const Chat = ({
  conversation,
  startNewConversation,
  onError,
  onSuccess,
  defaultMessages = [],
  onSubmitStreamingUrl,
  onRegenerate,
  onReset,
  onChurn,
  onConversationSelect,
  setEmailDraft,
  emailDraft,
  setIsLoading,
  addOns = {},
  additionalRequestParams = {},
  showAgentTelemetry,
}: {
  conversation: Partial<Omit<Conversation, "id">> & { id: string }
  startNewConversation?: () => void
  onError?: () => void
  // Calls onSuccess with the AI response
  onSuccess?: (message?: string) => void
  defaultMessages?: Message[]
  onSubmitStreamingUrl: string
  onRegenerate?: () => void
  onReset?: () => void
  onChurn?: () => void
  onConversationSelect?: (conversationId: string) => void
  setEmailDraft?: (emailDraft: EmailDraftInput) => void
  emailDraft?: EmailDraftInput
  setIsLoading?: (isLoading: boolean) => void
  addOns?: {
    suggestedPrompts?: {
      enabled: boolean
      defaultPrompts?: string[]
    }
    negotiation?: {
      enabled: boolean
      negotiation: NegotiationWithContext
      negotiationConversation: NegotiationConversation | undefined
      emailThread: EmailThread | undefined
      onThreadChange: (emailThreadId: string | undefined) => void
      onSendSuccess?: () => void
    }
  }
  additionalRequestParams?: Record<string, string | undefined>
  showAgentTelemetry?: boolean
  onSubmitSuccess?: () => void
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()
  const location = useLocation()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { id: conversationId } = conversation
  const { negotiationConversation, negotiation } = addOns.negotiation ?? {}
  const [messages, setMessages] = useState<Message[] | undefined>(conversation?.messages ?? defaultMessages)
  const [status, setStatus] = useState("waiting")
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)
  const bottomRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const { insertEmailContext } = useChatContext()
  const [suggestedPrompts, setSuggestedPrompts] = useState<string[] | undefined>(defaultSuggestedPrompts)

  const [showConversationHistory, setShowConversationHistory] = useState(false)

  const [updateNegotiationContext, { isLoading: isUpdatingNegotiationContext }] =
    usePostNegotiationV1UpdateContextMutation()
  const [getDocumentUrlByObject] = useLazyGetDocumentV1ByObjectTypeAndObjectIdDocumentsDocumentIdUrlQuery()

  // Reset state when conversation Id changes
  useEffect(() => {
    setMessages(conversation.messages ?? defaultMessages)
    setSuggestedPrompts(addOns.suggestedPrompts?.defaultPrompts)
    setIsLoadingAnswer(false)
    inputRef.current?.focus()
    setShowConversationHistory(false)
    // We do not care if the default messages change, not point in re-running this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, conversation.messages, addOns.suggestedPrompts?.defaultPrompts, negotiationConversation?.id])

  useEffect(() => {
    if (!showConversationHistory) {
      // let the input become visible before focusing it
      setTimeout(() => {
        inputRef.current?.focus()
      }, 50)
    }
  }, [showConversationHistory])

  const pickableEntityFilters: Omit<PickableEntityFilter, "name"> = useMemo(
    () => ({
      entities: DEFAULT_PICKABLE_ENTITIES,
    }),
    []
  )

  useEffect(() => {
    if (!isLoadingAnswer && suggestedPrompts && suggestedPrompts.length > 0) {
      // Scroll to bottom when suggested prompts are updated
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }

    if (bottomRef.current && window.innerHeight - bottomRef.current.getBoundingClientRect().y > 0) {
      // Pin to bottom if the user is already scrolled to the bottom
      bottomRef.current.scrollIntoView({ behavior: "instant" })
    }

    // Scroll to bottom on new agent replies
    if (messages?.[messages.length - 1]?.content === "") {
      bottomRef.current?.scrollIntoView({ behavior: "instant" })
    }
  }, [messages, suggestedPrompts, isLoadingAnswer])

  const isReadOnly: boolean = conversation?.user_id ? whoami?.id !== conversation.user_id : false

  const userAuthor: ChatMessageAuthor = useMemo(
    () =>
      conversation.user_id && conversation?.user_id !== whoami?.id
        ? {
            name:
              conversation?.user?.first_name ||
              (conversation?.user && displayPersonName(conversation.user, intl)) ||
              "User",
            image: getPublicImageGcsUrl(conversation?.user?.profile_image?.gcs_file_name),
            role: "user" as const,
          }
        : {
            name: whoami?.first_name || (whoami && displayPersonName(whoami, intl)) || "User",
            image: getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name),
            role: "user" as const,
          },
    [conversation, whoami, intl]
  )

  const fetchAnswer = useCallback(
    async (draft: Descendant[], messages: Message[] | undefined) => {
      let dataReceived = false
      const htmlEditorRef = document.getElementById(EMAIL_COMPOSER_ID)
      await fetchEventSource(onSubmitStreamingUrl, {
        method: "POST",
        credentials: "include",
        openWhenHidden: true,
        body: JSON.stringify({
          query: draft,
          messages,
          conversation_id: conversation.id,
          log_message: true,
          ...(addOns.negotiation?.enabled && emailDraft ? { email_draft: emailDraft } : {}),
          ...additionalRequestParams,
        }),
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: "text/event-stream",
          ["Content-Type"]: "application/json",
        },
        async onopen(res) {
          if (res.ok && res.status === 200) {
            // do nothing
            return
          }
          if (res.status === 401) {
            navigate("/login")
            return
          }

          throw new Error("braim api failure")
        },
        onmessage(event) {
          if (event.event === "data") {
            dataReceived = true
            const data = JSON.parse(event.data)
            const text = data?.text
            const suggestedPrompts = data?.suggestedPrompts
            const emailDraft = data?.emailDraft
            const replyToEmailId = data?.replyToEmailId
            if (addOns.suggestedPrompts?.enabled && !isEmpty(suggestedPrompts)) {
              setSuggestedPrompts(suggestedPrompts)
            }

            if (addOns.negotiation?.enabled && !isEmpty(replyToEmailId) && replyToEmailId !== emailDraft?.id) {
              // Focus default reply to email
              const email = negotiation?.email_threads
                ?.flatMap((emailThread) => emailThread.emails)
                .find((email) => email.id === replyToEmailId)
              if (email) {
                insertEmailContext(email)
                const hashParams = new URLSearchParams(location.hash.slice(1))
                hashParams.set("email", email.id)
                navigate({
                  search: location.search,
                  hash: `#${hashParams}`,
                })
              }
            }
            if (addOns.negotiation?.enabled && !isEmpty(emailDraft)) {
              htmlEditorRef?.scrollIntoView({ behavior: "smooth" })
              setEmailDraft?.(emailDraft)
            }
            if (!isEmpty(text)) {
              setStatus("waiting")
              setMessages((c) => {
                const currentMessage: Message = (c ?? []).at(-1)!
                const ret = c?.slice(0, -1) ?? []
                ret.push({ ...currentMessage, content: `${currentMessage?.content ?? ""}${text}` })
                return ret
              })
            }
          } else if (event.event === "status") {
            const data = JSON.parse(event.data)
            setStatus(data.status)
            log.info("braim api status", data.status)
          }
        },
        onclose() {
          if (!dataReceived) {
            throw new Error("braim api failure")
          }
        },
        onerror(e) {
          log.error("braim api failure", e, { draft, messages })

          // reset the last two messages
          setMessages((messages) => {
            // remove the last two messages
            return messages?.slice(0, -2) ?? []
          })

          toast(DEFAULT_ERROR_TOAST)
          onError?.()
          setIsLoadingAnswer(false)
          throw e
        },
      })
    },
    [
      onSubmitStreamingUrl,
      conversation.id,
      addOns.negotiation?.enabled,
      addOns.suggestedPrompts?.enabled,
      setEmailDraft,
      emailDraft,
      additionalRequestParams,
      navigate,
      negotiation?.email_threads,
      insertEmailContext,
      location.hash,
      location.search,
      toast,
      onError,
    ]
  )
  const submit = useCallback(
    async (draft: Descendant[] | undefined) => {
      const trimmedBody = draft && trimRichTextWhiteSpace(draft)
      if (trimmedBody && trimmedBody.length > 0) {
        const serializedQuery = serializeRichTextToMarkdown(trimmedBody)
        if (!serializedQuery) {
          return
        }

        setMessages((currentMessages) => {
          return [
            ...(currentMessages ?? []),
            { content: serializedQuery, role: "user", rich_text: trimmedBody, tool_calls: null, citations: null },
            { content: "", role: "assistant", tool_calls: null, citations: null },
          ]
        })
        setIsLoadingAnswer(true)
        setIsLoading?.(true)
        await fetchAnswer(trimmedBody, messages)

        // handle success
        setIsLoadingAnswer(false)
        setIsLoading?.(false)
        setStatus("waiting")
        inputRef.current?.focus()
        onSuccess?.(messages?.[messages.length - 1]?.content ?? "")
      }
    },
    [messages, setMessages, fetchAnswer, onSuccess, setIsLoading]
  )

  const negotiationContextDisclosure = useDisclosure()
  const agentInfoDisclosure = useDisclosure()

  const getDocumentTagHref = useMemo(
    () =>
      addOns.negotiation?.enabled && negotiation?.id
        ? (documentId: string) => negotiationRouteByIdWithDocument(negotiation.id, documentId)
        : undefined,
    [addOns.negotiation?.enabled, negotiation?.id]
  )

  const getDocumentUrl = useMemo(
    () =>
      addOns.negotiation?.enabled && negotiation?.id
        ? async (documentId: string) =>
            (await getDocumentUrlByObject({ objectType: "Negotiation", objectId: negotiation.id, documentId }).unwrap())
              .download_url
        : undefined,
    [addOns.negotiation?.enabled, negotiation?.id, getDocumentUrlByObject]
  )

  const computedHeaderHeight: number = addOns.negotiation?.enabled ? CHAT_HEADER_HEIGHT : 0

  const displayConversations: ConversationSummary[] = (negotiation?.conversations ?? [])
    .toReversed()
    .map((conversation) => ({
      id: conversation.id,
      display_name: conversation.betsy_conversation.display_name,
      created_at: conversation.betsy_conversation.created_at,
    }))

  return (
    <HStack width="100%" height="100%" flex={1} minH={0} gap={0}>
      {addOns.negotiation?.enabled && negotiation && negotiation.context !== null && (
        <NegotiationDataModal
          {...negotiationContextDisclosure}
          isUpdating={isUpdatingNegotiationContext}
          onUpdate={async (key, value) => {
            // Handle data updates
            await updateNegotiationContext({
              body: {
                negotiation_id: negotiation.id,
                key,
                value,
              },
            })
          }}
          currentData={negotiation.context}
        />
      )}

      <Flex overflow="hidden" height="100%" minWidth="320px" flex={1} flexDirection="column">
        {/* Chat Header */}
        {addOns.negotiation?.enabled && negotiation && (
          <NegotiationPanelHeader
            title="Chat"
            height={`${computedHeaderHeight}px`}
            before={
              <IconButtonWithTooltip
                {...iconButtonStyle}
                onClick={() => {
                  setShowConversationHistory(!showConversationHistory)
                }}
                icon={<Icon as={BackIcon} />}
                label="Conversation History"
              />
            }
            after={
              <Flex gap={2}>
                <Spacer />

                <Menu placement="bottom-end">
                  <Tooltip
                    label={intl.formatMessage({
                      id: "chat.automated_actions",
                      description: "Tooltip for automated actions button",
                      defaultMessage: "Automated Actions",
                    })}
                  >
                    <MenuButton
                      as={IconButton}
                      {...iconButtonStyle}
                      icon={<Icon as={BeakerIcon} />}
                      aria-label={intl.formatMessage({
                        id: "chat.automated_actions",
                        description: "Aria label for automated actions button",
                        defaultMessage: "Automated Actions",
                      })}
                    />
                  </Tooltip>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        try {
                          setMessages(undefined)
                          onChurn?.()
                        } catch (_) {
                          toast(DEFAULT_ERROR_TOAST)
                        }
                      }}
                    >
                      <Icon as={EmailIcon} />
                      <FormattedMessage
                        id="chat.test.draft.churn.email"
                        defaultMessage="Draft Churn Email"
                        description="Menu item to draft churn email"
                      />
                    </MenuItem>
                  </MenuList>
                </Menu>

                {onReset && (
                  <IconButtonWithTooltip
                    {...iconButtonStyle}
                    onClick={() => {
                      try {
                        setMessages(undefined)
                        onReset?.()
                        navigate({
                          pathname: negotiationRouteById(negotiation.id),
                        })
                      } catch (_) {
                        toast(DEFAULT_ERROR_TOAST)
                      }
                    }}
                    icon={<Icon as={EditIcon} />}
                    aria-label={intl.formatMessage({
                      id: "chat.reset",
                      description: "Aria label for the reset conversation button in the chat",
                      defaultMessage: "New Conversation",
                    })}
                    label="New Conversation"
                  />
                )}
              </Flex>
            }
          />
        )}

        {showConversationHistory && (
          <Flex flexDirection="column" height={`calc(100% - ${computedHeaderHeight}px)`} width="100%">
            <BetsyConversationList
              conversations={displayConversations}
              selectedConversationId={additionalRequestParams.negotiation_conversation_id}
              isLoading={false}
              onConversationSelected={onConversationSelect ? onConversationSelect : () => {}}
            />
          </Flex>
        )}

        <Flex
          flexDirection="column"
          height={`calc(100% - ${computedHeaderHeight}px)`}
          width="100%"
          padding={4}
          // space the bottom of the chat container a little
          paddingBottom={8}
          // use display none instead of conditional rendering to preserver the input ref connection
          display={showConversationHistory ? "none" : "flex"}
        >
          {/* Chat messages */}
          {messages && messages.length > 0 ? (
            <ChatMessages
              showAgentTelemetry={showAgentTelemetry ?? false}
              messages={messages}
              status={status}
              isLoadingAnswer={isLoadingAnswer}
              defaultMessages={defaultMessages}
              conversationId={conversationId}
              userAuthor={userAuthor}
              bottomRef={bottomRef}
              getDocumentTagHref={getDocumentTagHref}
              getDocumentUrl={getDocumentUrl}
              onRegenerate={
                onRegenerate
                  ? () => {
                      onRegenerate()
                      // removing the messages on the client reduces flicker during the regeneration process
                      setMessages((messages) => {
                        if (!messages) {
                          return undefined
                        }

                        // remove the last N assistant messages until we have a user message
                        let N = 0
                        for (let i = messages.length - 1; i >= 0; i--) {
                          if (messages[i]?.role === "assistant") {
                            N++
                          } else {
                            break
                          }
                        }
                        return messages?.slice(0, -N) ?? []
                      })
                    }
                  : undefined
              }
            />
          ) : (
            <ChatMessages
              showAgentTelemetry={showAgentTelemetry ?? false}
              messages={defaultMessages ?? []}
              status={status}
              isLoadingAnswer={isLoadingAnswer}
              defaultMessages={defaultMessages}
              conversationId={conversationId}
              userAuthor={userAuthor}
              bottomRef={bottomRef}
            />
          )}

          {/* Spacer to move the rich text editor down */}
          <Spacer minHeight={2} />

          <Flex flexDirection="column" as="form" gap={1} width="100%" maxWidth="500px" marginX="auto">
            {/* Suggested prompts */}
            {addOns.suggestedPrompts?.enabled && (
              <HStack flexWrap="wrap" gap={2} padding={2}>
                {!isLoadingAnswer &&
                  suggestedPrompts?.map((prompt, i) => (
                    <SuggestedPromptBadge
                      key={i}
                      prompt={prompt}
                      onClick={async () => {
                        try {
                          await submit([
                            {
                              type: "paragraph",
                              children: [
                                {
                                  text: prompt,
                                },
                              ],
                            },
                          ])
                        } catch (_) {
                          toast(DEFAULT_ERROR_TOAST)
                        }
                      }}
                    />
                  ))}
              </HStack>
            )}

            <ChatRichTextEditor
              getDocumentTagHref={getDocumentTagHref}
              getDocumentUrl={getDocumentUrl}
              documents={negotiation?.documents}
              negotiationId={negotiation?.id}
              enableWritingPersonaModal={addOns.negotiation?.enabled ?? false}
              isLoadingAnswer={(isLoadingAnswer || negotiationConversation?.show_agent_telemetry) ?? false}
              isReadOnly={(isReadOnly || negotiationConversation?.show_agent_telemetry) ?? false}
              showAgentTelemetry={showAgentTelemetry ?? false}
              startNewConversation={startNewConversation}
              submit={submit}
              pickableEntityFilters={pickableEntityFilters}
              ref={inputRef}
              onNegotiationContextOpen={
                addOns.negotiation?.enabled ? () => negotiationContextDisclosure.onOpen() : undefined
              }
            />

            {/* Agent info popover */}
            <Box paddingLeft={2}>{addOns.negotiation?.enabled && <AgentInfoPopover {...agentInfoDisclosure} />}</Box>
          </Flex>
        </Flex>
      </Flex>
    </HStack>
  )
}

// TODO: centralize the names for the tools, maybe move api/src/agent-tools
// into its own package or into an existing shared package.
const toolCallToAgentAction: Record<ToolCall["function"]["name"], AgentAction> = {
  write_email: { displayName: "Drafted email", name: "write_email" },
  fetch_url: { displayName: "Read a website", name: "fetch_url" },
  web_search: { displayName: "Searched the web", name: "web_search" },
  read_legal_agreement: { displayName: "Read the agreement", name: "read_legal_agreement" },
  update_negotiation_context: { displayName: "Took some notes", name: "update_negotiation_context" },
  get_document: { displayName: "Retrieved a document", name: "get_document" },
  read_email_thread: { displayName: "Read the email thread", name: "read_email_thread" },
  read_email_draft: { displayName: "Read email draft", name: "read_email_draft" },
}

function getActions(message: Message): AgentAction[] | undefined {
  if (message.tool_calls === undefined || message.tool_calls === null) {
    return undefined
  }

  const actions: AgentAction[] = []
  for (const toolCall of message.tool_calls) {
    const defaultAction = toolCallToAgentAction[toolCall.function.name]
    if (defaultAction) {
      actions.push(defaultAction)
    }
  }

  return actions
}

/**
 * This function transforms the input chat messages into a list of message suitable for display.
 * - combines assistant messages into a single message, which makes the chat display more compact.
 */
function getDisplayMessages(messages: Message[]): Message[] {
  const displayMessages: Message[] = []

  // standard for loops are generally the fastest way to iterate over an array
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i]
    if (!message) {
      continue
    }

    const lastDisplayMessage = displayMessages.at(-1)

    if (lastDisplayMessage?.role === "assistant" && message.role === "assistant") {
      const lastToolCalls = lastDisplayMessage.tool_calls ?? []
      const lastCitations = lastDisplayMessage.citations ?? []
      const lastRichText = lastDisplayMessage.rich_text ?? []

      const currentToolCalls = message.tool_calls ?? []
      const currentCitations = message.citations ?? []
      const currentRichText = message.rich_text ?? []

      // combine the assistant messages
      const combinedMessage: Message = {
        role: "assistant",
        content: `${lastDisplayMessage.content}\n${message.content}`.trim(),
        tool_calls: lastToolCalls.concat(currentToolCalls),
        citations: lastCitations.concat(currentCitations),
        rich_text: lastRichText.concat(currentRichText),
      }

      // replace the last display message with the combined message
      displayMessages[displayMessages.length - 1] = combinedMessage
      continue
    }

    // trivially push the user message
    displayMessages.push(message)
  }

  return displayMessages
}

export function ChatMessages({
  showAgentTelemetry,
  messages,
  status,
  isLoadingAnswer,
  defaultMessages,
  conversationId,
  userAuthor,
  bottomRef,
  getDocumentTagHref,
  getDocumentUrl,
  shareEnabled = false,
  onRegenerate,
}: {
  showAgentTelemetry: boolean
  messages: Message[]
  status: string
  isLoadingAnswer: boolean
  defaultMessages: Message[]
  conversationId: string
  userAuthor: ChatMessageAuthor
  bottomRef: React.RefObject<HTMLDivElement>
  getDocumentTagHref?: (documentId: string) => string
  getDocumentUrl?: (documentId: string) => Promise<string>
  shareEnabled?: boolean
  onRegenerate?: () => void
}) {
  const toast = useToast()
  const intl = useIntl()

  const containerRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (!containerRef.current) {
      return
    }
    // scroll instantly on page load
    containerRef.current.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: "instant",
    })
  }, [containerRef])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    // scroll smoothly when the user is already looking at the page
    // and new messages are added in
    containerRef.current.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: "smooth",
    })
  }, [messages, containerRef])

  return (
    <Box ref={containerRef} overflowY="auto" paddingY={50}>
      <Stack
        maxW="prose"
        mx="auto"
        paddingX={{ base: "1", md: "0" }}
        divider={
          <Box marginLeft="14!">
            <StackDivider />
          </Box>
        }
        spacing="5"
      >
        {getDisplayMessages(messages).map((m, i) => (
          <ChatMessage
            citations={m.citations ?? undefined}
            actions={getActions(m)}
            richText={(m.rich_text as Descendant[]) ?? undefined}
            status={i === messages.length - 1 && status !== "waiting" ? status : undefined}
            after={
              m !== defaultMessages?.[0] && (
                <HStack py={2}>
                  <IconButtonWithTooltip
                    placement="bottom"
                    label={intl.formatMessage({
                      id: "message.copy.tooltip",
                      description: "Tooltip for the copy button",
                      defaultMessage: "Copy",
                    })}
                    variant="ghost"
                    onClick={async () => {
                      await navigator.clipboard.writeText(m.content)
                      toast({
                        description: intl.formatMessage({
                          id: "conversation.copy.success",
                          description: "Message displayed when the user successfully copies the message",
                          defaultMessage: "Copied to clipboard",
                        }),
                        status: "success",
                      })
                    }}
                    icon={<Icon as={CopyIcon} />}
                    size="sm"
                  />
                  {shareEnabled && (
                    <IconButtonWithTooltip
                      variant="ghost"
                      onClick={async () => {
                        const url = new URL(`braim/${conversationId}`, window.location.origin)
                        await navigator.clipboard.writeText(url.toString())
                        toast({
                          description: intl.formatMessage({
                            id: "conversation.share.success",
                            description: "Message displayed when the user successfully copies the conversation link",
                            defaultMessage: "Link copied to clipboard",
                          }),
                          status: "success",
                        })
                      }}
                      placement="bottom"
                      label={intl.formatMessage({
                        id: "conversation.share.tooltip",
                        description: "Tooltip for the share button",
                        defaultMessage:
                          "Share conversation. Anyone in your organization with the link will be able to view this conversation",
                      })}
                      icon={<Icon as={ShareIcon} />}
                      size="sm"
                    />
                  )}
                  {onRegenerate && i === messages.length - 1 && m.role === "assistant" && (
                    <IconButtonWithTooltip
                      {...iconButtonStyle}
                      onClick={() => {
                        try {
                          onRegenerate()
                        } catch (_) {
                          toast(DEFAULT_ERROR_TOAST)
                        }
                      }}
                      icon={<Icon as={RenewalIcon} />}
                      label={intl.formatMessage({
                        id: "chat.regenerate",
                        description: "Aria label for the regenerate response button in the chat",
                        defaultMessage: "Try Again",
                      })}
                    />
                  )}
                </HStack>
              )
            }
            isLoading={i === messages.length - 1 && isLoadingAnswer}
            key={i}
            author={
              m.role === "user"
                ? userAuthor
                : {
                    name: "Braim",
                    renderedName: (
                      <Text display="inline" fontWeight="medium">
                        <BetsyDisplayName />
                      </Text>
                    ),
                    image: braimLogo,
                    role: m.role,
                  }
            }
            content={m.content}
            getDocumentTagHref={getDocumentTagHref}
            getDocumentUrl={getDocumentUrl}
          />
        ))}

        {showAgentTelemetry && (
          <ChatMessage
            author={{
              name: "Braim",
              role: "assistant",
              renderedName: (
                <Text display="inline" fontWeight="medium">
                  <BetsyDisplayName />
                </Text>
              ),
            }}
            content=""
            isAgentTelemetry={true}
            isLoading={false}
            getDocumentTagHref={getDocumentTagHref}
            getDocumentUrl={getDocumentUrl}
          />
        )}
      </Stack>
      <Box height="1px" ref={bottomRef} />
    </Box>
  )
}

export default Chat
