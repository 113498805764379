import { Box, Flex, HStack, Text } from "@chakra-ui/react"
import { FileTypeIcon } from "../../../components/Document/FileTypeIcon.js"
import { formatFileSize } from "../../../util/format.js"

// Simple interface with only the properties required for rendering
export interface Document {
  id: string
  file_name: string
  file_size: number
  mime_type: string
  download_url: string
}

interface DocumentAttachmentProps {
  document: Document
}

const DocumentAttachment = ({ document }: DocumentAttachmentProps) => {
  return (
    <Flex
      as="a"
      href={document.download_url}
      rel="noopener noreferrer"
      target="_blank"
      borderWidth="1px"
      borderRadius="xl"
      p={3}
      alignItems="center"
      justifyContent="space-between"
      bg="white"
      width="100%"
      gap={1}
      display="flex"
      cursor="pointer"
      _hover={{ bg: "gray.50" }}
      textDecoration="none"
    >
      <HStack spacing={3} flex={1} overflow="hidden">
        <FileTypeIcon mimeType={document.mime_type} boxSize={6} />
        <Box flex={1} minWidth={0} display="flex" flexDirection="column" gap={0.5}>
          <Text fontWeight="medium" fontSize="sm" noOfLines={1} title={document.file_name}>
            {document.file_name}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {formatFileSize(document.file_size)}
          </Text>
        </Box>
      </HStack>
    </Flex>
  )
}

export default DocumentAttachment
