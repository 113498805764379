import { hasAllPermissions, hasPermission } from "@brm/schema-helpers/role.js"
import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type {
  DocumentWithExtraction,
  FieldCategory,
  Permission,
  VendorDetails,
  VendorPatch,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { isString } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  useGetVendorV1ByIdQuery,
  usePutVendorV1ByIdFollowingMutation,
} from "../../app/services/generated-api.js"
import { useContextMenu } from "../../components/ContextMenu/context-menu.js"
import OwnerCell from "../../components/DataTable/CellRenderer/OwnerCell.js"
import { DividedRowSubHeader } from "../../components/DividedRowSubHeader.js"
import DocumentsPanel from "../../components/Document/DocumentsPanel.js"
import type { DynamicFormProps } from "../../components/DynamicForm/DynamicForm.js"
import { Link } from "../../components/Link.js"
import { LogoHeader } from "../../components/LogoHeader.js"
import { PaymentsPanel } from "../../components/Payments.js"
import SessionStorageSidebar from "../../components/SessionStorageSidebar.js"
import ShowHideTimelineButton from "../../components/ShowHideDrawerButton.js"
import TagGroup from "../../components/TagGroup.js"
import TimelineSidebarContent from "../../components/Timeline/TimelineSidebarContent.js"
import { logoHeaderBoxSize } from "../../components/constants.js"
import { VendorLogo } from "../../components/icons/Logo.js"
import {
  FlagIcon,
  LinkExternalIcon,
  MoreMenuIcon,
  NotificationsDisabledIcon,
  NotificationsEnabledIcon,
} from "../../components/icons/icons.js"
import NotificationIconButton from "../../components/icons/system/NotificationIconButton.js"
import { getDisplayUrl, getPublicImageGcsUrl } from "../../util/url.js"
import { useObjectPatchSchema } from "../../util/use-schema.js"
import { FlagVendorModal } from "./FlagVendorModal.js"
import { HideVendorModal } from "./HideVendorModal.js"
import VendorCategory from "./VendorCategory.js"
import VendorOverview from "./VendorOverview.js"
import { VENDOR_TIMELINE_HIDE_STORAGE_KEY, isVendorTab, vendorTabs, type VendorTab } from "./constants.js"
import VendorStatusBadge from "./status/VendorStatusBadge.js"
import { displayTransactionCategory } from "./vendor-list/util.js"

interface VendorDataTab {
  displayName: string
  permissions?: Permission[]
  panel: JSX.Element | null
}

export default function Vendor() {
  const { vendorId, tab } = useParams()
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps, menuButtonProps } =
    useContextMenu<HTMLDivElement>({
      betsyEnabled: true,
      objectType: "Vendor",
      id: vendorId,
    })
  isString(vendorId, "Missing ID")
  const shownTab: VendorTab = tab && isVendorTab(tab) ? tab : "overview"

  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()
  const flagDataModal = useDisclosure()
  const hideVendorModal = useDisclosure()

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: vendor, isError } = useGetVendorV1ByIdQuery({ id: vendorId })
  useEffect(() => {
    if (isError) {
      navigate("/")
    }
  }, [vendorId, isError, navigate])
  const [updateVendorFollowing, { isLoading: isFollowingSaving }] = usePutVendorV1ByIdFollowingMutation()

  const [document, setDocument] = useState<DocumentWithExtraction | undefined>()

  const followVendor = useCallback(
    async (vendor: VendorDetails) => {
      const newFollowState = !vendor.following
      try {
        await updateVendorFollowing({
          id: vendor.id,
          body: { following: newFollowState },
        })
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "vendor.notification.toggle.enabled",
                description: "Notification toggle enabled toast title",
                defaultMessage: "Vendor followed",
              })
            : intl.formatMessage({
                id: "vendor.notification.toggle.disabled",
                description: "Notification toggle disabled toast title",
                defaultMessage: "Vendor unfollowed",
              }),
          status: "success",
        })
      } catch (_err) {
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "vendor.notification.toggle.error.enabled",
                description: "Notification toggle enabled error toast title",
                defaultMessage: "Error following vendor",
              })
            : intl.formatMessage({
                id: "vendor.notification.toggle.error.disabled",
                description: "Notification toggle disabled error toast title",
                defaultMessage: "Error unfollowing vendor",
              }),
          status: "error",
        })
      }
    },
    [updateVendorFollowing, toast, intl]
  )

  const vendorPatchSchema = useObjectPatchSchema("Vendor")
  const formFieldsByCategory = useMemo(():
    | Map<FieldCategory | undefined, DynamicFormProps<VendorPatch>["formFields"]>
    | undefined => {
    if (!vendorPatchSchema) {
      return undefined
    }

    const formFieldsByCategory = new Map<FieldCategory | undefined, DynamicFormProps<VendorPatch>["formFields"]>()
    const categories = FieldCategorySchema.anyOf.map((category) => category.const)

    for (const category of categories) {
      const formFields = schemaToFormFields({ schema: vendorPatchSchema, objectType: "Vendor", category })
      const mappedFields = formFields.map((field) => ({
        ...field,
        path: field.is_custom ? ["custom", field.field_name] : [field.field_name],
        is_internal_only: false,
      }))
      formFieldsByCategory.set(category, Array.from(mappedFields))
    }

    const formFields = schemaToFormFields({
      schema: vendorPatchSchema,
      objectType: "Vendor",
      category: undefined,
    })
    const mappedFields = formFields.map((field) => ({
      ...field,
      path: field.is_custom ? ["custom", field.field_name] : [field.field_name],
      is_internal_only: false,
    }))
    formFieldsByCategory.set(undefined, Array.from(mappedFields))

    return formFieldsByCategory
  }, [vendorPatchSchema])

  const dataByTab: Record<VendorTab, VendorDataTab> = {
    overview: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.overview",
        description: "Vendor overview info tab",
        defaultMessage: "Overview",
      }),
      panel: vendor ? <VendorOverview vendor={vendor} /> : null,
    },
    general: {
      displayName: intl.formatMessage({
        id: "tool.tabs.general",
        description: "Tool view tab general",
        defaultMessage: "General",
      }),
      permissions: [],
      panel: vendor ? (
        <VendorCategory
          category={undefined}
          vendor={vendor}
          formFields={formFieldsByCategory?.get(undefined)}
          document={document}
          setDocument={setDocument}
        />
      ) : null,
    },
    compliance: {
      displayName: intl.formatMessage({
        id: "tool.tabs.compliance",
        description: "Tool view tab compliance",
        defaultMessage: "Compliance",
      }),
      permissions: ["compliance:update"],
      panel: vendor ? (
        <VendorCategory
          category="compliance"
          vendor={vendor}
          formFields={formFieldsByCategory?.get("compliance")}
          document={document}
          setDocument={setDocument}
        />
      ) : null,
    },
    it: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.it",
        description: "Vendor view tab it",
        defaultMessage: "IT",
      }),
      permissions: ["it:update"],
      panel: vendor ? (
        <VendorCategory
          category="it"
          vendor={vendor}
          formFields={formFieldsByCategory?.get("it")}
          document={document}
          setDocument={setDocument}
        />
      ) : null,
    },
    finance: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.finance",
        description: "Vendor view tab finance",
        defaultMessage: "Finance",
      }),
      permissions: ["finance:update"],
      panel: vendor ? (
        <VendorCategory
          category="finance"
          vendor={vendor}
          formFields={formFieldsByCategory?.get("finance")}
          document={document}
          setDocument={setDocument}
        />
      ) : null,
    },
    legal: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.legal",
        description: "Vendor view tab legal",
        defaultMessage: "Legal",
      }),
      permissions: ["legal:update"],
      panel: vendor ? (
        <VendorCategory
          category="legal"
          vendor={vendor}
          formFields={formFieldsByCategory?.get("legal")}
          document={document}
          setDocument={setDocument}
        />
      ) : null,
    },
    payments: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.payments",
        description: "Vendor view tab payments",
        defaultMessage: "Payments",
      }),
      panel: vendor ? <PaymentsPanel vendorId={vendor.id} /> : null,
      permissions: ["transaction:read"],
    },
    documents: {
      displayName: intl.formatMessage({
        id: "vendor.tabs.documents",
        description: "Vendor view tab documents",
        defaultMessage: "Documents",
      }),
      panel: vendor ? <DocumentsPanel vendorId={vendor.id} /> : null,
    },
  }

  const availableTabs =
    vendor && vendor.owner && vendor.owner.user_id === whoami?.id
      ? vendorTabs
      : vendorTabs.filter((tab) => {
          const permissions = dataByTab[tab].permissions
          switch (tab) {
            case "general": {
              const formFields = formFieldsByCategory?.get(undefined)
              if (!formFields || formFields.length === 0) {
                return false
              }
              break
            }
            case "compliance":
            case "it":
            case "finance":
            case "legal": {
              const formFields = formFieldsByCategory?.get(tab)
              if (!formFields || formFields.length === 0) {
                return false
              }
              break
            }
            case "documents":
            case "payments":
            case "overview":
              break
            default:
              unreachable(tab)
          }
          return permissions ? hasAllPermissions(whoami?.roles, permissions) : true
        })

  const tabIndex = availableTabs.indexOf(shownTab)

  useEffect(() => {
    // If user navigates to a tab that is not available via url, navigate back to the overview tab
    if (tabIndex === -1) {
      navigate("../")
    }
  }, [availableTabs, tabIndex, navigate])

  if (!vendor) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={2} flexGrow={1} minHeight={0} {...subjectProps.baseProps}>
      <LogoHeader
        logoElement={
          <VendorLogo logo={getPublicImageGcsUrl(vendor.image_asset?.gcs_file_name)} boxSize={logoHeaderBoxSize} />
        }
        heading={
          <HStack>
            <Heading size="md" display="flex" flexShrink={1} minW={0}>
              <Text as="span" isTruncated>
                {vendor.display_name}
              </Text>
            </Heading>
            <Flex flexShrink={0}>
              <VendorStatusBadge status={vendor.status} editVendorId={vendorId} />
            </Flex>
          </HStack>
        }
        rightActions={
          <>
            <Menu>
              <MenuButton as={IconButton} variant="outline" icon={<Icon as={MoreMenuIcon} />} />
              <MenuList>
                <MenuItem onClick={flagDataModal.onOpen}>
                  <FormattedMessage
                    id="vendor.report.bad.data"
                    description="Report bad data menu item"
                    defaultMessage="Report bad data"
                  />
                </MenuItem>
                {hasPermission(whoami?.roles, "vendor:hide") && (
                  <MenuItem onClick={hideVendorModal.onOpen}>
                    <FormattedMessage
                      id="vendor.hide"
                      description="Hide vendor menu item"
                      defaultMessage="Hide vendor"
                    />
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            <NotificationIconButton
              variant="outline"
              notificationEnabled={vendor.following}
              isDisabled={isFollowingSaving}
              onClick={() => followVendor(vendor)}
            />
            <ShowHideTimelineButton
              onOpen={() => setDocument(undefined)}
              hideStorageKey={VENDOR_TIMELINE_HIDE_STORAGE_KEY}
              size="md"
              expandLabel={intl.formatMessage({
                id: "timeline.show.tooltip",
                description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                defaultMessage: "Show Activity",
              })}
              collapseLabel={intl.formatMessage({
                id: "timeline.hide.tooltip",
                description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                defaultMessage: "Hide Activity",
              })}
            />
            {flagDataModal.isOpen && <FlagVendorModal {...flagDataModal} vendor={vendor} />}
            {hideVendorModal.isOpen && <HideVendorModal {...hideVendorModal} vendorId={vendor.id} />}
          </>
        }
      >
        <HStack justifyContent="space-between" flexGrow={1}>
          <Stack gap={4} flexGrow={1}>
            {vendor.description && (
              <Box width="70%">
                <Text>{vendor.description}</Text>
              </Box>
            )}
            <DividedRowSubHeader>
              <OwnerCell
                owner={vendor.owner}
                objectType="Vendor"
                objectId={vendor.id}
                showTooltip
                tooltipOpenDelay={0}
              />
              {vendor.vendor_categories && vendor.vendor_categories.length > 0 && (
                <Flex gap={1}>
                  <TagGroup
                    displayNames={vendor.vendor_categories.map((c) => displayTransactionCategory(c, intl))}
                    limit={1}
                  />
                </Flex>
              )}
              {vendor.website ? (
                <Link variant="highlighted" to={vendor.website}>
                  <HStack gap={1}>
                    <Box flexGrow={1} maxWidth={250} overflow="hidden">
                      <Text noOfLines={1}>{getDisplayUrl(new URL(vendor.website))}</Text>
                    </Box>
                    <Icon as={LinkExternalIcon} />
                  </HStack>
                </Link>
              ) : null}
            </DividedRowSubHeader>
          </Stack>
        </HStack>
      </LogoHeader>

      <Flex flex={1} alignItems="start" minHeight={0}>
        <Tabs
          isLazy
          isFitted
          variant="line"
          display="flex"
          flexDir="column"
          index={tabIndex}
          pt={1}
          flex={1}
          minHeight={0}
          height="100%"
          overflowY="auto"
        >
          <Grid
            height="100%"
            width="100%"
            templateColumns="[tabs] minmax(0, 1fr) [activity] auto"
            templateRows="[title] auto [content] minmax(0, 1fr)"
            columnGap={0}
            rowGap={0}
          >
            <GridItem zIndex={1}>
              <TabList>
                {availableTabs.map((tab) => (
                  <Tab key={tab} as={Link} to={`../${tab}`} relative="path" onClick={() => setDocument(undefined)}>
                    {dataByTab[tab].displayName}
                  </Tab>
                ))}
              </TabList>
            </GridItem>
            <GridItem zIndex={0} borderBottomWidth="1px" />
            <GridItem>
              <TabPanels minW={600} height="100%" overflow="auto">
                {availableTabs.map((tab) => (
                  <TabPanel key={tab} padding={0} height="100%">
                    {dataByTab[tab].panel}
                  </TabPanel>
                ))}
              </TabPanels>
            </GridItem>
            <GridItem>
              <SessionStorageSidebar hideStorageKey={VENDOR_TIMELINE_HIDE_STORAGE_KEY} width="450px">
                <TimelineSidebarContent
                  timelineProps={{
                    filterParams: { vendorId: vendor.id },
                  }}
                />
              </SessionStorageSidebar>
            </GridItem>
          </Grid>
        </Tabs>
      </Flex>
      <Portal>
        <Menu {...menuProps}>
          <MenuButton {...menuButtonProps} />
          <MenuList {...menuListProps}>
            {menuListProps.children}
            <MenuItem
              {...menuItemProps}
              onClick={() => followVendor(vendor)}
              icon={<Icon as={vendor.following ? NotificationsEnabledIcon : NotificationsDisabledIcon} />}
            >
              {vendor.following ? (
                <FormattedMessage
                  defaultMessage="Unfollow vendor"
                  description="Label for the menu item vendor unfollow button"
                  id="context-menu.item.unfollow-vendor"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Follow vendor"
                  description="Label for the menu item vendor unfollow button"
                  id="context-menu.item.follow-vendor"
                />
              )}
            </MenuItem>
            <MenuItem {...menuItemProps} onClick={flagDataModal.onOpen} icon={<Icon as={FlagIcon} color="red.700" />}>
              <FormattedMessage
                id="context-menu.item.report-bad-data"
                description="Report bad data context menu item"
                defaultMessage="Report bad data"
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
    </Flex>
  )
}
