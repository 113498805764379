import { type ColumnPinningState } from "@tanstack/react-table"

import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { negotiationRouteById } from "@brm/util/routes.js"
import { pickFieldsFromSchema } from "@brm/util/schema.js"
import { Center, Flex, Spinner } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query/react"
import { useCallback, useEffect, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  usePostNegotiationV1ListQuery,
  type NegotiationListItem,
  type SavedView,
  type TableIdentifier,
} from "../../app/services/generated-api.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  type TableParamsState,
} from "../../util/schema-table.js"
import { getTabIndexFromLocation, type FilterTabData } from "../../util/tabs.js"
import { useObjectSchema } from "../../util/use-schema.js"
import DataTable from "../DataTable/DataTable.js"
import FullDataTableHeader from "../DataTable/FullDataTableHeader.js"
import TablePageHeader from "../DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../DataTable/SchemaTableColumnCustomization.js"
import { useSchemaColumns } from "../DataTable/use-schema-columns.js"
import { useLocalStorageTableParamsSync, useUrlTableParams } from "../DataTable/use-schema-table-params.js"

const defaultColumns: BRMPaths<NegotiationListItem>[] = [
  "display_name",
  "user",
  "status",
  "vendor",
  "tool",
  "input_legal_agreement.decision_date",
  "input_legal_agreement.total_contract_value",
  "created_at",
  "last_activity_at",
]

const NEGOTIATION_TABLE_DEFAULT_PARAMS = {
  sorting: [{ id: "created_at", desc: true }],
} satisfies Partial<TableParamsState<string>>

const liteColumns: BRMPaths<NegotiationListItem>[] = [
  "display_name",
  "user",
  "status",
  "input_legal_agreement.decision_date",
  "input_legal_agreement.total_contract_value",
  "created_at",
  "last_activity_at",
]

const NEGOTIATION_TABLE_ID: TableIdentifier = "negotiations"

const primaryColumn = "display_name" satisfies BRMPaths<NegotiationListItem>
const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}
const savedViews: SavedView[] = []

export const NegotiationList = () => {
  const intl = useIntl()
  const location = useLocation()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const navigate = useNavigate()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization?.is_lite
  const fullNegotiationSchema = useObjectSchema("Negotiation")
  const negotiationSchema = useMemo(() => {
    if (!fullNegotiationSchema) {
      return undefined
    }

    if (!isLiteOrganization) {
      return fullNegotiationSchema
    }
    // this joined list of available columns causes a complex type error so we have to cast to string[]
    return pickFieldsFromSchema(fullNegotiationSchema, [
      primaryColumn,
      ...(defaultColumns as string[]),
      ...(liteColumns as string[]),
    ])
  }, [fullNegotiationSchema, isLiteOrganization])

  useLocalStorageTableParamsSync(NEGOTIATION_TABLE_ID)

  const { tableParams, updateTableParams, getUpdatedSerializedParams } = useUrlTableParams<string>({
    defaultParams: NEGOTIATION_TABLE_DEFAULT_PARAMS,
    objectSchema: negotiationSchema,
    primarySearchColumn: primaryColumn,
    savedViews,
  })

  const apiParams = useMemo(
    () => tableParams && negotiationSchema && packageSortFilterOptionsForAPI(tableParams, negotiationSchema, intl),
    [intl, tableParams, negotiationSchema]
  )

  const filterTabs = useMemo(
    () =>
      [
        {
          locationHash: "active",
          label: (
            <FormattedMessage
              id="negotiations.list.page.tab.active"
              description="Tab label to show active negotiations on the negotiations list page"
              defaultMessage="Active negotiations"
            />
          ),
          filters: [
            [
              {
                column: "status",
                fields: { comparator: "any", includeNull: false, values: ["active"] },
              },
            ],
          ],
        },
        {
          locationHash: "mine",
          label: (
            <FormattedMessage
              id="negotiations.list.page.tab.mine"
              description="Tab label to show active negotiations on the negotiations list page"
              defaultMessage="My negotiations"
            />
          ),
          filters: whoami && [
            [
              {
                column: "user.id",
                fields: { comparator: "eq", value: whoami.id },
              },
            ],
          ],
        },
        {
          locationHash: "all",
          label: (
            <FormattedMessage
              id="negotiations.list.page.tab.all"
              description="Tab label to show all negotiations on the negotiations list page"
              defaultMessage="All negotiations"
            />
          ),
          filters: undefined,
        },
      ] satisfies FilterTabData[],
    [whoami]
  )

  const tabIndex = filterTabs && getTabIndexFromLocation(location, filterTabs)
  const allNegotiationsResult = usePostNegotiationV1ListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken
  )

  const myNegotiationsResult = usePostNegotiationV1ListQuery(
    apiParams
      ? { listQueryStringParams: { ...apiParams, filter: [...apiParams.filter, ...(filterTabs[1]?.filters ?? [])] } }
      : skipToken
  )

  const activeNegotiationsResult = usePostNegotiationV1ListQuery(
    apiParams
      ? { listQueryStringParams: { ...apiParams, filter: [...apiParams.filter, ...(filterTabs[0]?.filters ?? [])] } }
      : skipToken
  )

  const tabResult = [activeNegotiationsResult, myNegotiationsResult, allNegotiationsResult]

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<NegotiationListItem>({
    objectSchema: negotiationSchema,
    shownColumns,
    primaryColumn,
  })

  const handleChangeTab = useCallback(
    (index: number) => {
      const newTab = filterTabs[index]
      isNotUndefined(newTab)
      if (newTab.locationHash) {
        hashParams.set("tab", newTab.locationHash)
      } else {
        hashParams.delete("tab")
      }
      navigate(
        {
          search: getUpdatedSerializedParams?.({ page: 1 }).toString(),
          hash: `#${hashParams}`,
        },
        { replace: true }
      )
    },
    [filterTabs, getUpdatedSerializedParams, hashParams, navigate]
  )

  useEffect(() => {
    if (activeNegotiationsResult.data?.total === 0 && tabIndex !== 1) {
      handleChangeTab(1)
    }
  }, [handleChangeTab, activeNegotiationsResult.data?.total, tabIndex])

  if (tabIndex === undefined || !tableParams || !negotiationSchema || !shownColumns || !columns) {
    return null
  }

  if (tabResult[tabIndex]?.isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  const negotiations = tabResult[tabIndex]?.data
  if (!negotiations) {
    return null
  }

  if (tabResult[tabIndex]?.isError) {
    throw tabResult[tabIndex]?.error
  }

  return (
    <Flex justifyContent="center" flexDir="column" flexGrow={1} minHeight={0}>
      <FullDataTableHeader
        displayTitle={intl.formatMessage({
          id: "negotiations.title",
          description: "title of the negotiations page",
          defaultMessage: "Negotiations",
        })}
      >
        <TablePageHeader
          tableId={NEGOTIATION_TABLE_ID}
          primarySearch={{
            column: primaryColumn,
            currentPrimaryFilter: tableParams.primaryFilter?.fields,
            placeholder: intl.formatMessage({
              id: "negotiationList.searchPlaceholder",
              description: "placeholder text for the negotiation search input",
              defaultMessage: "Search Negotiation",
            }),
            onPrimaryFilterChange: (fields) =>
              updateTableParams({ primaryFilter: fields && { column: primaryColumn, fields } }),
          }}
          tabProps={{
            data: filterTabs.map((tab, index) => {
              const total = tabResult[index]?.data?.total ?? 0
              return {
                ...tab,
                total,
                isDisabled: total === 0,
              }
            }),
            handleChangeTab,
            selectedIndex: tabIndex,
          }}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
          objectSchema={negotiationSchema}
          selectedColumns={shownColumns}
          afterSavedView={
            <SchemaTableColumnCustomization
              activeColumns={shownColumns}
              primaryColumn={primaryColumn}
              onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
              objectSchema={negotiationSchema}
            />
          }
        />
      </FullDataTableHeader>
      <DataTable<NegotiationListItem>
        data={negotiations.items}
        columns={columns}
        columnPinning={columnPinning}
        columnOrder={[primaryColumn, ...shownColumns]}
        sorting={tableParams.sorting}
        onSortingChange={(newSorting) =>
          newSorting instanceof Function
            ? updateTableParams({ sorting: newSorting(tableParams.sorting) })
            : updateTableParams({ sorting: newSorting })
        }
        paginationProps={{
          page: tableParams.page,
          pageSize: tableParams.pageSize,
          onPageChange: (page) => updateTableParams({ page }),
          onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
          totalListElements: negotiations.total,
        }}
        isSticky
        onRowClick={(row) => {
          navigate(negotiationRouteById(row.id))
        }}
      />
    </Flex>
  )
}

export default NegotiationList
