import { Center, Spinner, chakra, type StyleProps } from "@chakra-ui/react"
import { memo, useRef, useState, type IframeHTMLAttributes } from "react"

const Iframe = memo(function Iframe(
  props: Omit<IframeHTMLAttributes<HTMLIFrameElement>, "children"> &
    StyleProps & {
      children?: React.ReactNode
      id: string
      // So we can conditionally render the iframe without unmounting.
      // Unmounting is expensive since we need to refetch the iframe src
      visible?: boolean
    }
) {
  const { children, id, visible, ...rest } = props
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [height, setHeight] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      {isLoading && visible && (
        <Center pb={4}>
          <Spinner />
        </Center>
      )}
      <chakra.iframe
        {...rest}
        visibility={visible ? "visible" : "hidden"}
        ref={iframeRef}
        width="100%"
        height={visible && !isLoading ? (height ? `calc(${height}px + 16px)` : "100%") : 0}
        sandbox="allow-scripts allow-popups-to-escape-sandbox allow-popups"
        onLoad={() => {
          setIsLoading(false)
          const iframe = iframeRef.current
          if (iframe && iframe.contentWindow) {
            window.addEventListener("message", (event) => {
              if (event.data.type === "resize" && event.data.id === id) {
                setHeight(event.data.height)
              }
            })
          }
        }}
      >
        {children}
      </chakra.iframe>
    </>
  )
})

export default Iframe
