import type {
  DocumentBackgroundExtractionResponse,
  DocumentMinimal,
  LegalAgreementWithMinimalRelations,
} from "@brm/schema-types/types.js"
import { useToast } from "@chakra-ui/react"
import { forwardRef } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { To } from "react-router-dom"
import {
  usePostDocumentV1ByIdBackgroundExtractMutation,
  type DocumentBackgroundExtractionRequest,
} from "../../app/services/generated-api.js"
import type { DocumentChangeHandler, DocumentUploadProps } from "./DocumentUpload.js"
import { DocumentUpload } from "./DocumentUpload.js"

type Props = Omit<DocumentUploadProps, "onChange"> & {
  onDocumentChange: DocumentChangeHandler
  onExtractionEnqueued?: (extractionEnqueued: DocumentBackgroundExtractionResponse) => void
  fieldName?: string
  legalAgreement?: Pick<LegalAgreementWithMinimalRelations, "id" | "vendor" | "tools">
  extractionRequest?: DocumentBackgroundExtractionRequest
  /** Gets where to navigate to when the document is clicked on */
  getLinkDestination?: (document: DocumentMinimal) => To
  selectedDocument?: Pick<DocumentMinimal, "id">
}

const ExtractLegalDocumentUpload = forwardRef<HTMLButtonElement, Props>(function ExtractLegalDocumentUpload(
  { onDocumentChange, onExtractionEnqueued, legalAgreement, extractionRequest, ...props }: Props,
  ref
) {
  const toast = useToast()
  const intl = useIntl()

  const [initializeExtractionById] = usePostDocumentV1ByIdBackgroundExtractMutation()

  return (
    <DocumentUpload
      {...props}
      legalAgreement={legalAgreement}
      value={props.value}
      ref={ref}
      onChange={async ({ documents, type, document }) => {
        onDocumentChange({ documents, type, document })
        if (type === "add" && extractionRequest) {
          try {
            const extractionResult = await initializeExtractionById({
              id: document.id,
              documentBackgroundExtractionRequest: extractionRequest,
            }).unwrap()
            onExtractionEnqueued?.(extractionResult)
            toast({
              description: intl.formatMessage({
                id: "document.extraction.legal.initialize.success",
                description: "Toast success title when initializing legal extraction succeeds",
                defaultMessage: "Document uploaded successfully. Starting the extraction for relevant fields.",
              }),
              status: "success",
            })
          } catch (_) {
            toast({
              status: "warning",
              description: (
                <FormattedMessage
                  defaultMessage="Could not initialize extraction for the document {fileName}."
                  description="The toast description for an error when initializing legal extraction fails"
                  id="document.extraction.legal.initialize.error"
                  values={{ fileName: <code>{document.file_name}</code> }}
                />
              ),
            })
          }
        }
      }}
    />
  )
})

export default ExtractLegalDocumentUpload
