import { useContext } from "react"
import { ChatContext, type ChatContextType } from "./chat-context.js"

// Custom hook to use the context
export function useChatContext(): ChatContextType {
  const context = useContext(ChatContext)
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatContextProvider")
  }
  return context
}

// Create a safe version for components that might be used outside the context
export function useSafeChatContext(): ChatContextType {
  try {
    return useChatContext()
  } catch (_) {
    // Return no-op functions when used outside the provider
    return {
      focusedContexts: [],
      getFocusedContexts: () => [],
      chatValue: [],
      setChatValue: () => {},
      insertEmailContext: () => {},
      insertDocumentContext: () => {},
      insertText: () => {},
      insertPlaybookSectionContext: () => {},
      removeContext: () => {},
    }
  }
}
