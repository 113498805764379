import type {
  AgentPicker,
  ObjectType,
  StandardObjectElement,
  UserPicker,
  WorkflowBuyerPicker,
  WorkflowSellerPicker,
} from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Collapse,
  Flex,
  Portal,
  chakra,
  useBoolean,
  useMultiStyleConfig,
  type SystemStyleObject,
  type Theme,
} from "@chakra-ui/react"
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type KeyboardEventHandler,
} from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useIntl } from "react-intl"
import { Editor, Range, Transforms, createEditor } from "slate"
import { withHistory } from "slate-history"
import { Editable, ReactEditor, Slate, withReact, type RenderElementProps, type RenderLeafProps } from "slate-react"
import type { EditableProps } from "slate-react/dist/components/editable.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { Truncate } from "../Truncate.js"
import { RichTextEditorToolbar } from "./EditorToolbar.js"
import { Element } from "./Element.js"
import { Leaf } from "./Leaf.js"
import RichTextError from "./RichTextError.js"
import TagPickerList, { type TagPickerListRef } from "./TagPickerList.js"
import type { RichTextEditorProps } from "./types.js"
import { insertAgentTag, withAgentTags } from "./util/agent-tag.js"
import { EMPTY_RICH_TEXT_BODY, moveToNextNode } from "./util/common.js"
import { insertFieldTag, withFieldTags } from "./util/field-tag.js"
import { useFileUpload, withFiles } from "./util/file.js"
import { isLinkAtCursorEnd, withInlines } from "./util/inline.js"
import { withShortcuts } from "./util/markdown.js"
import { insertMention, withMentions } from "./util/mentions.js"
import { HOTKEYS, toggleMark } from "./util/rich-text.js"
import { insertStandardObjectTag, withStandardObjectTags } from "./util/standard-object.js"

const RichTextEditorWithErrorBoundary = React.memo(
  forwardRef<{ focus: () => void }, RichTextEditorProps & GetLogoForOrganizationProps>(
    function RichTextEditorWithErrorBoundary(props, ref) {
      return (
        <ErrorBoundary fallbackRender={() => <RichTextError />}>
          <RichTextEditor {...props} ref={ref} />
        </ErrorBoundary>
      )
    }
  )
)

/**
 * RichTextEditor is a slate-based input component that allows for rich text editing with markdown shortcuts and mentions.
 * isReadOnly: If true, the editor is read-only and does not allow for editing but is still rendered as a tab-focusable input.
 * Focus and error styles are borrow from the chakra-ui Textarea component theme.
 *
 * If you want to display rich text content without the input styles and editing capabilities, use the RichTextDisplay component.
 *
 * If you make a change to this editor ensure the following features still work
 * - [ ] drag and drop multiple valid files
 * - [ ] attach multiple valid files via the attachment button
 * - [ ] copy and paste multiple valid files
 * - [ ] mention people, agents, and standard objects
 * - [ ] inline links
 * - [ ] markdown shortcuts
 * - [ ] text formatting operations work as expected
 */
const RichTextEditor = React.memo(
  forwardRef<{ focus: () => void }, RichTextEditorProps & GetLogoForOrganizationProps>(
    (
      {
        initialValue,
        onChange,
        onDocument,
        placeholder,
        isReadOnly,
        isPrivate,
        hasError,
        containerProps,
        disableMentions,
        additionalToolbarButtons,
        pickableEntityFilters,
        getLogoToShowByOrganizationId,
        getDocumentUrl,
        getDocumentTagHref,
        forceFocus,
        disableFileDrop,
        disableTextEditingToolbar,
        ...editableProps
      },
      ref
    ) => {
      const intl = useIntl()

      const [isDragging, setIsDragging] = useBoolean(false)
      const [isFocusedState, setIsFocusedState] = useState(false)
      const isFocused = forceFocus || isFocusedState

      const [editor] = useState(() => {
        const editor = createEditor()
        withReact(editor)
        withShortcuts(editor)
        withInlines(editor)
        withStandardObjectTags(editor)
        withMentions(editor)
        withFieldTags(editor)
        withAgentTags(editor)
        withHistory(editor)
        return editor
      })

      const onImageOrDocumentUpload = useFileUpload({ editor, onDocument })

      useMemo(() => {
        withFiles(editor, onImageOrDocumentUpload)
        // we only want to run this once
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [editor])

      /** Mention state */
      const [mentionRange, setMentionRange] = useState<Range | null>(null)
      const mentionSearch = mentionRange ? Editor.string(editor, mentionRange).substring(1) : ""
      const mentionOptionsRef = useRef<TagPickerListRef | null>(null)

      const insertMentionElement = useCallback(
        (selectedMentionOption: UserPicker | WorkflowBuyerPicker | WorkflowSellerPicker) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertMention(
              editor,
              selectedMentionOption.id,
              displayPersonName(selectedMentionOption, intl),
              selectedMentionOption.organization_id
            )
            setMentionRange(null)
            editor.onChange()
          }
        },
        [editor, intl, mentionRange]
      )

      const insertAgentTagElement = useCallback(
        (selectedAgentOption: AgentPicker) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertAgentTag(editor, selectedAgentOption)
          }
          setMentionRange(null)
          editor.onChange()
        },
        [editor, mentionRange]
      )

      const insertFieldTagElement = useCallback(
        (fieldName: string, objectType: ObjectType, isCustom: boolean) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertFieldTag(editor, fieldName, objectType, isCustom)
          }
          setMentionRange(null)
          editor.onChange()
        },
        [editor, mentionRange]
      )

      const insertStandardObjectElement = useCallback(
        (objectType: StandardObjectElement["object_type"], id: string, display_name: string, website?: string) => {
          if (mentionRange) {
            Transforms.select(editor, mentionRange)
            insertStandardObjectTag(editor, objectType, display_name, id, website)
          }
          setMentionRange(null)
          editor.onChange()
        },
        [editor, mentionRange]
      )

      const richTextContainerRef = useRef<HTMLDivElement>(null)
      useImperativeHandle(ref, () => ({
        focus: () => {
          richTextContainerRef.current?.scrollIntoView({ block: "center" })
          ReactEditor.focus(editor)
          setTimeout(() => {
            Transforms.select(editor, Editor.end(editor, []))
          }, 10)
        },
      }))

      const decorate = useCallback<NonNullable<EditableProps["decorate"]>>(
        ([_, path]) => {
          if (mentionRange) {
            const intersection = Range.intersection(mentionRange, Editor.range(editor, path))

            if (intersection === null) {
              return []
            }

            const range = {
              highlighted: true,
              ...intersection,
            }

            return [range]
          }
          return []
        },
        [editor, mentionRange]
      )

      const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
      const renderElement = useCallback(
        (props: RenderElementProps) => (
          <Element
            {...props}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            isReadOnly={isReadOnly}
            getDocumentUrl={getDocumentUrl}
            getDocumentTagHref={getDocumentTagHref}
          />
        ),
        [getLogoToShowByOrganizationId, isReadOnly, getDocumentUrl, getDocumentTagHref]
      )

      const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
        (event) => {
          if (event.key === "ArrowRight") {
            if (isLinkAtCursorEnd(editor)) {
              event.preventDefault()
              moveToNextNode(editor)
              return
            }
          }

          if (event.code === "Space") {
            if (isLinkAtCursorEnd(editor)) {
              moveToNextNode(editor)
              return
            }
          }

          // Meta key hotkeys for applying marks to the current selection
          if (event.key && (event.metaKey || event.ctrlKey)) {
            const mark = HOTKEYS[event.key]
            if (mark) {
              event.preventDefault()
              toggleMark(editor, mark)
            }
            return
          }

          if (event.key === "@" && !disableMentions) {
            if (!mentionRange && editor.selection && Range.isCollapsed(editor.selection)) {
              const characterBeforePoint = Editor.before(editor, editor.selection.anchor, { unit: "character" })
              const characterBeforeRange =
                characterBeforePoint && Editor.range(editor, characterBeforePoint, editor.selection.anchor)
              const characterBefore = characterBeforeRange && Editor.string(editor, characterBeforeRange)
              // If the user types @ and the user selection is collapsed, set the current position as the mention range
              if (!characterBefore || /\s/u.test(characterBefore)) {
                const newRange = Editor.range(
                  editor,
                  editor.selection.anchor,
                  Editor.after(editor, editor.selection.anchor, { unit: "character" })
                )
                setMentionRange(newRange)
              }
            }
            return
          }

          if (mentionRange) {
            // Arrow key navigation and selection of mention options
            switch (event.key) {
              case "ArrowDown": {
                event.preventDefault()
                mentionOptionsRef.current?.incrementIndex()
                break
              }
              case "ArrowUp": {
                event.preventDefault()
                mentionOptionsRef.current?.decrementIndex()
                break
              }
              case "Tab":
              case "Enter": {
                event.preventDefault()
                mentionOptionsRef.current?.selectCurrent()
                setMentionRange(null)
                break
              }
              case "Escape": {
                setMentionRange(null)
                break
              }
            }
          }

          // enter + shift creates a new line
          if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault()
            event.stopPropagation()
            Editor.insertText(editor, "\n")
            return
          }
        },
        [editor, setMentionRange, mentionRange, disableMentions]
      )

      const textAreaStyleConfig = useMultiStyleConfig("Textarea", {
        size: "sm",
      }) as NonNullable<Theme["components"]["Textarea"]["baseStyle"]>

      // Sync the content of the rich text editor when the data changes
      useEffect(() => {
        // Skip if there's no editor instance
        // Also skip if there is no initial value. If the caller wants to clear the editor, they should pass EMPTY_RICH_TEXT_BODY as initial value
        if (!editor || !initialValue) return

        // Compare current editor value with initialValue
        const currentValue = editor.children
        const isValueDifferent = JSON.stringify(currentValue) !== JSON.stringify(initialValue)

        if (isValueDifferent) {
          // Remove each node individually from the editor by iterating over the top-level nodes
          const { children } = editor
          for (let i = children.length - 1; i >= 0; i--) {
            Transforms.removeNodes(editor, { at: [i] })
          }

          // Insert the new nodes at the root level
          Transforms.insertNodes(editor, initialValue)
          Transforms.move(editor)
        }
      }, [initialValue, editor])

      const editorContent = (
        <Slate
          editor={editor}
          initialValue={initialValue ?? EMPTY_RICH_TEXT_BODY}
          onSelectionChange={(newSelection) => {
            if (!mentionRange) {
              return
            }
            if (!newSelection) {
              setMentionRange(null)
              return
            }
            const intersecting = Range.intersection(newSelection, mentionRange)
            if (!intersecting) {
              setMentionRange(null)
            }
          }}
          onValueChange={(value) => {
            onChange?.(value)
            // Check if the mention range should change
            if (mentionRange) {
              if (editor.selection && Range.isCollapsed(editor.selection)) {
                const cursorPoint = editor.selection.anchor
                if (cursorPoint) {
                  // The potential new mention range is the current mention range start up to the cursor
                  const newRange = Editor.range(editor, mentionRange.anchor, cursorPoint)
                  const mentionText = Editor.string(editor, newRange)
                  if (mentionText.startsWith("@")) {
                    setMentionRange(newRange)
                  } else {
                    // If the @ has been deleted, clear the mention range
                    setMentionRange(null)
                  }
                }
              }
            }
          }}
        >
          <Editable
            decorate={decorate}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            renderPlaceholder={({ children, attributes }) => (
              <chakra.span
                {...attributes}
                style={{
                  ...attributes.style,
                  color: "var(--chakra-colors-chakra-placeholder-color)",
                  opacity: 1,
                  // IMPORTANT: needs to match the padding of the Editable style below
                  top: "var(--chakra-space-2)",
                }}
              >
                {children}
              </chakra.span>
            )}
            style={{
              outline: "none",
              boxShadow: "none",
              background: !isReadOnly && isDragging ? "var(--chakra-colors-brand-50)" : "inherit",
              // IMPORTANT: needs to match the top of the renderPlaceholder style above
              padding: "var(--chakra-space-2)",
              borderRadius: "var(--input-border-radius)",
            }}
            spellCheck
            onContextMenu={(e) => {
              e.stopPropagation()
            }}
            onFocus={() => {
              setIsFocusedState(true)
              ReactEditor.focus(editor)
            }}
            onBlur={(event) => {
              // If an input is in a popover, prevent popover from closing when clicking on mention options
              if (event.relatedTarget?.matches(".mention-option")) {
                event.stopPropagation()
              } else {
                setMentionRange(null)
                setIsFocusedState(false)
                ReactEditor.blur(editor)
              }
            }}
            tabIndex={0}
            onKeyDown={onKeyDown}
            onDragOver={setIsDragging.on}
            onDragLeave={setIsDragging.off}
            onDrop={async (event) => {
              event.preventDefault()
              setIsDragging.off()
              if (disableFileDrop || isReadOnly) {
                return
              }
              for (const file of event.dataTransfer.files) {
                await onImageOrDocumentUpload?.(file)
              }
              // Focus the editor after the files have been uploaded
              setTimeout(() => {
                ReactEditor.focus(editor)
              }, 50)
            }}
            onPaste={(event) => {
              if (
                isReadOnly ||
                // If file upload is disabled, prevent pasting in files, but allow pasting in text
                (disableFileDrop && event.clipboardData?.files.length > 0)
              ) {
                event.preventDefault()
                event.stopPropagation()
                return
              }
            }}
            readOnly={isReadOnly}
            aria-readonly={isReadOnly}
            {...editableProps}
          />

          <Collapse in={!isReadOnly && isFocused} animateOpacity>
            <Flex padding={1} gap={1} alignItems="center" onMouseDown={(event) => event.preventDefault()}>
              {!disableTextEditingToolbar && <RichTextEditorToolbar />}
              {additionalToolbarButtons}
            </Flex>
          </Collapse>
          {mentionRange && (
            <Portal>
              <TagPickerList
                ref={mentionOptionsRef}
                mentionSearch={mentionSearch}
                mentionRange={mentionRange}
                resetMentionRange={() => setMentionRange(null)}
                insertMentionElement={insertMentionElement}
                insertAgentTagElement={insertAgentTagElement}
                insertFieldTagElement={insertFieldTagElement}
                insertStandardObjectElement={insertStandardObjectElement}
                getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                pickableEntityFilters={pickableEntityFilters}
              />
            </Portal>
          )}
        </Slate>
      )

      const boxStyles: SystemStyleObject = {
        ...textAreaStyleConfig,
        ...(isReadOnly
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (textAreaStyleConfig as any)._readOnly
          : hasError
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (textAreaStyleConfig as any)._invalid
            : {}),
        "&:focus, &:focus-within": {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(textAreaStyleConfig as any)["&:focus, &:focus-within"],
          ...(isReadOnly
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (textAreaStyleConfig as any)._readOnly._focus
            : hasError
              ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (textAreaStyleConfig as any)._invalid
              : {}),
        },
        // Textarea styles have some intrinsic height we will override
        minHeight: undefined,
        height: undefined,
        // We set the padding on the box conditionally below. ignore the textarea style
        paddingY: undefined,
        p: 0,
      }

      return (
        <Flex
          flexDir="column"
          ref={richTextContainerRef}
          position="relative"
          // Explicitly checking private since undefined should have a different background from false
          background={isPrivate === false ? "blue.50" : "white"}
          // https://stackoverflow.com/questions/34354085/clicking-outside-a-contenteditable-div-stills-give-focus-to-it
          // Prevents issue where clicking outside the editor still gives focus to it
          display="inline-block"
          sx={boxStyles}
          {...containerProps}
        >
          {isReadOnly ? <Truncate noOfLines={5}>{editorContent}</Truncate> : editorContent}
        </Flex>
      )
    }
  )
)

export default RichTextEditorWithErrorBoundary
