import { Button, FormLabel, Radio, Stack, Text } from "@chakra-ui/react"

const RoleRadioOption = <T extends string>({
  isChecked,
  onChange,
  valueForRole,
  label,
  details,
}: {
  isChecked: boolean
  onChange: (newValue: T) => void
  valueForRole: T
  label: string
  details: string
}) => {
  return (
    <Button
      as={FormLabel}
      variant="outline"
      htmlFor={valueForRole}
      requiredIndicator={null}
      py={2}
      px={4}
      gap={2}
      height="auto"
      justifyContent="flex-start"
      cursor="pointer"
    >
      <Radio
        id={valueForRole}
        value={valueForRole}
        isChecked={isChecked}
        onChange={(e) => {
          if (!e.target.checked) {
            return
          }
          onChange(e.target.value as T)
        }}
      />
      <Stack spacing={0} ml={2}>
        <Text fontWeight="bold">{label}</Text>
        <Text fontWeight="light">{details}</Text>
      </Stack>
    </Button>
  )
}

export default RoleRadioOption
