import type { FieldSourceOutputProperties } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Button, Flex, Icon, Tooltip, chakra } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { InfoIcon, SearchIcon } from "../icons/icons.js"
import { Timestamp } from "../Timestamp.js"
import { getDerivedSourceDisplayName } from "./utils.js"

export const FieldSourceFooter = ({
  fieldSource,
  onClick,
}: {
  fieldSource: FieldSourceOutputProperties
  onClick?: () => void
}) => {
  const intl = useIntl()
  switch (fieldSource.type) {
    case "user": {
      if (!fieldSource.source_display_name) {
        return null
      }
      if (fieldSource.assigned_by_metadata && onClick) {
        const sourceDisplayName = fieldSource.assigned_by_metadata.object_field_source.source_display_name
        const source = (
          <Button
            colorScheme="brand"
            onClick={onClick}
            variant="link"
            fontWeight="normal"
            display="inline"
            textAlign="left"
            whiteSpace="nowrap"
            flex={1}
            rightIcon={<Icon as={SearchIcon} />}
            iconSpacing={1}
            justifyContent="start"
            width="full"
            textOverflow="ellipsis"
            isTruncated
            alignItems="center"
          >
            {sourceDisplayName}
          </Button>
        )
        return fieldSource.updated_at ? (
          <Flex gap={1} alignItems="baseline" whiteSpace="nowrap">
            <FormattedMessage
              defaultMessage="Assigned {timestamp} by {user} from {source}"
              description="The source field for a field manually edited by a user"
              id="dynamicForm.sourceField.helperText.user"
              values={{
                user: fieldSource.source_display_name,
                source,
                timestamp: <Timestamp dateTime={fieldSource.updated_at} />,
              }}
            />
          </Flex>
        ) : (
          <FormattedMessage
            defaultMessage="Assigned by {user} from {source}"
            description="The source field for a field manually edited by a user"
            id="dynamicForm.sourceField.helperText.user"
            values={{
              user: fieldSource.source_display_name,
              source,
            }}
          />
        )
      }
      // Plain user update will get rendered next to the field name. Render nothing here to prevent duplicate information.
      return null
    }
    case "derived": {
      return (
        <Flex gap={1} alignItems="center">
          <FormattedMessage
            defaultMessage="Suggested by BRM"
            description="The source field suggested by BRM"
            id="dynamicForm.sourceField.helperText.suggested"
          />
          {fieldSource.derived_from && fieldSource.derived_from?.length > 0 && (
            <Tooltip shouldWrapChildren label={getDerivedSourceDisplayName(intl, fieldSource)}>
              <Icon as={InfoIcon} />
            </Tooltip>
          )}
        </Flex>
      )
    }
    case "document":
    case "transaction": {
      const displayName = fieldSource.source_display_name
      if (!displayName) {
        return (
          <FormattedMessage
            defaultMessage="Extracted from deleted source"
            description="The source field for a field extracted from an unknown source"
            id="dynamicForm.sourceField.helperText.extraction.unknown"
          />
        )
      }
      const extractedFromText = intl.formatMessage(
        {
          defaultMessage: "Extracted from {source}",
          description: "The source field for a field extracted from a document",
          id: "dynamicForm.sourceField.helperText.extraction",
        },
        {
          source: displayName,
        }
      )
      return onClick ? (
        <Button
          color="purple.500"
          onClick={onClick}
          variant="link"
          fontWeight="normal"
          w="100%"
          iconSpacing={1}
          rightIcon={<Icon as={SearchIcon} />}
          display="inline-flex"
          flex={1}
          justifyContent="start"
        >
          <chakra.span isTruncated={true} maxW="100%">
            {extractedFromText}
          </chakra.span>
        </Button>
      ) : (
        extractedFromText
      )
    }
    case "sor":
    case "link":
    case "listing":
    case undefined: {
      return null
    }
    default:
      unreachable(fieldSource.type)
  }
}
