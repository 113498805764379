import type { Task } from "@brm/schema-types/types.js"
import { Flags } from "@brm/util/flags.js"
import {
  agreementRouteById,
  negotiationRouteById,
  requestRouteById,
  toolRouteById,
  vendorRouteById,
} from "@brm/util/routes.js"
import { unreachable } from "@brm/util/unreachable.js"
import posthog from "posthog-js"
import type { Location } from "react-router-dom"
import { isNotVoid } from "typed-assert"
import { AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH, AUTO_SELECT_STEP_HASH } from "../../workflows/constants.js"

export const getTaskPrimarySrcUrl = (task: Task) => {
  const { pathName, searchParams, hash } = getTaskUrlParts(task)
  if (hash) {
    return `${pathName}#${hash}`
  }
  if (searchParams) {
    return `${pathName}?${searchParams.toString()}`
  }
  return pathName
}

const getTaskUrlParts = (task: Task): { pathName: string; searchParams?: URLSearchParams; hash?: string } => {
  const isNegotiationEnabled = posthog.isFeatureEnabled(Flags.NEGOTIATION_ENABLED)
  const taskBasePath = `/inbox/tasks/${task.type.toLowerCase()}`
  switch (task.type) {
    case "workflow_step_gatherer": {
      const requestPath = requestRouteById(task.workflow_run_id)
      return {
        pathName: `${taskBasePath}${requestPath}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    }
    case "workflow_run_step_ready_for_review":
    case "workflow_run_aggregated_steps_ready_for_review_or_approval": {
      const requestPath = requestRouteById(task.workflow_run_id)
      return {
        pathName: `${taskBasePath}${requestPath}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    }
    case "workflow_field_gatherer": {
      return {
        pathName: `${taskBasePath}${requestRouteById(task.workflow_run_id)}`,
        searchParams: new URLSearchParams({
          step: task.workflow_run_step_id,
          task: task.type,
          type: task.object_type,
          object: task.object_id,
          field: task.field_name,
        }),
      }
    }
    case "workflow_run_aggregated_gather_fields": {
      const requestPath = requestRouteById(task.workflow_run_id)
      return {
        pathName: `${taskBasePath}${requestPath}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    }
    case "agreement_renewal": {
      const shouldRouteToNegotiation = isNegotiationEnabled && task.negotiation_status === "not_started"
      if (shouldRouteToNegotiation && task.negotiation_id) {
        const negotiationPath = negotiationRouteById(task.negotiation_id, "decision")
        return {
          pathName: `${taskBasePath}${negotiationPath}`,
        }
      }

      if (task.tool_id) {
        const toolPath = toolRouteById(task.tool_id)
        return {
          pathName: `${taskBasePath}${toolPath}`,
        }
      }
      const vendorPath = vendorRouteById(task.vendor_id)
      return {
        pathName: `${taskBasePath}${vendorPath}`,
      }
    }
    case "agreement_verification": {
      const agreementPath = agreementRouteById("create").replace("/agreements/create", "/agreements")
      return {
        pathName: `${taskBasePath}${agreementPath}`,
        hash: AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH,
      }
    }
    case "reply":
      if (task.workflow_run_id) {
        isNotVoid(task.object_type)
        isNotVoid(task.object_id)

        const searchParams = new URLSearchParams({
          event: task.timeline_event_id,
          type: task.object_type,
          object: task.object_id,
        })
        if (task.workflow_run_step_id) {
          searchParams.set("step", task.workflow_run_step_id)
        }

        if (task.field_name) {
          searchParams.set("field", task.field_name)
        }

        return {
          pathName: `${taskBasePath}${requestRouteById(task.workflow_run_id)}`,
          searchParams,
        }
      }
      if (task.vendor_id) {
        return {
          pathName: `${taskBasePath}${vendorRouteById(task.vendor_id, "overview")}`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      if (task.tool_id) {
        return {
          pathName: `${taskBasePath}${toolRouteById(task.tool_id, "overview")}`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      throw new Error("Reply task has no workflow run or vendor or tool")
    case "disconnected_integration":
      return {
        pathName:
          task.integration.provider === "gmail_oauth"
            ? `${taskBasePath}/settings/contract-collector`
            : `${taskBasePath}/settings/integrations`,
      }
    case "tools_inactive_owner_reassignment": {
      return {
        pathName: `${taskBasePath}/tools`,
        searchParams: new URLSearchParams({
          "owner.status": "inactive_employee*inactive_user",
        }),
      }
    }
    case "vendors_inactive_owner_reassignment": {
      return {
        pathName: `${taskBasePath}/vendors`,
        searchParams: new URLSearchParams({
          "owner.status": "inactive_employee*inactive_user",
        }),
      }
    }
    case "agreement_duplicative_subscriptions": {
      if (task.object_type === "Tool") {
        const objectPath = toolRouteById(task.object_id, "overview")
        return {
          pathName: `${taskBasePath}${objectPath}`,
          hash: "agreements",
        }
      }
      if (task.object_type === "Vendor") {
        const objectPath = vendorRouteById(task.object_id, "overview")
        return {
          pathName: `${taskBasePath}${objectPath}`,
          hash: "agreements",
        }
      }
      return {
        pathName: `${taskBasePath}/agreements`,
      }
    }
    case "negotiation_reply": {
      const negotiationPath = negotiationRouteById(task.negotiation_id)
      return {
        pathName: `${taskBasePath}${negotiationPath}`,
      }
    }
    default:
      unreachable(task)
  }
}

export const compareLocationToTaskUrl = (location: Location, task: Task) => {
  const { pathname } = location
  const taskUrlData = getTaskUrlParts(task)
  return pathname.includes(taskUrlData.pathName)
}
