import type { NegotiationContextElement } from "@brm/schema-types/types.js"
import { NegotiationPlaybookSectionKeySchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import { chakra, type ChakraProps } from "@chakra-ui/react"
import { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { LinkOrSpan } from "../Link.js"

export const NegotiationContextTag = ({
  attributes,
  children,
  element,
}: RenderElementProps &
  GetLogoForOrganizationProps & {
    element: NegotiationContextElement
  }) => {
  const selected = useSelected()
  const focused = useFocused()

  const spanStyle: ChakraProps = useMemo(
    () => ({
      padding: "0 2px",
      margin: "0 1px",
      verticalAlign: "baseline",
      display: "inline-block",
      borderRadius: "4px",
      backgroundColor: "brand.50",
      borderWidth: "1px",
      borderColor: "brand.200",
      fontSize: "0.9em",
      boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
    }),
    [selected, focused]
  )

  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  return (
    <chakra.span
      {...attributes}
      contentEditable={false}
      data-cy={`negotiation-context-${element.section_key.replaceAll(" ", "-")}`}
      {...spanStyle}
    >
      <LinkOrSpan to={`#playbook=${element.section_key}`}>
        <FormattedMessage
          defaultMessage="Playbook: {playbookTitle}"
          description="Negotiation context tag titles"
          id={`negotiation-context-${element.section_key.replaceAll(" ", "-")}`}
          values={{ playbookTitle: getTitle(element.section_key, NegotiationPlaybookSectionKeySchema) }}
        />
      </LinkOrSpan>
      {children}
    </chakra.span>
  )
}
