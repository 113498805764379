import { type EnabledOnboardingStep, type NullableOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { chakra, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import {
  AgreementsTableIcon,
  CalendarCheckIcon,
  CalendarIcon,
  CollatedDocumentsIcon,
  DocumentDeleteIcon,
  DocumentIcon,
  FTSIcon,
  InboxIcon,
  JumpToSourceIcon,
  SuperagentIcon,
  ThreeStarsIcon,
  VerifiedIcon,
} from "../icons/icons.js"
interface StepCardConfig {
  icon: React.ElementType
  heading: React.ReactNode
  subtitle: React.ReactNode
}

export const OnboardingCalloutConfigurations: Partial<Record<EnabledOnboardingStep, StepCardConfig>> = {
  "overview.agreement_list": {
    icon: AgreementsTableIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_agreement_list"
        description="Introduction to viewing a sample agreement in BRM"
        defaultMessage="Your Agreements, All in One Place"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_agreement_list.subtitle"
        description="Explanation of BRM’s automatic agreement import feature"
        defaultMessage="As AI pulls in agreements from your inbox, they’ll show up here automagically so they’re easy to find. Let’s check out the sample agreement."
      />
    ),
  },
  "overview.view_agreement": {
    icon: DocumentIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_agreement"
        description="Introduction to viewing a sample agreement in BRM"
        defaultMessage="Here’s a Sample Agreement"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_agreement.subtitle"
        description="Explanation of BRM’s automatic agreement import feature"
        defaultMessage="On the left are full documents, and on the right are the key terms—extracted for you. Check the status, see what’s verified, and read a quick summary. {bold}"
        values={{
          bold: (
            <Text as="span" fontWeight="semibold" color="brand.500">
              <FormattedMessage
                id="onboarding.callout.view_agreement.subtitle_highlight"
                description="Explanation of BRM’s automatic agreement import feature"
                defaultMessage="No more endless digging through documents!"
              />
            </Text>
          ),
        }}
      />
    ),
  },
  "verify_agreement.multiple_documents": {
    icon: CollatedDocumentsIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.multiple_documents"
        description="Introduction to BRM’s information extraction capabilities"
        defaultMessage="Multiple Documents Collated"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.multiple_documents.subtitle"
        description="Prompt for user to click the agreement source to see the extracted information source"
        defaultMessage="Agreements sometimes include more than one document. AI automagically groups Terms of Service, DPAs, NDAs, and more—so you always get the full picture of your vendor relationship."
      />
    ),
  },
  "verify_agreement.jump_to_source": {
    icon: JumpToSourceIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.jump_to_source"
        description="Introduction to BRM’s multi-document handling"
        defaultMessage="Jump to the Source"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.jump_to_source.subtitle"
        description="Explanation of BRM’s multi-document handling"
        defaultMessage="Every extracted term is linked to where it appears in the documents. Click a <bold>purple link</bold> to jump straight to the original text and verify. While AI is more accurate than a human, a quick spot check keeps things honest."
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "verify_agreement.verify_decision_date": {
    icon: CalendarCheckIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_decision_date"
        description="Introduction to BRM’s renewal tracking feature"
        defaultMessage="Your Most Important Deadline"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_decision_date.subtitle"
        description="Explanation of BRM’s renewal tracking feature"
        defaultMessage="This date defines your window to cancel or renegotiate before your agreement renews and locks in. AI automagically calculates the <bold>Renewal Decision Date</bold> for you—keeping you ahead of your vendors."
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "verify_agreement.accept_agreement": {
    icon: VerifiedIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.accept_agreement"
        description="Prompt for user to accept the agreement"
        defaultMessage="Complete the Review"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.accept_agreement.subtitle"
        description="Explanation of BRM’s agreement verification feature"
        defaultMessage="Click <bold>Verify</bold> to confirm the extracted terms. Once verified, your team can access the <bold>Agreement</bold>—keeping everyone on the same page."
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "overview.view_renewal_agenda": {
    icon: CalendarIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_renewal_agenda"
        description="Introduction to the Renewal Agenda feature"
        defaultMessage="Your Renewal Calendar"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_renewal_agenda.subtitle"
        description="Message shown when user needs to view renewal agenda"
        defaultMessage="Stay ahead of every renewal. This calendar is built for you using the <bold>Renewal Decision Dates</bold> we just saw—keeping you in control of vendor agreements and deadlines."
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "full_text_search.search_document_text": {
    icon: FTSIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.search_document_text"
        description="Message shown when user needs to search for a document"
        defaultMessage="Find Anything, Instantly"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.search_document_text.subtitle"
        description="Message shown when user needs to search for a document"
        defaultMessage="Now that your Agreements are in BRM, you can search your entire account to <bold>answer questions, find clauses and terms, and get what you need—fast.</bold> Your contracts just became a powerful tool."
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "overview.ask_ai": {
    icon: ThreeStarsIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.chat_about_agreement"
        description="Message shown when user needs to chat about an agreement"
        defaultMessage="Your AI-Powered Contract Expert"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.chat_about_agreement.subtitle"
        description="Message shown when user needs to chat about an agreement"
        defaultMessage="BRM’s AI isn’t just smart—it knows your contracts inside and out. Ask questions, get insights, and make informed decisions in seconds. {highlight}"
        values={{
          highlight: (
            <Text as="span" fontWeight="semibold" color="brand.500">
              <FormattedMessage
                id="onboarding.callout.chat_about_agreement.subtitle.highlight"
                description="Message shown when user needs to chat about an agreement"
                defaultMessage="No more guesswork."
              />
            </Text>
          ),
        }}
      />
    ),
  },
  "overview.view_inbox": {
    icon: InboxIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.view_inbox"
        description="Introduction to BRM’s inbox feature"
        defaultMessage="Your BRM Inbox"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.view_inbox.subtitle"
        description="Explanation of BRM’s task management system"
        defaultMessage="This is where you’ll see tasks to review and verify new agreements. Whenever something needs your attention, it’ll be right here—so nothing slips through the cracks."
      />
    ),
  },
  "agreements.delete_sample_agreement": {
    icon: DocumentDeleteIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.delete_sample_agreement"
        description="Message when user needs to delete the sample agreement"
        defaultMessage="Your Data, Your Decision"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.delete_sample_agreement.subtitle"
        description="Message when user needs to delete the sample agreement"
        defaultMessage="Any extraneous document that slips through our intelligent filters can be removed with a single click. <bold>Go ahead—try deleting this sample agreement now.</bold>"
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
        }}
      />
    ),
  },
  "inbox.verify_agreements": {
    icon: SuperagentIcon,
    heading: (
      <FormattedMessage
        id="onboarding.callout.verify_agreements"
        description="Message shown when user needs to verify agreements"
        defaultMessage="Mission Accomplished"
      />
    ),
    subtitle: (
      <FormattedMessage
        id="onboarding.callout.verify_agreements.subtitle"
        description="Message shown when user needs to verify agreements"
        defaultMessage="You’re <bold>armed with the right</bold> tools to stay ahead—no missed renewals, no surprises. You can take control of your vendor relationships. Your time is freed up to focus on what really matters. <brmHighlight>BRM’s AI SuperAgents are on your side!</brmHighlight>"
        values={{
          bold: (chunks) => <chakra.span fontWeight="semibold">{chunks}</chakra.span>,
          brmHighlight: (chunks) => (
            <Text as="span" fontWeight="semibold" color="brand.500">
              {chunks}
            </Text>
          ),
        }}
      />
    ),
  },
}

export const isCalloutConfiguredStep = (step: NullableOnboardingStep): boolean => {
  return step !== null && step in OnboardingCalloutConfigurations
}
