import { Flex, HStack } from "@chakra-ui/react"
import OverflownText from "../OverflownText.js"

export const NegotiationPanelHeader = ({
  title,
  after,
  before,
  height,
}: {
  title: string
  after?: React.ReactNode
  before?: React.ReactNode
  height?: string
}) => {
  return (
    <Flex
      padding={2}
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      borderColor="gray.200"
      borderBottomWidth={1}
      minHeight="40px"
      {...(height && { height })}
    >
      <HStack flex={1} gap={1} overflow="hidden" alignItems="center">
        {before}
        <OverflownText fontSize="lg" color="gray.600" fontWeight="semibold">
          {title}
        </OverflownText>
      </HStack>
      {after}
    </Flex>
  )
}

export default NegotiationPanelHeader
