import type { DocumentMinimal, ImageAsset } from "@brm/schema-types/types.js"
import { useToast } from "@chakra-ui/react"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import type { Editor } from "slate"
import {
  usePostDocumentV1Mutation,
  usePostImageAssetV1ImageAssetsMutation,
  usePutDocumentV1ByIdMutation,
} from "../../../app/services/generated-api.js"
import { imageDownloadUrl } from "../../../util/document.js"
import { uploadFile } from "../../Document/upload-document.js"
import { createAndUploadDocument } from "../../Document/util.js"
import type { CustomEditor } from "../types.js"
import { handleDocumentUploadError, insertDocument } from "./document.js"
import { handleImageUploadError, insertImage } from "./image.js"

export interface FileUploadProps {
  editor: Editor
  onDocument?: (document: DocumentMinimal) => void
}

type FileUpload = (file: File) => Promise<DocumentMinimal | ImageAsset | undefined>

export function useFileUpload(props: FileUploadProps): FileUpload {
  const { editor, onDocument } = props

  const intl = useIntl()
  const toast = useToast()

  const [uploadImage] = usePostImageAssetV1ImageAssetsMutation()
  const [createDocument] = usePostDocumentV1Mutation()
  const [markUploaded] = usePutDocumentV1ByIdMutation()

  const onUploadUserImage = useCallback(
    async (file: File) => {
      const uploadableImage = await uploadImage({
        imageAssetInput: {
          file_name: file.name,
          file_size: file.size,
          mime_type: file.type,
        },
      }).unwrap()
      await uploadFile(uploadableImage, file)
      return uploadableImage
    },
    [uploadImage]
  )

  const onUploadDocument = useCallback(
    async (file: File) => {
      const createdDocumentResponse = await createDocument({
        documentInput: {
          file_name: file.name,
          file_size: file.size,
          mime_type: file.type,
        },
      }).unwrap()
      const uploadedFile = await createAndUploadDocument({
        file,
        createdDocument: createdDocumentResponse,
        onUpload: async () =>
          await markUploaded({
            id: createdDocumentResponse.id,
            body: { status: "uploaded" },
          }).unwrap(),
        onQuarantined: () =>
          toast({
            description: intl.formatMessage({
              id: "richTextEditor.documentQuarantined",
              defaultMessage: "Your document was quarantined because it may contain malicious content.",
              description: "Message displayed when a document is quarantined",
            }),
            status: "warning",
          }),
      })
      if (!uploadedFile) {
        throw new Error("Failed to upload document, no uploaded file returned")
      }
      onDocument?.(uploadedFile)
      return uploadedFile
    },
    [createDocument, markUploaded, toast, intl, onDocument]
  )

  const onImageOrDocumentUpload = useCallback(
    async (file: File): Promise<DocumentMinimal | ImageAsset | undefined> => {
      if (file.type.startsWith("image/")) {
        try {
          const uploadedImage = await onUploadUserImage(file)
          insertImage(editor, imageDownloadUrl(uploadedImage.id), file.name)
          return uploadedImage
        } catch (err) {
          handleImageUploadError(err, toast, intl)
        }
      } else {
        try {
          const uploadedDocument = await onUploadDocument(file)
          insertDocument(editor, { id: uploadedDocument.id, fileName: file.name, mime_type: file.type })
          return uploadedDocument
        } catch (err) {
          handleDocumentUploadError(err, toast, intl)
        }
      }
      return undefined
    },
    [onUploadUserImage, onUploadDocument, intl, toast, editor]
  )

  return onImageOrDocumentUpload
}

export const withFiles = (editor: CustomEditor, uploadFile: FileUpload) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === "document" ? true : isVoid(element)
  }

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element)
  }

  editor.insertData = async (data) => {
    const { files } = data
    for (const file of files) {
      await uploadFile(file)
    }
    insertData(data)
  }

  return editor
}
