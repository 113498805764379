import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type {
  CurrencyAmount,
  CustomizableObjectType,
  FieldCategory,
  FormFieldConfig,
  FrequencyOrOneTime,
  TransactionPaymentMethod,
  WorkflowRunStatePatch,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getDiscriminatorSchema, getTitle } from "@brm/util/schema.js"
import { Badge, Box, Heading, Stack } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import { memo, useMemo } from "react"
import { useIntl, type IntlShape } from "react-intl"
import { useParams } from "react-router-dom"
import type { Except } from "type-fest"
import type { DynamicFormFieldProps } from "../../../../components/DynamicForm/DynamicFormField.js"
import { DynamicFormField } from "../../../../components/DynamicForm/DynamicFormField.js"
import { getLegalDateShortcuts, getLegalDurationShortcuts } from "../../../../util/form.js"
import { useObjectInputSchema, useObjectPatchSchema } from "../../../../util/use-schema.js"
import { formatCustomizableObjectType } from "../../../settings/criteria/util.js"
import { convertDurationToCreditCardInterval } from "../../util.js"

interface WorkflowRunStepObjectFormProps {
  objectType: CustomizableObjectType
  category?: FieldCategory
  formFieldProps: Except<DynamicFormFieldProps, "path" | "formField">
  mergedState: WorkflowRunStatePatch
  workflowDisplayName: string
  isExternal: boolean
}

export default function WorkflowRunStepObjectForm({
  objectType,
  category,
  formFieldProps,
  mergedState,
  workflowDisplayName,
  isExternal,
}: WorkflowRunStepObjectFormProps) {
  const intl = useIntl()
  const { code } = useParams<{ code: string }>()
  const categorySchema = useMemo(() => FieldCategorySchema.anyOf.find((c) => c.const === category), [category])

  const stdObjectSchema = useObjectPatchSchema(objectType === "LegalAgreement" ? skipToken : objectType, code)
  const legalAgreementObjectSchema = useObjectInputSchema(
    objectType === "LegalAgreement" ? objectType : skipToken,
    code
  )
  const discriminatedRootSchema = useMemo(
    () => getDiscriminatorSchema(legalAgreementObjectSchema, { agreement_type: "enterprise" }),
    [legalAgreementObjectSchema]
  )
  const objectSchema = stdObjectSchema ?? discriminatedRootSchema

  const formFields = useMemo(() => {
    if (!objectSchema) {
      return []
    }
    const formFields = schemaToFormFields({
      schema: objectSchema,
      objectType,
      category,
      isRequestConfig: true,
    })
    const mappedFields = formFields.map((field) => ({
      ...field,
      path: field.is_custom ? ["custom", field.field_name] : [field.field_name],
    }))
    return mappedFields
  }, [objectSchema, objectType, category])

  if (!objectSchema) {
    return null
  }

  if (formFields.length === 0) {
    return null
  }

  return (
    <Box borderWidth={1} borderColor="gray.200" borderRadius="lg" padding={4}>
      <Heading size="xs" display="flex" alignItems="center" gap={2}>
        {formatCustomizableObjectType(objectType, intl)}
        {category && (
          <Badge colorScheme={categorySchema?.colorScheme} variant="subtleOutlined" size="sm">
            {getTitle(category, categorySchema)}
          </Badge>
        )}
      </Heading>
      <Stack gap={6}>
        {formFields
          .filter((field) => !isExternal || !field.is_internal_only)
          .map((field) => {
            const maybeCustomFieldName = field.is_custom ? ["custom", field.field_name] : [field.field_name]
            if (field.object_type === "LegalAgreement") {
              return (
                <LegalAgreementFormField
                  key={maybeCustomFieldName.join(".")}
                  field={field}
                  formFieldProps={formFieldProps}
                  maybeCustomFieldName={maybeCustomFieldName}
                  intl={intl}
                />
              )
            }
            if (field.object_type === "Vendor") {
              const path = ["vendor", ...maybeCustomFieldName]
              return <DynamicFormField key={path.join(".")} formField={field} path={path} {...formFieldProps} />
            }
            if (field.object_type === "Tool") {
              return (
                mergedState.tools?.map((_, toolIndex) => {
                  const path = ["tools", toolIndex, ...maybeCustomFieldName]
                  return <DynamicFormField key={path.join(".")} formField={field} path={path} {...formFieldProps} />
                }) ?? []
              )
            }
            if (field.object_type === "WorkflowRun") {
              let defaultValue: unknown
              const path = maybeCustomFieldName
              if (field.field_name === "assigned_credit_card") {
                const paymentMethod = formFieldProps.getValue("payment_method") as TransactionPaymentMethod | undefined
                if (paymentMethod !== "credit_card") {
                  return null
                }
                const requestedSpend = formFieldProps.getValue("requested_spend") as CurrencyAmount | undefined
                const requestedSpendInterval = formFieldProps.getValue("requested_spend_frequency") as
                  | FrequencyOrOneTime
                  | undefined
                defaultValue = {
                  spend_limit: requestedSpend,
                  spend_limit_interval: requestedSpendInterval
                    ? convertDurationToCreditCardInterval(requestedSpendInterval)
                    : undefined,
                  display_name: workflowDisplayName,
                }
              }
              return (
                <DynamicFormField
                  key={path.join(".")}
                  formField={field}
                  path={path}
                  {...formFieldProps}
                  defaultValue={defaultValue}
                />
              )
            }
            return null
          })}
      </Stack>
    </Box>
  )
}

const LegalAgreementFormField = memo(function LegalAgreementFormField({
  field,
  formFieldProps,
  maybeCustomFieldName,
  intl,
}: {
  field: FormFieldConfig
  formFieldProps: Except<DynamicFormFieldProps, "path" | "formField">
  maybeCustomFieldName: string[]
  intl: IntlShape
}) {
  const path = useMemo(() => ["legal_agreement", ...maybeCustomFieldName], [maybeCustomFieldName])

  const legalAgreementPatch = formFieldProps.getValue("legal_agreement")

  const dateShortcuts = useMemo(
    () =>
      legalAgreementPatch
        ? getLegalDateShortcuts(field.field_name, formFieldProps.rootBaseSchema, legalAgreementPatch, intl)
        : undefined,
    [field.field_name, formFieldProps.rootBaseSchema, legalAgreementPatch, intl]
  )

  const durationShortcuts = useMemo(() => getLegalDurationShortcuts(field.field_name, intl), [field.field_name, intl])

  return (
    <DynamicFormField
      {...formFieldProps}
      formField={field}
      path={path}
      dateShortcuts={dateShortcuts}
      durationShortcuts={durationShortcuts}
    />
  )
})
