import type { VariableType, WorkflowStepStandardType } from "@brm/schema-types/types.js"
import { uniqBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import { Flex, Icon, Stack, Text, Tooltip } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { Controller, useFieldArray, useWatch, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { isPresent } from "ts-is-present"
import OrganizationEntityMultiPicker from "../../../components/Form/OrganizationEntityMultiPicker.js"
import { HelpIcon } from "../../../components/icons/icons.js"
import { getMinimalRoleForPermission, getPermissionRequiredToEditStep } from "../run/utils.js"
import ConditionalPrompt from "./ConditionalPrompt.js"
import ConditionalToggle from "./ConditionalToggle.js"
import { getPrevStepObjects, type ConfigureWorkflowStepsFormState } from "./utils.js"

export default function ApprovalStepDefinition({
  approvalStep,
  approvalStepIdx,
  stepIndex,
  control,
  stepType,
  approverVariableTypes,
}: {
  approvalStep: ConfigureWorkflowStepsFormState["steps"][number]["approval_steps"][number]
  approvalStepIdx: number
  stepIndex: number
  control: Control<ConfigureWorkflowStepsFormState>
  stepType: WorkflowStepStandardType
  approverVariableTypes?: VariableType[]
}) {
  const intl = useIntl()
  const [conditionalToggle, setConditionalToggle] = useState<null | "conditional">(
    approvalStep.condition ? "conditional" : null
  )

  const steps = useWatch({ control, name: "steps" })
  const currentStep = steps[stepIndex]

  const conditionalInputRef = useRef<{ focus: () => void }>(null)

  const approversArray = useFieldArray({
    name: `steps.${stepIndex}.approval_steps.${approvalStepIdx}.approvers`,
    control,
    rules: {
      minLength: 1,
    },
  })

  if (!approvalStep) {
    return null
  }

  return (
    <Stack key={approvalStepIdx} flex={1}>
      <Text>
        <FormattedMessage
          id="workflow.definition.step.approvalStep.index"
          defaultMessage="Who can approve?"
          description="Label for the approval step index"
        />
      </Text>
      <Stack>
        <OrganizationEntityMultiPicker
          includedEntities={["user", "person", "variable"]}
          variableTypes={approverVariableTypes}
          onChange={(newValue) =>
            approversArray.replace(
              newValue
                .map((v) =>
                  v.type === "user"
                    ? ({ type: "user", user: { ...v, profile_image: v.image_asset } } as const)
                    : v.type === "variable"
                      ? ({ type: "variable", variable: v.variable } as const)
                      : null
                )
                // A Person is not a valid approver and should not appear in approversArray. Filter out persons to satisfy type
                .filter(isPresent)
            )
          }
          value={approversArray.fields.map((approver) =>
            approver.type === "user"
              ? {
                  ...approver.user,
                  display_name: displayPersonName(approver.user, intl),
                  type: "user",
                  object_type: "User",
                  image_asset: approver.user.profile_image,
                }
              : { variable: approver.variable, type: "variable" }
          )}
          permission={{
            value: getPermissionRequiredToEditStep(stepType),
            includeAll: true,
            defaultInviteRole: getMinimalRoleForPermission(getPermissionRequiredToEditStep(stepType)),
          }}
        />
        <Controller
          name={`steps.${stepIndex}.approval_steps.${approvalStepIdx}.condition`}
          control={control}
          render={({ field }) => (
            <>
              <Flex alignItems="center">
                <ConditionalToggle
                  conditionalToggle={conditionalToggle}
                  setConditionalToggle={(value) => {
                    setConditionalToggle(value)
                    if (value === "conditional") {
                      setTimeout(() => {
                        conditionalInputRef.current?.focus()
                      }, 10)
                    }
                  }}
                  onChangeConditional={field.onChange}
                  labels={{
                    conditional: intl.formatMessage({
                      id: "workflow.definition.step.approver.conditional",
                      defaultMessage: "Require approval when...",
                      description: "Option to require approval when a condition is met",
                    }),
                    always: intl.formatMessage({
                      id: "workflow.definition.step.approver.always",
                      defaultMessage: "Always require approval",
                      description: "Option to always require approval",
                    }),
                  }}
                />
                <Tooltip
                  shouldWrapChildren
                  label={intl.formatMessage({
                    id: "workflow.definition.step.approver.add.tooltip",
                    defaultMessage:
                      "Add conditional logic to the approval step, ensuring the right approvals occur based on dynamic conditions. You may reference any criteria applied to the step, and any step before it. For example:<br></br><br></br> Require approval from James McGillicuddy if Total Contract Value is greater than $50,000.00.",
                    description: "Tooltip text for the add multiple approvers button",
                  })}
                >
                  <Icon as={HelpIcon} color="gray.500" />
                </Tooltip>
              </Flex>
              {conditionalToggle === "conditional" && (
                <ConditionalPrompt
                  ref={conditionalInputRef}
                  onChange={field.onChange}
                  getEligibleObjects={() =>
                    currentStep
                      ? uniqBy(
                          structuredClone(
                            getPrevStepObjects(steps, currentStep)
                              .concat(currentStep.objects)
                              .filter((obj) => !obj.disabled_at)
                          ),
                          (object) => `${object.object_type}.${object.category}`
                        )
                      : []
                  }
                  basePath={`steps.${stepIndex}.approval_steps.${approvalStepIdx}.condition`}
                  control={control}
                />
              )}
            </>
          )}
        />
      </Stack>
    </Stack>
  )
}
