import type { AnyFilter, ArrContainsFilter } from "@brm/schema-types/types.js"
import { isStdObjSchema } from "@brm/type-helpers/schema.js"
import { mapBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import { expandUUID } from "@brm/util/short-uuids.js"
import { Avatar, AvatarGroup, Icon, Text } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { isString } from "typed-assert"
import { usePostPersonV1ListQuery } from "../../../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../../../util/url.js"
import { NoUserIcon, UserIcon } from "../../icons/icons.js"
import { isValuesFilter } from "./util.js"

export function ObjectReferenceAppliedFilterValue({
  filter,
  fieldSchema,
}: {
  filter: ArrContainsFilter | AnyFilter

  /**
   * The schema of the field the filter applies to.
   *
   * Must be either a schema for a standard object or an array of standard objects.
   */
  fieldSchema: ReadonlyDeep<JSONSchemaObject>
}) {
  const intl = useIntl()

  const objectType = isStdObjSchema(fieldSchema)
    ? fieldSchema.properties.object_type.const
    : isStdObjSchema(fieldSchema.items)
      ? fieldSchema.items.properties.object_type.const
      : undefined
  isString(objectType, "Expected field schema for ObjectReferenceAppliedFilterValue to have object_type")

  // Only persons are listed with avatars, other object_types just have "N selected" displayed in the UI
  const personsResult = usePostPersonV1ListQuery(objectType === "Person" ? { listQueryStringParams: {} } : skipToken)
  const personsById = useMemo(
    () => personsResult.data && mapBy(personsResult.data.persons, (person) => person.id),
    [personsResult.data]
  )

  const numSelected = filter.values.length + Number(filter.includeNull)
  if (objectType !== "Person" || numSelected === 0) {
    return (
      <Text fontWeight="medium">
        <FormattedMessage
          defaultMessage="{numSelected} selected"
          id="filterSummary.entitySelector.emptyState"
          description="Text shown in a filter summary of the number of selected items"
          values={{ numSelected }}
        />
      </Text>
    )
  }

  const showNullAvatar = isValuesFilter(filter) && !!filter.includeNull
  return (
    <AvatarGroup max={4} spacing={-1} size="sm">
      {showNullAvatar && <Avatar icon={<Icon as={NoUserIcon} />} textColor="black" />}
      {filter.values.map((id) => {
        const person = personsById?.get(expandUUID(id))
        if (!person) {
          return null
        }
        return (
          <Avatar
            key={id}
            textColor="white"
            referrerPolicy="no-referrer"
            src={getPublicImageGcsUrl(person.profile_image?.gcs_file_name)}
            name={displayPersonName(person, intl)}
            icon={<Icon as={UserIcon} />}
          />
        )
      })}
    </AvatarGroup>
  )
}
