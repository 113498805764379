import { isLoginResult, LoginError } from "@brm/type-helpers/user.js"
import { BRM_SUPPORT_EMAIL } from "@brm/util/email.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  chakra,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useStore } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { brmApi } from "../../app/services/brm-api.js"
import { usePostUserV1ForgotPasswordMutation, usePostUserV1LoginMutation } from "../../app/services/generated-api.js"
import PasswordField from "../../components/Form/PasswordField.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { LoginBaseContainer } from "./LoginBaseContainer.js"
import OAuthButtonGroup from "./OAuthButtonGroup.js"

interface LoginFormState {
  email: string
  password: string
  token: string | null
}

/**
 * @see https://pro.chakra-ui.com/components/application/authentication
 */
export default function Login() {
  const [login, loginResult] = usePostUserV1LoginMutation()
  const [forgotPassword] = usePostUserV1ForgotPasswordMutation()

  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()
  const toast = useToast()
  const [searchParams, setSearchParam] = useSearchParams()
  const store = useStore()

  const form = useForm<LoginFormState>({
    defaultValues: {
      email: "",
      password: "",
      token: null,
    },
    reValidateMode: "onSubmit",
  })

  let searchParamsError: string | undefined
  switch (searchParams.get("error")) {
    case LoginError.INVALID_STATE:
      searchParamsError = intl.formatMessage({
        id: "login.oauth.invalid-state",
        description: "OAuth invalid state error",
        defaultMessage: "State mismatch",
      })
      break
    case LoginError.EMAIL_NO_SSO:
      searchParamsError = intl.formatMessage({
        id: "login.oauth.no-sso",
        description: "OAuth no SSO error",
        defaultMessage: "Email is not enabled for SSO",
      })
      break
    case LoginError.ACCESS_DENIED:
      searchParamsError = intl.formatMessage({
        id: "login.oauth.access-denied",
        description: "OAuth access denied error",
        defaultMessage: "An issue occurred while logging in. Please try again or contact support.",
      })
      break
    case LoginError.JOIN_WAITLIST:
      searchParamsError = intl.formatMessage({
        id: "login.oauth.join-waitlist",
        description: "OAuth join waitlist error",
        defaultMessage: "Signup is not available at this time. Email sales@brm.ai to join the waitlist.",
      })
      break
    case LoginError.ORGANIZATION_TRIAL_USAGE_LIMIT_EXCEEDED:
      searchParamsError = intl.formatMessage(
        {
          id: "login.oauth.organization-trial-usage-limit-exceeded",
          description: "OAuth organization trial usage limit exceeded error",
          defaultMessage:
            "Your organization has exceeded its free trial usage limit. Contact {supportEmail} for more details.",
        },
        {
          supportEmail: BRM_SUPPORT_EMAIL,
        }
      )
      break
  }

  const errorMessage =
    searchParamsError ||
    (loginResult.isError &&
      (getAPIErrorMessage(loginResult.error) ??
        intl.formatMessage({
          id: "login.wrong-user-or-password",
          description: "Failed login unknown",
          defaultMessage: "Login failed",
        })))

  const display2FA =
    loginResult.data?.state === "2fa_required" ||
    (loginResult.error &&
      "data" in loginResult.error &&
      isLoginResult(loginResult.error.data) &&
      loginResult.error.data.state === "2fa_required") ||
    form.watch("token")

  return (
    <LoginBaseContainer
      heading={intl.formatMessage({
        id: "login.heading",
        description: "Heading on login page",
        defaultMessage: "Log in to your account",
      })}
    >
      <OAuthButtonGroup />
      <HStack>
        <Divider />
        <Text whiteSpace="nowrap" color="muted">
          <FormattedMessage
            id="login.form.oauth"
            description="Login form message to direct users to oauth sign in options"
            defaultMessage="or"
          />
        </Text>
        <Divider />
      </HStack>
      <Stack
        spacing="5"
        as={chakra.form}
        onSubmit={(event) =>
          form.handleSubmit(async (values) => {
            setSearchParam({ error: "" })
            try {
              const result = await login({
                loginInput: {
                  email: values.email,
                  password: values.password,
                  ...(values.token ? { token: values.token } : {}),
                },
              }).unwrap()
              if (result.state === "success") {
                // Clear redux store to make sure that no state from the logged out state persists
                store.dispatch(brmApi.util.resetApiState())
                const prev = location.state?.from
                navigate(prev || "/", { replace: true })
              } else if (result.state === "2fa_required") {
                form.setValue("token", null)
              }
            } catch (err) {
              if (isObject(err) && "status" in err && err.status !== 401) {
                toast({
                  status: "error",
                  description: intl.formatMessage({
                    id: "login.error.toast",
                    description: "Login error toast message",
                    defaultMessage: "Something went wrong while logging in. Please try again or contact support.",
                  }),
                })
              }
            }
          })(event)
        }
      >
        <Controller
          control={form.control}
          name="email"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor="email">
                <FormattedMessage
                  id="login.form.email"
                  description="Label for login form email field"
                  defaultMessage="Email"
                />
              </FormLabel>
              <Input {...field} autoFocus={!display2FA} id="email" type="email" />
            </FormControl>
          )}
        />

        <Stack>
          <Controller
            control={form.control}
            name="password"
            rules={{ required: true }}
            render={({ field, fieldState }) => <PasswordField {...field} isInvalid={fieldState.invalid} isRequired />}
          />
          <HStack justify="end">
            <Button
              variant="link"
              colorScheme="brand"
              size="sm"
              onClick={async () => {
                const email = form.watch("email")
                if (!email) {
                  toast({
                    description: intl.formatMessage({
                      id: "reset-password.missing-email",
                      description: "reset password but no email",
                      defaultMessage: "Please enter an email address first",
                    }),
                    status: "error",
                  })
                } else {
                  try {
                    await forgotPassword({
                      body: { email },
                    }).unwrap()
                    toast({
                      description: intl.formatMessage({
                        id: "reset-password.success",
                        description: "reset password succeeded",
                        defaultMessage: "Check your email inbox for further instructions.",
                      }),
                      status: "success",
                    })
                  } catch (_err) {
                    toast({
                      description: intl.formatMessage({
                        id: "reset-password.failure",
                        description: "reset password failed",
                        defaultMessage: "Failed to reset password.",
                      }),
                      status: "error",
                    })
                  }
                }
              }}
            >
              <FormattedMessage
                id="login.form.forgot-password"
                description="Login form message to reset password"
                defaultMessage="Forgot password?"
              />
            </Button>
          </HStack>
        </Stack>
        {display2FA && (
          <Controller
            control={form.control}
            name="token"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid}>
                <FormLabel htmlFor="token">
                  <FormattedMessage
                    id="login.form.token"
                    description="Label for login form token field"
                    defaultMessage="Code"
                  />
                </FormLabel>
                <Input
                  {...field}
                  value={field.value ?? undefined}
                  autoFocus
                  placeholder="123456"
                  _placeholder={{ color: "gray.300" }}
                  type="text"
                  name="token"
                  id="token"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  autoComplete="one-time-code"
                />
                <FormHelperText>
                  <FormattedMessage
                    id="login.form.token"
                    description="Help text for login form token field"
                    defaultMessage="Open your authenticator app to find your one time code."
                  />
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Button id="password-login" isLoading={loginResult.isLoading} colorScheme="brand" type="submit">
          <FormattedMessage
            id="login.form.signin"
            description="Login form sign in button text"
            defaultMessage="Sign in"
          />
        </Button>
      </Stack>
    </LoginBaseContainer>
  )
}
