import type { NegotiationContext } from "@brm/schema-types/types.js"
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  type UseModalProps,
} from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { AgentMemoryIcon, ArrowRightIcon, BackIcon } from "../../components/icons/icons.js"

// Define the available tabs
type TabKey = keyof Omit<NegotiationContext, "id" | "created_at" | "updated_at">

interface TabData {
  key: TabKey
  label: string
  description: string
}

const TABS: TabData[] = [
  {
    key: "agreement_notes",
    label: "Agreement",
    description: "Executive summary of the previous agreement",
  },
  {
    key: "usage_and_roi",
    label: "Usage & ROI",
    description: "Product or service usage and return on investment",
  },
  {
    key: "vendor_performance",
    label: "Vendor Performance",
    description: "Vendor performance and service quality",
  },
  {
    key: "market_research",
    label: "Market Research",
    description: "Market pricing analysis and competitive landscape",
  },
  {
    key: "negotiation_goals",
    label: "Negotiation Goals",
    description: "Goals for the negotiation",
  },
  {
    key: "notes",
    label: "Notes",
    description: "Additional notes and comments",
  },
]

function TabTextInput({
  value,
  onChange,
  placeholder,
  isLastTab,
  isFirstTab,
  onNext,
  onBack,
}: {
  value: string
  onChange: (value: string) => void
  placeholder: string
  isLastTab: boolean
  isFirstTab: boolean
  onNext?: () => void
  onBack?: () => void
}) {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        resize="none"
        height="23rem"
      />
      <Box display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="outline" onClick={onBack} isDisabled={isFirstTab}>
          <Icon as={BackIcon} boxSize={4} />
        </Button>

        <Button variant="outline" onClick={onNext} isDisabled={isLastTab}>
          <Icon as={ArrowRightIcon} boxSize={4} />
        </Button>
      </Box>
    </Box>
  )
}

interface NegotiationDataModalProps extends UseModalProps {
  onUpdate: (key: keyof NegotiationContext, value: string) => Promise<void>
  currentData: NegotiationContext
  isUpdating: boolean
}

// Tune this value up or down to change how fast the circular progress bar fills
// relative to the amount of text in the tab
const UPPER_TRANSFORMATION_THRESHOLD = 1024

export function NegotiationDataModal({
  isOpen,
  onClose,
  currentData,
  onUpdate,
  isUpdating,
}: NegotiationDataModalProps) {
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState<number>(0)
  const [context, setContext] = useState<NegotiationContext>(currentData)

  useEffect(() => {
    setContext(currentData)
  }, [currentData])

  // create an internal sync timer using a ref
  const syncTimer = useRef<NodeJS.Timeout | null>(null)

  const handleTextChange = (key: keyof NegotiationContext, value: string) => {
    setContext((prev) => ({
      ...prev,
      [key]: value,
    }))

    // clear the timer if it exists
    if (syncTimer.current) {
      clearTimeout(syncTimer.current)
    }

    // set a new timer
    syncTimer.current = setTimeout(() => {
      void onUpdate(key, value)
    }, 500)
  }

  const handleNext = () => {
    if (activeTab < TABS.length - 1) {
      setActiveTab(activeTab + 1)
    } else {
      onClose()
    }
  }

  const handleBack = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent height="fit-content" paddingBottom={0}>
        <ModalHeader display="flex" gap={2}>
          <Icon as={AgentMemoryIcon} boxSize={5} color="gray.600" />
          <FormattedMessage
            id="negotiation.data.modal.title"
            description="Title of the negotiation data modal"
            defaultMessage="Negotiation Notes"
          />
          <Box display={isUpdating ? "inline" : "none"}>
            <Spinner size="sm" />
          </Box>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody padding={0}>
          <Tabs index={activeTab} onChange={setActiveTab} display="flex" flexDirection="column" height="100%">
            <TabList px={4}>
              {TABS.map((tab) => (
                <Tab key={tab.key} textAlign="left">
                  {/* This ui shows how "full" a tab is via a circular progress bar + a linear transformation of the length of the text in the tab */}
                  {context[tab.key as keyof NegotiationContext]?.trim() && (
                    <Box width={6}>
                      <CircularProgress
                        size="1rem"
                        thickness={14}
                        trackColor="gray.300"
                        value={Math.min(
                          99,
                          Math.max(
                            0,
                            ((context[tab.key as keyof NegotiationContext]?.length || 0) /
                              UPPER_TRANSFORMATION_THRESHOLD) *
                              99
                          )
                        )}
                      />
                    </Box>
                  )}
                  {tab.label}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.key}>
                  <Text fontSize="sm" color="gray.600" mb={4}>
                    {tab.description}
                  </Text>
                  <TabTextInput
                    value={context[tab.key as keyof NegotiationContext] ?? ""}
                    onChange={(value) => handleTextChange(tab.key as keyof NegotiationContext, value)}
                    placeholder={intl.formatMessage({
                      id: `negotiation.data.${tab.key}.placeholder`,
                      description: "Placeholder for negotiation context input",
                      defaultMessage: `Enter ${tab.label.toLowerCase()} details...`,
                    })}
                    isLastTab={index === TABS.length - 1}
                    isFirstTab={index === 0}
                    onNext={handleNext}
                    onBack={handleBack}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
