import { Viewer, Worker } from "@brmlabs/react-pdf-viewer-core"
import { thumbnailPlugin } from "@brmlabs/react-pdf-viewer-thumbnail"
import { Box, Spinner, Stack, Text } from "@chakra-ui/react"
import pdfJsWorker from "pdfjs-dist/build/pdf.worker?url"
import { useState } from "react"
import { useIntl } from "react-intl"
import { LinkOrSpan } from "../Link.js"
import { DocumentDisplay } from "./DocumentDisplay.js"
import { pageThumbnailPlugin } from "./DocumentViewerPlugin.js"

// Displays a thumbnail of the first page of the document
export const DocumentThumbnailPreview = ({
  fileUrl,
  displayName,
  mimeType,
}: {
  fileUrl: string
  displayName: string
  mimeType: string
}) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const intl = useIntl()
  const thumbnailPluginInstance = thumbnailPlugin({
    renderSpinner: () => <Spinner marginTop={2} />,
  })
  const { Cover } = thumbnailPluginInstance
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PageThumbnail: (
      <Box
        marginTop={1.5}
        padding={2}
        backgroundColor="gray.700"
        borderRadius="md"
        width="100%"
        cursor="pointer"
        onClick={() => {
          window.open(fileUrl, "_blank")
        }}
      >
        <Cover getPageIndex={() => 0} width={235} />
      </Box>
    ),
  })

  // TODO: Create thumbnails for non pdf type files (csv, docx, etc)
  if (!mimeType.startsWith("application/pdf")) {
    return (
      <DocumentDisplay
        document={{
          file_name: displayName,
          mime_type: mimeType,
          status: "uploaded",
        }}
      />
    )
  }

  return (
    <Stack gap={0.5}>
      <LinkOrSpan
        to={fileUrl}
        target="_blank"
        fontWeight="semibold"
        color="gray.600"
        noOfLines={1}
        isTruncated
        overflow="hidden"
        _hover={{
          color: "gray.700",
          textDecoration: "underline",
        }}
      >
        {displayName}
      </LinkOrSpan>
      {numPages && (
        <Text fontSize="sm" color="gray.500">
          {intl.formatMessage(
            {
              id: "document.thumbnail.preview.pageCount",
              defaultMessage: "{pageCount, plural, one {# page} other {# pages}}",
              description: "The number of pages in the document",
            },
            {
              pageCount: numPages,
            }
          )}
        </Text>
      )}
      <Worker workerUrl={pdfJsWorker}>
        <Viewer
          fileUrl={fileUrl}
          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
          onDocumentLoad={(documentLoadEvent) => {
            setNumPages(documentLoadEvent.doc.numPages)
          }}
          renderLoader={() => <Spinner marginTop={2} />}
        />
      </Worker>
    </Stack>
  )
}
