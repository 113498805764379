import type { PickableEntityFilter } from "@brm/schema-types/types.js"
import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Descendant } from "slate"
import { RefreshIcon } from "../../../../../components/icons/icons.js"
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor.js"
import { EMPTY_RICH_TEXT_BODY } from "../../../../../components/RichTextEditor/util/common.js"
import { isApplePlatform } from "../../../../../util/platform.js"
import { type GetLogoForOrganizationProps, displayWorkflowRunStepStatus } from "../../utils.js"
import { WorkflowRunStepStatusBadge } from "../status/WorkflowRunStepStatusBadge.js"

export default function WorkflowRunStepUnapproveButtonCore({
  workflowRunStepDisplayName,
  workflowRunStepGathererName,
  getLogoToShowByOrganizationId,
  onUnapprove,
  isSubmitting,
  formId,
  pickableEntityFilters,
}: {
  workflowRunStepDisplayName: string
  workflowRunStepGathererName: string
  onUnapprove: (reason: Descendant[]) => Promise<void>
  isSubmitting: boolean
  formId: string
  pickableEntityFilters: Omit<PickableEntityFilter, "name">
} & GetLogoForOrganizationProps) {
  const toast = useToast()
  const intl = useIntl()
  const unapproveModal = useDisclosure()
  const initialFocusRef = useRef<HTMLTextAreaElement | null>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const form = useForm<{ reason: Descendant[] }>({
    defaultValues: { reason: EMPTY_RICH_TEXT_BODY },
    mode: "onSubmit",
  })

  const undoApprovalLabel = intl.formatMessage(
    {
      id: "requests.run.step.approval.undo.label",
      description: "Label for user action that can undo approval of an approved step",
      defaultMessage: "Undo {stepName} Approval",
    },
    { stepName: workflowRunStepDisplayName }
  )

  return (
    <>
      <Button
        variant="outline"
        aria-label={undoApprovalLabel}
        onClick={unapproveModal.onOpen}
        rightIcon={<Icon as={RefreshIcon} />}
      >
        {undoApprovalLabel}
      </Button>
      <Modal {...unapproveModal} size="xl" isCentered initialFocusRef={initialFocusRef}>
        <ModalOverlay />
        <ModalContent
          as={chakra.form}
          noValidate={true}
          method="dialog"
          ref={formRef}
          id={formId}
          onSubmit={async (event) => {
            event.preventDefault()
            event.stopPropagation()
            await form.handleSubmit(async (values) => {
              try {
                await onUnapprove(values.reason)
                toast({
                  description: intl.formatMessage(
                    {
                      id: "requests.run.step.approval.undo.toast.title",
                      description: "Toast title when a step is unapproved",
                      defaultMessage: "Step moved back to {inProgress}",
                    },
                    {
                      inProgress: displayWorkflowRunStepStatus({ status: "in_progress" }, intl),
                    }
                  ),
                  status: "success",
                })
                unapproveModal.onClose()
              } catch (_err) {
                toast({
                  description: intl.formatMessage({
                    id: "requests.run.step.approval.undo.toast.error.title",
                    description: "Toast title when there is an error unapproving a step",
                    defaultMessage: "Something went wrong while changing the approval status of this step",
                  }),

                  status: "error",
                })
              }
            })(event)
          }}
        >
          <ModalHeader>
            {undoApprovalLabel}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <chakra.span>
              <FormattedMessage
                defaultMessage="This will move the step back to {inProgress} and notify {gatherer} to submit again."
                description="The body of the undo approval modal"
                id="requests.run.unapprove.modal.body"
                values={{
                  inProgress: <WorkflowRunStepStatusBadge step={{ status: "in_progress" }} />,
                  gatherer: <strong>{workflowRunStepGathererName}</strong>,
                }}
              />
            </chakra.span>
            <Controller
              name="reason"
              control={form.control}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={fieldState.invalid}>
                  <RichTextEditor
                    ref={initialFocusRef}
                    initialValue={EMPTY_RICH_TEXT_BODY}
                    onChange={(value) => field.onChange(value)}
                    placeholder={intl.formatMessage({
                      id: "requests.run.step.approval.undo.popup.textarea.placeholder",
                      description:
                        "Placeholder text in text area that prompts user to explain the reasoning for undoing the approval",
                      defaultMessage: "Please explain why you are undoing the approval (optional)",
                    })}
                    aria-invalid={fieldState.invalid}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Unapprove step reason",
                      description: "Aria label for the unapprove step reason input",
                      id: "requests.run.step.approval.undo.reason.label",
                    })}
                    hasError={fieldState.invalid}
                    pickableEntityFilters={pickableEntityFilters}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                    form={formId}
                    containerProps={{
                      onKeyDown: (event) => {
                        if (event.key === "Enter" && (event.metaKey || event.ctrlKey) && !isSubmitting) {
                          formRef.current?.requestSubmit()
                        }
                      },
                    }}
                    disableFileDrop
                  />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button type="button" onClick={unapproveModal.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="The label of the cancel button in the undo approval modal"
                id="requests.run.unapprove.modal.cancel"
              />
            </Button>
            <Button
              isLoading={isSubmitting}
              colorScheme="brand"
              type="submit"
              aria-keyshortcuts={isApplePlatform() ? "Meta+Enter" : "Ctrl+Enter"}
            >
              <FormattedMessage
                defaultMessage="Confirm"
                description="The label of the confirm button in the undo approval modal"
                id="requests.run.unapprove.modal.confirm"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
