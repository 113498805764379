import { type ListQueryStringParams, type ToolListItem } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { mapBy } from "@brm/util/collections.js"
import { toolRouteById } from "@brm/util/routes.js"
import { Box, Center, Flex, useToast } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { ColumnPinningState } from "@tanstack/react-table"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import {
  useGetSavedViewV1ByTableIdentifierQuery,
  usePostToolV1AiFilterMutation,
  usePostToolV1CsvMutation,
  usePostToolV1ListQuery,
} from "../../../app/services/generated-api.js"
import DataTable from "../../../components/DataTable/DataTable.js"
import FullDataTableHeader from "../../../components/DataTable/FullDataTableHeader.js"
import TablePageHeader from "../../../components/DataTable/SchemaFilter/TablePageHeader.js"
import SchemaTableColumnCustomization from "../../../components/DataTable/SchemaTableColumnCustomization.js"
import { displayNameForTableId } from "../../../components/DataTable/const.js"
import { pathForColumn, ROOT_COLUMN_ID, useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import {
  useLocalStorageTableParamsSync,
  useUrlTableParams,
} from "../../../components/DataTable/use-schema-table-params.js"
import Spinner from "../../../components/spinner.js"
import { toolComplianceDocumentDownloadUrl } from "../../../util/document.js"
import { log } from "../../../util/logger.js"
import {
  packageSortFilterOptionsForAPI,
  shownColumnsForTableParamState,
  TABLE_INITIAL_PAGE,
} from "../../../util/schema-table.js"
import type { FilterTabData } from "../../../util/tabs.js"
import { getTabIndexFromLocation } from "../../../util/tabs.js"
import { useObjectSchema } from "../../../util/use-schema.js"
import ZeroIntegrationsView from "../../error/ZeroIntegrationsView.js"
import { TOOL_TABLE_DEFAULT_PARAMS, TOOL_TABLE_ID } from "./constants.js"

const defaultColumns: BRMPaths<ToolListItem>[] = [
  "owner",
  "spend.ltm",
  "payment_methods",
  "total_people",
  "primary_legal_agreement.display_name",
  "primary_legal_agreement.total_contract_value",
]

// The primary column is the root tool object (so that the cell can access the name, logo and ID).
const primaryColumn = ROOT_COLUMN_ID
const primarySearchColumn: BRMPaths<ToolListItem> = "display_name"

const columnPinning: ColumnPinningState = {
  left: [primaryColumn],
}

export default function ToolList() {
  const intl = useIntl()
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const toolSchema = useObjectSchema("Tool")

  const [queryToolAiFilter] = usePostToolV1AiFilterMutation()
  const [exportCsv, exportCsvResult] = usePostToolV1CsvMutation()

  const { data: savedViewData } = useGetSavedViewV1ByTableIdentifierQuery({ tableIdentifier: TOOL_TABLE_ID })

  useLocalStorageTableParamsSync(TOOL_TABLE_ID)
  const { tableParams, getUpdatedSerializedParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TOOL_TABLE_DEFAULT_PARAMS,
    objectSchema: toolSchema,
    savedViews: savedViewData,
    primarySearchColumn,
  })

  const apiParams = useMemo(
    () => tableParams && toolSchema && packageSortFilterOptionsForAPI(tableParams, toolSchema, intl),
    [intl, tableParams, toolSchema]
  )

  const tabConfig: FilterTabData[] = [
    {
      locationHash: "",
      label: (
        <FormattedMessage
          id="tool.list.page.tab.all"
          description="Tab label to show all tools"
          defaultMessage="All tools"
        />
      ),
    },
    {
      locationHash: "paid",
      label: (
        <FormattedMessage
          id="tool.list.page.tab.paid"
          description="Tab label to show all tools with total spend"
          defaultMessage="Paid"
        />
      ),
      filters: [
        [{ column: "spend.total" satisfies BRMPaths<ToolListItem>, fields: { comparator: "exists", value: true } }],
      ],
    },
    {
      locationHash: "unpaid",
      label: (
        <FormattedMessage
          id="tool.list.page.tab.unpaid"
          description="Tab label to show all tools without a total spend"
          defaultMessage="Unpaid"
        />
      ),
      filters: [
        [{ column: "spend.total" satisfies BRMPaths<ToolListItem>, fields: { comparator: "exists", value: false } }],
      ],
    },
  ]

  const [tabIndex, setTabIndex] = useState(getTabIndexFromLocation(location, tabConfig))

  const apiParamsForTabs =
    apiParams &&
    ([
      apiParams,
      { ...apiParams, filter: [...(apiParams?.filter ?? []), ...(tabConfig[1]?.filters ?? [])] },
      { ...apiParams, filter: [...(apiParams?.filter ?? []), ...(tabConfig[2]?.filters ?? [])] },
    ] satisfies [ListQueryStringParams, ListQueryStringParams, ListQueryStringParams])

  const allToolsQuery = usePostToolV1ListQuery(
    apiParamsForTabs ? { listQueryStringParams: apiParamsForTabs[0] } : skipToken
  )
  const paidToolsQuery = usePostToolV1ListQuery(
    apiParamsForTabs ? { listQueryStringParams: apiParamsForTabs[1] } : skipToken
  )
  const unpaidToolsQuery = usePostToolV1ListQuery(
    apiParamsForTabs ? { listQueryStringParams: apiParamsForTabs[2] } : skipToken
  )

  const tabData = [allToolsQuery.data, paidToolsQuery.data, unpaidToolsQuery.data]
  const data = tabData[tabIndex]
  const isFetching = [allToolsQuery.isFetching, paidToolsQuery.isFetching, unpaidToolsQuery.isFetching][tabIndex]
  const isLoading = [allToolsQuery.isLoading, paidToolsQuery.isLoading, unpaidToolsQuery.isLoading][tabIndex]

  const shownColumns = useMemo(
    () =>
      tableParams &&
      shownColumnsForTableParamState(tableParams, defaultColumns).filter((column) => column !== primaryColumn),
    [tableParams]
  )

  const columns = useSchemaColumns<ToolListItem>({
    objectSchema: toolSchema,
    shownColumns,
    getDocumentDownloadUrlWithRowValue: (tool, fieldPath) =>
      toolComplianceDocumentDownloadUrl(tool.id, fieldPath.join("/")),
    primaryColumn,
  })

  if (!columns || !toolSchema || !getUpdatedSerializedParams || !shownColumns || !apiParamsForTabs) {
    return null
  }

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  const handleChangeTab = (index: number) => {
    const newTab = tabConfig[index]
    isNotUndefined(newTab)
    const hashParams = new URLSearchParams(location.hash.slice(1))
    if (newTab.locationHash) {
      hashParams.set("tab", newTab.locationHash)
    } else {
      hashParams.delete("tab")
    }
    navigate(
      { search: getUpdatedSerializedParams({ page: TABLE_INITIAL_PAGE }).toString(), hash: `#${hashParams}` },
      { replace: true }
    )
    setTabIndex(index)
  }

  return (
    <Flex justifyContent="center" flexDir="column" gap={0} flexGrow={1} minHeight={0}>
      <FullDataTableHeader
        displayTitle={displayNameForTableId(TOOL_TABLE_ID, intl)}
        savedViews={savedViewData}
        savedViewState={tableParams.savedViewState}
        onSavedViewStateChange={(newSavedViewState) => updateTableParams({ savedViewState: newSavedViewState })}
      >
        <TablePageHeader
          tableId={TOOL_TABLE_ID}
          tabProps={{
            data: tabConfig.map((tab, idx) => ({ ...tab, total: tabData[idx]?.total })),
            handleChangeTab,
            selectedIndex: tabIndex,
          }}
          primarySearch={{
            column: primarySearchColumn,
            currentPrimaryFilter: tableParams.primaryFilter?.fields,
            placeholder: intl.formatMessage({
              id: "tool.list.search.name",
              description: "text showing the option to search tools by the name column",
              defaultMessage: "Search tools by name",
            }),
            onPrimaryFilterChange: (fields) =>
              updateTableParams({ primaryFilter: fields && { column: primarySearchColumn, fields } }),
          }}
          queryAiFilter={async (query) => {
            try {
              const result = await queryToolAiFilter({ body: { query } }).unwrap()
              updateTableParams({ filterMap: mapBy(result, (result) => result.column) })
            } catch (err) {
              log.error("Failed to apply AI filter", err)
              toast({
                description: intl.formatMessage({
                  id: "tool.list.filter.ai.error",
                  description: "error message when the AI filter fails",
                  defaultMessage: "Failed to apply AI filter",
                }),
                status: "error",
              })
            }
          }}
          objectSchema={toolSchema}
          filterMap={tableParams.filterMap}
          onChangeFilters={(filterMap) => updateTableParams({ filterMap })}
          savedViewState={tableParams.savedViewState}
          onSavedViewStateChange={(savedViewState) => updateTableParams({ savedViewState })}
          selectedColumns={shownColumns}
          afterSavedView={
            <SchemaTableColumnCustomization
              activeColumns={shownColumns}
              primaryColumn={primaryColumn}
              onActiveColumnsChange={(selectedColumns) => updateTableParams({ selectedColumns })}
              objectSchema={toolSchema}
            />
          }
          isExporting={exportCsvResult.isLoading}
          exportCsv={async () => {
            const result = await exportCsv({
              body: {
                ...apiParamsForTabs[tabIndex],
                columns: [pathForColumn(primaryColumn), ...shownColumns],
              },
            }).unwrap()
            return result.url
          }}
        />
      </FullDataTableHeader>
      {
        // HANDLE EMPTY TABLE STATE
        data ? (
          <DataTable<ToolListItem>
            data={data.tools}
            columns={columns}
            columnPinning={columnPinning}
            columnOrder={[primaryColumn, ...shownColumns]}
            sorting={tableParams.sorting}
            onSortingChange={(newSorting) =>
              newSorting instanceof Function
                ? updateTableParams({ sorting: newSorting(tableParams.sorting) })
                : updateTableParams({ sorting: newSorting })
            }
            onRowClick={(row, event) => {
              if (event.metaKey || event.ctrlKey) {
                return window.open(toolRouteById(row.id))
              }
              return navigate(row.id)
            }}
            paginationProps={{
              page: tableParams.page,
              pageSize: tableParams.pageSize,
              onPageChange: (page) => updateTableParams({ page }),
              onPageSizeChange: (pageSize) => updateTableParams({ pageSize }),
              totalListElements: data.total,
            }}
            isSticky
          />
        ) : (
          <Box flexGrow={1}>
            {!isFetching && tableParams.filterMap.size === 0 && tableParams.sorting.length === 0 && (
              <ZeroIntegrationsView />
            )}
          </Box>
        )
      }
    </Flex>
  )
}
