import type {
  DocumentWithExtraction,
  ExtractedReceiptFields,
  FieldMetadataWithSuggestions,
  FieldTagPicker,
  LegalClauses,
  LegalClausesPatch,
  PageSource,
  PickableEntityFilter,
  Receipt,
} from "@brm/schema-types/types.js"
import { theme } from "@brm/theme"
import { isCurrencyAmountType } from "@brm/type-helpers/schema.js"
import { formatCurrency } from "@brm/util/currency/format.js"
import { displayPersonName } from "@brm/util/names.js"
import { getSchemaAtPath, getTitle, isIntegerType, isNumberType, isStringType } from "@brm/util/schema.js"
import { hasOwnProperty, isEmpty, isObject } from "@brm/util/type-guard.js"
import { SpecialZoomLevel, Viewer, Worker } from "@brmlabs/react-pdf-viewer-core"
import "@brmlabs/react-pdf-viewer-core/lib/styles/index.css"
import "@brmlabs/react-pdf-viewer-default-layout/lib/styles/index.css"
import {
  highlightPlugin,
  type HighlightArea,
  type RenderHighlightsProps,
  type RenderHighlightTargetProps,
} from "@brmlabs/react-pdf-viewer-highlight"
import type {
  Match,
  RenderHighlightsProps as RenderHighlightSearchProps,
  RenderSearchProps,
} from "@brmlabs/react-pdf-viewer-search"
import { searchPlugin } from "@brmlabs/react-pdf-viewer-search"
import { thumbnailPlugin } from "@brmlabs/react-pdf-viewer-thumbnail"
import "@brmlabs/react-pdf-viewer-thumbnail/lib/styles/index.css"
import { toolbarPlugin, type ToolbarSlot } from "@brmlabs/react-pdf-viewer-toolbar"
import type { ColorHues, StyleProps } from "@chakra-ui/react"
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useStyleConfig,
  useToast,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import escapeRegExp from "escape-string-regexp"
import equal from "fast-deep-equal"
import objectPath from "object-path"
import pdfJsWorker from "pdfjs-dist/build/pdf.worker?url"
import { memo, useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import type { ReadonlyDeep } from "type-fest"
import { useCustomCompareCallback } from "use-custom-compare"
import { useResizeObserver } from "usehooks-ts"
import zIndices from "../../../../packages/theme/src/foundations/z-index.js"
import {
  usePostOrganizationV1PickableEntitiesQuery,
  usePostSchemaV1FieldsTextToFieldMutation,
  type Transaction,
} from "../../app/services/generated-api.js"
import { getLegalClausesDisplayNames } from "../../features/legal/legal-clause-labels.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { matchesLegalAgreementPath } from "../../util/json-schema.js"
import { log } from "../../util/logger.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { useContextMenu } from "../ContextMenu/context-menu.js"
import { ExtractionIcon } from "../ExtractionHighlight.js"
import { FormattedCurrency } from "../FormattedCurrency.js"
import { FormattedDate } from "../FormattedDate.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import {
  ArrowDownIcon,
  ArrowUpIcon,
  AssignToCriteriaIcon,
  BackIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DownloadIcon,
  PageActualSizeIcon,
  PageFitIcon,
  PageWidthIcon,
  ReceiptIcon,
  RefreshIcon,
  SearchIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "../icons/icons.js"
import { Link } from "../Link.js"
import { ListBoxItem, ListBoxSection } from "../ListBox.js"
import { ListBoxWithInput } from "../ListBoxWithInput.js"
import OverflownText from "../OverflownText.js"
import Spinner from "../spinner.js"
import { FileTypeIcon } from "./FileTypeIcon.js"

export interface DocumentViewerProps {
  document?: DocumentWithExtraction | null
  /** Relative path from the closest standard object as root */
  path?: (string | number)[]
  /** Full path with the schema as root */
  parentPath?: (string | number)[]
  value?: unknown
  rootSchema: ReadonlyDeep<JSONSchema> | undefined
  downloadUrl?: string
  closeButton?: ReactNode
  onAssignToCriteriaSuccess?: (
    path: (string | number)[],
    newVal: unknown,
    source: PageSource,
    field: FieldTagPicker
  ) => void
  /**
   * A ReactNode to be rendered in the context menu.
   * This is useful for adding custom actions to the context menu.
   */
  menuActions?: ReactNode
  /** The standard object that is requesting the document */
  requestingEntity: PickableEntityFilter["requesting_entity"]
  fieldMetadata?: FieldMetadataWithSuggestions
  /** Transaction data shown under toolbar */
  transactionData?: {
    transactions: Transaction[]
    selectedTransaction?: Transaction
  }
}

function documentViewerPropsAreEqual(prevProps: DocumentViewerProps, nextProps: DocumentViewerProps) {
  return (
    prevProps.path === nextProps.path &&
    prevProps.parentPath === nextProps.parentPath &&
    prevProps.value === nextProps.value &&
    prevProps.document === nextProps.document &&
    prevProps.rootSchema === nextProps.rootSchema &&
    prevProps.downloadUrl === nextProps.downloadUrl &&
    prevProps.closeButton === nextProps.closeButton &&
    prevProps.menuActions === nextProps.menuActions &&
    prevProps.requestingEntity === nextProps.requestingEntity &&
    prevProps.onAssignToCriteriaSuccess === nextProps.onAssignToCriteriaSuccess &&
    prevProps.fieldMetadata === nextProps.fieldMetadata &&
    prevProps.transactionData === nextProps.transactionData
  )
}

const zoomOptions = [50, 75, 100, 125, 150, 200, 300]
// TODO: Find a better way to match all quotation marks
const quotations = /['"‘’“”„‚«»‹›「」『』《》〈〉〝〞〟｀´`ʼ]/gu
const transactionPathToReceiptPath: Record<string, keyof ExtractedReceiptFields> = {
  start_date: "service_start_date",
  end_date: "service_end_date",
}

const textToRegexp = (searchedText: string) => {
  const escapedString = escapeRegExp(searchedText)
  // Fuzzy search to match any white space and quotes
  const regexPattern = escapedString.replace(/\s+/gu, /\s*/u.source).replace(quotations, quotations.source)
  return new RegExp(regexPattern, "g")
}

/** Heavier weight version of DocumentViewer that supports source highlighting */
export const DocumentViewerWithHighlighting = memo(function DocumentViewerWithHighlighting({
  document,
  path,
  parentPath,
  value,
  rootSchema,
  downloadUrl,
  closeButton,
  menuActions,
  requestingEntity,
  onAssignToCriteriaSuccess,
  fieldMetadata,
  transactionData,
}: DocumentViewerProps) {
  const intl = useIntl()
  const {
    isOpen: isSearchOpen,
    onOpen: onSearchOpen,
    onClose: onSearchClose,
    onToggle: onSearchToggle,
  } = useDisclosure()
  const {
    isOpen: isAssignToCriteriaOpen,
    onClose: onAssignToCriteriaClose,
    onOpen: onAssignToCriteriaOpen,
  } = useDisclosure()
  const highlightColor = (theme.colors?.brand as Partial<ColorHues>)[300] ?? "brand"
  const [highlightedText, setHighlightedText] = useState<string | undefined>(undefined)
  const [highlightedAreas, setHighlightedAreas] = useState<HighlightArea[] | undefined>(undefined)
  const { menuListProps, menuProps, subjectProps, betsyProps, menuItemProps, menuButtonProps } =
    useContextMenu<HTMLDivElement>({
      betsyEnabled: true,
      objectType: requestingEntity?.object_type,
      id: requestingEntity?.object_id,
    })

  const renderHighlightTarget = useCallback((props: RenderHighlightTargetProps) => {
    setHighlightedText(props.selectedText)
    setHighlightedAreas(props.highlightAreas)
    return <div></div>
  }, [])

  const renderHighlightedText = useCallback(
    (props: RenderHighlightsProps) => {
      if (!highlightedAreas || !isAssignToCriteriaOpen) {
        return <div></div>
      }
      return (
        <div style={{ opacity: 0.3 }}>
          {highlightedAreas.map((area, i) => (
            <div
              key={i}
              style={{
                ...props.getCssProperties(area, props.rotation),
                backgroundColor: highlightColor,
                outline: `4px solid ${highlightColor}`,
                borderRadius: 0,
              }}
            />
          ))}
        </div>
      )
    },
    [highlightColor, highlightedAreas, isAssignToCriteriaOpen]
  )

  const highlightPluginInstance = highlightPlugin({ renderHighlightTarget, renderHighlights: renderHighlightedText })
  const thumbnailPluginInstance = thumbnailPlugin({ renderSpinner: () => <Spinner /> })
  const toolbarPluginInstance = toolbarPlugin({})

  const {
    Toolbar,
    pageNavigationPluginInstance: { jumpToPage },
    zoomPluginInstance: { zoomTo },
  } = toolbarPluginInstance
  const { Thumbnails } = thumbnailPluginInstance

  const [zoomState, setZoomState] = useState<undefined | SpecialZoomLevel>(SpecialZoomLevel.PageWidth)
  const schema = useMemo(
    () => path && getSchemaAtPath(rootSchema, [...(parentPath || []), ...path]),
    [path, rootSchema, parentPath]
  )
  const pathString = path?.join(".") || ""

  const extractedSource: PageSource | undefined = useMemo(
    () =>
      fieldMetadata?.assigned_by_metadata?.source ||
      (objectPath.get(document?.legal_agreement_extraction_result?.sources || {}, pathString) as
        | PageSource
        | undefined) ||
      (objectPath.get(document?.tool_extraction_result?.sources || {}, pathString) as PageSource | undefined) ||
      (objectPath.get(document?.vendor_extraction_result?.sources || {}, pathString) as PageSource | undefined) ||
      (objectPath.get(
        document?.receipt_extraction_result?.sources || {},
        transactionPathToReceiptPath[pathString] || pathString
      ) as PageSource | undefined) ||
      (objectPath.get(document?.sor_extraction_result?.sources || {}, pathString) as PageSource | undefined),
    [document, fieldMetadata?.assigned_by_metadata?.source, pathString]
  )

  /**
   * This callback powers highlighting and jumping to provenance source in document.
   * We must make an element with rpv-search__highlight classname for React PDF Viewer to manage in the highlight layer.
   * RPV always jumps to first highlighted element and scrolls it into view on top of the page.
   * Due to this, we make a rpv-search__highlight classname element with a 50% marginTop, in which we place our highlighting element at the bottom.
   * When RPV scrolls rpv-search__highlight to top of page, our highlighted text will be centered.
   */
  const renderHighlights = useCallback(
    (props: RenderHighlightSearchProps) => {
      const color =
        fieldMetadata?.type === "user"
          ? highlightColor
          : ((theme.colors?.purple as Partial<ColorHues>)[300] ?? "purple")
      return (
        <Box opacity={0.3}>
          {props.highlightAreas.map((area, index) => {
            return (
              <Box
                // Default library className for auto scroll
                // https://github.com/react-pdf-viewer/react-pdf-viewer/blob/a9e65324d4e4f8fed563aedaa0544a8edcc8197f/packages/search/src/Highlights.tsx#L338
                className="rpv-search__highlight"
                data-index={index}
                key={index}
                title={area.keywordStr.trim()}
                sx={{
                  ...props.getCssProperties(area),
                }}
                marginTop="-50%" // 50% scroll buffer Top
                paddingTop="50%" // 50% scroll buffer Top
                backgroundColor="transparent" // Dunno why RPV keep putting red backgrounds by default
                borderRadius={0}
              >
                <Box
                  height={`${(area.height * area.pageHeight) / 100}px`} // area.height is a percentage. This scaling is needed to respect Zoom level.
                  backgroundColor={color}
                  outline={`4px solid ${color}`}
                  borderRadius={0}
                />
              </Box>
            )
          })}
        </Box>
      )
    },
    [fieldMetadata?.type, highlightColor]
  )

  const searchPluginInstance = searchPlugin({
    renderHighlights,
  })
  const { highlight, clearHighlights, setTargetPages } = searchPluginInstance

  const containerRef = useRef<HTMLDivElement>(null)
  const [showThumbnails, setShowThumbnails] = useState(true)
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (!width) {
        return
      }
      const breakpoint = 800
      if (width < breakpoint) {
        setShowThumbnails(false)
      } else if (width >= breakpoint) {
        setShowThumbnails(true)
      }
    },
  })
  const highlightProvenance = useCustomCompareCallback(
    async () => {
      async function highlightSource(targetPage?: number) {
        if (extractedSource && extractedSource.text?.length > 0) {
          if (targetPage !== undefined) {
            setTargetPages((target) => target.pageIndex === targetPage)
          }
          const regex = extractedSource.text.filter((t) => t.trim().length > 1).map((t) => textToRegexp(t.trim()))
          const matches = await highlight(regex)
          // Reset target page
          setTargetPages((_) => true)

          if (matches.length === 0 && targetPage !== undefined) {
            await highlightSource()
          }
        }
      }
      const textsToHighlight: string[] = []
      clearHighlights()
      if (value && isObject(schema) && isStringType(schema, value) && schema.format === "date") {
        textsToHighlight.push(value.trim())
        textsToHighlight.push(Temporal.PlainDate.from(value).toLocaleString(intl.locale))
        textsToHighlight.push(
          Temporal.PlainDate.from(value).toLocaleString(intl.locale, {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        )
        textsToHighlight.push(Temporal.PlainDate.from(value).toLocaleString(intl.locale, { dateStyle: "long" }))
      } else if (value && isCurrencyAmountType(schema, value)) {
        const amount = parseFloat(value.amount).toString()
        textsToHighlight.push(amount)
        const formattedCurrency = formatCurrency(value, intl)
        const numericAmount = formattedCurrency.replace(/[^0-9.,-]/gu, "")
        textsToHighlight.push(numericAmount)
      } else if (value && isStringType(schema, value)) {
        textsToHighlight.push(value.trim())
      } else if (value && (isNumberType(schema, value) || isIntegerType(schema, value))) {
        textsToHighlight.push(value.toString())
      }
      // Some values we can naively look up
      if (textsToHighlight.length > 0) {
        const regex = textsToHighlight.map((text) => textToRegexp(text.trim()))
        let matches: Match[] = []
        try {
          matches = await highlight(regex)
        } catch (err) {
          log.error("Failed to highlight regex", err)
        }
        // Highlight source if no values are matched
        if (matches.length === 0) {
          await highlightSource(extractedSource?.page_number)
        }
      } else {
        await highlightSource(extractedSource?.page_number)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, schema, extractedSource, intl],
    equal
  )

  useEffect(() => {
    const runHighlightProvenance = async () => {
      await highlightProvenance()
    }
    void runHighlightProvenance()
  }, [highlightProvenance])

  // Short cut to open search tool
  useHotkeys(
    ["mod+f"],
    (e) => {
      e.preventDefault()
      onSearchToggle()
    },
    { enableOnFormTags: ["input"] }
  )

  // Close search tool
  useHotkeys(
    ["esc"],
    () => {
      onSearchClose()
    },
    { enableOnFormTags: ["input"] }
  )

  const onClose = useCallback(() => {
    onAssignToCriteriaClose()
    setHighlightedText(undefined)
    setHighlightedAreas(undefined)
  }, [onAssignToCriteriaClose])

  if (!document) {
    return (
      <Box flexDirection="column" width="full" height="full" paddingBottom={2}>
        <DocumentToolbar
          closeButton={closeButton}
          isSearchOpen={isSearchOpen}
          onSearchOpen={onSearchOpen}
          onSearchClose={onSearchClose}
          zoomState={zoomState}
          setZoomState={setZoomState}
        />
        <Center height="full" flexDirection="column" alignItems="center">
          <Text fontSize="2xl" color="gray.500">
            <FormattedMessage
              defaultMessage="No document to view"
              description="Placeholder for when there are no documents to display"
              id="document.iframe.noDocument"
            />
          </Text>
        </Center>
      </Box>
    )
  }

  if (!downloadUrl) {
    return null
  }

  const fieldName = path?.[path.length - 1]
  const transactionSourceFieldName =
    fieldMetadata?.type === "transaction" &&
    fieldMetadata.id === transactionData?.selectedTransaction?.id &&
    typeof fieldName === "string"
      ? getTitle(fieldName, schema)
      : undefined

  const mimeType = document.mime_type
  if (mimeType && mimeType.startsWith("image/")) {
    return (
      <Box flexDirection="column" width="full" height="full" paddingBottom={2}>
        <DocumentToolbar
          downloadUrl={downloadUrl}
          closeButton={closeButton}
          isSearchOpen={isSearchOpen}
          onSearchOpen={onSearchOpen}
          onSearchClose={onSearchClose}
          zoomState={zoomState}
          setZoomState={setZoomState}
          displayName={document.file_name ?? undefined}
        />
        <Stack flex={1} justifyContent="center" alignItems="center" gap={4} marginTop={2}>
          {transactionData && (
            <TransactionToolbar {...transactionData} transactionSourceFieldName={transactionSourceFieldName} />
          )}
          <Center height="full" flexDirection="column" alignItems="center">
            <Img maxH="100%" maxW="100%" objectFit="contain" src={downloadUrl} onClick={(e) => e.stopPropagation()} />
          </Center>
        </Stack>
      </Box>
    )
  }
  if (mimeType && mimeType.startsWith("application/pdf")) {
    const top = typeof menuButtonProps.top === "number" ? menuButtonProps.top : 0
    return (
      <Stack width="full" height="full" paddingBottom={2} {...subjectProps.baseProps} ref={containerRef}>
        <Portal>
          {isAssignToCriteriaOpen ? (
            <Popover isOpen={isAssignToCriteriaOpen} onClose={onClose}>
              <PopoverContent>
                <AssignToCriteriaMenu
                  requestingEntity={requestingEntity}
                  highlightedText={highlightedText}
                  highlightedTextPageNumber={highlightedAreas?.[0]?.pageIndex}
                  onAssignToCriteriaError={onClose}
                  onAssignToCriteriaSuccess={(path, newVal, source, field) => {
                    onAssignToCriteriaSuccess?.(path, newVal, source, field)
                    onClose()
                  }}
                  // hacky, but works. it would be preferred to have a PopoverAnchor.
                  top={window.innerHeight - top < 350 ? top - 350 : top}
                  left={menuButtonProps.left}
                />
              </PopoverContent>
            </Popover>
          ) : (
            <Menu {...menuProps}>
              <MenuButton {...menuButtonProps} />
              <MenuList zIndex={zIndices.popover} {...menuListProps}>
                {menuListProps.children}
                {!isEmpty(highlightedText?.trim()) && (
                  <MenuItem
                    key="assign-to-criteria"
                    icon={<Icon as={AssignToCriteriaIcon} />}
                    {...menuItemProps}
                    onClick={onAssignToCriteriaOpen}
                  >
                    <FormattedMessage
                      defaultMessage="Assign to criteria"
                      description="The label for the assign to criteria option in the options menu"
                      id="documentViewer.modal.options.assignToCriteria"
                    />
                  </MenuItem>
                )}
                {menuActions}
              </MenuList>
            </Menu>
          )}
        </Portal>
        {betsyProps?.BetsyModal}
        <Toolbar>
          {(props) => (
            <DocumentToolbar
              {...props}
              downloadUrl={downloadUrl}
              closeButton={closeButton}
              onSearchOpen={onSearchOpen}
              onSearchClose={onSearchClose}
              isSearchOpen={isSearchOpen}
              jumpToPage={jumpToPage}
              zoomState={zoomState}
              setZoomState={setZoomState}
              displayName={document.file_name ?? undefined}
            />
          )}
        </Toolbar>
        <Flex
          height="full"
          width="full"
          sx={{ "span::selection": { backgroundColor: highlightColor } }}
          overflow="hidden"
          flex={1}
        >
          {showThumbnails && (
            <Flex width="20%" overflow="auto" borderRightColor="gray.200" borderRightWidth={1}>
              <Thumbnails />
            </Flex>
          )}
          <Stack flex={1} justifyContent="center" alignItems="center" gap={4}>
            {transactionData && (
              <TransactionToolbar {...transactionData} transactionSourceFieldName={transactionSourceFieldName} />
            )}
            <Flex flex={1} overflow="auto" width="full" height="full">
              <Worker workerUrl={pdfJsWorker}>
                <Viewer
                  // Add some margin between pages
                  pageLayout={{
                    transformSize: ({ size }) => ({
                      height: size.height + 50,
                      width: size.width + 30,
                    }),
                    buildPageStyles: () => ({
                      alignItems: "center",
                      // The library has some weird highlighting bugs when using flex
                      display: "box",
                      justifyContent: "center",
                      overflow: "hidden",
                    }),
                  }}
                  fileUrl={downloadUrl}
                  renderLoader={() => <Spinner />}
                  renderError={(error) => {
                    if (error.name === "UnexpectedResponseException") {
                      // This happens when the gcs url expires
                      return (
                        <Center height="full" flexDirection="column" alignItems="center">
                          <Text fontSize="2xl" color="gray.500">
                            <FormattedMessage
                              defaultMessage="This document timed out"
                              description="Error message when the document times out"
                              id="document.iframe.loadError.timeout"
                            />
                          </Text>
                          <Button onClick={() => window.location.reload()} mt={4} leftIcon={<Icon as={RefreshIcon} />}>
                            <FormattedMessage
                              defaultMessage="Refresh document"
                              description="Refresh document button label"
                              id="document.iframe.loadError.refresh.button"
                            />
                          </Button>
                        </Center>
                      )
                    }
                    return (
                      <Center height="full" flexDirection="column" alignItems="center">
                        <Text fontSize="2xl" color="gray.500">
                          <FormattedMessage
                            defaultMessage="Failed to load document"
                            description="Error message when document fails to load"
                            id="document.iframe.loadError"
                          />
                        </Text>
                      </Center>
                    )
                  }}
                  plugins={[
                    toolbarPluginInstance,
                    thumbnailPluginInstance,
                    searchPluginInstance,
                    highlightPluginInstance,
                  ]}
                  // Re highlight on document load
                  onDocumentLoad={() => {
                    if (zoomState) {
                      zoomTo(zoomState)
                    }
                    // Document needs a bit of time to load before attempting to highlight.
                    // Otherwise it won't find anything
                    setTimeout(async () => {
                      await highlightProvenance()
                    }, 200)
                  }}
                />
              </Worker>
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    )
  }

  return (
    <Stack align="center">
      <Text fontSize="2xl" color="gray.500">
        <FormattedMessage
          defaultMessage="File type can not be displayed in preview"
          description="Placeholder for when no preview"
          id="document.iframe.noPreview"
        />
      </Text>
      <Button as="a" href={downloadUrl} target="_blank" rel="noopener noreferrer" mt={4}>
        <FormattedMessage
          defaultMessage="Download {file_name}"
          description="file download"
          id="document.iframe.download"
          values={{ file_name: document.file_name }}
        />
      </Button>
    </Stack>
  )
}, documentViewerPropsAreEqual)

const PageInput = (props: { jumpToPage: (page: number) => void; numPages: number; currentPage: number }) => {
  const { numPages, currentPage, jumpToPage } = props
  const [value, setValue] = useState(currentPage + 1)

  useEffect(() => {
    setValue(currentPage + 1)
  }, [currentPage])

  return (
    <Input
      width="3rem"
      value={value}
      type="number"
      onChange={(event) => {
        setValue(parseInt(event.currentTarget.value, 10))
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          const index = value - 1
          if (index < numPages && index >= 0) {
            jumpToPage(index)
          } else {
            setValue(props.currentPage + 1)
          }
        }
      }}
    />
  )
}

const SearchPopover = (
  props: RenderSearchProps & {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
) => {
  const { isOpen, onClose, onOpen } = props
  const intl = useIntl()
  const [searchDone, setSearchDone] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Popover initialFocusRef={inputRef} isOpen={isOpen} placement="bottom-start">
      <PopoverTrigger>
        <IconButton
          isActive={isOpen}
          onClick={isOpen ? onClose : onOpen}
          aria-label={intl.formatMessage({
            id: "documentViewerWithHighlighting.searchButtonLabel",
            description: "Label on search button in document viewer with highlighting",
            defaultMessage: "Search",
          })}
          icon={<Icon as={SearchIcon} />}
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <InputGroup my={1}>
            <InputLeftElement>
              <Icon as={SearchIcon} color="gray.500" />
            </InputLeftElement>
            <Input
              ref={inputRef}
              placeholder={intl.formatMessage({
                id: "documentViewerWithHighlighting.searchPlaceholder",
                description: "Placeholder for search in document viewer with highlighting",
                defaultMessage: "Search document",
              })}
              onKeyDown={async (event) => {
                if (event.key === "Enter" && props.keyword) {
                  if (searchDone) {
                    props.jumpToNextMatch()
                  } else {
                    await props.search()
                    setSearchDone(true)
                  }
                }
              }}
              onChange={(event) => {
                props.setKeyword(event.target.value)
                setSearchDone(false)
              }}
            />
            <InputRightElement width="fit-content" px={2}>
              <Text color="gray.500">
                <FormattedMessage
                  id="DocumentViewerWithHighlighting.search.input.matches"
                  description="Current match out of total match label"
                  defaultMessage="{currentMatch} of {totalMatches}"
                  values={{
                    currentMatch: props.currentMatch,
                    totalMatches: props.numberOfMatches,
                  }}
                />
              </Text>
            </InputRightElement>
          </InputGroup>
          <Stack gap={3} py={2}>
            <HStack>
              <Checkbox
                isChecked={props.matchCase}
                onChange={(e) => {
                  setSearchDone(false)
                  props.changeMatchCase(!!e.target.checked)
                }}
              >
                <FormattedMessage
                  defaultMessage="Match case"
                  description="Match case checkbox label in search popover"
                  id="documentViewerWithHighlighting.matchCase"
                />
              </Checkbox>
            </HStack>
            <HStack>
              <Checkbox
                isChecked={props.wholeWords}
                onChange={(e) => {
                  setSearchDone(false)
                  props.changeWholeWords(!!e.target.checked)
                }}
              >
                <FormattedMessage
                  defaultMessage="Whole words"
                  description="Match whole words checkbox label in search popover"
                  id="documentViewerWithHighlighting.wholeWords"
                />
              </Checkbox>
            </HStack>
          </Stack>
        </PopoverBody>
        <PopoverFooter display="flex" alignItems="center" justifyContent="space-between">
          <ButtonGroup isAttached variant="outline">
            <IconButton
              icon={<Icon color="gray.500" as={ArrowUpIcon} />}
              isDisabled={props.numberOfMatches === 0 || props.currentMatch === 1}
              aria-label={intl.formatMessage({
                id: "documentViewerWithHighlighting.jumpToPreviousMatch",
                description: "Label on jump to previous match button in document viewer with highlighting",
                defaultMessage: "Previous match",
              })}
              onClick={() => {
                props.jumpToPreviousMatch()
              }}
            />
            <IconButton
              icon={<Icon color="gray.500" as={ArrowDownIcon} />}
              isDisabled={props.numberOfMatches === 0}
              aria-label={intl.formatMessage({
                id: "documentViewerWithHighlighting.jumpToNextMatch",
                description: "Label on jump to next match button in document viewer with highlighting",
                defaultMessage: "Next match",
              })}
              onClick={() => {
                props.jumpToNextMatch()
              }}
            >
              <FormattedMessage
                defaultMessage="Clear"
                description="Clear button in search popover"
                id="documentViewerWithHighlighting.clear"
              />
            </IconButton>
          </ButtonGroup>
          <Button colorScheme="brand" onClick={onClose}>
            <FormattedMessage
              id="documentViewerWithHighlighting.search.closeButton"
              description="Button to close the search input in document viewer"
              defaultMessage="Close"
            />
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

const DocumentToolbar = (
  props: Partial<ToolbarSlot> & {
    onSearchOpen: () => void
    isSearchOpen: boolean
    onSearchClose: () => void
    jumpToPage?: (page: number) => void
    zoomState: SpecialZoomLevel | undefined
    setZoomState?: (zoomState: SpecialZoomLevel | undefined) => void
    closeButton?: ReactNode
    displayName?: string
    downloadUrl?: string
  }
) => {
  const intl = useIntl()
  const {
    GoToNextPage,
    GoToPreviousPage,
    CurrentPageLabel,
    NumberOfPages,
    Zoom,
    ZoomIn,
    ZoomOut,
    Search,
    isSearchOpen,
    onSearchClose,
    onSearchOpen,
    jumpToPage,
    zoomState,
    setZoomState,
    closeButton,
    displayName,
    downloadUrl,
  } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [showDisplayName, setShowDisplayName] = useState(true)
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (!width) {
        return
      }
      const breakpoint = 800
      if (width < breakpoint) {
        setShowDisplayName(false)
      } else if (width >= breakpoint) {
        setShowDisplayName(true)
      }
    },
  })
  return (
    <Flex
      alignItems="center"
      borderBottomColor="gray.200"
      borderBottomWidth={1}
      shadow="xs"
      padding={2}
      gap={1}
      ref={containerRef}
    >
      <Flex flex={1} gap={1} align="center">
        {closeButton}
        {Search && (
          <Search>
            {(props) => (
              <SearchPopover {...props} onOpen={onSearchOpen} isOpen={isSearchOpen} onClose={onSearchClose} />
            )}
          </Search>
        )}
        {showDisplayName && <OverflownText maxW="200px">{displayName}</OverflownText>}
      </Flex>
      <Flex flex={1} justifyContent="center">
        {GoToPreviousPage &&
          CurrentPageLabel &&
          NumberOfPages &&
          GoToNextPage &&
          ZoomIn &&
          ZoomOut &&
          Zoom &&
          jumpToPage && (
            <Flex flex={2} gap={1} align="center" justifyContent="center">
              <GoToPreviousPage>
                {(props) => (
                  <IconButtonWithTooltip
                    icon={<Icon as={ChevronUpIcon} />}
                    onClick={props.onClick}
                    variant="ghost"
                    label={intl.formatMessage({
                      id: "documentViewerWithHighlighting.previousPageButtonLabel",
                      description: "Label on previous page button in document viewer with highlighting",
                      defaultMessage: "Previous page",
                    })}
                  />
                )}
              </GoToPreviousPage>
              <CurrentPageLabel>
                {(props) => (
                  <PageInput numPages={props.numberOfPages} currentPage={props.currentPage} jumpToPage={jumpToPage} />
                )}
              </CurrentPageLabel>
              <NumberOfPages />
              <GoToNextPage>
                {(props) => (
                  <IconButtonWithTooltip
                    icon={<Icon as={ChevronDownIcon} />}
                    onClick={props.onClick}
                    variant="ghost"
                    label={intl.formatMessage({
                      id: "documentViewerWithHighlighting.nextPageButtonLabel",
                      description: "Label on next page button in document viewer with highlighting",
                      defaultMessage: "Next page",
                    })}
                  />
                )}
              </GoToNextPage>
              <Divider orientation="vertical" />
              <ZoomOut>
                {(props) => (
                  <IconButtonWithTooltip
                    icon={<Icon as={ZoomOutIcon} />}
                    onClick={() => {
                      props.onClick()
                      setZoomState?.(undefined)
                    }}
                    variant="ghost"
                    label={intl.formatMessage({
                      id: "documentViewerWithHighlighting.zoomOutButtonLabel",
                      description: "Label on zoom out button in document viewer with highlighting",
                      defaultMessage: "Zoom out",
                    })}
                  />
                )}
              </ZoomOut>
              <Zoom>
                {(props) => (
                  <Menu>
                    <MenuButton as={Button} fontWeight="normal" variant="ghost" alignItems="center">
                      {`${Math.round(props.scale * 100)}%`}
                    </MenuButton>
                    <MenuList>
                      {zoomOptions.map((zoom) => (
                        <MenuItem
                          key={zoom}
                          onClick={() => {
                            props.onZoom(zoom / 100)
                            setZoomState?.(undefined)
                          }}
                        >
                          {`${zoom}%`}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )}
              </Zoom>
              <ZoomIn>
                {(props) => (
                  <IconButtonWithTooltip
                    icon={<Icon as={ZoomInIcon} />}
                    onClick={() => {
                      props.onClick()
                      setZoomState?.(undefined)
                    }}
                    variant="ghost"
                    label={intl.formatMessage({
                      id: "documentViewerWithHighlighting.zoomInButtonLabel",
                      description: "Label on zoom in button in document viewer with highlighting",
                      defaultMessage: "Zoom in",
                    })}
                  />
                )}
              </ZoomIn>
              <Divider orientation="vertical" />
              <Zoom>
                {(props) => (
                  <>
                    <IconButtonWithTooltip
                      icon={<Icon as={PageActualSizeIcon} />}
                      onClick={() => {
                        props.onZoom(SpecialZoomLevel.ActualSize)
                        setZoomState?.(SpecialZoomLevel.ActualSize)
                      }}
                      variant="ghost"
                      label={intl.formatMessage({
                        id: "documentViewerWithHighlighting.actualSizeZoom",
                        description: "Label on actual size zoom button in document viewer with highlighting",
                        defaultMessage: "Actual size",
                      })}
                      isActive={zoomState === SpecialZoomLevel.ActualSize}
                    />
                    <IconButtonWithTooltip
                      icon={<Icon as={PageFitIcon} />}
                      onClick={() => {
                        props.onZoom(SpecialZoomLevel.PageFit)
                        setZoomState?.(SpecialZoomLevel.PageFit)
                      }}
                      variant="ghost"
                      label={intl.formatMessage({
                        id: "documentViewerWithHighlighting.pageFitZoom",
                        description: "Label on page fit button in document viewer with highlighting",
                        defaultMessage: "Page fit",
                      })}
                      isActive={zoomState === SpecialZoomLevel.PageFit}
                    />
                    <IconButtonWithTooltip
                      icon={<Icon as={PageWidthIcon} />}
                      onClick={() => {
                        props.onZoom(SpecialZoomLevel.PageWidth)
                        setZoomState?.(SpecialZoomLevel.PageWidth)
                      }}
                      variant="ghost"
                      label={intl.formatMessage({
                        id: "documentViewerWithHighlighting.pageWidthZoom",
                        description: "Label on page width button in document viewer with highlighting",
                        defaultMessage: "Page width",
                      })}
                      isActive={zoomState === SpecialZoomLevel.PageWidth}
                    />
                  </>
                )}
              </Zoom>
            </Flex>
          )}
      </Flex>
      <Flex flex={1} justifyContent="flex-end">
        {downloadUrl && (
          <Flex gap={1} align="center">
            <Tooltip
              label={intl.formatMessage({
                id: "documentViewerWithHighlighting.downloadButtonLabel",
                description: "Label on download button in document viewer with highlighting",
                defaultMessage: "Download",
              })}
            >
              <IconButton
                to={downloadUrl}
                download={true}
                variant="ghost"
                as={Link}
                icon={<Icon as={DownloadIcon} />}
                aria-label={intl.formatMessage({
                  id: "documentViewerWithHighlighting.downloadButton.ariaLabel",
                  description: "Aria label on download button in document viewer with highlighting",
                  defaultMessage: "Download",
                })}
              />
            </Tooltip>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

const AssignToCriteriaMenu = ({
  requestingEntity,
  highlightedText,
  highlightedTextPageNumber,
  onAssignToCriteriaSuccess,
  onAssignToCriteriaError,
  ...rest
}: {
  requestingEntity: PickableEntityFilter["requesting_entity"]
  highlightedText?: string
  highlightedTextPageNumber?: number
  onAssignToCriteriaSuccess?: (
    path: (string | number)[],
    newVal: unknown,
    source: PageSource,
    field: FieldTagPicker
  ) => void
  onAssignToCriteriaError: (e?: unknown) => void
} & StyleProps) => {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()
  const [currentSearchParams] = useSearchParams()
  const location = useLocation()
  const ref = useRef<HTMLInputElement>(null)

  const [clausesCriteriaSelected, setClausesCriteriaSelected] = useState(false)
  const [criteriaSearch, setCriteriaSearch] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuStyleConfig = useStyleConfig("Menu") as any
  const { data, isLoading } = usePostOrganizationV1PickableEntitiesQuery({
    body: {
      filter: {
        entities: ["field"],
        requesting_entity: requestingEntity,
        name: criteriaSearch,
      },
      limit: 10,
    },
  })
  const [isLoadingClauses, setIsLoadingClauses] = useState(false)
  const [detectedClauses, setDetectedClauses] = useState<LegalClausesPatch>({})

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [clausesCriteriaSelected])

  const focusField = useCallback(
    (field: FieldTagPicker, editField?: boolean) => {
      const hashParams = new URLSearchParams(location.hash.slice(1))
      const targetSearchParams = new URLSearchParams(currentSearchParams)
      targetSearchParams.set("field", field.field_name)
      if (requestingEntity?.object_id) {
        targetSearchParams.delete("event")
        targetSearchParams.set("type", field.object_type)
      }
      /** Focuses & opens dropdown */
      if (editField) {
        targetSearchParams.set("edit_field", "true")
      }
      navigate({
        search: targetSearchParams.toString(),
        hash: `#${hashParams}`,
      })
    },
    [currentSearchParams, location.hash, navigate, requestingEntity?.object_id]
  )

  const onSuccess = useCallback(
    (path: (number | string)[], fieldValue: unknown, field: FieldTagPicker) => {
      onAssignToCriteriaSuccess?.(
        path,
        fieldValue,
        {
          text: [highlightedText ?? ""],
          page_number: highlightedTextPageNumber,
        },
        field
      )
      setCriteriaSearch("")
      setClausesCriteriaSelected(false)
      setDetectedClauses({})
      // Focus and scroll to field
      focusField(field)
    },
    [focusField, highlightedText, highlightedTextPageNumber, onAssignToCriteriaSuccess]
  )
  const onError = useCallback(
    (e: unknown, field: FieldTagPicker) => {
      onAssignToCriteriaError(e)
      toast({
        status: "warning",
        description: getAPIErrorMessage(e) ?? (
          <FormattedMessage
            defaultMessage="Could not extract value from selected text. Please manually update the field."
            description="The toast description for an error when extracting a value from text"
            id="documentViewerWithHighlighting.toast.error.textToValue"
          />
        ),
      })
      focusField(field, true)
    },
    [focusField, onAssignToCriteriaError, toast]
  )
  const filteredClauses = useMemo(
    () =>
      (Object.entries(getLegalClausesDisplayNames(intl)) as [keyof LegalClauses, string][]).filter(([_, clauseName]) =>
        clauseName.toLocaleLowerCase().includes(criteriaSearch.toLocaleLowerCase())
      ),
    [criteriaSearch, intl]
  )

  const getClausesItem = useCallback(
    ([clauseKey, clauseName]: [keyof LegalClauses, string], isDetected: boolean) => {
      const clauseField: FieldTagPicker = {
        field_name: "clauses",
        is_custom: false,
        object_type: "LegalAgreement",
        type: "field",
        display_name: clauseName,
      }
      return (
        <AssignToCriteriaItem
          key={`clauses.${clauseKey.toString()}`}
          highlightedText={highlightedText || ""}
          onSuccess={(val) => {
            const path = ["clauses", clauseKey]
            onSuccess(path, val, clauseField)
          }}
          onError={(e) => onError(e, clauseField)}
          field={{
            type: "field",
            field_name: clauseKey,
            is_custom: false,
            object_type: "LegalAgreement",
            display_name: clauseName,
          }}
          value={true}
          rightIcon={isDetected && <Icon as={ExtractionIcon} boxSize={4} color="purple.700" />}
        />
      )
    },
    [highlightedText, onError, onSuccess]
  )

  const detectedClausesOption = useMemo(
    () =>
      filteredClauses
        .filter(([key]) => hasOwnProperty(detectedClauses, key) && detectedClauses[key])
        .map((c) => getClausesItem(c, true)),
    [detectedClauses, filteredClauses, getClausesItem]
  )
  const defaultClausesOption = useMemo(
    () =>
      filteredClauses
        .filter(([key]) => !(key in detectedClauses && detectedClauses[key]))
        .map((c) => getClausesItem(c, false)),
    [filteredClauses, detectedClauses, getClausesItem]
  )
  return (
    <ListBoxWithInput
      ref={ref}
      width="250px"
      iconButton={
        clausesCriteriaSelected && (
          <IconButton
            variant="unstyled"
            aria-label={intl.formatMessage({
              id: "documentViewer.modal.options.assignToCriteria.backButton",
              description: "Back icon button to see full list of criteria",
              defaultMessage: "Go back to all criteria",
            })}
            icon={<Icon as={BackIcon} />}
            onClick={() => {
              setClausesCriteriaSelected(false)
              setCriteriaSearch("")
              setDetectedClauses({})
            }}
          />
        )
      }
      emptyMessage={intl.formatMessage({
        id: "documentViewer.modal.options.assignToCriteria.emptyMessage",
        description: "Empty message for assign to criteria in document viewer",
        defaultMessage: "No criteria found",
      })}
      value={criteriaSearch}
      placeholder={
        clausesCriteriaSelected
          ? intl.formatMessage({
              id: "documentViewer.modal.options.assignToClauses.placeholder",
              description: "Placeholder for assign to clauses in document viewer",
              defaultMessage: "Search clauses",
            })
          : intl.formatMessage({
              id: "documentViewer.modal.options.assignToCriteria.placeholder",
              description: "Placeholder for assign to criteria in document viewer",
              defaultMessage: "Search criteria",
            })
      }
      onChange={(e) => setCriteriaSearch(e.target.value)}
      sx={{ ...menuStyleConfig?.list }}
      position="fixed"
      ariaLabel={intl.formatMessage({
        id: "documentViewer.modal.options.assignToCriteria.ariaLabel",
        description: "Aria label for assign to criteria in document viewer",
        defaultMessage: "Assign to criteria",
      })}
      isLoading={isLoading}
      {...rest}
    >
      {clausesCriteriaSelected ? (
        <>
          <ListBoxSection
            title={
              isLoadingClauses
                ? intl.formatMessage({
                    id: "documentViewer.modal.options.assignToClauses.detectingClauses",
                    defaultMessage: "Detecting clauses...",
                    description: "Detecting clauses label",
                  })
                : undefined
            }
          >
            {detectedClausesOption}
          </ListBoxSection>
          {defaultClausesOption}
        </>
      ) : (
        data?.map((criteria) =>
          criteria.type === "field" ? (
            criteria.field_name === "clauses" ? (
              <AssignToCriteriaItem
                key={criteria.field_name}
                highlightedText={highlightedText || ""}
                field={criteria}
                onClick={() => {
                  setClausesCriteriaSelected(true)
                  setCriteriaSearch("")
                  setIsLoadingClauses(true)
                }}
                onSuccess={(val) => {
                  setIsLoadingClauses(false)
                  setDetectedClauses(val as LegalClausesPatch)
                }}
                onError={() => setIsLoadingClauses(false)}
                rightIcon={<Icon as={ChevronRightIcon} boxSize={4} />}
              />
            ) : (
              <AssignToCriteriaItem
                key={criteria.field_name}
                highlightedText={highlightedText || ""}
                field={criteria}
                onSuccess={(val) => {
                  const path = criteria.is_custom ? ["custom", criteria.field_name] : [criteria.field_name]
                  onSuccess(path, val, criteria)
                }}
                onError={(e) => onError(e, criteria)}
              />
            )
          ) : null
        )
      )}
    </ListBoxWithInput>
  )
}

const AssignToCriteriaItem = ({
  field,
  highlightedText,
  onClick,
  onSuccess,
  onError,
  value,
  rightIcon,
}: {
  field: FieldTagPicker
  highlightedText: string
  onClick?: () => void
  onSuccess?: (value: unknown) => void
  onError?: (e?: unknown) => void
  /** Pre calculated value, will skip openai call if value is provided. */
  value?: unknown
  rightIcon?: ReactNode
}) => {
  const [textToField, textToFieldResult] = usePostSchemaV1FieldsTextToFieldMutation()
  return (
    <ListBoxItem
      key={field.field_name}
      justifyContent="space-between"
      textValue={field.display_name}
      onAction={async () => {
        if (!highlightedText?.trim()) {
          return
        }
        onClick?.()
        if (value) {
          onSuccess?.(value)
          return
        }
        const { data, error } = await textToField({
          body: {
            source_text: highlightedText,
            object_type: field.object_type,
            is_custom: field.is_custom,
            field_name: field.field_name,
          },
        })
        if (data?.value) {
          onSuccess?.(data.value)
        } else {
          onError?.(error)
        }
      }}
    >
      {field.display_name}
      {rightIcon ??
        (textToFieldResult.isLoading && (
          <Center>
            <Spinner size="sm" />
          </Center>
        ))}
    </ListBoxItem>
  )
}

const TransactionToolbar = ({
  transactions,
  selectedTransaction,
  transactionSourceFieldName,
}: {
  transactions: Transaction[]
  selectedTransaction?: Transaction
  transactionSourceFieldName?: string
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const currentIndex = transactions.findIndex((t) => t.id === selectedTransaction?.id) ?? 0
  const previousTransaction = currentIndex > 0 ? transactions[currentIndex - 1] : undefined
  const nextTransaction = currentIndex < transactions.length - 1 ? transactions[currentIndex + 1] : undefined

  const hashParams = new URLSearchParams(location.hash.slice(1))

  const navigateToTransaction = (transaction: Transaction, receipt?: Receipt) => {
    const hash = new URLSearchParams(hashParams)
    hash.set("transaction", transaction.id)
    if (receipt) {
      hash.set("receipt", receipt.id)
    } else {
      hash.delete("receipt")
    }
    hash.delete("document")
    return navigate(
      {
        search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
        hash: `#${hash}`,
      },
      { state: location.state }
    )
  }

  return (
    <Stack gap={0}>
      <HStack bg="white" boxShadow="md" borderRadius="md" p={2} spacing={4} zIndex={2}>
        {/* Transaction Nav */}
        <Flex alignItems="center" flex={1}>
          <IconButtonWithTooltip
            variant="ghost"
            size="sm"
            aria-label="Previous transaction"
            icon={<Icon as={ChevronLeftIcon} />}
            isDisabled={!previousTransaction}
            onClick={() => previousTransaction && navigateToTransaction(previousTransaction)}
            label={intl.formatMessage({
              id: "documentViewer.modal.options.assignToCriteria.previousTransaction",
              defaultMessage: "Previous transaction",
              description: "Previous transaction label",
            })}
          />
          <Text whiteSpace="nowrap">
            {currentIndex + 1} {" / "} {transactions.length}
          </Text>
          <IconButtonWithTooltip
            size="sm"
            variant="ghost"
            aria-label="Next transaction"
            icon={<Icon as={ChevronRightIcon} />}
            isDisabled={!nextTransaction}
            onClick={() => nextTransaction && navigateToTransaction(nextTransaction)}
            label={intl.formatMessage({
              id: "documentViewer.modal.options.assignToCriteria.nextTransaction",
              defaultMessage: "Next transaction",
              description: "Next transaction label",
            })}
          />
        </Flex>
        {/* Transaction Selector */}
        <Flex>
          <Menu placement="bottom-end" matchWidth>
            <MenuButton as={Button} size="sm" variant="ghost" rightIcon={<Icon as={ChevronDownIcon} />}>
              {selectedTransaction?.transacted_at ? (
                <FormattedDate value={selectedTransaction?.transacted_at} />
              ) : (
                <Text>
                  <FormattedMessage
                    id="documentViewer.modal.options.assignToCriteria.noTransactionSelected"
                    defaultMessage="View transaction"
                    description="No transaction selected label"
                  />
                </Text>
              )}
            </MenuButton>
            <Portal>
              <MenuList textAlign="right" minWidth={0} maxHeight={400} overflow="auto">
                {transactions.map((t) => (
                  <MenuItem key={t.id} value={t.id} onClick={() => navigateToTransaction(t)} justifyContent="end">
                    <FormattedDate value={t.transacted_at} />
                  </MenuItem>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
        {/* Current Amount */}
        {selectedTransaction && (
          <Flex>
            <Text>
              <FormattedCurrency currencyAmount={selectedTransaction.currency_amount} />
            </Text>
          </Flex>
        )}
        {/* Paid by user */}
        {selectedTransaction?.card?.owner && (
          <Flex alignItems="center" gap={1}>
            <Avatar
              src={getPublicImageGcsUrl(selectedTransaction.card?.owner?.profile_image?.gcs_file_name)}
              size="xs"
            ></Avatar>
            <Link
              to={`/people/${selectedTransaction.card?.owner?.id}`}
              target="_blank"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {displayPersonName(selectedTransaction.card?.owner, intl)}
            </Link>
          </Flex>
        )}
        {/* Receipt Selector */}
        <Menu matchWidth={true} placement="bottom-end">
          <MenuButton
            as={Button}
            size="sm"
            variant="ghost"
            isDisabled={!selectedTransaction || selectedTransaction.receipts.length === 0}
            rightIcon={<Icon as={ChevronDownIcon} color="gray.600" />}
            leftIcon={<Icon as={ReceiptIcon} boxSize={4} color="gray.600" />}
          >
            <Text>
              <FormattedMessage
                id="documentViewer.modal.options.assignToCriteria.receipts"
                defaultMessage="{count} {count, plural, one {receipt} other {receipts}}"
                description="Receipts label"
                values={{
                  count: selectedTransaction?.receipts.length,
                }}
              />
            </Text>
          </MenuButton>
          <Portal>
            <MenuList minWidth={0}>
              {selectedTransaction?.receipts.map((r, index) => (
                <MenuItem
                  flexShrink={1}
                  key={r.id}
                  value={r.id}
                  onClick={() => navigateToTransaction(selectedTransaction, r)}
                  justifyContent="space-between"
                >
                  <Icon as={FileTypeIcon} boxSize={4} mimeType={r.document.mime_type} />
                  <Text>
                    <FormattedMessage
                      id="documentViewer.modal.options.assignToCriteria.receipts.index"
                      defaultMessage="Receipt {index}"
                      description="Receipt index label"
                      values={{
                        index: index + 1,
                      }}
                    />
                  </Text>
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      </HStack>
      {transactionSourceFieldName && (
        <Flex width="100%" bg="purple.50" color="purple.700" p={1} alignItems="center">
          <Text fontSize="xs" fontWeight="medium">
            <Icon as={SearchIcon} boxSize={3} />{" "}
            <FormattedMessage
              id="documentViewer.modal.options.assignToCriteria.transactionSourceFieldName"
              defaultMessage="{fieldName} extracted from transaction"
              description="Source field label"
              values={{
                fieldName: transactionSourceFieldName,
              }}
            />
          </Text>
        </Flex>
      )}
    </Stack>
  )
}
