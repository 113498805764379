import type { FieldMetadata, LegalAgreementInput } from "@brm/schema-types/types.js"
import { safeTrim } from "@brm/util/string.js"
import type { Location } from "react-router-dom"
import { matchesLegalAgreementPath } from "../../../util/json-schema.js"
import type { TableParamsState } from "../../../util/schema-table.js"

export const SAVE_AGREEMENT_DEBOUNCE_DELAY = 150

export const getFieldSourceLink = (location: Location, fieldSource: FieldMetadata) => {
  const hashParams = new URLSearchParams(location.hash.slice(1))
  hashParams.delete("receipt")
  hashParams.delete("transaction")
  hashParams.delete("document")
  switch (fieldSource.type) {
    case "document":
      hashParams.set("document", fieldSource.id ?? "")
      return {
        search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "transaction":
      hashParams.set("transaction", fieldSource.id ?? "")
      return {
        search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "user":
      if (fieldSource.assigned_by_metadata?.object_field_source) {
        hashParams.set(
          fieldSource.assigned_by_metadata.object_field_source.type,
          fieldSource.assigned_by_metadata.object_field_source.id
        )
        return {
          search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
          hash: `#${hashParams}`,
        }
      }
      return undefined
    default:
      return undefined
  }
}

// We cannot directly pass in the form state to the api because there are certain fields that need to be formatted before sending to the api
export const formatInputForApi = (values: LegalAgreementInput): LegalAgreementInput => {
  return {
    ...values,
    display_name: values.display_name.trim(),
    // Mark any field that was edited as now coming from "user" input as opposed to extraction, but preserve
    // all previous source metadata for all other fields.
    fields_metadata: {
      ...values.fields_metadata,
    },
    ...(values.agreement_type === "enterprise"
      ? {
          buyer_signer_name: safeTrim(values.buyer_signer_name),
          buyer_signer_title: safeTrim(values.buyer_signer_title),
          vendor_signer_name: safeTrim(values.vendor_signer_name),
          vendor_signer_title: safeTrim(values.vendor_signer_title),
        }
      : {}),
    auto_renewal_opt_out_period: values.auto_renews ? values.auto_renewal_opt_out_period : undefined,
    start_date: safeTrim(values.start_date),
    end_date: safeTrim(values.end_date),
    first_invoice_date: safeTrim(values.first_invoice_date),
  }
}

export const baseUnverifiedAgreementsApiParams: TableParamsState<string> = {
  sorting: [{ id: "verification_status", desc: false }],
  sortingColumns: ["verification_status"],
  page: 1,
  pageSize: 100,
  filterMap: new Map([
    [
      "verification_status",
      {
        column: "verification_status",
        fields: { comparator: "any", includeNull: false, values: ["in_review", "draft"] },
      },
    ],
  ]),
  savedViewState: {},
}

export const onboardingAgreementsApiParams: TableParamsState<string> = {
  sorting: [],
  sortingColumns: [],
  page: 1,
  pageSize: 100,
  filterMap: new Map([
    [
      "visibility_status",
      { column: "visibility_status", fields: { comparator: "any", includeNull: false, values: ["demo"] } },
    ],
  ]),
  savedViewState: {},
}
