import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { OnboardingWelcomeCard } from "../../components/Onboarding/OnboardingWelcomeCard.js"
import { goToNextOnboardingStep } from "./onboarding-slice.js"
import { OnboardingLogoLayout } from "./OnboardingLogoLayout.js"
import { posthogCaptureEvent } from "../../util/posthog-captures.js"
export const OnboardingWelcomeLanding = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const goToNextStep = useCallback(() => {
    posthogCaptureEvent("onboarding_clicked_begin_setup", { step: "welcome.never_miss_a_renewal" })
    dispatch(goToNextOnboardingStep())
  }, [dispatch])

  return (
    <OnboardingLogoLayout
      content={
        <OnboardingWelcomeCard
          preHeader={intl.formatMessage({
            id: "onboarding.welcome.preHeader",
            defaultMessage: "Welcome to BRM!",
            description: "Pre-header text welcoming users to BRM",
          })}
          heading={intl.formatMessage({
            id: "onboarding.welcome.heading",
            defaultMessage: "Never Miss a Renewal.",
            description: "Main heading for the welcome page",
          })}
          contentParagraphs={[
            intl.formatMessage({
              id: "onboarding.welcome.content",
              defaultMessage:
                "Our SuperAgents ensure you stay ahead of every deadline—no last-minute surprises. We handle the busywork, automagically finding, extracting, analyzing, and storing key terms so you don’t have to.",
              description: "Main content paragraph explaining what BRM does",
            }),
          ]}
          contentHighlight={intl.formatMessage({
            id: "onboarding.welcome.highlight",
            defaultMessage: "Everything stays organized. No more manual data entry.",
            description: "Highlighted text emphasizing the benefits of using BRM",
          })}
          cta={intl.formatMessage({
            id: "onboarding.welcome.cta",
            defaultMessage: "Begin Setup",
            description: "Call to action button text to start onboarding",
          })}
          onCtaClick={goToNextStep}
        />
      }
    />
  )
}
