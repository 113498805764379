import type { EnabledOnboardingStep, NullableOnboardingStep } from "@brm/type-helpers/onboarding.js"
import { useEffect, useRef } from "react"
import Popover, { PopoverContent, PopoverTrigger, type PopoverProps } from "../Popover.js"
import OnboardingCallout from "./OnboardingCallout.js"
import { isCalloutConfiguredStep } from "./OnboardingCalloutConfigurations.js"

// Callers configure the position of the popover
interface OnboardingPopoverProps {
  step: NullableOnboardingStep
  anchorElement: React.ReactNode
  isOpen: boolean
  onOpen?: () => void
  hasArrow?: boolean
  arrowSize?: number
  position?: PopoverProps["position"]
  positionMainOffset?: number
  positionCrossOffset?: number
  onNextStep?: () => void
}

export const OnboardingPopover = ({
  step,
  anchorElement,
  isOpen,
  onOpen,
  hasArrow = true,
  arrowSize = 16,
  position,
  positionMainOffset = 16,
  positionCrossOffset = 0,
  onNextStep,
}: OnboardingPopoverProps) => {
  const triggerRef = useRef<HTMLDivElement>(null)
  const wasOpenRef = useRef(false)

  useEffect(() => {
    if (isOpen && !wasOpenRef.current && onOpen) {
      onOpen()
    }
    wasOpenRef.current = isOpen
  }, [isOpen, onOpen])

  useEffect(() => {
    if (isOpen && triggerRef.current) {
      triggerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }, [isOpen])

  if (!step || !isOpen || !isCalloutConfiguredStep(step)) {
    return anchorElement
  }

  return (
    <Popover
      opened={isOpen}
      styles={{
        dropdown: {
          padding: 0,
          borderRadius: "var(--chakra-radii-md)",
          boxShadow: "var(--chakra-shadows-sm)",
          border: "2px solid var(--mantine-color-brand-5)",
          backgroundColor: "white",
        },
        arrow: {
          border: "2px solid var(--mantine-color-brand-5)",
        },
      }}
      position={position}
      withArrow={hasArrow}
      arrowSize={arrowSize}
      offset={{ mainAxis: positionMainOffset, crossAxis: positionCrossOffset }}
    >
      <PopoverTrigger ref={triggerRef}>{anchorElement}</PopoverTrigger>
      <PopoverContent>
        <OnboardingCallout step={step as EnabledOnboardingStep} onNextStep={onNextStep} />
      </PopoverContent>
    </Popover>
  )
}
