import type {
  EmailDraftInput,
  EmailResponseType,
  EmailWithReplyDrafts,
  NegotiationConversation,
  NegotiationWithContext,
  StandardObjectElement,
} from "@brm/schema-types/types.js"
import { constructReplyEmailDraft } from "@brm/type-helpers/email.js"
import { diffWords, visualizeWordDiff } from "@brm/util/diff.js"
import { formatDateTime } from "@brm/util/format-date-time.js"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { chakra, type StyleProps, type SystemStyleObject } from "@chakra-ui/system"
import he from "he"
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type Ref,
} from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { isPresent } from "ts-is-present"
import { useDebouncedCallback } from "use-debounce"
import {
  useGetUserV1WhoamiQuery,
  usePostNegotiationV1EmailSendMutation,
  usePostNegotiationV1RestoreMutation,
  type EmailThread,
} from "../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import {
  BackIcon,
  BoldIcon,
  BulletedListIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CollapseIcon,
  CopyIcon,
  DotsHorizontalIcon,
  EmailIcon,
  FlipBackwardIcon,
  FlipForwardIcon,
  ForwardIcon,
  HeadingIcon,
  ItalicIcon,
  MoreMenuIcon,
  NumberedListIcon,
  QuoteIcon,
  ReflectIcon,
  ReplyAllIcon,
  ReplyIcon,
  SendIcon,
  UnderlineIcon,
} from "../../components/icons/icons.js"
import Iframe from "../../components/Iframe.js"
import NegotiationPanelHeader from "../../components/negotiation/NegotiationPanelHeader.js"
import { EMPTY_EMAIL_DRAFT } from "../../components/negotiation/util.js"
import OverflownText from "../../components/OverflownText.js"
import { BlockButton, MarkButton } from "../../components/RichTextEditor/EditorToolbar.js"
import { EMPTY_RICH_TEXT_BODY } from "../../components/RichTextEditor/util/common.js"
import { attachDownloadUrlsToDocuments } from "../../util/document.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import AttachedDocumentSection from "./email/AttachedDocumentSection.js"
import { useChatContext } from "./use-chat-context.js"

const emailStyles: SystemStyleObject = {
  "& ul": {
    paddingLeft: "var(--chakra-space-4)",
  },
  "& ol": {
    paddingLeft: "var(--chakra-space-4)",
  },
  "& blockquote": {
    marginLeft: "var(--chakra-space-4)",
    borderLeft: "1px solid",
    borderColor: "var(--chakra-colors-gray-300)",
    paddingLeft: "var(--chakra-space-2)",
  },
  "& h1": {
    fontSize: "lg",
  },
}

type ExecCommandType =
  | "bold"
  | "italic"
  | "insertUnorderedList"
  | "insertOrderedList"
  | "underline"
  | "formatBlock"
  | "insertHTML"
  | "insertText"
// Add the new type after EmailResponseType and before EmailComposerComponent
type EmailWithReplyDraftsAndAttachments = EmailWithReplyDrafts & {
  processedAttachments?: Array<{
    id: string
    file_name: string
    file_size: number
    mime_type: string
    download_url: string
  }>
}

export const EmailComposer = memo(EmailComposerComponent)

function EmailComposerComponent({
  emailDraft,
  onChange,
  negotiation,
  negotiationConversation,
  isLoading,
  onSendSuccess,
  emailThread,
  onThreadChange,
}: {
  emailDraft: EmailDraftInput
  onChange: (value: Partial<EmailDraftInput>) => void
  negotiation: NegotiationWithContext
  negotiationConversation: NegotiationConversation | undefined
  isLoading: boolean
  onSendSuccess?: () => void
  emailThread: EmailThread | undefined
  onThreadChange: (emailThreadId: string | undefined) => void
}) {
  const intl = useIntl()

  const { insertEmailContext, setChatValue, getFocusedContexts } = useChatContext()

  const replyToEmailId = (
    getFocusedContexts({ type: "standard-object", objectType: "Email" }) as StandardObjectElement[]
  )[0]?.id

  const replyToEmail = useMemo(() => {
    return negotiation?.email_threads
      ?.map((emailThread) => emailThread.emails.find((email) => email.id === replyToEmailId))
      .find(isPresent)
  }, [negotiation?.email_threads, replyToEmailId])

  // Process emailThread to include attachments with emails
  const processedEmailThread = useMemo(() => {
    if (!emailThread) return undefined

    // Get documents with download URLs
    const documentsWithDownloadUrls = attachDownloadUrlsToDocuments(negotiation.documents, negotiation.id)

    // Map emails to include processed attachments
    return {
      ...emailThread,
      emails: emailThread.emails.map((email) => {
        // Create processed attachments for this email
        const processedAttachments = !email.attachments?.length
          ? []
          : email.attachments
              .map((attachment) => {
                // Find the document with download URL
                return documentsWithDownloadUrls.find((doc) => doc.id === attachment.document_id)
              })
              .filter(isPresent)
              // Filter for documents with all required properties
              .filter((doc) => Boolean(doc.file_name && doc.file_size !== null && doc.mime_type && doc.download_url))
              .map((doc) => ({
                id: doc.id,
                file_name: doc.file_name,
                file_size: doc.file_size,
                mime_type: doc.mime_type,
                download_url: doc.download_url,
              }))

        return {
          ...email,
          processedAttachments,
        } as EmailWithReplyDraftsAndAttachments
      }),
    }
  }, [emailThread, negotiation.documents, negotiation.id])

  const [responseType, setResponseType] = useState<EmailResponseType>("reply")
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const onReply = useCallback(
    async (typeOfResponse: EmailResponseType, respondingEmail: EmailWithReplyDrafts) => {
      setResponseType(typeOfResponse)
      const isSelectingCurrentEmail = respondingEmail.id === replyToEmail?.id

      let latestDraft = emailDraft
      if (!isSelectingCurrentEmail) {
        // Selects new email to reply to and adds context to chat
        insertEmailContext(respondingEmail)
        latestDraft = respondingEmail.reply_email_drafts?.at(-1) ?? EMPTY_EMAIL_DRAFT
      }

      const replyDraft = constructReplyEmailDraft(respondingEmail, typeOfResponse, whoami?.email ?? "", intl)
      onChange({
        ...latestDraft,
        ...replyDraft,
      })
    },
    [replyToEmail?.id, emailDraft, whoami?.email, intl, onChange, insertEmailContext]
  )

  const emailDraftProps: EmailDraftProps = {
    negotiationId: negotiation.id,
    emailDrafts:
      emailThread && emailThread.emails.length > 0
        ? (replyToEmail?.reply_email_drafts ?? [])
        : (negotiation?.initial_email_drafts ?? []),
    emailDraft,
    onChange,
    negotiationConversation,
    isLoading,
    responseType,
    onReply,
    onSendSuccess: () => {
      setChatValue(EMPTY_RICH_TEXT_BODY)
      onSendSuccess?.()
    },
    replyToEmail,
  }

  const sectionTitle = emailThread
    ? (emailThread.emails.at(0)?.subject ?? "")
    : negotiation.email_threads.length > 1
      ? intl.formatMessage({
          id: "email.thread",
          description: "Email thread title",
          defaultMessage: "Email",
        })
      : intl.formatMessage({
          id: "email.thread.new",
          description: "New email thread title",
          defaultMessage: "New email thread",
        })

  return (
    <Stack width="100%" height="100%" textAlign="left" flexDirection="column">
      <NegotiationPanelHeader
        title={sectionTitle}
        // Only show thread navigation if there are multiple threads
        before={
          !!emailThread && negotiation.email_threads.length > 1 ? (
            <IconButton
              variant="ghost"
              aria-label={intl.formatMessage({
                id: "email.back",
                description: "Back to email thread",
                defaultMessage: "Back to email thread",
              })}
              onClick={() => onThreadChange(undefined)}
              icon={<Icon as={BackIcon} />}
              size="sm"
            />
          ) : null
        }
      />

      <Flex direction="column" overflow="auto" textAlign="left" height="100%">
        {processedEmailThread && processedEmailThread.emails.length > 0 ? (
          <EmailThread
            emailThread={processedEmailThread.emails}
            onReply={onReply}
            replyToEmail={replyToEmail}
            emailDraftProps={emailDraftProps}
            currentUserEmail={whoami?.email}
          />
        ) : !processedEmailThread && negotiation.email_threads.length > 1 ? (
          <ThreadNavigator emailThreads={negotiation.email_threads} onThreadClick={onThreadChange} />
        ) : (
          <EmailDraft {...emailDraftProps} />
        )}
      </Flex>
    </Stack>
  )
}

const ThreadNavigator = ({
  emailThreads,
  onThreadClick,
}: {
  emailThreads: EmailThread[]
  onThreadClick?: (emailThreadId: string) => void
}) => {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  return (
    <Stack gap={0}>
      {emailThreads
        .filter((emailThread) => emailThread.emails.length > 0)
        .map((emailThread, index) => {
          const lastEmailActivity = emailThread.emails.at(-1)?.received_at
          const recipients = [
            ...(emailThread.emails.at(0)?.to_email_addresses ?? []),
            ...(emailThread.emails.at(0)?.cc_email_addresses ?? []),
            emailThread.emails.at(0)?.from_email_address,
          ]
          // Recipients that don't have same domain as user's email
          const recipientsInSellerOrg = recipients
            .filter(isPresent)
            .filter((recipient) => recipient.split("@")[1] !== whoami?.email?.split("@")[1])
          return (
            <Button
              padding={2}
              key={index}
              variant="unstyled"
              onClick={() => onThreadClick?.(emailThread.email_thread_id)}
              display="flex"
              gap={0}
              flexDirection="row"
              leftIcon={<Icon as={EmailIcon} boxSize={5} margin={2} />}
              rightIcon={<Icon as={ChevronRightIcon} color="gray.400" margin={2} />}
              height="100%"
              borderBottom="1px solid"
              borderColor="gray.200"
              borderRadius={0}
            >
              <Stack textAlign="left" flex={1} minW={0} gap={1}>
                <OverflownText fontSize="md" fontWeight="semibold">
                  {emailThread.emails.at(0)?.subject}
                </OverflownText>
                <OverflownText fontSize="sm" fontWeight="normal" color="gray.500">
                  {recipientsInSellerOrg.join(", ")}
                </OverflownText>
                <Text fontSize="sm" fontWeight="normal" color="gray.500">
                  {`${emailThread.emails.length} ${emailThread.emails.length === 1 ? "email" : "emails"} | ${
                    lastEmailActivity ? formatDateTime(intl, lastEmailActivity) : "No activity"
                  }`}
                </Text>
              </Stack>
            </Button>
          )
        })}
    </Stack>
  )
}

interface EmailDraftProps {
  emailDrafts: EmailDraftInput[]
  emailDraft: EmailDraftInput
  onChange: (value: Partial<EmailDraftInput>) => void
  negotiationConversation: NegotiationConversation | undefined
  isLoading: boolean
  onReply: (typeOfResponse: EmailResponseType, respondingEmail: EmailWithReplyDrafts) => void
  responseType: EmailResponseType
  onSendSuccess?: () => void
  replyToEmail: EmailWithReplyDrafts | undefined
  negotiationId: string
}

const EmailDraft = forwardRef(function EmailDraft(
  {
    emailDrafts,
    emailDraft,
    onChange,
    negotiationConversation,
    isLoading,
    onReply,
    responseType,
    onSendSuccess,
    replyToEmail,
    negotiationId,
  }: EmailDraftProps,
  ref: Ref<HTMLFormElement | null>
) {
  const intl = useIntl()
  const toast = useToast()
  const [sendEmail, { isLoading: isSendingEmail }] = usePostNegotiationV1EmailSendMutation()
  const [hideCc, setHideCc] = useState(emailDraft.cc_addresses.length === 0)
  const [hideBcc, setHideBcc] = useState(emailDraft.bcc_addresses.length === 0)
  const containerRef = useRef<HTMLFormElement | null>(null)
  useImperativeHandle(ref, () => containerRef.current)

  useEffect(() => {
    if (emailDraft.cc_addresses.length > 0) {
      setHideCc(false)
    }
    if (emailDraft.bcc_addresses.length > 0) {
      setHideBcc(false)
    }
  }, [emailDraft.cc_addresses, emailDraft.bcc_addresses])

  const onSubmit = async () => {
    if (emailDraft && negotiationConversation?.id) {
      try {
        const response = await sendEmail({
          body: {
            email_draft: emailDraft,
            negotiation_conversation_id: negotiationConversation.id,
            reply_to_email_id: replyToEmail?.id,
          },
        })
        if (response.error && "data" in response.error) {
          const errorData = response.error.data as { message?: string }
          throw new Error(errorData.message ?? "Error")
        }
        onChange(EMPTY_EMAIL_DRAFT)
      } catch {
        toast({
          description: intl.formatMessage({
            id: "email.send.error",
            description: "Error message when sending email fails",
            defaultMessage: "Failed to send email",
          }),
          status: "error",
        })
        throw new Error("Failed to send email")
      }
      onSendSuccess?.()
    }
  }

  return (
    <chakra.form
      display="flex"
      flexDirection="column"
      gap={3}
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      paddingX={10}
      paddingY={6}
      ref={containerRef}
    >
      {!replyToEmail && (
        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage id="email.subject" description="Label for email subject field" defaultMessage="Subject" />
          </FormLabel>
          <Input
            value={emailDraft.subject}
            onChange={(e) => onChange({ ...emailDraft, subject: e.target.value })}
            placeholder={intl.formatMessage({
              id: "email.subject.placeholder",
              description: "Placeholder for email subject field",
              defaultMessage: "Enter subject",
            })}
          />
        </FormControl>
      )}
      <FormControl isRequired>
        <HStack justify="space-between">
          <FormLabel display="flex" justifyContent="space-between">
            <FormattedMessage id="email.to" description="Label for email to field" defaultMessage="To" />
          </FormLabel>
          <HStack gap={0}>
            {hideCc && (
              <Button onClick={() => setHideCc(false)} variant="link">
                <FormattedMessage
                  id="email.cc.button"
                  description="Button to show CC field in email form"
                  defaultMessage="Cc"
                />
              </Button>
            )}
            {hideBcc && (
              <Button onClick={() => setHideBcc(false)} variant="link">
                <FormattedMessage
                  id="email.bcc.button"
                  description="Button to show BCC field in email form"
                  defaultMessage="Bcc"
                />
              </Button>
            )}
          </HStack>
        </HStack>
        <InputGroup>
          {replyToEmail && (
            <InputLeftAddon>
              <Menu>
                <Tooltip
                  label={intl.formatMessage({
                    id: "email.responseType.tooltip",
                    description: "Tooltip for email response type picker",
                    defaultMessage: "Type of response",
                  })}
                >
                  <MenuButton gap={1} type="button">
                    <Icon
                      as={
                        responseType === "reply" ? ReplyIcon : responseType === "reply_all" ? ReplyAllIcon : ForwardIcon
                      }
                    />
                    <Icon as={ChevronDownIcon} />
                  </MenuButton>
                </Tooltip>
                <EmailReplyOptions onReply={(responseType) => onReply(responseType, replyToEmail)} />
              </Menu>
            </InputLeftAddon>
          )}
          <Input
            autoFocus={true}
            value={emailDraft.to_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                ...emailDraft,
                to_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                ...emailDraft,
                to_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.to.placeholder",
              description: "Placeholder for email to field",
              defaultMessage: "Enter recipients (comma separated)",
            })}
          />
        </InputGroup>
      </FormControl>

      {!hideCc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.cc" description="Label for email cc field" defaultMessage="Cc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.cc_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                cc_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                cc_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.cc.placeholder",
              description: "Placeholder for email cc field",
              defaultMessage: "Add Cc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      {!hideBcc && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="email.bcc" description="Label for email bcc field" defaultMessage="Bcc" />
          </FormLabel>
          <Input
            autoFocus={true}
            value={emailDraft.bcc_addresses.join(", ")}
            onBlur={(e) =>
              onChange({
                bcc_addresses: e.target.value
                  .split(",")
                  .map((s) => s.trim())
                  .filter(Boolean),
              })
            }
            onChange={(e) =>
              onChange({
                bcc_addresses: [e.target.value],
              })
            }
            placeholder={intl.formatMessage({
              id: "email.bcc.placeholder",
              description: "Placeholder for email bcc field",
              defaultMessage: "Add Bcc recipients (comma separated)",
            })}
          />
        </FormControl>
      )}

      <FormControl minHeight="640px">
        <EmailBodyEditor
          negotiation_id={negotiationId}
          onChange={(htmlBody) => onChange({ ...emailDraft, html_body: htmlBody })}
          emailDrafts={emailDrafts}
          emailDraft={emailDraft}
          isLoading={isLoading}
          reply_value={emailDraft.html_reply_body ?? ""}
          sendButton={
            <IconButtonWithTooltip
              size="sm"
              variant="link"
              colorScheme="brand"
              padding={1}
              isLoading={isSendingEmail}
              onClick={onSubmit}
              icon={<Icon as={SendIcon} />}
              label={intl.formatMessage({
                id: "email.send",
                description: "Aria label for the send button in the email composer",
                defaultMessage: "Send email",
              })}
            />
          }
        />
      </FormControl>
    </chakra.form>
  )
})

const CollapsableEmails = ({
  emails,
  onReply,
  emailDraftProps,
  currentUserEmail,
  ...props
}: {
  emails: EmailWithReplyDraftsAndAttachments[]
  onReply: (typeOfResponse: EmailResponseType, respondingEmail: EmailWithReplyDrafts) => void
  emailDraftProps: EmailDraftProps
  currentUserEmail?: string
} & StyleProps) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      {!isOpen && (
        <Button
          {...props}
          onClick={onToggle}
          variant="ghost"
          flex={1}
          justifyContent="flex-start"
          color="gray.500"
          fontWeight="normal"
          gap={2}
          paddingX={3}
          paddingY={2}
          borderRadius={0}
        >
          <Icon color="gray.400" as={CollapseIcon} boxSize={6} marginX={1} />
          <FormattedMessage
            id="email.thread.toggle"
            description="Button to toggle email thread"
            defaultMessage="{count} {count, plural, one {email} other {emails}} collapsed"
            values={{ count: emails.length }}
          />
        </Button>
      )}
      <Collapse in={isOpen}>
        {emails.map((email, i) => (
          <EmailItem
            email={email}
            key={i + 1}
            borderBottom={0}
            borderTop={i === 0 ? 0 : undefined}
            onReply={onReply}
            emailDraftProps={emailDraftProps}
            currentUserEmail={currentUserEmail}
            isDrafting={false}
          />
        ))}
      </Collapse>
    </>
  )
}

const EmailThread = ({
  emailThread,
  onReply,
  emailDraftProps,
  currentUserEmail,
  replyToEmail,
}: {
  emailThread: EmailWithReplyDraftsAndAttachments[]
  onReply: (typeOfResponse: EmailResponseType, respondingEmail: EmailWithReplyDrafts) => void
  emailDraftProps: EmailDraftProps
  currentUserEmail?: string
  replyToEmail: EmailWithReplyDrafts | undefined
}) => {
  const [indexes, setIndexes] = useState<number[]>([emailThread ? emailThread.length - 1 : 0])
  // the expected experience is that the first and last emails are always visible
  // and the middle emails are collapsed
  // examples
  // [1] => first: 1, middle = [], last = 1 (we filter this case via the thread length)
  // [1,2] => first: 1, middle = [], last = 2
  // [1,2,3] => first: 1, middle = [2], last = 3
  // [1,2,3,4] => first: 1, middle = [2,3], last = 4
  // ...
  const firstEmail = emailThread.at(0)
  const middleEmails = emailThread.slice(1, -1)
  const lastEmail = emailThread.at(-1)

  // 3 flows
  // if there are 0 middle emails, return null
  // if there are 1-4 middle emails, return the emails without any fancy collapsing
  // if there are 5+ middle emails, return a button to toggle the emails
  // if the draft is replying to a middle email, split up the collapse into 2 sections
  const getMiddleItems = () => {
    if (middleEmails.length === 0) return null

    if (middleEmails.length <= 4) {
      return middleEmails.map((email, i) => (
        <EmailItem
          key={i}
          email={email}
          onReply={onReply}
          emailDraftProps={emailDraftProps}
          currentUserEmail={currentUserEmail}
          borderBottom={0}
          borderTop={i === 0 ? 0 : undefined}
          isDrafting={replyToEmail?.id === email.id}
        />
      ))
    }

    const isReplyingToEmailIndex = middleEmails.findIndex((email) => email.id === replyToEmail?.id)

    if (isReplyingToEmailIndex !== -1) {
      const firstHalf = middleEmails.slice(0, isReplyingToEmailIndex)
      const secondHalf = middleEmails.slice(isReplyingToEmailIndex + 1)
      const replyingToEmail = middleEmails[isReplyingToEmailIndex]
      return (
        <>
          {firstHalf.length > 0 && (
            <CollapsableEmails emails={firstHalf} onReply={onReply} emailDraftProps={emailDraftProps} />
          )}
          {replyingToEmail && (
            <EmailItem
              email={replyingToEmail}
              onReply={onReply}
              emailDraftProps={emailDraftProps}
              currentUserEmail={currentUserEmail}
              borderTopWidth={firstHalf.length === 0 ? 0 : undefined}
              isDrafting={true}
            />
          )}
          {secondHalf.length > 0 && (
            <CollapsableEmails
              emails={secondHalf}
              onReply={onReply}
              emailDraftProps={emailDraftProps}
              borderTopWidth={1}
            />
          )}
        </>
      )
    }

    return <CollapsableEmails emails={middleEmails} onReply={onReply} emailDraftProps={emailDraftProps} />
  }

  const emailToShowReplyButtons =
    lastEmail && replyToEmail?.id !== lastEmail.id
      ? lastEmail
      : !lastEmail && replyToEmail?.id === firstEmail?.id
        ? firstEmail
        : undefined

  return (
    <Accordion
      reduceMotion
      allowMultiple={true}
      index={indexes}
      onChange={(newIndexes) => setIndexes(newIndexes as number[])}
    >
      {firstEmail && (
        <EmailItem
          email={firstEmail}
          key={0}
          borderTop={0}
          borderBottomWidth={middleEmails.length > 0 ? 1 : 0}
          onReply={onReply}
          emailDraftProps={emailDraftProps}
          currentUserEmail={currentUserEmail}
          isDrafting={replyToEmail?.id === firstEmail?.id}
        />
      )}
      {getMiddleItems()}
      {lastEmail && emailThread.length > 1 && (
        <EmailItem
          email={lastEmail}
          key={emailThread.length - 1}
          onReply={onReply}
          emailDraftProps={emailDraftProps}
          currentUserEmail={currentUserEmail}
          isDrafting={replyToEmail?.id === lastEmail?.id}
        />
      )}
      {emailToShowReplyButtons && (
        <HStack mx={12} py={4} marginBottom={20} gap={4} borderTopWidth={1} borderColor="gray.200">
          <Button
            colorScheme="brand"
            leftIcon={<Icon as={ReplyAllIcon} />}
            onClick={() => onReply("reply_all", emailToShowReplyButtons)}
          >
            <FormattedMessage id="email.replyAll" description="Button to reply all emails" defaultMessage="Reply All" />
          </Button>
          <Button
            colorScheme="brand"
            leftIcon={<Icon as={ReplyIcon} />}
            onClick={() => onReply("reply", emailToShowReplyButtons)}
          >
            <FormattedMessage id="email.reply" description="Button to reply last email" defaultMessage="Reply" />
          </Button>
          <Button leftIcon={<Icon as={ForwardIcon} />} onClick={() => onReply("forward", emailToShowReplyButtons)}>
            <FormattedMessage id="email.forward" description="Button to forward email" defaultMessage="Forward" />
          </Button>
        </HStack>
      )}
    </Accordion>
  )
}

const EmailItem = ({
  email,
  onReply,
  emailDraftProps,
  currentUserEmail,
  isDrafting,
  ...props
}: {
  email: EmailWithReplyDraftsAndAttachments
  onReply: (typeOfResponse: EmailResponseType, respondingEmail: EmailWithReplyDrafts) => void
  emailDraftProps: EmailDraftProps
  currentUserEmail?: string
  isDrafting: boolean
} & StyleProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hashParams = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash])
  const intl = useIntl()
  const formattedMe = intl.formatMessage({
    id: "email.me",
    description: "Label for email me",
    defaultMessage: "me",
  })
  const emailIdInUrl = hashParams.get("email")
  const emailContainerRef = useRef<HTMLDivElement>(null)
  const draftContainerRef = useRef<HTMLFormElement>(null)
  const { isOpen, onClose, onToggle } = useDisclosure()

  // Attachments are now pre-processed and passed in the email object
  const attachmentsAttachedToEmail = email.processedAttachments || []
  const iframeUrl = new URL(`/email/v1/${email.id}/body`, import.meta.env.VITE_API_BASE_URL).href

  useEffect(() => {
    if (emailIdInUrl === email.id) {
      if (draftContainerRef.current) {
        // Wait for the page to load before scrolling to the email composer
        setTimeout(() => {
          draftContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
          hashParams.delete("email")
          navigate({ search: location.search, hash: `#${hashParams}` }, { replace: true })
        }, 100)
      } else {
        setTimeout(() => {
          emailContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "center" })
          hashParams.delete("email")
          navigate({ search: location.search, hash: `#${hashParams}` }, { replace: true })
        }, 100)
      }
    }
  }, [emailIdInUrl, location.pathname, navigate, hashParams, location.search, email.id])

  return (
    <>
      <AccordionItem {...props} ref={emailContainerRef}>
        {({ isExpanded }) => {
          const showBody = isExpanded || isDrafting
          return (
            <>
              <AccordionButton
                px={3}
                py={2}
                _hover={{ backgroundColor: "white", cursor: isDrafting ? "default" : "pointer" }}
              >
                <HStack gap={3} width="100%">
                  <Avatar
                    size="md"
                    src={getPublicImageGcsUrl(email.from_person?.profile_image?.gcs_file_name)}
                    name={email.from_person?.display_name ?? email.from_email_address}
                  />
                  <Stack gap={0} width="100%">
                    <HStack justify="space-between">
                      <OverflownText fontWeight="semibold" color="gray.600">
                        {email.from_person?.display_name ?? email.from_email_address}
                      </OverflownText>
                      <HStack>
                        {email.received_at && (
                          <Text color="gray.500" fontSize="xs" flexShrink={0}>
                            {formatDateTime(intl, email.received_at)}
                          </Text>
                        )}
                        {(isDrafting || isExpanded) && (
                          <HStack alignItems="flex-start" gap={1}>
                            <IconButtonWithTooltip
                              color="gray.600"
                              size="sm"
                              variant="ghost"
                              icon={<Icon as={ReplyAllIcon} />}
                              label={intl.formatMessage({
                                id: "email.replyAll",
                                description: "Label for email reply all button",
                                defaultMessage: "Reply All",
                              })}
                              onClick={(e) => {
                                e.stopPropagation()
                                onReply("reply_all", email)
                              }}
                            />
                            <Menu>
                              <IconButtonWithTooltip
                                as={MenuButton}
                                color="gray.600"
                                size="sm"
                                variant="ghost"
                                icon={<Icon as={MoreMenuIcon} />}
                                label={intl.formatMessage({
                                  id: "email.replyOptions",
                                  description: "Label for email reply options",
                                  defaultMessage: "More",
                                })}
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                              />
                              <EmailReplyOptions onReply={(responseType) => onReply(responseType, email)} />
                            </Menu>
                          </HStack>
                        )}
                      </HStack>
                    </HStack>
                    <chakra.span
                      color="gray.600"
                      fontSize="sm"
                      noOfLines={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textAlign="left"
                    >
                      {isDrafting || isExpanded ? (
                        <Flex maxW="50%" alignItems="center" gap={1}>
                          <OverflownText>
                            {intl.formatList(
                              [
                                <FormattedMessage
                                  key="to"
                                  id="email.to"
                                  description="Label for email to field"
                                  defaultMessage="To: {to}"
                                  values={{
                                    to: email.to_email_addresses
                                      .map((e) => (e === currentUserEmail ? formattedMe : e))
                                      .join(", "),
                                  }}
                                />,
                                email.cc_email_addresses && email.cc_email_addresses.length > 0 && (
                                  <FormattedMessage
                                    key="cc"
                                    id="email.cc"
                                    description="Label for email cc field"
                                    defaultMessage="cc: {cc}"
                                    values={{
                                      cc: email.cc_email_addresses
                                        .map((e) => (e === currentUserEmail ? formattedMe : e))
                                        .join(", "),
                                    }}
                                  />
                                ),
                                email.bcc_email_addresses && email.bcc_email_addresses.length > 0 && (
                                  <FormattedMessage
                                    key="bcc"
                                    id="email.bcc"
                                    description="Label for email bcc field"
                                    defaultMessage="bcc: {bcc}"
                                    values={{
                                      bcc: email.bcc_email_addresses
                                        .map((e) => (e === currentUserEmail ? formattedMe : e))
                                        .join(", "),
                                    }}
                                  />
                                ),
                              ].filter(isPresent),
                              { type: "unit" }
                            )}
                          </OverflownText>
                          <Popover
                            isOpen={isOpen}
                            onClose={onClose}
                            placement="bottom-start"
                            closeOnBlur={true}
                            returnFocusOnClose={false}
                          >
                            <Tooltip
                              shouldWrapChildren
                              label={intl.formatMessage({
                                id: "email.showMore",
                                description: "Label for email show more button",
                                defaultMessage: "Show details",
                              })}
                            >
                              <PopoverTrigger>
                                <IconButton
                                  color="gray.600"
                                  size="xs"
                                  variant="ghost"
                                  icon={<Icon as={ChevronDownIcon} />}
                                  aria-label={intl.formatMessage({
                                    id: "email.showMore",
                                    description: "Label for email show more button",
                                    defaultMessage: "Show details",
                                  })}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    onToggle()
                                  }}
                                />
                              </PopoverTrigger>
                            </Tooltip>
                            <Portal>
                              <PopoverContent minW="200px" width="auto" maxW="500px">
                                <PopoverBody color="gray.500" display="flex" flexDirection="column" gap={1}>
                                  <Text>
                                    <FormattedMessage
                                      id="email.from"
                                      description="Label for email from field"
                                      defaultMessage="From: {from}"
                                      values={{
                                        from: <chakra.span color="gray.900">{email.from_email_address}</chakra.span>,
                                      }}
                                    />
                                  </Text>
                                  <Text>
                                    <FormattedMessage
                                      id="email.to"
                                      description="Label for email to field"
                                      defaultMessage="To: {to}"
                                      values={{
                                        to: <chakra.span color="gray.900">{email.to_email_addresses}</chakra.span>,
                                      }}
                                    />
                                  </Text>
                                  {email.cc_email_addresses && email.cc_email_addresses.length > 0 && (
                                    <Text>
                                      <FormattedMessage
                                        id="email.cc"
                                        description="Label for email cc field"
                                        defaultMessage="Cc: {cc}"
                                        values={{
                                          cc: <chakra.span color="gray.900">{email.cc_email_addresses}</chakra.span>,
                                        }}
                                      />
                                    </Text>
                                  )}
                                  {email.bcc_email_addresses && email.bcc_email_addresses.length > 0 && (
                                    <Text>
                                      <FormattedMessage
                                        id="email.bcc"
                                        description="Label for email bcc field"
                                        defaultMessage="Bcc: {bcc}"
                                        values={{
                                          bcc: <chakra.span color="gray.900">{email.bcc_email_addresses}</chakra.span>,
                                        }}
                                      />
                                    </Text>
                                  )}
                                  {email.subject && (
                                    <Text>
                                      <FormattedMessage
                                        id="email.subject"
                                        description="Label for email subject field"
                                        defaultMessage="Subject: {subject}"
                                        values={{
                                          subject: <chakra.span color="gray.900">{email.subject}</chakra.span>,
                                        }}
                                      />
                                    </Text>
                                  )}
                                </PopoverBody>
                              </PopoverContent>
                            </Portal>
                          </Popover>
                        </Flex>
                      ) : (
                        email.snippet && he.decode(email.snippet)
                      )}
                    </chakra.span>
                  </Stack>
                </HStack>
              </AccordionButton>
              <Iframe id={email.id} src={iframeUrl} visible={showBody} marginLeft={12} paddingLeft={2} />
              {attachmentsAttachedToEmail.length > 0 && showBody && (
                <AttachedDocumentSection
                  attachments={attachmentsAttachedToEmail.map((a) => ({
                    id: a.id,
                    file_name: a.file_name,
                    file_size: a.file_size,
                    mime_type: a.mime_type,
                    download_url: a.download_url,
                  }))}
                />
              )}
            </>
          )
        }}
      </AccordionItem>
      {isDrafting && (
        <Box borderTopWidth={1} borderColor="gray.200" width="100%">
          <EmailDraft {...emailDraftProps} ref={draftContainerRef} />
        </Box>
      )}
    </>
  )
}

const EmailReplyOptions = ({ onReply }: { onReply: (typeOfResponse: EmailResponseType) => void }) => {
  return (
    <Portal>
      <MenuList>
        <MenuItem icon={<Icon as={ReplyIcon} color="gray.600" />} onClick={() => onReply("reply")}>
          <FormattedMessage id="email.reply" description="Label for email reply button" defaultMessage="Reply" />
        </MenuItem>
        <MenuItem icon={<Icon as={ReplyAllIcon} color="gray.600" />} onClick={() => onReply("reply_all")}>
          <FormattedMessage
            id="email.replyAll"
            description="Label for email reply all button"
            defaultMessage="Reply All"
          />
        </MenuItem>
        <MenuItem icon={<Icon as={ForwardIcon} color="gray.600" />} onClick={() => onReply("forward")}>
          <FormattedMessage id="email.forward" description="Label for email forward button" defaultMessage="Forward" />
        </MenuItem>
      </MenuList>
    </Portal>
  )
}

export const EMAIL_COMPOSER_ID = "email-composer"

interface DisplayEmailDraft {
  id: string | undefined
  html_body: string | undefined
}

export function EmailBodyEditor(props: {
  negotiation_id: string
  reply_value: string // html text
  isLoading: boolean
  emailDrafts: EmailDraftInput[]
  emailDraft: EmailDraftInput
  sendButton: React.ReactNode
  onChange: (htmlBody: string) => void
  debug?: boolean
}) {
  const { reply_value, isLoading, emailDrafts, emailDraft, sendButton, onChange, debug = false, negotiation_id } = props
  const intl = useIntl()
  const toast = useToast()
  const [isFocused, setIsFocused] = useState(false)
  const [activeFormats, setActiveFormats] = useState({
    bold: false,
    italic: false,
    underline: false,
    heading: false,
    quote: false,
    bulletedList: false,
    numberedList: false,
  })
  const [viewMode, setViewMode] = useState<"current" | "diff" | "previous">("current")
  const [showReply, setShowReply] = useState(false)
  const replyEditorRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<HTMLDivElement>(null)

  const [htmlBody, setHtmlBody] = useState<DisplayEmailDraft>({
    id: emailDraft.id,
    html_body: emailDraft.html_body,
  })
  const [previousHtmlBody, setPreviousHtmlBody] = useState<DisplayEmailDraft>({
    id: emailDrafts.at(-2)?.id,
    html_body: emailDrafts.at(-2)?.html_body,
  })

  const emailDraftCursor = useRef<number | undefined>(emailDrafts.length === 0 ? undefined : emailDrafts.length - 1)

  const getIsCurrentDraft = (): boolean => {
    return emailDrafts.length === 0 || emailDraftCursor.current === emailDrafts.length - 1
  }

  const [isCurrentDraft, setIsCurrentDraft] = useState(getIsCurrentDraft())

  const isFlipForwardDisabled: boolean =
    emailDraftCursor.current === undefined || (!!emailDrafts && emailDraftCursor.current >= emailDrafts.length - 1)

  const isFlipBackwardDisabled: boolean =
    emailDraftCursor.current === undefined || (!!emailDrafts && emailDraftCursor.current <= 0)

  const [restoreDraft, { isLoading: isRestoringDraft }] = usePostNegotiationV1RestoreMutation()

  const onFlipForward = isFlipForwardDisabled
    ? null
    : () => {
        if (emailDraftCursor.current !== undefined && emailDrafts) {
          emailDraftCursor.current++
          const newEmailDraft = emailDrafts?.[emailDraftCursor.current]
          const newPreviousEmailDraft = emailDrafts?.[emailDraftCursor.current - 1]
          if (newEmailDraft && newPreviousEmailDraft) {
            setHtmlBody({
              id: newEmailDraft.id,
              html_body: newEmailDraft.html_body,
            })
            setPreviousHtmlBody({
              id: newPreviousEmailDraft.id,
              html_body: newPreviousEmailDraft.html_body,
            })
            setIsCurrentDraft(getIsCurrentDraft())
          }
        }
      }

  const onFlipBackward = isFlipBackwardDisabled
    ? null
    : () => {
        // the email draft cursor position is initially set to the index of the most recent email_draft
        if (emailDraftCursor.current !== undefined && emailDrafts) {
          const savedIndex = emailDraftCursor.current
          emailDraftCursor.current--
          const newEmailDraft = emailDrafts?.[emailDraftCursor.current]

          // specifically, when the incoming cursor index is 1,
          // then we need to set the previous email to empty
          const newPreviousEmailDraft =
            savedIndex === 1 ? EMPTY_EMAIL_DRAFT : emailDrafts?.[emailDraftCursor.current - 1]

          if (newEmailDraft && newPreviousEmailDraft) {
            setHtmlBody({
              id: newEmailDraft.id,
              html_body: newEmailDraft.html_body,
            })
            setPreviousHtmlBody({
              id: newPreviousEmailDraft.id,
              html_body: newPreviousEmailDraft.html_body,
            })
            setIsCurrentDraft(getIsCurrentDraft())
          }
        }
      }

  const backToLatest = () => {
    // reset the email draft cursor to the last email draft
    emailDraftCursor.current = emailDrafts.length - 1
    // restore the last email draft
    setHtmlBody({
      id: emailDrafts.at(-1)?.id,
      html_body: emailDrafts.at(-1)?.html_body,
    })
    setPreviousHtmlBody({
      id: emailDrafts.at(-2)?.id,
      html_body: emailDrafts.at(-2)?.html_body,
    })
    setIsCurrentDraft(true)
  }

  // update the html body when the email draft changes, to allow for streaming updates
  useEffect(() => {
    setHtmlBody({
      id: emailDraft.id,
      html_body: emailDraft.html_body,
    })
  }, [emailDraft])

  useEffect(() => {
    setPreviousHtmlBody({
      id: emailDrafts.at(-2)?.id,
      html_body: emailDrafts.at(-2)?.html_body,
    })
    if (emailDrafts) {
      emailDraftCursor.current = emailDrafts.length - 1
    }
  }, [emailDraftCursor, emailDrafts])

  // lock the editor in the current view mode if the email is loading
  useEffect(() => {
    if (isLoading === true) {
      setViewMode("current")
    }
  }, [isLoading, editorRef])

  useEffect(() => {
    if (editorRef.current) {
      const selection = window.getSelection()
      const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null
      const isEditorFocused = document.activeElement === editorRef.current

      switch (viewMode) {
        case "previous":
          editorRef.current.innerHTML = `<div style="background-color: var(--chakra-colors-orange-50); padding: 8px; border-radius: 4px;">${previousHtmlBody.html_body}</div>`
          break
        case "diff": {
          const diffObject = diffWords(previousHtmlBody.html_body ?? "", htmlBody.html_body ?? "")
          editorRef.current.innerHTML = visualizeWordDiff(diffObject)
          break
        }
        case "current":
        default:
          // Only update innerHTML if it's different from current value
          // This prevents unnecessary cursor resets
          if (editorRef.current.innerHTML !== htmlBody.html_body) {
            editorRef.current.innerHTML = htmlBody.html_body ?? ""
          }
          break
      }

      // Restore selection if editor was focused and we had a valid range
      if (isEditorFocused && selection && range) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }, [editorRef, htmlBody, viewMode, previousHtmlBody])

  useEffect(() => {
    if (replyEditorRef.current) {
      replyEditorRef.current.innerHTML = reply_value
    }
  }, [replyEditorRef, reply_value, showReply])

  const execCommand = useCallback(
    (command: ExecCommandType, value?: string) => {
      document.execCommand(command, false, value)
      editorRef.current?.focus()
      checkActiveFormats()
    },
    [editorRef]
  )

  const debouncedOnChange = useDebouncedCallback((content: string) => {
    onChange(content)
  }, 500)

  const checkActiveFormats = () => {
    setActiveFormats({
      bold: document.queryCommandState("bold"),
      italic: document.queryCommandState("italic"),
      underline: document.queryCommandState("underline"),
      // TODO: This is not working as expected need to use selections
      heading: document.queryCommandState("formatBlock"),
      quote: document.queryCommandState("formatBlock"),
      bulletedList: document.queryCommandState("insertUnorderedList"),
      numberedList: document.queryCommandState("insertOrderedList"),
    })
  }

  useEffect(() => {
    const handleSelectionChange = () => {
      // Only check formats if the selection is within our editor
      if (document.activeElement === editorRef.current) {
        checkActiveFormats()
      }
    }

    document.addEventListener("selectionchange", handleSelectionChange)
    return () => document.removeEventListener("selectionchange", handleSelectionChange)
  }, [editorRef])

  return (
    <>
      <HStack paddingBottom={1} justifyContent="flex-end">
        {debug && (
          <Text fontSize="sm" color="gray.500">
            <FormattedMessage
              id="email.draft.counter"
              description="Counter showing current draft number out of total drafts"
              defaultMessage="Draft {current} of {total}"
              values={{
                current: (emailDraftCursor.current ?? 0) + 1,
                total: emailDrafts.length,
              }}
            />
          </Text>
        )}

        {!isCurrentDraft && (
          <>
            <Button
              variant="ghost"
              size="sm"
              colorScheme="brand"
              isLoading={isRestoringDraft}
              onClick={async () => {
                if (htmlBody.id) {
                  const currentLatestDraft = emailDrafts.at(-1)
                  const restoredDraft = htmlBody

                  try {
                    const response = await restoreDraft({
                      body: {
                        negotiation_id,
                        draft_id: htmlBody.id,
                      },
                    }).unwrap()

                    setHtmlBody({
                      id: response.draft_id,
                      html_body: restoredDraft?.html_body,
                    })

                    setPreviousHtmlBody({
                      id: currentLatestDraft?.id,
                      html_body: currentLatestDraft?.html_body,
                    })

                    setIsCurrentDraft(true)

                    onChange(restoredDraft?.html_body ?? "")

                    toast({
                      title: "Draft restored",
                      status: "success",
                    })
                  } catch (_err) {
                    toast({
                      title: "Error restoring draft",
                      status: "error",
                    })
                  }
                }
              }}
            >
              <FormattedMessage
                id="email.delete"
                description="Label for the delete button in the editor toolbar"
                defaultMessage="Restore this version"
              />
            </Button>

            <Button variant="ghost" size="sm" onClick={backToLatest}>
              <FormattedMessage
                id="email.delete"
                description="Label for the delete button in the editor toolbar"
                defaultMessage="Back to latest"
              />
            </Button>
          </>
        )}
        <Spacer />

        <IconButtonWithTooltip
          isDisabled={isLoading}
          variant="ghost"
          icon={<Icon as={ReflectIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.reflect",
            description: "Label for the reflect button in the editor toolbar",
            defaultMessage: "View Changes",
          })}
          onClick={() => {
            if (viewMode === "diff") {
              setViewMode("current")
            } else {
              setViewMode("diff")
            }
          }}
        />
        <IconButtonWithTooltip
          isDisabled={isLoading || onFlipBackward === null}
          variant="ghost"
          icon={<Icon as={FlipBackwardIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.flip-backward",
            description: "Label for the flip backward button in the editor toolbar",
            defaultMessage: "Previous Email",
          })}
          onClick={() => onFlipBackward?.()}
        />
        <IconButtonWithTooltip
          isDisabled={isLoading || onFlipForward === null}
          variant="ghost"
          icon={<Icon as={FlipForwardIcon} />}
          label={intl.formatMessage({
            id: "editor.toolbar.flip-forward",
            description: "Label for the flip forward button in the editor toolbar",
            defaultMessage: "Next Email",
          })}
          onClick={() => onFlipForward?.()}
        />
      </HStack>

      <Stack
        borderRadius="md"
        border="1px solid"
        borderColor={isFocused ? "brand.500" : "gray.200"}
        boxShadow={isFocused ? "outline" : "none"}
        padding={1}
        transitionProperty="common"
        transitionDuration="normal"
      >
        {/* if this is not the current draft, make the text faint grey and disable editing */}
        <Box
          id={EMAIL_COMPOSER_ID}
          padding={1}
          ref={editorRef}
          onMouseUp={checkActiveFormats}
          onInput={(e) => debouncedOnChange(e.currentTarget.innerHTML)}
          contentEditable={isCurrentDraft ? true : false}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          minHeight={50}
          onPaste={(e) => {
            e.preventDefault()
            const text = e.clipboardData.getData("text/html")
            if (text) {
              execCommand("insertHTML", text)
            } else {
              // Insert plain text if no HTML is available
              const plainText = e.clipboardData.getData("text/plain")
              if (plainText) {
                execCommand("insertText", plainText)
              }
            }
          }}
          boxShadow={0}
          outline={0}
          _focus={{
            outline: "none",
            boxShadow: "none",
          }}
          sx={emailStyles}
          listStylePos="inside"
          {...(!isCurrentDraft && {
            color: "gray.500",
          })}
        />

        {showReply ? (
          <Box
            id={`${EMAIL_COMPOSER_ID}-reply`}
            padding={1}
            ref={replyEditorRef}
            onMouseUp={checkActiveFormats}
            contentEditable={false}
            color="gray.600"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            minHeight={50}
            onPaste={(e) => {
              e.preventDefault()
              const text = e.clipboardData.getData("text/html")
              execCommand("insertHTML", text)
            }}
            boxShadow={0}
            outline={0}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            sx={emailStyles}
            listStylePos="inside"
          />
        ) : (
          reply_value && (
            <IconButtonWithTooltip
              size="sm"
              width="fit-content"
              height="fit-content"
              textAlign="center"
              icon={<Icon as={DotsHorizontalIcon} />}
              onClick={() => setShowReply(true)}
              label={intl.formatMessage({
                id: "editor.toolbar.show-trimmed-content",
                description: "Label for the show trimmed content button in the editor toolbar",
                defaultMessage: "Show trimmed content",
              })}
            />
          )
        )}

        <Flex
          {...(!isCurrentDraft && {
            display: "none",
          })}
          alignItems="center"
          gap={4}
          height={6}
          justifyContent="space-between"
          onMouseDown={(event) => event.preventDefault()}
        >
          <Flex alignItems="center" overflowY="auto" gap={2} height={6} sx={{ scrollbarWidth: "none" }}>
            <MarkButton
              isActive={activeFormats.bold}
              onClick={() => execCommand("bold")}
              icon={<Icon as={BoldIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.bold",
                description: "Label for the bold button in the editor toolbar",
                defaultMessage: "Bold",
              })}
            />
            <MarkButton
              isActive={activeFormats.italic}
              onClick={() => execCommand("italic")}
              icon={<Icon as={ItalicIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.italic",
                description: "Label for the italic button in the editor toolbar",
                defaultMessage: "Italic",
              })}
            />
            <MarkButton
              isActive={activeFormats.underline}
              onClick={() => execCommand("underline")}
              icon={<Icon as={UnderlineIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.underline",
                description: "Label for the underline button in the editor toolbar",
                defaultMessage: "Underline",
              })}
            />
            {/* <AddLinkButton onAddLink={(url) => insertLink(editor, url)} isLinkActive={isLinkActive(editor)} /> */}
            <BlockButton
              isActive={activeFormats.heading}
              onClick={() => execCommand("formatBlock", "h1")}
              icon={<Icon as={HeadingIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.heading",
                description: "Label for the heading button in the editor toolbar",
                defaultMessage: "Heading",
              })}
            />
            <BlockButton
              isActive={activeFormats.quote}
              onClick={() => execCommand("formatBlock", "blockquote")}
              icon={<Icon as={QuoteIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.quote",
                description: "Label for the quote button in the editor toolbar",
                defaultMessage: "Quote",
              })}
            />
            <BlockButton
              isActive={activeFormats.bulletedList}
              onClick={() => execCommand("insertUnorderedList")}
              icon={<Icon as={BulletedListIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.bulleted-list",
                description: "Label for the bulleted list button in the editor toolbar",
                defaultMessage: "Bulleted List",
              })}
            />
            <BlockButton
              isActive={activeFormats.numberedList}
              onClick={() => execCommand("insertOrderedList")}
              icon={<Icon as={NumberedListIcon} />}
              label={intl.formatMessage({
                id: "editor.toolbar.numbered-list",
                description: "Label for the numbered list button in the editor toolbar",
                defaultMessage: "Numbered List",
              })}
            />
          </Flex>
          <HStack gap={1}>
            <IconButtonWithTooltip
              variant="ghost"
              size="sm"
              icon={<Icon as={CopyIcon} />}
              onClick={() => {
                if (editorRef.current) {
                  void navigator.clipboard.write([
                    new ClipboardItem({
                      "text/html": new Blob([editorRef.current.innerHTML], { type: "text/html" }),
                      "text/plain": new Blob([editorRef.current.innerHTML], { type: "text/plain" }),
                    }),
                  ])
                  toast({
                    description: intl.formatMessage({
                      id: "email.copy.success",
                      description: "Success message when copying email content",
                      defaultMessage: "Email copied to clipboard",
                    }),
                    status: "success",
                    duration: 2000,
                  })
                }
              }}
              label={intl.formatMessage({
                id: "email.copy.success",
                description: "Success message when copying email content",
                defaultMessage: "Copy to clipboard",
              })}
            />
            {sendButton}
          </HStack>
        </Flex>
      </Stack>
    </>
  )
}

export default EmailComposer
