import { Box, Flex } from "@chakra-ui/react"
import type { ReactNode } from "react"
import BrmLogo from "../../components/BrmLogo.js"

interface NegotiationAgentLayoutProps {
  children: ReactNode
}

const NegotiationAgentLayout = ({ children }: NegotiationAgentLayoutProps) => {
  return (
    <Box my="auto" display="flex" alignItems="center" justifyContent="center">
      <Flex mx="auto" direction={{ base: "column", md: "row" }} gap={{ base: 8, md: 20 }} align="center">
        {/* Logo section */}
        <Flex justify="center">
          <BrmLogo minW={60} minH={60} />
        </Flex>

        {/* Content section */}
        <Flex direction="column" maxW="22rem" textAlign="center">
          {children}
        </Flex>
      </Flex>
    </Box>
  )
}

export default NegotiationAgentLayout
