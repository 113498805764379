import type { DocumentMinimal } from "@brm/schema-types/types.js"
import { Box, Button, Flex, HStack, Icon, Spinner, Text, chakra } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { To } from "react-router-dom"
import { documentStatusText } from "../../features/legal/helpers.js"
import UserWithProviderBadge from "../DataTable/CellRenderer/UserWithProviderBadge.js"
import { ExtractionIcon } from "../ExtractionHighlight.js"
import { RefreshIcon } from "../icons/icons.js"
import { Link, LinkOrSpan } from "../Link.js"
import OverflownText from "../OverflownText.js"
import { FileTypeIcon } from "./FileTypeIcon.js"

export interface DocumentDisplayProps {
  document:
    | Pick<DocumentMinimal, "file_name" | "mime_type" | "status" | "extraction_status" | "user_email_integrations">
    | File
  isUploading?: boolean
  isError?: boolean
  to?: To
  // Callback when extraction status is failed
  onRetryExtraction?: () => void
  newExtractedFieldCount?: number
}

export const DocumentDisplay: FunctionComponent<DocumentDisplayProps> = ({
  document,
  isUploading,
  isError,
  to,
  onRetryExtraction,
  newExtractedFieldCount,
}) => {
  const intl = useIntl()
  const fileName = document instanceof File ? document.name : document.file_name
  const extractionStatus = "extraction_status" in document ? document.extraction_status : null
  const extractionText =
    newExtractedFieldCount && newExtractedFieldCount > 0 ? (
      <chakra.span>
        <FormattedMessage
          id="document.newExtractedFields"
          defaultMessage="{count, plural, one {# new extracted value} other {# new extracted values}}"
          description="Text indicating the number of new extracted fields"
          values={{ count: newExtractedFieldCount }}
        />
        <ExtractionIcon />
      </chakra.span>
    ) : "extraction_status" in document ? (
      documentStatusText(document, intl)
    ) : null

  return (
    <HStack minW={0} justify="space-between" width="full">
      <HStack overflow="hidden" flex={1}>
        <FileTypeIcon mimeType={"mime_type" in document ? document.mime_type : document.type} boxSize={6} />
        <Flex flexDirection="column" alignItems="start" minW={0} flex={1}>
          <LinkOrSpan to={to} minW="4ch" maxW="90%">
            <OverflownText fontWeight="medium" textColor={isError ? "error" : "black"}>
              {fileName || (
                <FormattedMessage
                  id="file.filename.unknown"
                  description="Unknown filename placeholder"
                  defaultMessage="Unnamed file"
                />
              )}
            </OverflownText>
          </LinkOrSpan>
          {extractionText && (
            <Text fontWeight="normal" color={isError ? "error.600" : "gray.600"} whiteSpace="nowrap" fontSize="sm">
              {extractionText}
            </Text>
          )}
          {"object_type" in document && extractionStatus === "failed" && onRetryExtraction && (
            <Button
              as={Link}
              variant="link"
              onClick={onRetryExtraction}
              textColor="gray.700"
              fontSize="sm"
              paddingBottom={1}
              gap={2}
            >
              <FormattedMessage
                id="document.retry_extraction"
                defaultMessage="Retry extraction"
                description="Text shown under a document when extraction has failed"
              />
              <Icon as={RefreshIcon} boxSize={4} />
            </Button>
          )}
        </Flex>
      </HStack>
      {isUploading ? (
        <Box>
          <Spinner boxSize={5} />
        </Box>
      ) : null}
      {!(document instanceof File) && !isUploading && (
        <HStack gap={1}>
          {document.user_email_integrations?.map((userWithProvider) => (
            <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} />
          ))}
        </HStack>
      )}
    </HStack>
  )
}
