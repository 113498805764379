import { Icon, type ButtonProps } from "@chakra-ui/react"
import { useState } from "react"
import { useIntl } from "react-intl"
import { IconButtonWithTooltip } from "../../components/IconButtonWithTooltip.js"
import { MicrophoneIcon, RecordingIcon } from "../../components/icons/icons.js"
import { SUPPORTED_AUDIO_MIME_TYPE, useSpeechToText, type UseSpeechToTextProps } from "./speech-to-text.js"

interface SpeechToTextIconButtonProps extends UseSpeechToTextProps {
  children?: React.ReactNode
}

export const SpeechToTextIconButton: React.FC<SpeechToTextIconButtonProps & ButtonProps> = ({
  onText,
  onError,
  ...props
}) => {
  const intl = useIntl()
  const { startListening, stopListening } = useSpeechToText({
    onText,
    onError,
  })

  const [isRecording, setIsRecording] = useState(false)

  if (SUPPORTED_AUDIO_MIME_TYPE === undefined) {
    return null
  }

  return (
    <IconButtonWithTooltip
      onClick={async () => {
        if (isRecording) {
          stopListening()
        } else {
          await startListening()
        }
        setIsRecording(!isRecording)
      }}
      isActive={isRecording}
      _active={{
        backgroundColor: "brand.50",
      }}
      icon={<Icon as={isRecording ? RecordingIcon : MicrophoneIcon} color={isRecording ? "brand.600" : "gray.600"} />}
      label={intl.formatMessage({
        id: "speech.to.text.button.label",
        description: "Label for the speech to text button",
        defaultMessage: "Speech to text",
      })}
      {...props}
    />
  )
}
