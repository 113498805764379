import type {
  CommentTimelineEvent1,
  InboxNotification,
  TimelineEventReplyTimelineEvent,
} from "@brm/schema-types/types.js"
import {
  agreementRouteById,
  linkRouteByCode,
  requestRouteById,
  toolRouteById,
  vendorRouteById,
} from "@brm/util/routes.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { Path } from "react-router-dom"

export function getNotificationLinkDestination(
  notification: InboxNotification,
  userOrganizationId: string
): Partial<Path> {
  switch (notification.type) {
    case "mention":
    case "workflow_run.timeline_comment":
    case "tool_owned.timeline_comment":
    case "tool.timeline_comment":
    case "vendor_owned.timeline_comment":
    case "vendor.timeline_comment":
    case "timeline_comment.reaction":
      return timelineEventNotificationDestination(notification.timeline_event, notification, userOrganizationId)
    case "workflow_run.input_needed":
    case "workflow_run.started":
    case "workflow_run.completed":
      return {
        pathname: requestRouteById(notification.workflow_run.id),
      }
    // Currently not shown in UI
    // case "workflow_run_field.gatherer_assigned":
    case "workflow_run_step.approved":
    case "workflow_run_step.approval_requested":
    case "workflow_run_step.approval_overridden":
    case "workflow_run_step.unapproved":
    case "workflow_run_step.changes_requested":
    case "workflow_run_step.seller_submitted":
      return {
        pathname: requestRouteById(notification.workflow_run_step.workflow_run_id),
        search: new URLSearchParams({ step: notification.workflow_run_step.id }).toString(),
      }
    case "workflow_run_step_field.reopened": {
      const search = new URLSearchParams({
        step: notification.workflow_run_step.id,
        type: notification.timeline_event.object_type.toLowerCase(),
        object: notification.timeline_event.object_id,
        field: notification.timeline_event.field_name,
      })
      return {
        pathname: requestRouteById(notification.workflow_run_step.workflow_run_id),
        search: search.toString(),
      }
    }
    case "renewal_reminder":
    case "tool_owned_renewal_reminder":
    case "vendor_owned_renewal_reminder":
      return {
        pathname: agreementRouteById(notification.legal_agreement.id),
      }
    // Currently not shown in UI
    // case "vendor_digest":
    //   return {
    //     pathname: "vendors",
    //     search: new URLSearchParams({ all: "", sort_desc: "created_at" satisfies keyof VendorListItem }).toString(),
    //   }
    // case "tool_digest":
    //   return {
    //     pathname: "tools",
    //     search: new URLSearchParams({ all: "", sort_desc: "created_at" satisfies keyof ToolListItem }).toString(),
    //   }
    case "document.email_ingested":
      switch (true) {
        case notification.parent_object_type === "LegalAgreement":
          return {
            pathname: agreementRouteById(notification.parent_object_id),
          }
        case notification.parent_object_type === "Tool":
          return {
            pathname: toolRouteById(notification.parent_object_id, "overview"),
          }
        case notification.parent_object_type === "Vendor":
          return {
            pathname: vendorRouteById(notification.parent_object_id, "overview"),
          }
        case notification.parent_object_type === "WorkflowRun":
          return {
            pathname: requestRouteById(notification.parent_object_id),
          }
        default: {
          throw new Error("Unexpected parent object type for notification", { cause: notification })
        }
      }
    case "legal_agreement_created_by_system":
      return {
        pathname: agreementRouteById(notification.legal_agreement.id),
      }
    case "free_trial_expired":
      return {
        pathname: "/settings/activate",
      }
    case "integration.historical_agreement_crawl_done":
      return {
        pathname: "/agreements",
      }
    default:
      unreachable(notification)
  }
}

function timelineEventNotificationDestination(
  timelineEvent: CommentTimelineEvent1 | TimelineEventReplyTimelineEvent,
  notification: InboxNotification,
  userOrganizationId: string
): Partial<Path> {
  const search = new URLSearchParams()
  search.set("event", timelineEvent.id)
  if (timelineEvent.workflow_run_id) {
    if (timelineEvent.workflow_run_step_id) {
      search.set("step", timelineEvent.workflow_run_step_id)
    }
    if (timelineEvent.type === "comment" && timelineEvent.field_name) {
      search.set("type", timelineEvent.object_type.toLowerCase())
      search.set("object", timelineEvent.object_id)
      search.set("field", timelineEvent.field_name)
    }

    if (
      (notification.type === "workflow_run.timeline_comment" || notification.type === "mention") &&
      notification.workflow_run_organization_id !== userOrganizationId &&
      notification.link_auth_code
    ) {
      return {
        pathname: linkRouteByCode(notification.link_auth_code),
        search: search.toString(),
      }
    }

    return {
      pathname: requestRouteById(timelineEvent.workflow_run_id),
      search: search.toString(),
    }
  }
  if (timelineEvent.object_type === "Tool") {
    return {
      pathname: toolRouteById(timelineEvent.object_id, "overview"),
      search: search.toString(),
    }
  }
  if (timelineEvent.object_type === "Vendor") {
    return {
      pathname: vendorRouteById(timelineEvent.object_id, "overview"),
      search: search.toString(),
    }
  }
  throw Object.assign(new Error("Unexpected object type for notification comment"), {
    object_type: timelineEvent.object_type,
  })
}
