import { isObject } from "@brm/util/type-guard.js"
import { Flex, Text } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { useGetIntegrationV1InitialExtractionBannerQuery } from "../../app/services/generated-api.js"
import { Link } from "../Link.js"

export default function InitialGmailExtractionBanner() {
  const { data } = useGetIntegrationV1InitialExtractionBannerQuery()

  // Stores the most recent data we've seen to be shown when extraction has finished
  const [lastSeenData, setLastSeenData] = useState<{
    extraction_progress: number
    agreements_extracted: number
  } | null>(null)

  if (
    isObject(data) &&
    (data.agreements_extracted !== lastSeenData?.agreements_extracted ||
      data.extraction_progress !== lastSeenData?.extraction_progress)
  ) {
    // Keep the last seen data up to date whenever data changes
    setLastSeenData({
      extraction_progress: data.extraction_progress,
      agreements_extracted: data.agreements_extracted,
    })
  }

  // Only show the banner if we have data. If the first data returned is "completed", then lastSeenData will stay null and we will not show banner
  if (!lastSeenData || !data) {
    return null
  }

  const progressPercent = data === "completed" ? 1 : data.extraction_progress

  const agreementCount = data === "completed" ? lastSeenData.agreements_extracted : data.agreements_extracted

  return (
    <>
      <Flex
        minH="30px"
        width="100vw"
        background="brand.500"
        alignItems="center"
        justifyContent="center"
        textColor="white"
        gap={2}
      >
        <Text as="span" zIndex={1}>
          {progressPercent >= 1 ? (
            <FormattedMessage
              defaultMessage="Finished extracting documents <link>{count} agreements</link> found!"
              description="The text for the banner that shows the progress of the initial Gmail extraction"
              id="gmail.initialExtraction.banner.extracted"
              values={{
                link: (chunks) => (
                  <Link to="/agreements" fontWeight="medium" textDecoration="underline">
                    {chunks}
                  </Link>
                ),
                count: agreementCount,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="Found <link>{count} {count, plural, one {agreement} other {agreements}}</link> in your email – {percent} complete"
              description="The text for the banner that shows the progress of the initial Gmail extraction"
              id="gmail.initialExtraction.banner.extracting"
              values={{
                link: (chunks) => (
                  <Link to="/agreements" fontWeight="medium" textDecoration="underline">
                    {chunks}
                  </Link>
                ),
                count: agreementCount,
                percent: (
                  <FormattedNumber
                    value={progressPercent}
                    style="percent"
                    minimumFractionDigits={0}
                    maximumFractionDigits={1}
                  />
                ),
              }}
            />
          )}
        </Text>
      </Flex>
      <Flex
        minH="30px"
        width={`${Math.floor(progressPercent * 1000) / 10}vw`}
        zIndex={0}
        background="brand.600"
        position="absolute"
        top={0}
        left={0}
        transition="width 0.3s linear"
      />
    </>
  )
}
