import type { Role } from "@brm/schema-types/types.js"
import { deepEqualStdObjects } from "@brm/util/workflow.js"
import { Stack } from "@chakra-ui/react"
import React from "react"
import { useIntl } from "react-intl"
import RoleRadioOption from "./RoleRadioOption.js"

type LiteRole = "super_admin" | "legal" | "user"

const liteRoleToRoleList: Record<LiteRole, Role[]> = {
  super_admin: ["super_admin"],
  legal: ["legal", "finance"],
  user: [],
}

const RoleSelectLite: React.FunctionComponent<{
  onChange: (roles: Role[]) => void
  roles: Role[]
}> = ({ onChange, roles }) => {
  const intl = useIntl()

  const handleRoleChange = (newValue: LiteRole) => {
    onChange(liteRoleToRoleList[newValue])
  }

  return (
    <Stack>
      <RoleRadioOption
        isChecked={deepEqualStdObjects(roles, liteRoleToRoleList.super_admin)}
        onChange={handleRoleChange}
        valueForRole="super_admin"
        label={intl.formatMessage({
          id: "roles.superAdmin.label",
          defaultMessage: "Super Admin",
          description: "Label for super admin role option",
        })}
        details={intl.formatMessage({
          id: "roles.superAdmin.details",
          defaultMessage: "Full access to all organization settings and data",
          description: "Description of super admin role capabilities",
        })}
      />
      <RoleRadioOption
        isChecked={deepEqualStdObjects(roles, liteRoleToRoleList.legal)}
        onChange={handleRoleChange}
        valueForRole="legal"
        label={intl.formatMessage({
          id: "roles.legal.label",
          defaultMessage: "Agreement Manager",
          description: "Label for legal role option",
        })}
        details={intl.formatMessage({
          id: "roles.legal.details",
          defaultMessage: "Can view legal documents and edit agreement details",
          description: "Description of legal role capabilities",
        })}
      />
      <RoleRadioOption
        isChecked={deepEqualStdObjects(roles, liteRoleToRoleList.user)}
        onChange={handleRoleChange}
        valueForRole="user"
        label={intl.formatMessage({
          id: "roles.readonly.label",
          defaultMessage: "User",
          description: "Label for readonly role option",
        })}
        details={intl.formatMessage({
          id: "roles.readonly.details",
          defaultMessage: "Can view list of agreements but cannot view details or make changes",
          description: "Description of readonly role capabilities",
        })}
      />
    </Stack>
  )
}

export default RoleSelectLite
