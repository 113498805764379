import { Box, Tooltip, type TextProps } from "@chakra-ui/react"
import { useRef } from "react"

export default function OverflownText({ isTruncated, children, ...props }: TextProps & { isTruncated?: boolean }) {
  const ref = useRef<HTMLDivElement>(null)
  const isOverflown = isTruncated ? true : ref.current ? ref.current.scrollWidth > ref.current.clientWidth : false

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" minW={0} isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}
