import { Box, Button, Card, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { AlertIcon, BackIcon, SupportChatIcon, TrashIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"

export function AgreementDeletedCard() {
  return (
    <Card p={8}>
      <Stack spacing={6} align="center">
        {/* Delete Icon */}
        <Icon as={TrashIcon} color="red.500" boxSize={20} bg="red.50" p={5} borderRadius="full" />
        {/* Main Heading */}
        <Heading size="lg">
          <FormattedMessage
            id="legal.agreementDeleted.title"
            defaultMessage="Agreement Deleted"
            description="Title shown when agreement has been deleted"
          />
        </Heading>
        {/* Deleted Agreement Box */}
        <Box p={4} borderRadius="md" borderWidth={1} borderColor="red.200" bg="red.50">
          <Flex gap={3} alignItems="center">
            <Icon as={AlertIcon} color="red.500" mt={1} />
            <Stack gap={1}>
              <Text fontWeight="medium" color="red.500">
                <FormattedMessage
                  id="legal.agreementDeleted.notFoundHeading"
                  defaultMessage="The agreement you are looking for has been deleted. If this is an error, please contact support."
                  description="Heading for the deleted agreement section"
                />
              </Text>
            </Stack>
          </Flex>
        </Box>
        {/* Action Buttons */}
        <Flex gap={4} width="full">
          <Link to="/agreements" flex={1}>
            <Button width="full" colorScheme="brand" leftIcon={<Icon as={BackIcon} />}>
              <FormattedMessage
                id="legal.permissionDenied.returnToAgreements"
                defaultMessage="Return to agreements"
                description="Label for the return to agreements button"
              />
            </Button>
          </Link>
          <Button
            variant="outline"
            flex={1}
            onClick={() => window.Pylon?.("show")}
            leftIcon={<Icon as={SupportChatIcon} />}
          >
            <FormattedMessage
              id="legal.permissionDenied.goBack"
              defaultMessage="Contact support"
              description="Label for the go back button"
            />
          </Button>
        </Flex>
      </Stack>
    </Card>
  )
}
