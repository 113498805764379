import type { BlockQuoteElement, ObjectType } from "@brm/schema-types/types.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  type MenuItemProps,
  type ModalProps,
} from "@chakra-ui/react"
import equal from "fast-deep-equal"
import { useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { type Descendant } from "slate"
import { useCustomCompareEffect } from "use-custom-compare"
import {
  useGetUserV1WhoamiQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  useLazyGetToolV1ByIdQuery,
  useLazyGetVendorV1ByIdQuery,
  useLazyGetWorkflowV1RunsByIdQuery,
  type LegalAgreementListItem,
  type Tool,
  type Vendor,
  type WorkflowRun,
} from "../../app/services/generated-api.js"
import Chat from "../../features/betsy/Chat.js"
import { ChatContextProvider } from "../../features/betsy/ChatContextProvider.js"
import {
  defaultLiteMessages,
  defaultLiteSuggestedPrompts,
  defaultMessages,
  defaultOnboardingMessages,
  defaultSuggestedPrompts,
} from "../../features/betsy/util.js"
import { selectCurrentOnboardingStep } from "../../features/onboarding/onboarding-slice.js"
import BraimIcon from "../BraimIcon.js"
import BetsyDisplayName from "./BetsyDisplayName.js"

export const BetsyContextMenuItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props} icon={<BraimIcon />}>
      <FormattedMessage
        defaultMessage="Ask {ai}"
        description="Label for the menu item open up chat with brm ai"
        id="context-menu.item.ask-betsy"
        values={{
          ai: <BetsyDisplayName />,
        }}
      />
    </MenuItem>
  )
}

type ContextType = LegalAgreementListItem | Tool | Vendor | WorkflowRun | undefined

export const BetsyModal = (
  props: Omit<ModalProps, "children"> & {
    /** Initial highlighted text before opening betsy modal */
    highlightedText?: string
    /** Object type to fetch */
    objectType?: ObjectType
    /** Object id to fetch */
    id?: string
  }
) => {
  const { highlightedText, objectType, id, ...modalProps } = props
  const [context, setContext] = useState<ContextType>(undefined)
  const [getAgreementById] = useLazyGetLegalV1AgreementsByIdQuery()
  const [getToolById] = useLazyGetToolV1ByIdQuery()
  const [getWorkflowRunById] = useLazyGetWorkflowV1RunsByIdQuery()
  const [getVendorById] = useLazyGetVendorV1ByIdQuery()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization.is_lite

  const inOnboarding = useSelector(selectCurrentOnboardingStep) === "overview.ask_ai"

  const initialValue = useMemo(() => {
    const trimmedHighlightedText = highlightedText?.trim()
    return context
      ? ([
          {
            type: "paragraph",
            children: [
              {
                type: "standard-object",
                display_name: context.display_name,
                object_type: context.object_type,
                id: context.id,
                children: [{ text: "" }],
              },
            ],
          },
          ...(trimmedHighlightedText && !isEmpty(trimmedHighlightedText)
            ? [
                {
                  type: "block-quote",
                  children: [
                    {
                      text: trimmedHighlightedText,
                    },
                  ],
                } as BlockQuoteElement,
              ]
            : []),
          {
            type: "paragraph",
            children: [{ text: " " }],
          },
        ] satisfies Descendant[])
      : undefined
  }, [context, highlightedText])

  const defaultChatHistory = useMemo(() => {
    return isLiteOrganization
      ? inOnboarding
        ? defaultOnboardingMessages(context?.display_name, context?.id)
        : defaultLiteMessages
      : defaultMessages
  }, [context, isLiteOrganization, inOnboarding])

  useCustomCompareEffect(
    () => {
      const fetchData = async () => {
        if (objectType && id) {
          switch (objectType) {
            case "LegalAgreement": {
              const agreement = await getAgreementById({ id }).unwrap()
              setContext(agreement)
              break
            }
            case "Tool": {
              const tool = await getToolById({ id }).unwrap()
              setContext(tool)
              break
            }
            case "Vendor": {
              const vendor = await getVendorById({ id }).unwrap()
              setContext(vendor)
              break
            }
            case "WorkflowRun": {
              const workflowRun = await getWorkflowRunById({ id }).unwrap()
              setContext(workflowRun)
              break
            }
          }
        }
      }

      void fetchData()
    },
    [getAgreementById, getToolById, getVendorById, getWorkflowRunById, objectType, id],
    equal
  )

  const [conversationId, setConversationId] = useState<string>(crypto.randomUUID())
  return (
    <Modal {...modalProps} size="2xl">
      <ModalContent height="85vh" boxShadow="2xl">
        <ModalCloseButton />
        <ModalBody
          height="100%"
          overflow="auto"
          sx={{
            scrollbarWidth: "thin",
          }}
          paddingTop={4}
        >
          {initialValue && (
            <ChatContextProvider initialChatValue={initialValue}>
              <Chat
                startNewConversation={() => setConversationId(crypto.randomUUID())}
                conversation={{ id: conversationId }}
                onSubmitStreamingUrl={`${import.meta.env.VITE_API_BASE_URL}/betsy/v1/ask`}
                defaultMessages={defaultChatHistory}
                addOns={{
                  suggestedPrompts: {
                    enabled: true,
                    defaultPrompts: isLiteOrganization ? defaultLiteSuggestedPrompts : defaultSuggestedPrompts,
                  },
                }}
              />
            </ChatContextProvider>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
