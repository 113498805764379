import type { DocumentElement } from "@brm/schema-types/types.js"
import type { CreateToastFnReturn } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"
import { Transforms } from "slate"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import type { CustomEditor } from "../types.js"

export const insertDocument = (
  editor: CustomEditor,
  { id, fileName, mime_type }: { id: string; fileName: string; mime_type: string }
) => {
  const text = { text: "" }
  const document: DocumentElement = { type: "document", id, file_name: fileName, mime_type, children: [text] }
  Transforms.insertNodes(editor, document)
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  })
}

export const handleDocumentUploadError = (err: unknown, toast: CreateToastFnReturn, intl: IntlShape) => {
  toast({
    description:
      getAPIErrorMessage(err) ??
      intl.formatMessage({
        id: "richText.document.upload.error",
        description: "Error toast displayed when a user uploads a document to a rich text input and an error occurs",
        defaultMessage: "Something went wrong while uploading your document",
      }),
    status: "error",
    duration: LONG_TOAST_DURATION,
  })
}
