import { Icon, Input, InputGroup, InputLeftElement, InputRightElement, type InputProps } from "@chakra-ui/react"
import { forwardRef, type ForwardedRef } from "react"
import { useIntl } from "react-intl"
import { SearchIcon, SpinnerIcon, XIcon } from "../icons/icons.js"

interface DebouncedSearchInputProps extends InputProps {
  isLoading?: boolean
  onClear?: () => void
  rightElement?: React.ReactNode
}

export const SearchInput = forwardRef(function SearchInput(
  { isLoading, onClear, rightElement, placeholder, ...inputProps }: DebouncedSearchInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const intl = useIntl()

  return (
    <InputGroup width="auto">
      <InputLeftElement pointerEvents="none">
        <Icon as={isLoading ? SpinnerIcon : SearchIcon} />
      </InputLeftElement>
      <Input
        ref={ref}
        placeholder={
          placeholder ??
          intl.formatMessage({
            id: "search.placeholder",
            description: "The placeholder on the text input for full text search",
            defaultMessage: "Search",
          })
        }
        {...inputProps}
      />
      {inputProps.value && (
        <InputRightElement
          onClick={onClear}
          cursor="pointer"
          aria-label={intl.formatMessage({
            id: "search.clear",
            description: "The label for the clickable icon that clears all text in an input search field.",
            defaultMessage: "Clear search",
          })}
        >
          <Icon as={XIcon} />
        </InputRightElement>
      )}
      {!inputProps.value && rightElement && <InputRightElement width="4rem">{rightElement}</InputRightElement>}
    </InputGroup>
  )
})
