import { chakra } from "@chakra-ui/react"
import React, { useCallback, useMemo } from "react"
import type { Descendant } from "slate"
import { createEditor } from "slate"
import { Editable, Slate, withReact, type RenderElementProps, type RenderLeafProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { Element } from "./Element.js"
import { Leaf } from "./Leaf.js"
export interface RichTextDisplayProps {
  content: Descendant[]
  placeholder?: string
  getDocumentUrl?: (documentId: string) => Promise<string>
  getDocumentTagHref?: (documentId: string) => string
}

/**
 * RichTextDisplay is a read-only component that displays rich text content. It uses Slate to render the content.
 */
const RichTextDisplay = React.memo(function RichTextDisplay({
  content,
  placeholder,
  getLogoToShowByOrganizationId,
  getDocumentUrl,
  getDocumentTagHref,
}: RichTextDisplayProps & GetLogoForOrganizationProps) {
  const editor = useMemo(() => withReact(createEditor()), [])

  const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, [])
  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        isReadOnly
        getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        getDocumentUrl={getDocumentUrl}
        getDocumentTagHref={getDocumentTagHref}
      />
    ),
    [getLogoToShowByOrganizationId, getDocumentUrl, getDocumentTagHref]
  )

  return (
    <Slate editor={editor} initialValue={content}>
      <Editable
        renderPlaceholder={({ children, attributes }) => (
          <chakra.span
            {...attributes}
            style={{
              ...attributes.style,
              color: "var(--chakra-colors-chakra-placeholder-color)",
              opacity: 1,
            }}
          >
            {children}
          </chakra.span>
        )}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck={false}
        readOnly
        aria-readonly="true"
        placeholder={placeholder}
      />
    </Slate>
  )
})

export default RichTextDisplay
