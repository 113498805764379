import type { Conversation } from "@brm/schema-types/types.js"
import { Center, Flex, Spinner } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { useNavigate, useParams } from "react-router-dom"
import {
  useGetBetsyV1ConversationsQuery,
  useGetUserV1WhoamiQuery,
  useLazyGetBetsyV1ConversationsByIdQuery,
} from "../../app/services/generated-api.js"
import FullTextSearch from "../search/FullTextSearch.js"
import BetsyConversationList from "./BetsyConversationList.js"
import Chat from "./Chat.js"
import { ChatContextProvider } from "./ChatContextProvider.js"
import { defaultLiteMessages, defaultLiteSuggestedPrompts, defaultMessages, defaultSuggestedPrompts } from "./util.js"

export default function Betsy() {
  const { id: urlConversationId } = useParams()
  const navigate = useNavigate()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization.is_lite

  const [fetchConversation, fetchConversationResult] = useLazyGetBetsyV1ConversationsByIdQuery()

  const {
    data: conversations,
    isLoading: isLoadingConversations,
    refetch: refetchConversations,
  } = useGetBetsyV1ConversationsQuery()

  const [conversation, _setConversation] = useState<Conversation | { id: string }>({ id: crypto.randomUUID() })
  const setConversation = (id: string, fetch?: boolean) => {
    if (id !== conversation.id) {
      if (window.location.pathname !== `/braim/${id}`) {
        navigate(`/braim/${id}`)
      }
      _setConversation({ id })
      if (fetch) {
        void fetchConversation({ id })
      }
    }
  }

  useEffect(() => {
    if (fetchConversationResult.data) {
      _setConversation(fetchConversationResult.data)
    }
  }, [fetchConversationResult.data])

  useHotkeys(
    ["shift+mod+k"],
    async () => {
      setConversation(crypto.randomUUID())
    },
    { enableOnFormTags: ["input", "select", "textarea"] }
  )

  // Only on first load: initialize the conversationId from the URL (existing conversation)
  // or populate current ID on the new URL (new conversation)
  useEffect(() => {
    if (urlConversationId && urlConversationId !== conversation.id) {
      // initialize the conversation from the url if an ID is present
      setConversation(urlConversationId, true)
    } else if (!urlConversationId) {
      // update the URL with the new conversation ID is being started
      navigate(`/braim/${conversation.id}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlConversationId])

  return (
    <Flex direction="row" height="100%">
      <Flex width={320} height="100%">
        <BetsyConversationList
          conversations={conversations ?? []}
          selectedConversationId={conversation.id}
          isLoading={isLoadingConversations}
          onConversationSelected={async (selectedId) => setConversation(selectedId, true)}
          onNewConversation={() => setConversation(crypto.randomUUID(), false)}
        />
      </Flex>

      <Flex flexGrow={1} direction="column" pos="relative" height="100%" overflow="hidden" fontSize="md">
        <Flex px={4} py={3} justifyContent="flex-end">
          <FullTextSearch />
        </Flex>
        {fetchConversationResult.isLoading || fetchConversationResult.isFetching ? (
          <Center height="full" alignItems="center">
            <Spinner />
          </Center>
        ) : (
          <ChatContextProvider>
            <Chat
              addOns={{
                suggestedPrompts: {
                  enabled: true,
                  defaultPrompts: isLiteOrganization ? defaultLiteSuggestedPrompts : defaultSuggestedPrompts,
                },
              }}
              conversation={conversation}
              startNewConversation={() => setConversation(crypto.randomUUID(), false)}
              onError={refetchConversations}
              onSuccess={refetchConversations}
              onSubmitStreamingUrl={`${import.meta.env.VITE_API_BASE_URL}/betsy/v1/ask`}
              defaultMessages={isLiteOrganization ? defaultLiteMessages : defaultMessages}
            />
          </ChatContextProvider>
        )}
      </Flex>
    </Flex>
  )
}
