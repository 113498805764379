import { Badge, Box, Button, Card, Flex, HStack, Icon, Spacer, Stack, Tooltip } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import type { To } from "react-router-dom"
import type { LegalAgreementMinimal, ToolMinimal } from "../../app/services/generated-api.js"
import ToolCell from "../../components/DataTable/CellRenderer/ToolCell.js"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import { AlertIcon, CheckIcon, ChevronRightIcon } from "../../components/icons/icons.js"
import { Link, LinkOrSpan } from "../../components/Link.js"
import StartWorkflowModal from "../workflows/run/start/StartWorkflowModal.js"
import AgreementAutoRenews from "./AgreementAutoRenews.js"
import AgreementSigner from "./AgreementSigner.js"
import AgreementStartEndDate from "./AgreementStartEndDate.js"
import AgreementTcv from "./AgreementTcv.js"
import LegalAgreementStatusBadge from "./LegalAgreementStatusBadge.js"

export interface LegalAgreementCardLayoutProps {
  legalAgreement: LegalAgreementMinimal
  verificationBadge?: { colorScheme: string; title: string }
  isDuplicate?: boolean
  isPrimary?: boolean
  isEnterprise?: boolean
  tool?: ToolMinimal
  userCanUpdate: boolean
  canRenewAgreement: boolean
  showTCV: boolean
  renewalSuccessorId?: string
  onStartRenewal?: () => void
  isStartWorkflowModalOpen?: boolean
  onStartWorkflowModalClose: () => void
  prevLocation?: unknown
  hoverable?: boolean
  selected?: boolean
  to?: To
}

export const LegalAgreementCardLayout = ({
  legalAgreement,
  verificationBadge,
  isDuplicate,
  isPrimary,
  isEnterprise,
  canRenewAgreement,
  showTCV,
  renewalSuccessorId,
  onStartRenewal,
  isStartWorkflowModalOpen,
  onStartWorkflowModalClose,
  prevLocation,
  hoverable,
  selected,
  tool,
  to,
}: LegalAgreementCardLayoutProps) => {
  return (
    <Card
      variant="outline"
      px={6}
      py={4}
      borderRadius="xl"
      borderWidth="2px"
      borderColor={selected ? "green.500" : undefined}
      _hover={
        hoverable
          ? {
              borderColor: selected ? "green.500" : "green.300",
            }
          : undefined
      }
      cursor={hoverable ? "pointer" : undefined}
    >
      <Stack>
        <HStack alignItems="center" gap={2} flexWrap="wrap">
          <LinkOrSpan
            state={{ prevLocation }}
            to={to}
            fontSize="lg"
            fontWeight="medium"
            whiteSpace="nowrap"
            flexShrink={1}
            minW="5ch"
            isTruncated
          >
            {legalAgreement.display_name}
          </LinkOrSpan>
          {isDuplicate && (
            <Tooltip label="BRM has detected duplicative subscription agreements. Consider consolidating to a team plan.">
              <Box>
                <FeaturedIcon
                  icon={<Icon as={AlertIcon} color="warning.700" boxSize={5} />}
                  backgroundColor="warning.200"
                />
              </Box>
            </Tooltip>
          )}

          {isPrimary && (
            <Tooltip label="This is the primary agreement">
              <HStack alignItems="center" gap={1}>
                <Icon as={CheckIcon} />
              </HStack>
            </Tooltip>
          )}
          {legalAgreement.verification_status !== "verified" && verificationBadge && (
            <Badge variant="subtleOutlined" size="md" colorScheme={verificationBadge.colorScheme}>
              {verificationBadge.title}
            </Badge>
          )}
          <Spacer />
          <LegalAgreementStatusBadge status={legalAgreement.effective_status} />
        </HStack>
        <HStack paddingTop={2} gap={4} flexWrap="wrap" alignItems="end" flex={1}>
          <Flex columnGap={10} rowGap={4} fontSize="sm" fontWeight="medium" flexWrap="wrap">
            {isEnterprise && (
              <AgreementSigner
                is_signed={legalAgreement.is_signed}
                effective_status={legalAgreement.effective_status}
                buyer_signer_name={legalAgreement.buyer_signer_name}
              />
            )}
            <AgreementStartEndDate startDate={legalAgreement.start_date} endDate={legalAgreement.end_date} />
            {showTCV && <AgreementTcv totalContractValue={legalAgreement.total_contract_value} />}
            {typeof legalAgreement.auto_renews === "boolean" && (
              <AgreementAutoRenews autoRenews={legalAgreement.auto_renews} />
            )}
            {tool && (
              <HStack alignItems="center">
                <ToolCell tool={tool} />
              </HStack>
            )}
          </Flex>
          <Spacer />
          <Flex>
            {renewalSuccessorId ? (
              <Link to={`/requests/${renewalSuccessorId}`} fontWeight="semibold">
                <FormattedMessage
                  id="agreement.viewRenewalRequest"
                  description="Text on a link to view a renewal request of a legal agreement"
                  defaultMessage="View renewal"
                />
                <Icon as={ChevronRightIcon} />
              </Link>
            ) : (
              canRenewAgreement && (
                <Button variant="link" onClick={onStartRenewal}>
                  <FormattedMessage
                    id="agreement.startRenewal"
                    description="Text on a link to start a renewal request of a legal agreement"
                    defaultMessage="Start renewal"
                  />
                  <Icon as={ChevronRightIcon} />
                </Button>
              )
            )}
          </Flex>
        </HStack>
      </Stack>
      {isStartWorkflowModalOpen && (
        <StartWorkflowModal
          isOpen={isStartWorkflowModalOpen}
          onClose={onStartWorkflowModalClose}
          initialLegalAgreementId={legalAgreement.id}
        />
      )}
    </Card>
  )
}
