import type { NotificationChannel, UpdateNotificationSetting } from "@brm/schema-types/types.js"
import { type NotificationType } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Center, Checkbox, Grid, GridItem, HStack, Icon, Stack, Text, Tooltip, useToast } from "@chakra-ui/react"
import type { FC } from "react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl, type IntlShape } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetUserV1NotificationSettingsQuery,
  useGetUserV1WhoamiQuery,
  usePutUserV1NotificationSettingsMutation,
} from "../../app/services/generated-api.js"
import { SlackIcon } from "../../components/icons/provider-icons.js"
import Select from "../../components/Select/Select.js"
import Spinner from "../../components/spinner.js"
import { SHORT_TOAST_DURATION } from "../../util/constant.js"
import { posthogCaptureEvent } from "../../util/posthog-captures.js"
import { SettingsContainer } from "./SettingsContainer.js"
import { SettingsHeader } from "./SettingsHeader.js"

interface NotificationSubGroup {
  header: string
  notifications: {
    type: NotificationType
    inboxDisabled?: boolean
    emailDisabled?: boolean
    channelTooltips?: Map<NotificationChannel, string>
    otherChannelsRequireInbox?: boolean
    numDaysRequired?: boolean
  }[]
}

enum NotificationGroup {
  General = "general",
  WorkflowRuns = "workflow_runs",
  Agreements = "agreements",
  Tools = "tools",
  Vendors = "vendors",
}

const notificationsThatNeedNumDays: Set<NotificationType> = new Set([
  "renewal_reminder",
  "tool_owned_renewal_reminder",
  "vendor_owned_renewal_reminder",
])

const getLabelFromNotificationGroup = (group: NotificationGroup, intl: IntlShape): string => {
  switch (group) {
    case NotificationGroup.General:
      return intl.formatMessage({
        id: "notification.settings.general.description",
        defaultMessage: "General",
        description: "Notification group label for general notifications",
      })
    case NotificationGroup.WorkflowRuns:
      return intl.formatMessage({
        id: "notification.settings.requests.description",
        defaultMessage: "Requests",
        description: "Notification group label for requests notifications",
      })
    case NotificationGroup.Agreements:
      return intl.formatMessage({
        id: "notification.settings.agreements.description",
        defaultMessage: "Agreements",
        description: "Notification group label for agreements notifications",
      })
    case NotificationGroup.Tools:
      return intl.formatMessage({
        id: "notification.settings.tools.description",
        defaultMessage: "Tools (Owned by you)",
        description: "Notification group label for tools notifications",
      })
    case NotificationGroup.Vendors:
      return intl.formatMessage({
        id: "notification.settings.vendors.description",
        defaultMessage: "Vendors (Owned by you)",
        description: "Notification group label for vendors notifications",
      })
  }
}

const getNotificationTextForNotificationType = (notification: NotificationType, intl: IntlShape): string => {
  switch (notification) {
    case "mention":
      return intl.formatMessage({
        id: "notification.settings.mention",
        defaultMessage: "You are mentioned or someone replies to your comment",
        description: "Notification text for mentions",
      })
    case "workflow_run.started":
      return intl.formatMessage({
        id: "notification.settings.newRequests",
        defaultMessage: "New request created",
        description: "Notification text for new requests",
      })
    case "workflow_run.completed":
      return intl.formatMessage({
        id: "notification.settings.requestCompleted",
        defaultMessage: "Request completed",
        description: "Notification text for completed requests",
      })
    case "workflow_run.input_needed":
      return intl.formatMessage({
        id: "notification.settings.inputNeeded",
        defaultMessage: "Your input is needed",
        description: "Notification text for requests needing input",
      })
    case "workflow_run_step.approved":
      return intl.formatMessage({
        id: "notification.settings.stepApproved",
        defaultMessage: "Step approved",
        description: "Notification text for request steps getting approved",
      })
    case "workflow_run_step.approval_requested":
      return intl.formatMessage({
        id: "notification.settings.stepApprovalRequested",
        defaultMessage: "Step ready for review",
        description: "Notification text for request steps getting approval requested",
      })
    case "workflow_run_step.approval_overridden":
      return intl.formatMessage({
        id: "notification.settings.stepApprovalOverridden",
        defaultMessage: "Step approval overridden",
        description: "Notification text for request steps getting approved on behalf of you",
      })
    case "workflow_run_step_field.reopened":
      return intl.formatMessage({
        id: "notification.settings.fieldReopened",
        defaultMessage: "Field reopened",
        description: "Notification text for approved request field getting reopened",
      })
    case "workflow_run_step.seller_submitted":
      return intl.formatMessage({
        id: "notification.settings.stepSellerSubmitted",
        defaultMessage: "Seller submitted step",
        description: "Notification text for request step seller submitted",
      })
    case "workflow_run.timeline_comment":
      return intl.formatMessage({
        id: "notification.settings.timelineEvent",
        defaultMessage: "Field comments",
        description: "Notification text for request timeline events",
      })
    case "workflow_run_field.gatherer_assigned":
      return intl.formatMessage({
        id: "notification.settings.gathererAssigned",
        defaultMessage: "Field gatherer assigned",
        description: "Notification text for request gatherer assignment",
      })
    case "tool_owned.timeline_comment":
      return intl.formatMessage({
        id: "notification.settings.toolOwned.TimelineComment",
        defaultMessage: "Comments",
        description: "Notification text for tool timeline comment",
      })
    case "vendor_owned.timeline_comment":
      return intl.formatMessage({
        id: "notification.settings.vendorOwned.TimelineComment",
        defaultMessage: "Comments",
        description: "Notification text for vendor timeline comment",
      })
    case "tool.timeline_comment":
      return intl.formatMessage({
        id: "notification.settings.tool.TimelineComment",
        defaultMessage: "Comments",
        description: "Notification text for tool timeline comment",
      })
    case "vendor.timeline_comment":
      return intl.formatMessage({
        id: "notification.settings.vendor.TimelineComment",
        defaultMessage: "Comments",
        description: "Notification text for vendor timeline comment",
      })
    case "tool_digest":
      return intl.formatMessage({
        id: "notification.settings.toolDigest",
        defaultMessage: "New tool detected (weekly summary)",
        description: "Notification text for tool digest",
      })
    case "vendor_digest":
      return intl.formatMessage({
        id: "notification.settings.vendorDigest",
        defaultMessage: "New vendor detected (weekly summary)",
        description: "Notification text for vendor digest",
      })
    case "unverified_agreement_digest":
      return intl.formatMessage({
        id: "notification.settings.unverifiedAgreementDigest",
        defaultMessage: "Unverified agreements (daily summary)",
        description: "Notification text for unverified agreement digest",
      })
    case "legal_agreement_created_by_system":
      return intl.formatMessage({
        id: "notification.settings.legalAgreementCreatedBySystem",
        defaultMessage: "New agreement created",
        description: "Notification text for legal agreement created by system",
      })
    case "document.email_ingested":
      return intl.formatMessage({
        id: "notification.settings.documentIngested",
        defaultMessage: "Documents found by contract collector",
        description: "Notification text for document email ingested",
      })
    case "workflow_run_step.unapproved":
      return intl.formatMessage({
        id: "notification.settings.workflowRunStep.unapproved",
        defaultMessage: "Step unapproved",
        description: "Notification text for workflow run step unapproved",
      })
    case "workflow_run_step.changes_requested":
      return intl.formatMessage({
        id: "notification.settings.workflowRunStep.changesRequested",
        defaultMessage: "Step needs changes",
        description: "Notification text for workflow run step changes requested",
      })
    case "task.workflow_run":
      return intl.formatMessage({
        id: "notification.settings.task.workflowRun",
        defaultMessage: "Task Workflow Run",
        description: "Notification text for task workflow run",
      })
    case "renewal_reminder":
      return intl.formatMessage({
        id: "notification.settings.renewalReminder",
        defaultMessage: "Renewal reminder",
        description: "Notification text for renewal reminder",
      })
    case "tool_owned_renewal_reminder":
      return intl.formatMessage({
        id: "notification.settings.toolOwnedRenewalReminder",
        defaultMessage: "Renewal reminder",
        description: "Notification text for renewal reminder",
      })
    case "vendor_owned_renewal_reminder":
      return intl.formatMessage({
        id: "notification.settings.vendorOwnedRenewalReminder",
        defaultMessage: "Renewal reminder",
        description: "Notification text for renewal reminder",
      })
    case "timeline_comment.reaction":
      return intl.formatMessage({
        id: "notification.settings.timelineCommentReaction",
        defaultMessage: "Someone reacts to your comment",
        description: "Notification text for timeline comment reaction",
      })
    case "task.reply":
      return intl.formatMessage({
        id: "notification.settings.taskReply",
        defaultMessage: "Comment reply task",
        description: "Notification text for comment reply task",
      })
    case "integration.disconnected":
      return intl.formatMessage({
        id: "notification.settings.integrationDisconnected",
        defaultMessage: "Integration disconnected",
        description: "Notification text for integration disconnected",
      })
    case "tools.inactive_owner_reassignment":
      return intl.formatMessage({
        id: "notification.settings.tools.inactiveOwnerReassignment",
        defaultMessage: "Inactive owner reassignment",
        description: "Notification text for inactive owner reassignment",
      })
    case "vendors.inactive_owner_reassignment":
      return intl.formatMessage({
        id: "notification.settings.vendors.inactiveOwnerReassignment",
        defaultMessage: "Inactive owner reassignment",
        description: "Notification text for inactive owner reassignment",
      })
    case "organization.duplicative_subscriptions":
      return intl.formatMessage({
        id: "notification.settings.organization.duplicativeSubscriptions",
        defaultMessage: "Duplicative subscriptions detected",
        description: "Notification text for duplicative subscriptions",
      })
    case "tool_or_vendor.duplicative_subscriptions":
      return intl.formatMessage({
        id: "notification.settings.toolOrVendor.duplicativeSubscriptions",
        defaultMessage: "Duplicative subscriptions detected",
        description: "Notification text for duplicative subscriptions",
      })
    case "integration.historical_agreement_crawl_done":
      return intl.formatMessage({
        id: "notification.settings.integration.historicalAgreementCrawlDone",
        defaultMessage: "Gmail integration agreement import complete",
        description: "Notification text for historical agreement crawl done",
      })
    case "free_trial_expired":
      return intl.formatMessage({
        id: "notification.settings.freeTrialExpired",
        defaultMessage: "Free trial expired",
        description: "Notification text for free trial expired",
      })
    default:
      return unreachable(notification)
  }
}

const NotificationSettings: FC = () => {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const isLiteOrganization = whoami?.organization?.is_lite
  return (
    <SettingsContainer>
      <SettingsHeader
        title={intl.formatMessage({
          defaultMessage: "Notifications",
          description: "Title for notification settings page",
          id: "notification.settings.title",
        })}
        subtitle={intl.formatMessage({
          defaultMessage: "Configure your notification channels and events",
          description: "Description under the notification settings title",
          id: "notification.settings.title.description",
        })}
      />
      <Stack minWidth="450px" height="100%">
        <NotificationSettingsInner isLiteOrganization={isLiteOrganization ?? false} />
      </Stack>
    </SettingsContainer>
  )
}

export default NotificationSettings

const NotificationSettingsInner = ({ isLiteOrganization }: { isLiteOrganization: boolean }) => {
  const intl = useIntl()

  const requiredForInboxTooltip = intl.formatMessage({
    id: "notification.settings.requests.inboxTooltip",
    defaultMessage: "Required for inbox",
    description: "Tooltip for inbox notifications",
  })

  const digestNotAvailableInInboxTooltip = intl.formatMessage({
    id: "notification.settings.inbox.digest.tooltip",
    description: "Tooltip for Inbox checkbox when digest notifications are disabled",
    defaultMessage: "Digest notifications are not available in inbox",
  })

  const renewalRemindersRequiredInboxTooltip = intl.formatMessage({
    id: "notification.settings.inbox.renewalReminders.tooltip",
    description: "Tooltip for Inbox checkbox when renewal reminders are required",
    defaultMessage:
      "Renewal reminders are critical for managing vendor decisions, and cannot be disabled in the BRM inbox to ensure you never miss an upcoming renewal",
  })

  const integrationDisconnectedTooltip = intl.formatMessage({
    id: "notification.settings.inbox.integrationDisconnected.tooltip",
    description: "Tooltip for Inbox checkbox when integration disconnected is disabled",
    defaultMessage: "Disconnected integrations show up as a task in your inbox.",
  })

  const notificationTypeByGroup: Record<NotificationGroup, NotificationSubGroup> = {
    general: {
      header: intl.formatMessage({
        id: "notification.settings.general.description",
        defaultMessage: "Set preferences for notifications for general platform activities.",
        description: "Description of what general notifications are",
      }),
      notifications: [
        { type: "mention" },
        {
          type: "timeline_comment.reaction",
          emailDisabled: true,
        },
        {
          type: "task.reply",
          inboxDisabled: true,
        },
        {
          type: "tool_digest",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", digestNotAvailableInInboxTooltip]]),
        },
        {
          type: "vendor_digest",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", digestNotAvailableInInboxTooltip]]),
        },
        {
          type: "integration.disconnected",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", integrationDisconnectedTooltip]]),
        },
      ],
    },
    workflow_runs: {
      header: intl.formatMessage({
        id: "notification.settings.requests.description",
        defaultMessage: "Set preferences for notifications related to request activities.",
        description: "Description of what requests notifications are",
      }),
      notifications: [
        {
          type: "workflow_run.started",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run.input_needed",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run.completed",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.approval_requested",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.approval_overridden",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.changes_requested",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.approved",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.seller_submitted",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step.unapproved",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_step_field.reopened",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run.timeline_comment",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
        {
          type: "workflow_run_field.gatherer_assigned",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", requiredForInboxTooltip]]),
        },
      ],
    },
    agreements: {
      header: intl.formatMessage({
        id: "notification.settings.agreements.description",
        defaultMessage: "Set preferences for notifications related to agreements.",
        description: "Description of what agreements notifications are",
      }),
      notifications: [
        { type: "unverified_agreement_digest", inboxDisabled: true },
        { type: "organization.duplicative_subscriptions" },
        { type: "renewal_reminder", otherChannelsRequireInbox: true, numDaysRequired: true },
        { type: "integration.historical_agreement_crawl_done" },
        { type: "legal_agreement_created_by_system" },
        { type: "document.email_ingested" },
      ],
    },
    tools: {
      header: intl.formatMessage({
        id: "notification.settings.tools.description",
        defaultMessage: "Set preferences for notifications for tools you own.",
        description: "Description of what tools notifications are",
      }),
      notifications: [
        {
          type: "tool_owned_renewal_reminder",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", renewalRemindersRequiredInboxTooltip]]),
          numDaysRequired: true,
        },
        { type: "tool_owned.timeline_comment" },
      ],
    },
    vendors: {
      header: intl.formatMessage({
        id: "notification.settings.vendors.description",
        defaultMessage: "Set preferences for notifications for vendors you own.",
        description: "Description of what vendors notifications are",
      }),
      notifications: [
        {
          type: "vendor_owned_renewal_reminder",
          inboxDisabled: true,
          channelTooltips: new Map([["inbox", renewalRemindersRequiredInboxTooltip]]),
          numDaysRequired: true,
        },
        { type: "vendor_owned.timeline_comment" },
      ],
    },
  }

  const [setNotificationSettings] = usePutUserV1NotificationSettingsMutation()
  const { data } = useGetUserV1NotificationSettingsQuery()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      toast({
        description: error,
        status: "error",
        position: "top",
        onCloseComplete: () => setSearchParams(),
      })
    }
  }, [searchParams, intl, toast, setSearchParams])

  const { notificationSettingsByTypeMap, activeChannels } = useMemo((): {
    notificationSettingsByTypeMap: Map<
      NotificationType,
      Map<NotificationChannel, { enabled: boolean; numDays?: number }>
    >
    activeChannels: Set<NotificationChannel>
  } => {
    // make a copy of the default state
    const { activeChannels, settings } = data ?? { activeChannels: [], settings: [] }
    if (!settings) {
      return {
        notificationSettingsByTypeMap: new Map(),
        activeChannels: new Set(activeChannels),
      }
    }

    const notificationSettingsByTypeMap = new Map<
      NotificationType,
      Map<NotificationChannel, { enabled: boolean; numDays?: number }>
    >()

    settings.forEach((setting) => {
      const { channel, type, enabled, num_days } = setting
      const typeMap =
        notificationSettingsByTypeMap.get(type) ??
        new Map<NotificationChannel, { enabled: boolean; numDays?: number }>()
      typeMap.set(channel, { enabled, numDays: num_days ?? undefined })
      notificationSettingsByTypeMap.set(type, typeMap)
    })

    return {
      notificationSettingsByTypeMap,
      activeChannels: new Set(activeChannels),
    }
  }, [data])

  const updateNotifications = async (notificationSettings: UpdateNotificationSetting[]) => {
    try {
      const disabledToolRenewal = notificationSettings.find(
        (setting) => setting.type === "tool_owned_renewal_reminder" && setting.enabled === false
      )
      const disabledVendorRenewal = notificationSettings.find(
        (setting) => setting.type === "vendor_owned_renewal_reminder" && setting.enabled === false
      )

      // Update notification settings
      await setNotificationSettings({ body: notificationSettings }).unwrap()

      if (disabledToolRenewal) {
        posthogCaptureEvent("tool_owned_renewal_reminder_disabled", {})
      }
      if (disabledVendorRenewal) {
        posthogCaptureEvent("vendor_owned_renewal_reminder_disabled", {})
      }
      toast({
        description: intl.formatMessage({
          id: "notification.settings.updateSuccess",
          description: "Notification settings update success toast title",
          defaultMessage: "Notification settings updated",
        }),
        status: "success",
        duration: SHORT_TOAST_DURATION,
      })
    } catch (_) {
      toast({
        description: intl.formatMessage({
          id: "notification.settings.failure",
          description: "Toast failure message when saving notification settings",
          defaultMessage: "Failed to save notification settings",
        }),
        status: "error",
        duration: SHORT_TOAST_DURATION,
      })
    }
  }

  if (!data) {
    return (
      <Center width="100%" height="100%">
        <Spinner size="lg" />
      </Center>
    )
  }

  const SlackIntegrationToggle = () => {
    return (
      <HStack borderTopWidth={1} borderBottomWidth={1} py={5} mt={4}>
        <Icon as={SlackIcon} boxSize="32px" />
        <Stack gap={0.5}>
          <Text fontSize="sm" fontWeight="medium">
            <FormattedMessage
              defaultMessage="Slack Notifications"
              description="Notification Slack settings description"
              id="notification.settings.slack"
            />
          </Text>
          <Text fontSize="sm" color="gray.600">
            <FormattedMessage
              defaultMessage="Receive personal notifications in Slack"
              description="Notification Slack settings description"
              id="notification.settings.slack.description"
            />
          </Text>
        </Stack>
      </HStack>
    )
  }

  return (
    <>
      <SlackIntegrationToggle />
      <Grid
        templateColumns={`minmax(50px, 280px) auto repeat(${activeChannels.size}, 37px)`}
        columnGap={4}
        rowGap={4}
        maxWidth="960px"
        pt={6}
      >
        {Object.entries(
          isLiteOrganization
            ? {
                agreements: {
                  header: notificationTypeByGroup.agreements.header,
                  notifications: [
                    { type: "renewal_reminder", otherChannelsRequireInbox: true, numDaysRequired: true },
                    { type: "unverified_agreement_digest", inboxDisabled: true },
                    { type: "integration.historical_agreement_crawl_done" },
                    { type: "legal_agreement_created_by_system" },
                    { type: "document.email_ingested" },
                  ],
                },
              }
            : notificationTypeByGroup
        ).map(([sectionKey, section]) => {
          return (
            <Fragment key={sectionKey}>
              <SettingsSection
                sectionHeader={getLabelFromNotificationGroup(sectionKey as NotificationGroup, intl)}
                sectionSubHeader={section.header}
                slackIsActive={activeChannels.has("slack")}
                rows={section.notifications.map((notification) => {
                  return {
                    type: notification.type,
                    description: getNotificationTextForNotificationType(notification.type, intl),
                    emailChecked: notificationSettingsByTypeMap.get(notification.type)?.get("email")?.enabled ?? false,
                    slackChecked: notificationSettingsByTypeMap.get(notification.type)?.get("slack")?.enabled ?? false,
                    inboxChecked: notificationSettingsByTypeMap.get(notification.type)?.get("inbox")?.enabled ?? false,
                    // All channels should have the same numDays value. This assumption is a little fragile might be worth flattening notification_settings at some point
                    numDays: notificationSettingsByTypeMap.get(notification.type)?.get("inbox")?.numDays ?? undefined,
                    inboxDisabled: notification.inboxDisabled,
                    emailDisabled: notification.emailDisabled,
                    otherChannelsRequireInbox: notification.otherChannelsRequireInbox,
                    numDaysRequired: notification.numDaysRequired,
                    channelTooltips: notification.channelTooltips,
                  }
                })}
                onChangeSectionCheckbox={async (channel, enabled) => {
                  await updateNotifications(
                    section.notifications
                      .filter((notification) => {
                        switch (channel) {
                          case "inbox":
                            return !notification.inboxDisabled
                          case "slack":
                            return (
                              !notification.otherChannelsRequireInbox ||
                              notificationSettingsByTypeMap.get(notification.type)?.get("email")?.enabled
                            )
                          case "email":
                            return (
                              !notification.emailDisabled &&
                              (!notification.otherChannelsRequireInbox ||
                                notificationSettingsByTypeMap.get(notification.type)?.get("email")?.enabled)
                            )
                        }
                      })
                      .map((notification) => ({
                        type: notification.type,
                        channel,
                        enabled,
                        num_days: notificationsThatNeedNumDays.has(notification.type) ? 90 : undefined,
                      }))
                  )
                }}
                onChangeNumDays={async (type, numDays) => {
                  await updateNotifications(
                    Array.from(activeChannels, (channel) => ({
                      type,
                      channel,
                      num_days: numDays,
                      enabled: notificationSettingsByTypeMap.get(type)?.get(channel)?.enabled || false,
                    }))
                  )
                }}
                onChange={async (notification, channel, enabled) => {
                  const numDays = notificationsThatNeedNumDays.has(notification)
                    ? (notificationSettingsByTypeMap.get(notification)?.get("inbox")?.numDays ?? 90)
                    : undefined

                  // If a user is disabling inbox when other channels require it also disable the other channels
                  if (channel === "inbox" && enabled === false) {
                    const notificationData = section.notifications.find(
                      (notificationData) => notificationData.type === notification
                    )
                    if (notificationData && notificationData.otherChannelsRequireInbox) {
                      return await updateNotifications([
                        {
                          type: notification,
                          channel: "inbox",
                          enabled: false,
                          num_days: numDays,
                        },
                        {
                          type: notification,
                          channel: "email",
                          enabled: false,
                          num_days: numDays,
                        },
                        {
                          type: notification,
                          channel: "slack",
                          enabled: false,
                          num_days: numDays,
                        },
                      ])
                    }
                  }

                  await updateNotifications([
                    {
                      type: notification,
                      channel,
                      enabled,
                      num_days: numDays,
                    },
                  ])
                }}
              />
              <GridItem height="2rem" colSpan={activeChannels.size + 2} />
            </Fragment>
          )
        })}
      </Grid>
    </>
  )
}

interface SettingsSectionRow {
  type: NotificationType
  description: string
  emailChecked: boolean
  slackChecked: boolean
  inboxChecked: boolean
  inboxDisabled?: boolean
  emailDisabled?: boolean
  otherChannelsRequireInbox?: boolean
  numDaysRequired?: boolean
  numDays?: number
  channelTooltips?: Map<NotificationChannel, string>
}

interface SettingsSectionProps {
  sectionHeader: string
  sectionSubHeader: string
  rows: SettingsSectionRow[]
  onChange: (type: NotificationType, channel: NotificationChannel, enabled: boolean) => void
  onChangeNumDays: (type: NotificationType, numDays: number) => void
  onChangeSectionCheckbox: (channel: NotificationChannel, enabled: boolean) => void
  slackIsActive: boolean
}

export const SettingsSection: FC<SettingsSectionProps> = ({
  sectionHeader,
  sectionSubHeader,
  rows,
  onChange,
  onChangeNumDays,
  onChangeSectionCheckbox,
  slackIsActive,
}) => {
  const allInboxChecked = rows.every((row) => row.inboxChecked)
  const someInboxChecked = rows.some((row) => row.inboxChecked)
  const allSlackChecked = rows.every((row) => row.slackChecked)
  const someSlackChecked = rows.some((row) => row.slackChecked)
  const allEmailChecked = rows.every((row) => row.emailChecked)
  const someEmailChecked = rows.some((row) => row.emailChecked)
  const someInboxDisabled = rows.some((row) => row.inboxDisabled)
  return (
    <>
      {/* Column Header Row */}
      <GridItem colSpan={2} />
      <GridItem textAlign="center" paddingBottom={1} color="gray.600">
        <FormattedMessage
          id="notification.settings.inbox.column"
          defaultMessage="Inbox"
          description="Column header for inbox notifications in settings"
        />
      </GridItem>
      {slackIsActive && (
        <GridItem textAlign="center" paddingBottom={1} color="gray.600">
          <FormattedMessage
            id="notification.settings.slack.column"
            defaultMessage="Slack"
            description="Column header for Slack notifications in settings"
          />
        </GridItem>
      )}
      <GridItem textAlign="center" paddingBottom={1} color="gray.600">
        <FormattedMessage
          id="notification.settings.email.column"
          defaultMessage="Email"
          description="Column header for email notifications in settings"
        />
      </GridItem>
      {/* Header Row */}
      <GridItem rowSpan={rows.length * 2 + 1} display="flex">
        <Stack gap={0}>
          <Text fontSize="sm" fontWeight="semibold">
            {sectionHeader}
          </Text>
          <Text fontSize="sm" fontWeight="normal">
            {sectionSubHeader}
          </Text>
        </Stack>
      </GridItem>
      <GridItem />
      {rows.length > 1 && (
        <>
          <GridItem textAlign="center">
            <Checkbox
              isChecked={allInboxChecked}
              isIndeterminate={someInboxChecked && !allInboxChecked}
              onChange={() => onChangeSectionCheckbox("inbox", !someInboxChecked)}
              isDisabled={someInboxDisabled}
            />
          </GridItem>
          {slackIsActive && (
            <GridItem textAlign="center">
              <Checkbox
                isChecked={allSlackChecked}
                isIndeterminate={someSlackChecked && !allSlackChecked}
                onChange={() => onChangeSectionCheckbox("slack", !someSlackChecked)}
              />
            </GridItem>
          )}
          <GridItem textAlign="center">
            <Checkbox
              isChecked={allEmailChecked}
              isIndeterminate={someEmailChecked && !allEmailChecked}
              onChange={() => onChangeSectionCheckbox("email", !someEmailChecked)}
            />
          </GridItem>
        </>
      )}
      {/* Rows Section */}
      {rows.map((row, i) => (
        <SettingsSectionRowElement
          key={i}
          row={row}
          onChange={(enabled: boolean, channel: NotificationChannel) => {
            onChange(row.type, channel, enabled)
          }}
          onChangeNumDays={(numDays: number) => {
            onChangeNumDays(row.type, numDays)
          }}
          slackIsActive={slackIsActive}
        />
      ))}
    </>
  )
}

const SettingsSectionRowDivider: FC<{ numActiveChannels: number }> = ({ numActiveChannels }) => {
  return (
    <GridItem
      colSpan={numActiveChannels + 1}
      borderBottomWidth={1}
      height="2px"
      borderBottomColor="gray.200"
      borderBottomStyle="solid"
    />
  )
}

interface SettingsSectionRowElementProps {
  row: SettingsSectionRow
  onChange: (enabled: boolean, channel: NotificationChannel) => void
  onChangeNumDays: (numDays: number) => void
  slackIsActive: boolean
}

interface NumDaysOption {
  value: number
  label: string
}

interface NotificationDaysSelectProps {
  numDays: number | undefined
  onChangeNumDays: (numDays: number) => void
  isMenuOpen: boolean
  setIsMenuOpen: (open: boolean) => void
}

const NotificationDaysSelect: FC<NotificationDaysSelectProps> = ({
  numDays,
  onChangeNumDays,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const intl = useIntl()

  return (
    <Select<NumDaysOption>
      value={{
        value: numDays || 90,
        label: intl.formatMessage(
          {
            id: "notification.settings.numDaysRequired",
            description: "Notification settings number of days required",
            defaultMessage: "{numDays} days before decision date",
          },
          { numDays: numDays || 90 }
        ),
      }}
      onChange={(newValue) => newValue && onChangeNumDays(newValue.value)}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      menuIsOpen={isMenuOpen}
      options={[
        {
          value: 90,
          label: intl.formatMessage({
            id: "notification.settings.numDaysRequired.90days",
            description: "Notification settings number of days required",
            defaultMessage: "90 days before decision date",
          }),
        },
        {
          value: 60,
          label: intl.formatMessage({
            id: "notification.settings.numDaysRequired.60days",
            description: "Notification settings number of days required",
            defaultMessage: "60 days before decision date",
          }),
        },
        {
          value: 30,
          label: intl.formatMessage({
            id: "notification.settings.numDaysRequired.30days",
            description: "Notification settings number of days required",
            defaultMessage: "30 days before decision date",
          }),
        },
        {
          value: 15,
          label: intl.formatMessage({
            id: "notification.settings.numDaysRequired.15days",
            description: "Notification settings number of days required",
            defaultMessage: "15 days before decision date",
          }),
        },
      ]}
    />
  )
}

const SettingsSectionRowElement: FC<SettingsSectionRowElementProps> = ({
  row,
  onChange,
  onChangeNumDays,
  slackIsActive,
}) => {
  const channelsRequiringInboxDisabled = row.otherChannelsRequireInbox && !row.inboxChecked
  const [daysSelectMenuOpen, setDaysSelectMenuOpen] = useState(false)

  return (
    <>
      <SettingsSectionRowDivider numActiveChannels={slackIsActive ? 3 : 2} />
      <GridItem textAlign="left" display="flex" alignItems="center" justifyContent="space-between" gap={1}>
        {row.description}
        {row.numDaysRequired && (
          <NotificationDaysSelect
            numDays={row.numDays}
            onChangeNumDays={onChangeNumDays}
            isMenuOpen={daysSelectMenuOpen}
            setIsMenuOpen={setDaysSelectMenuOpen}
          />
        )}
      </GridItem>
      <GridItem textAlign="center" alignSelf="center">
        <Tooltip
          shouldWrapChildren
          isDisabled={!row.channelTooltips?.has("inbox")}
          label={row.channelTooltips?.get("inbox")}
        >
          <Checkbox
            isDisabled={row.inboxDisabled}
            isChecked={row.inboxChecked}
            onChange={(e) => onChange(e.target.checked, "inbox")}
          />
        </Tooltip>
      </GridItem>
      {slackIsActive && (
        <GridItem textAlign="center" alignSelf="center">
          <Tooltip
            shouldWrapChildren
            isDisabled={!row.channelTooltips?.has("slack")}
            label={row.channelTooltips?.get("slack")}
          >
            <Checkbox
              isDisabled={channelsRequiringInboxDisabled}
              isChecked={row.slackChecked}
              onChange={(e) => onChange(e.target.checked, "slack")}
            />
          </Tooltip>
        </GridItem>
      )}
      <GridItem textAlign="center" alignSelf="center">
        <Tooltip
          shouldWrapChildren
          isDisabled={!row.channelTooltips?.has("email")}
          label={row.channelTooltips?.get("email")}
        >
          <Checkbox
            isDisabled={channelsRequiringInboxDisabled || row.emailDisabled}
            isChecked={row.emailChecked}
            onChange={(e) => onChange(e.target.checked, "email")}
          />
        </Tooltip>
      </GridItem>
    </>
  )
}
