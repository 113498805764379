import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import BrmLogo from "../../components/BrmLogo.js"
import { Link } from "../../components/Link.js"

const contactEmail = "buy@brm.ai"

export const ActivateBrm: FunctionComponent = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" position="relative">
      <Box maxWidth="768px" width="100%" ml={4} mr={4} mt={4}>
        <VStack spacing={6} align="flex-start">
          <Heading size="md" fontWeight="bold">
            <FormattedMessage
              defaultMessage="Ready to unlock the full power of BRM?"
              description="Purchase page subtitle"
              id="Purchase.subtitle"
            />
          </Heading>

          <Text fontSize="lg">
            <FormattedMessage
              id="Purchase.description"
              defaultMessage="Purchase BRM to continue using our services. Send us an email at {emailAddress} to unlock future savings, or to let us know how your free trial went. We’d love to hear about your experience."
              description="Purchase page description"
              values={{
                emailAddress: (
                  <Link variant="highlighted" to={`mailto:${contactEmail}`}>
                    {contactEmail}
                  </Link>
                ),
              }}
            />
          </Text>

          <Flex width="100%" justifyContent="flex-start" mt={4}>
            <Link to={`mailto:${contactEmail}`} variant="noUnderline">
              <Button
                colorScheme="brand"
                borderRadius="full"
                fontSize="1.1rem"
                size="lg"
                px={8}
                py={6}
                fontWeight="bold"
                boxShadow="md"
              >
                <FormattedMessage
                  id="Purchase.contactUs"
                  defaultMessage="Contact us"
                  description="Purchase page contact us button"
                />
              </Button>
            </Link>
          </Flex>
        </VStack>
      </Box>

      <Box position="absolute" bottom="30px" right="30px">
        <BrmLogo height="200px" width="200px" opacity={0.8} />
      </Box>
    </Box>
  )
}

export default ActivateBrm
