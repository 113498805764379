import type { Message } from "@brm/schema-types/types.js"

export const defaultMessages: Message[] = [
  {
    content: "Hi, I will gladly answer your questions about tools, vendors, and more!",
    role: "assistant",
    tool_calls: [],
    citations: null,
  },
]
export const defaultSuggestedPrompts = ["What is BRM?", "Who owns BRM?", "Purchase BRM"]

export const defaultLiteMessages: Message[] = [
  {
    content: "Hi, I will gladly answer your questions about agreements, renewals, and more!",
    role: "assistant",
    tool_calls: [],
    citations: null,
  },
]
export const defaultLiteSuggestedPrompts = [
  "What clauses do I need to review?",
  "Are there usage restrictions?",
  "What are my non-financial obligations?",
  "Is there anything concerning about the pricing model?",
]

export const defaultOnboardingMessages = (
  display_name: string = "[SAMPLE] BRM Agreement",
  id: string = ""
): Message[] => [
  {
    content: "Hi, I will gladly answer your questions about agreements, renewals, and more!",
    role: "assistant",
    tool_calls: [],
    citations: null,
  },
  {
    content: `${display_name} Does this agreement include a clause mentioning a case study?`,
    role: "user",
    rich_text: [
      {
        type: "paragraph",
        children: [
          {
            text: "",
          },
          {
            type: "standard-object",
            display_name,
            object_type: "LegalAgreement",
            id,
            children: [
              {
                text: "",
              },
            ],
          },
          {
            text: " Does this agreement include a clause about a case study?",
          },
        ],
      },
    ],
    tool_calls: null,
    citations: null,
  },
  {
    content:
      "Yes, the agreement does include a clause about a case study. The order form associated with this agreement states that the customer agrees to allow BRM to use its name in marketing materials, including testimonials and case studies.",
    role: "assistant",
    tool_calls: null,
    citations: null,
  },
]
