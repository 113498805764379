import { isObject } from "@brm/util/type-guard.js"

export interface BrmError {
  status: number
  data: {
    message: string
    details?: string
  }
}

export function isBrmError(err: unknown): err is BrmError {
  if (!isObject(err)) {
    return false
  }

  const hasMessage =
    "data" in err && isObject(err.data) && "message" in err.data && typeof err.data.message === "string"

  const hasStatusCode = "status" in err && typeof err.status === "number" && !!err.status

  return hasMessage && hasStatusCode
}

export const getAPIErrorMessage = (err: unknown): string | undefined => {
  return (
    (isObject(err) &&
      "data" in err &&
      isObject(err.data) &&
      "message" in err.data &&
      typeof err.data.message === "string" &&
      err.data.message) ||
    undefined
  )
}
export const getAPIErrorStatusCode = (err: unknown): number | undefined => {
  return (isObject(err) && "status" in err && typeof err.status === "number" && err.status) || undefined
}

/** To reduce bundle size we can just declare the StatusCodes enum from http-status-codes we need instead of the whole package */
export enum StatusCodes {
  /**
   * Official Documentation @ https://tools.ietf.org/html/rfc7231#section-6.5.3
   *
   * The client does not have access rights to the content, i.e. they are unauthorized, so server is rejecting to give proper response. Unlike 401, the client's identity is known to the server.
   */
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
}
