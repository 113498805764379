import { requestRouteById } from "@brm/util/routes.js"
import { Center, Spinner } from "@chakra-ui/react"
import { useEffect, type FC } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { isString } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1LinksByCodeWorkflowRunQuery,
} from "../../../app/services/generated-api.js"
import useIsAppFocused from "../../../util/hooks/use-is-app-focused-hook.js"
import { WorkflowRunOverview } from "./WorkflowRunOverview.js"

/**
 * The form that is presented to the recipient of a BRM link to contribute to a workflow_run_step.
 * The data goes into a staging area of the link until accepted by the step owner.
 */
export const BrmLinkWorkflowRunPage: FC = () => {
  const { code } = useParams<{ code: string }>()
  const navigate = useNavigate()
  const isAppFocused = useIsAppFocused()
  isString(code, "Missing link auth code")

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const { data: workflowRun, refetch, error, isLoading } = useGetWorkflowV1LinksByCodeWorkflowRunQuery({ code })

  useEffect(() => {
    if (isAppFocused) {
      void refetch()
    }
  }, [isAppFocused, refetch])

  useEffect(() => {
    // If the user is signed into the buyer organization we redirect them to the workflow run page
    if (whoami && workflowRun && whoami.organization_id === workflowRun.owner.organization_id) {
      navigate(requestRouteById(workflowRun.id))
    }
  }, [navigate, whoami, workflowRun])

  if (error) {
    throw error
  }

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  if (!workflowRun) {
    return null
  }

  return <WorkflowRunOverview workflowRun={{ ...workflowRun, is_external: true }} />
}
