// eslint-disable-next-line no-restricted-imports
import { Popover as MantinePopover, type PopoverProps as MantinePopoverProps } from "@mantine/core"
import zIndices from "../../../packages/theme/src/foundations/z-index.js"

export interface PopoverProps extends MantinePopoverProps {
  children: React.ReactNode
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const Popover = ({ children, ...props }: PopoverProps) => {
  return (
    <MantinePopover
      zIndex={zIndices.popover}
      middlewares={{
        size: {
          apply: ({ availableHeight, elements }) => {
            elements.floating.style.maxHeight = `${Math.max(0, availableHeight)}px`
          },
        },
      }}
      styles={{
        dropdown: {
          padding: 0,
          borderRadius: "var(--chakra-radii-md)",
          boxShadow: "var(--chakra-shadows-sm)",
        },
      }}
      {...props}
    >
      {children}
    </MantinePopover>
  )
}

export const PopoverTrigger = MantinePopover.Target
export const PopoverContent = MantinePopover.Dropdown
export default Popover
