import { configureStore, type EnhancedStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import * as Sentry from "@sentry/react"
import { userListenerMiddleware } from "../features/auth/user-slice.js"
import { onboardingListenerMiddleware, onboardingReducer } from "../features/onboarding/onboarding-slice.js"
import { queryErrorHandler } from "./query-error-handler.js"
import { brmApi } from "./services/brm-api.js"
import { clearbitApi } from "./services/clearbit-api.js"

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const store: EnhancedStore = configureStore({
  reducer: {
    [brmApi.reducerPath]: brmApi.reducer,
    [clearbitApi.reducerPath]: clearbitApi.reducer,
    onboarding: onboardingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      brmApi.middleware,
      queryErrorHandler,
      clearbitApi.middleware,
      userListenerMiddleware.middleware,
      onboardingListenerMiddleware.middleware,
    ]),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
})

setupListeners(store.dispatch)
