import { HStack, Text, type SystemStyleObject } from "@chakra-ui/react"
import { CELL_SIZE_PROPS_MAP, type CellSize } from "../../components/DataTable/CellRenderer/util.js"
import { VendorLogo } from "../../components/icons/Logo.js"

interface VendorDisplayProps {
  vendorLogoUrl: string | undefined
  vendorName: string
  textContentStyle?: SystemStyleObject
  size?: CellSize
}

export default function VendorDisplay({
  vendorLogoUrl,
  vendorName,
  textContentStyle,
  size = "md",
}: VendorDisplayProps) {
  const cellSizeProps = CELL_SIZE_PROPS_MAP[size]

  return (
    <HStack>
      <VendorLogo logo={vendorLogoUrl} boxSize={cellSizeProps.boxSize} />
      <Text
        fontWeight={cellSizeProps.fontWeight}
        maxW="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        minW={0}
        sx={textContentStyle}
      >
        {vendorName}
      </Text>
    </HStack>
  )
}
