import type { CurrencyAmount, FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { COMMON_CURRENCIES, MORE_CURRENCIES } from "@brm/util/currency/consts.js"
import { formatCurrency, getLocaleSeparators, STANDARD_NUMBER_FORMAT } from "@brm/util/currency/format.js"
import { isEmpty } from "@brm/util/type-guard.js"
import type { FormControlProps } from "@chakra-ui/react"
import {
  Box,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { chakraComponents, type GroupBase, type Props } from "chakra-react-select"
import equal from "fast-deep-equal"
import type { ForwardedRef, ReactNode, Ref, RefObject } from "react"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { FormattedDisplayName, useIntl } from "react-intl"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"
import Select from "../Select/Select.js"

const defaultCurrency = "USD"

type CurrencyAmountFormControlProps = FormControlProps &
  CurrencyAmountInputGroupProps & {
    legend: ReactNode
    errorMessage?: ReactNode
  }

/**
 * A compound select box + input for a currency amount.
 */
export const CurrencyAmountFormControl = forwardRef(function CurrencyAmountFormControl(
  { legend, errorMessage, value, onChange, isReadOnly, ...formControlProps }: CurrencyAmountFormControlProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <FormControl as="fieldset" isReadOnly={isReadOnly} {...formControlProps}>
      <FormLabel as="legend">{legend}</FormLabel>
      <CurrencyAmountInputGroup ref={ref} value={value} onChange={onChange} isReadOnly={isReadOnly} />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
})

interface CurrencyAmountInputGroupProps {
  value: CurrencyAmount | null
  onChange?: (value: CurrencyAmount | null, fieldSource?: FieldMetadata) => void
  isReadOnly?: boolean
  disabled?: boolean
  disableCurrencySelect?: boolean
  suggestions?: ValueWithSource<CurrencyAmount>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
}

export const CurrencyAmountInputGroup = forwardRef(function CurrencyAmountInputGroup(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    fieldMetadata,
    fieldApproval,
    menuPortalTarget,
    disableCurrencySelect,
  }: CurrencyAmountInputGroupProps & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()

  // Keep ref to move focus to the amount input when the currency code is changed.
  const amountInputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => amountInputRef.current)
  const inputGroupRef = useRef<HTMLDivElement>(null)

  // Keep internal state of currency code so that the currency code can be set even if amount is empty.
  const [currencyCode, setCurrencyCode] = useState<string>(value?.currency_code ?? defaultCurrency)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Trim decimals on first render according to the currency on render because our backend always returns 4
  // decimals for simplicity. The NumericString is essentially a US locale floating number without decimal
  // separators and without a currency sign, so we can use Intl.NumberFormat for this.

  return (
    <InputGroup
      ref={inputGroupRef}
      // Only open dropdown when suggestions are available. Otherwise drop down is only openable via currency selector
      onFocus={suggestions && suggestions.length > 0 ? onOpen : undefined}
      onBlur={onClose}
    >
      <CurrencyInput
        ref={amountInputRef}
        value={value}
        currencyCode={currencyCode}
        onChange={onChange}
        isReadOnly={isReadOnly}
        disabled={disabled}
        suggestions={suggestions}
      />
      <InputRightElement justifyContent="right">
        <CurrencySelect
          disabled={disabled || disableCurrencySelect}
          value={{ amount: value?.amount || "", currency_code: currencyCode }}
          suggestions={suggestions}
          onChange={(option) => {
            let fieldSource = option.field_sources?.[0]
            let newValue: CurrencyAmount | null = null

            if (option.value) {
              const numericValue: number = parseFloat(option.value.amount)
              if (!isNaN(numericValue)) {
                newValue = {
                  amount: STANDARD_NUMBER_FORMAT.format(numericValue),
                  currency_code: option.value.currency_code,
                }
              }
            }

            // Fill fieldSource if selected option values match a suggestion
            if (!fieldSource) {
              const matchingSuggestion = suggestions?.find(
                (suggestion) => formatCurrency(suggestion.value, intl) === formatCurrency(newValue, intl)
              )
              fieldSource = matchingSuggestion?.field_sources?.[0]
            }

            setCurrencyCode(newValue?.currency_code ?? defaultCurrency)

            // Update only when value changes or suggested value is selected to prevent fieldSource resetting
            if (newValue && (suggestions?.some((suggestion) => equal(option, suggestion)) || !equal(newValue, value))) {
              onChange?.(newValue, fieldSource)
            }
            onClose()
          }}
          isReadOnly={isReadOnly}
          isOpen={isOpen}
          parentRef={inputGroupRef}
          fieldMetadata={fieldMetadata}
          fieldApproval={fieldApproval}
          onClose={onClose}
          onOpen={onOpen}
          menuPortalTarget={menuPortalTarget}
        />
      </InputRightElement>
    </InputGroup>
  )
})

// Navigation and control keys that should be allowed during numeric input
const NAVIGATION_KEYS = [
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Backspace",
  "Delete",
  "Tab",
  "Escape",
  "Enter",
  "Home",
  "End",
]

// Keys that are allowed when used with Ctrl/Cmd modifier
const CONTROL_KEYS = ["a", "c", "v", "x"]

/**
 * An input for a currency amount. This displays the currency symbol and allows for editing the amount.
 */
export const CurrencyInput = forwardRef(function CurrencyInput(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    currencyCode,
  }: CurrencyAmountInputGroupProps & { currencyCode: string },
  ref: Ref<HTMLInputElement | null>
) {
  const { amount, currency_code } = value ?? { amount: "0.00", currency_code: currencyCode }

  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  // initialize the raw value and display value based on the amount
  const [rawStringValue, setRawStringValue] = useState(formatCurrency({ currency_code, amount }, intl))
  const [displayValue, setDisplayValue] = useState(formatCurrency({ currency_code, amount }, intl))

  // Track if input is focused
  const [isFocused, setIsFocused] = useState(false)

  // Format raw value on blur
  const handleBlur = () => {
    setIsFocused(false)

    // Skip if empty
    if (!rawStringValue.trim()) {
      onChange?.(null)
      return
    }

    try {
      // Detect decimal separator for the current locale
      const { decimal, group } = getLocaleSeparators(intl.locale)

      // Clean the input based on locale-specific separators
      let cleanValue = rawStringValue
        .replace(new RegExp(`[^\\d${decimal}${group}-]`, "gu"), "") // Remove currency symbols
        .replace(new RegExp(group, "gu"), "") // Remove group separators

      // Ensure the decimal separator is a period for parseFloat
      if (decimal !== ".") {
        cleanValue = cleanValue.replace(new RegExp(decimal, "gu"), ".")
      }

      const numericValue = parseFloat(cleanValue)

      if (isNaN(numericValue)) {
        onChange?.(null)
        return
      }

      const formattedValue = STANDARD_NUMBER_FORMAT.format(numericValue)
      const newCurrencyValue = { amount: formattedValue, currency_code }

      // Check if formatted value matches any suggestions
      const matchingSuggestion = suggestions?.find(
        (suggestion) => formatCurrency(suggestion.value, intl) === formatCurrency(newCurrencyValue, intl)
      )

      onChange?.(newCurrencyValue, matchingSuggestion?.field_sources?.[0])

      // the key difference is that the display value is formatted based on the locale
      const newDisplayValue = formatCurrency(newCurrencyValue, intl)
      setDisplayValue(newDisplayValue)
    } catch (_err) {
      // In case of parsing error, revert to previous value
      setRawStringValue(displayValue)
    }
  }

  // Update raw value and display value when the value changes
  useEffect(() => {
    const { amount, currency_code } = value ?? { amount: "0.00", currency_code: currencyCode }
    setRawStringValue(formatCurrency({ currency_code, amount }, intl))
    setDisplayValue(formatCurrency({ currency_code, amount }, intl))
  }, [value, currencyCode, intl])

  return (
    <Input
      ref={inputRef}
      isReadOnly={isReadOnly}
      isDisabled={disabled}
      type="text"
      value={isFocused ? rawStringValue : displayValue}
      placeholder={formatCurrency({ currency_code: currencyCode, amount: "0" }, intl)}
      autoComplete="off"
      onFocus={() => {
        setIsFocused(true)
        // Optional: select all text for easy replacement
        inputRef.current?.select()
      }}
      onBlur={handleBlur}
      onChange={(e) => {
        setRawStringValue(e.target.value)
      }}
      // Allow only numeric input with decimal and minus
      onKeyDown={(e) => {
        // if the user hits the enter key, we should blur the input
        // this will trigger the handleBlur function and format the value
        if (e.key === "Enter" || e.key === "Escape") {
          inputRef.current?.blur()
          return
        }

        // Allow navigation and control keys
        if (NAVIGATION_KEYS.includes(e.key)) {
          return
        }

        // Allow: Ctrl+A/C/V/X
        if (CONTROL_KEYS.includes(e.key.toLowerCase()) && (e.ctrlKey || e.metaKey)) {
          return
        }

        const { decimal } = getLocaleSeparators(intl.locale)
        // Allow locale-specific decimal separator (and only one)
        if (e.key === decimal || e.key === "." || e.key === ",") {
          // Only allow the actual locale-specific decimal separator
          if (e.key !== decimal) {
            e.preventDefault()
            return
          }

          // Only allow one decimal separator
          if (rawStringValue.includes(decimal)) {
            const isEntireInputSelected =
              inputRef.current?.selectionStart === 0 && inputRef.current?.selectionEnd === rawStringValue.length

            // detect if the entire input is selected
            if (!isEntireInputSelected) {
              e.preventDefault()
            }

            // if the entire input is selected, and the user inputs a decimal
            // then we allow this input, which effectively cleans the input and puts decimal separator in the correct place
          }
          return
        }

        // Reject non-numeric characters
        if (!/^\d$/u.test(e.key)) {
          e.preventDefault()
        }
      }}
    />
  )
})

/**
 * A select box for a currency code.
 */
const CurrencySelect = forwardRef(function CurrencySelect(
  {
    value,
    onChange,
    isReadOnly,
    disabled,
    suggestions,
    isOpen,
    parentRef,
    fieldMetadata,
    onOpen,
    onClose,
    fieldApproval,
    menuPortalTarget,
  }: {
    /** The current 3-character ISO currency code. */
    value: CurrencyAmount

    /** Called with the new currency code. */
    onChange: (value: ValueWithSource<CurrencyAmount | undefined>) => void
    isReadOnly?: boolean
    disabled?: boolean
    suggestions?: ValueWithSource<CurrencyAmount>[]
    fieldMetadata?: FieldMetadataWithSuggestions
    isOpen?: boolean
    parentRef?: RefObject<HTMLDivElement>
    onOpen?: () => void
    onClose?: () => void
    fieldApproval?: DynamicFormFieldApproval
  } & Pick<Props, "menuPortalTarget">,
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  return (
    // Make sure that Enter in the menu doesn't submit a surrounding form
    <Box display="contents">
      <Select<
        ValueWithSource<CurrencyAmount | undefined>,
        false,
        GroupBase<ValueWithSource<CurrencyAmount | undefined>>
      >
        value={{ value, field_sources: fieldMetadata ? [fieldMetadata] : [] }}
        isOptionSelected={(option) => {
          if (!option.value) {
            return false
          }
          const amountEquals = formatCurrency(option.value, intl) === formatCurrency(value, intl)
          const fieldSourceEquals =
            option.field_sources?.some((source) => source.id === fieldMetadata?.id) ||
            (isEmpty(option.field_sources) && fieldMetadata?.type === "user")

          return amountEquals && fieldSourceEquals
        }}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          SingleValue: (props) => (
            <chakraComponents.SingleValue {...props}>{props.data.value?.currency_code}</chakraComponents.SingleValue>
          ),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option: (props) => {
            const fieldSources = props.data.field_sources
            return fieldSources && fieldSources.length > 0 ? (
              <chakraComponents.Option {...props}>
                <OptionWithFieldSource
                  fieldSources={fieldSources}
                  onMouseEnter={() => {
                    // pull focus when the users mouse enters a suggested option
                    inputRef.current?.focus()
                  }}
                >
                  {formatCurrency(props.data.value, intl)}
                </OptionWithFieldSource>
              </chakraComponents.Option>
            ) : (
              <chakraComponents.Option {...props}>
                <chakra.span>{props.data.value?.currency_code}</chakra.span>
                <Text
                  as="span"
                  fontSize="sm"
                  opacity={0.7}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  <FormattedDisplayName value={props.data.value?.currency_code ?? ""} type="currency" fallback="none" />
                </Text>
              </chakraComponents.Option>
            )
          },
        }}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        menuIsOpen={isOpen}
        isSearchable={false}
        menuPortalTarget={menuPortalTarget}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
          dropdownIndicator: (styles) => ({
            ...styles,
            cursor: "pointer",
          }),
        }}
        chakraStyles={{
          container: (styles) => ({
            ...styles,
            flexGrow: 0,
            flexShrink: 0,
          }),
          // This is a hack to make the menu the same width as the input field.
          // TODO: To improve this, we should override react-select's input with the currency input
          menu: (styles) => ({ ...styles, width: parentRef?.current?.clientWidth, right: 0 }),
          option: (styles, { data }) => {
            const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
            return {
              ...styles,
              display: "flex",
              ...(isNew && {
                backgroundColor: `${colorScheme}.50`,
              }),
            }
          },
          control: (styles) => ({
            ...styles,
            border: "none",
            _focus: {
              boxShadow: "none",
            },
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: 0,
            cursor: "pointer",
          }),
        }}
        options={[
          {
            options: suggestions || [],
          },
          {
            options: COMMON_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
          {
            options: MORE_CURRENCIES.map((currency) => ({
              value: { amount: value.amount, currency_code: currency },
              fieldSources: [],
            })),
          },
        ]}
        onChange={(v) => {
          if (v) {
            onChange(v)
          }
        }}
        isDisabled={disabled || isReadOnly}
        ref={(select) => {
          inputRef.current = select?.inputRef ?? null
        }}
      />
    </Box>
  )
})
